import { useEffect, useState } from "react";
import { RouterProvider } from "react-router-dom";
import { createBrowserHistory } from "history";
import ReactGA from "react-ga";
import router from "./router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { resetOrganisationStorage } from "./redux-features/slices/organisation/orgSlice";
import { resetAuth, signOutUser } from "./redux-features/slices/auth/authSlice";
import { setupTransformSocket, setupXeroDisconnectSocket } from "./socket-io/websockets";
import XeroReauthrizeModal from "./views/components/ui-elements/modals/apps/xero-reauthorize";
import { setIsLoadingOnDemandExtraction } from "./redux-features/slices/kpi/kpiSlice";

// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

// Create a browser history
const history = createBrowserHistory();

// Track pageview on route change
history.listen((location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

function App() {
  const dispatch = useDispatch();
  const [isIdle, setIsIdle] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const { organisation } = useSelector((state) => state.organisation);

  const handleSignOut = async () => {
    dispatch(signOutUser());
    await dispatch(resetAuth());
    await dispatch(resetOrganisationStorage());
    window.location.href = "/signin"; 
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const [transformSocket, setTransformSocket] = useState(null);
  const [xeroDisconnectSocket, setXeroDisconnectSocket] = useState(null);

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      const newTransformSocket = setupTransformSocket(
        () => {
          // WebSocket connection opened
        },
        (data) => {
          if (data.message) {
            if (organisation?.name && data.message.includes(organisation.name)) {
              toast.info(`New message: ${data.message}`, { autoClose: 5000 });
              setIsLoadingOnDemandExtraction()
            }
          }
        }
        
      );

      const newXeroDisconnectSocket = setupXeroDisconnectSocket(
        () => {
          // WebSocket connection opened
        },
        (data) => {
          if (data.message) {
            setModalMessage(data.message);
            setIsModalOpen(true);
          }
        },
        (error) => {
          toast.error(`WebSocket error: ${error.message}`, { autoClose: 5000 });
        }
      );

      setTransformSocket(newTransformSocket);
      setXeroDisconnectSocket(newXeroDisconnectSocket);

      return () => {
        if (newTransformSocket) {
          newTransformSocket.close();
        }
        if (newXeroDisconnectSocket) {
          newXeroDisconnectSocket.close();
        }
      };
    }
  }, [organisation?.name]); // Add organisation.name as a dependency

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <main className="App">
        <RouterProvider history={history} router={router} />
        {isModalOpen && (
          <XeroReauthrizeModal
            toggle={isModalOpen}
            toggleModal={toggleModal}
            message={modalMessage}
          />
        )}
      </main>
      <ToastContainer style={{ width: "550px" }} />
    </>
  );
}

export default App;

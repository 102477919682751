import React, { useEffect, useState } from 'react'
import { Form, Formik } from "formik";
import { IoIosCheckmarkCircle, IoIosCheckmarkCircleOutline } from 'react-icons/io';
import QRCode from '../../../assets/images/security/qr-code.png'
import {RxSwitch} from 'react-icons/rx'
import FormikControl from '../../components/form-elements/formik-control';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { createPhoneOTP, enableMFAAuth, resetMFA } from '../../../redux-features/slices/mfa/mfaAuthSlice';
import { toast } from "react-toastify";
import {BsArrowLeftShort} from 'react-icons/bs'
import { validatePhoneOTP } from '../../../redux-features/slices/mfa/mfaAuthSlice';
import Spinner from '../../components/ui-elements/utilis/spinner';
function SecurityMFAComponent() {
    const { user } = useSelector((state) => state.auth);
    const { enable2fa, isError, isSuccess, isSuccessEnable, isLoading, message, otp_response } =
      useSelector((state) => state.mfa);
    const dispatch=useDispatch()
    const [editPhoneVerification, setEditPhoneVerification] = useState(false);
    const [editEmailVerification, setEditEmailVerification] = useState(false);
    const [isPhoneOTPValid, setIsPhoneOTPValid] = useState(false);
    const token = user?.data?.api_token;
    const slug = user?.data?.id;
    const [isChecked, setIsChecked] = useState(false);
const [show2FAOptions, setShow2FAOptions] = useState(false);
    const toggleCheckbox = async () => {
      // First, toggle the isChecked state
      setIsChecked((prevState) => !prevState);
      // Now, you can access the updated isChecked state value
      const otp_data = { enabled_2fa: !isChecked };
      await dispatch(enableMFAAuth({ slug, otp_data, token }));
    };

  
 useEffect(() => {
   const fetchData = async () => {
     if (isError) {
       toast.error(message, {
         autoClose: 10000,
         toastId: "toast1",
         hideProgressBar: true,
       });
     }
     if (isSuccess) {
       toast.success("We have sent code to your phone number", {
         autoClose: 3000,
         hideProgressBar: true,
         toastId: "toast1",
         position: toast.POSITION.TOP_CENTER,
       });
       if (
       otp_response?.otp?.is_valid
       ){
setIsPhoneOTPValid(true)
       }
     }

     if (isSuccessEnable) {
       toast.success("2FA enabled", {
         autoClose: 3000,
         hideProgressBar: true,
         toastId: "toast1",
         position: toast.POSITION.TOP_CENTER,
       });
       show2FAOptions(true)
     }

   };

   fetchData();
 }, [
   isError,
   isSuccess,
   otp_response,
   dispatch,
   setIsChecked,
 ]);


     const initialPhoneValues = {
       email: "",
       user_phone_number: "",
     };

     const initialPhoneOTPCodeValues = {
       email: "",
       otp: "",
     };

     const onSubmitPhoneOTPCode = async (values, actions) => {
       const otp_data = {
         email: values.email,
         otp: values.otp,
       };
       if (otp_data) {
         dispatch(validatePhoneOTP({ otp_data, token }));
       }
     };

     const onSubmitPhone = async (values, actions) => {
       const otp_data = {
         email: values.email,
         user_phone_number: values.user_phone_number,
       };
       if (otp_data) {
         dispatch(createPhoneOTP({ otp_data, token }));
         await actions.resetForm(); // Reset the form
       }
     };

    const handleBackPhoneVerification=()=>{
      setEditPhoneVerification(!editPhoneVerification)
    }
    const handleBackEmailVerification = () => {
      setEditEmailVerification(!editEmailVerification);
    };
const handleBackPhoneOTPValidation=()=>{
  setIsPhoneOTPValid(!isPhoneOTPValid)
}
    if(isLoading){
      return (
        <div className="flex flex-col border-[1px] w-full p-4 h-2/5 border-slate-300">
          <Spinner></Spinner>
        </div>
      );
    }
    if (isPhoneOTPValid) {
      return (
        <div className="flex flex-col border-[1px] w-full p-4 h-2/5 border-slate-300">
          <p className="text-grey text-sm font-light hover:underline hover:cursor-pointer">
            <span
              className="flex flex-row items-center gap-2"
              onClick={handleBackPhoneOTPValidation}
            >
              <BsArrowLeftShort className="text-grey" size={20} />
              Back
            </span>
          </p>
          <div className="flex flex-row w-full justify-between mt-10">
            <p className="text-xl font-semibold">Write OTP Code</p>
          </div>
          <p className="text-sm font-regular">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
          <div className="flex flex-row gap-4 h-full w-full mt-6">
            <Formik
              initialValues={initialPhoneOTPCodeValues}
              onSubmit={onSubmitPhoneOTPCode}
            >
              {(formik) => (
                <Form className="w-full">
                  <div className="flex flex-row items-start gap-5">
                    <FormikControl
                      control="input"
                      type="text"
                      label="Enter Email Address"
                      name="email"
                      placeholder="Name"
                    />
                    <FormikControl
                      control="input"
                      type="text"
                      label="Enter OTP Code"
                      name="otp"
                      placeholder="Phone"
                    />
                  </div>
                  <div className="flex flex-col items-end justify-end w-full gap-6 mt-6">
                    <button
                      className="rounded-md px-5 py-2 bg-warning text-black font-semibold text-sm"
                      type="submit"
                    >
                      Confirm
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      );
    }

    if (editPhoneVerification){
      return (
        <>
          <div className=" flex flex-col border-[1px] w-full  p-4 h-2/5 border-slate-300">
            <p className="text-grey text-sm font-light hover:underline hover:cursor-pointer">
              <span
                className="flex flex-row items-center gap-2"
                onClick={handleBackPhoneVerification}
              >
                <BsArrowLeftShort className="text-grey" size={20} />
                Back
              </span>
            </p>
            <div className="flex flex-row w-full justify-between mt-5">
              <p className="text-xl font-semibold">Add Phone Number</p>
            </div>
            <p className="text-sm font-regular">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
            <div className="flex flex-row gap-4 h-full w-full  mt-6">
              <Formik
                initialValues={initialPhoneValues}
                onSubmit={onSubmitPhone}
              >
                {(formik) => (
                  <Form className=" w-full ">
                    <div className="flex flex-row items-start  gap-5 ">
                      <FormikControl
                        control="input"
                        type="text"
                        label="Enter Email Address"
                        name="email"
                        placeholder="Name"
                      />
                      <FormikControl
                        control="input"
                        type="text"
                        label="Enter Phone Number"
                        name="user_phone_number"
                        placeholder="Phone"
                      />
                    </div>
                    <div className="flex flex-col items-end justify-end w-full gap-6 mt-6">
                      <button
                        className="rounded-md px-5 py-2 bg-warning text-black font-semibold text-sm"
                        type="submit"
                      >
                        Confirm
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </>
      );
    }
    if (editEmailVerification) {
      return (
        <>
          <div className=" flex flex-col border-2 w-full  p-4 h-2/5 border-slate-300">
            <p className="text-grey text-sm font-light hover:underline hover:cursor-pointer">
              <span
                className="flex flex-row items-center gap-2"
                onClick={handleBackEmailVerification}
              >
                <BsArrowLeftShort className="text-grey" size={20} />
                Back
              </span>
            </p>
            <div className="flex flex-row w-full justify-between mt-5">
              <p className="text-xl font-semibold">Two factor authentication</p>
            </div>
            <p className="text-sm font-regular">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
            <div className="flex flex-row gap-4 h-full w-full mt-6">
              Add email
            </div>
          </div>
        </>
      );
    }
  return (
    <div className="w-full h-full flex  flex-col ">
      <div className=" flex flex-col border-2 w-full  p-4 h-2/5 border-slate-300">
        <div className="flex flex-row w-full justify-between">
          <p className="text-xl font-semibold">Two factor authentication</p>
          <div className="relative mt-4">
            <label className="inline-flex relative items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                readOnly
                checked={user?.data?.enabled_2fa}
              />
              <div
                className={`w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-green-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all 
                  peer-checked:bg-purple`}
                onClick={toggleCheckbox}
              ></div>
            </label>
          </div>
        </div>
        <p className="text-sm font-regular">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
        {user?.data?.enabled_2fa ? (
          <div className="flex flex-row gap-4 h-40  md:h-28 w-full mt-6">
            <div className="border-2 w-1/2 h-full border-purple rounded-sm hover:border-slate-400">
              <div className="w-full border-b-2 px-4 py-3 text-slate-600 font-medium border-b-light-grey">
                Email Verification
              </div>
              <div className="flex flex-col md:flex-row p-4 w-full justify-between">
                <div className="flex flex-row gap-3">
                  <IoIosCheckmarkCircle size={24} className="text-green-500" />
                  <p className="text-sm font-light">
                    Verified on{" "}
                    {moment(user?.data?.created_at).format("MMM D,YYYY")}
                  </p>
                </div>
                <div className="flex  flex-col md:flex-row gap-3">
                  <p className="text-sm font-regular">{user.data?.email}</p>
                  <p className="text-purple text-sm font-medium hover:underline hover:cursor-pointer">
                    <span onClick={handleBackEmailVerification}>Edit</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="border-2 w-1/2  h-40 md:h-28 border-purple rounded-sm">
              <div className="w-full border-b-2 px-4 py-3 text-slate-600 font-medium border-b-light-grey">
                Phone Verification
              </div>
              <div className="flex flex-row p-4 w-full justify-between">
                {/* <div className="flex flex-col md:flex-row gap-3">
                <IoIosCheckmarkCircle size={24} className="text-green-500" />
                <p className="text-sm font-light">Verified on Jan 3, 2023</p>
              </div> */}
                <div className="flex flex-row gap-3">
                  {/* <p className="text-sm font-regular">+44 234234 23423</p> */}
                  <p className="text-purple text-sm font-medium hover:underline hover:cursor-pointer">
                    <span onClick={handleBackPhoneVerification}>Add</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <p className="italic text-sm mt-6 text-grey ">
            Please enable two factor authentication first
          </p>
        )}
      </div>
      <div className="flex flex-row w-full gap-4 h-3/5  mt-4">
        <div className="border-2 w-full md:w-1/2 border-slate-300 p-4">
          <div className="flex flex-row w-full justify-between">
            <div className="flex flex-col gap-2">
              <p className="text-xl font-medium">Scan QR Code</p>
              <p className="text-sm font-light ">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
            <div>
              <div className="relative mt-4">
                <label className="inline-flex relative items-center cursor-pointer">
                  <input type="checkbox" className="sr-only peer" readOnly />
                  <div
                    className={`w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-green-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all 
                  peer-checked:bg-purple`}
                  ></div>
                </label>
              </div>
            </div>
          </div>
          <div className="flex flex-row w-full justify-between ">
            <div className="flex flex-col gap-5 mt-6  ">
              <button className="bg-light-purple py-2 text-purple ">
                13EWRJFSD WER324234DSF
              </button>
              <p className="text-[12px] font-light">
                If you are having problem with scanning, please enter the
                verification code below:
              </p>
              <Formik>
                {(formik) => (
                  <Form className=" flex flex-col items-start w-full gap-4 ">
                    <FormikControl
                      control="input"
                      type="text"
                      label="Enter Verification Code"
                      name="first_name"
                      placeholder="Name"
                    />
                    <button
                      className="rounded-md   bg-warning text-black font-regular text-sm px-4 py-2  "
                      type="submit"
                    >
                      Verify
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="w-1/2">
              <img src={QRCode} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecurityMFAComponent
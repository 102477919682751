import React, { useEffect, useState } from "react";
import FormikControl from "../../form-elements/formik-control";
import {  Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { IoIosClose } from "react-icons/io";
import { BiBuildings } from "react-icons/bi";
import {CgAddR} from 'react-icons/cg'
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getAllOrganisations,
  resetOrg,
} from "../../../../redux-features/slices/organisation/orgSlice";
import moment from "moment";
import { createConsolidateOrganisation } from "../../../../redux-features/slices/consolidation/consolidationSlice";
function ConsolidateOrganisationModal(props) {
  const { toggle, toggleModal, isEdit, token, organisations } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    isError,
    isSuccess,
    message,
  } = useSelector((state) => state.consolidatedOrganisation);
const initialValues = {
  name: "",
  email: "",
  organizations: [{ slug: "" }, { slug: "" }], // Initialize with empty slug
  financial_year: "",
};


const orgList = (organisations || []).map((org) => ({
  value: org.slug,
  key: org.name,
}));

  useEffect(() => {
    if (isError) {
      toast.error(message, {
        toastId: "toast2",
        autoClose: 10000,
        hideProgressBar: true,
        position: toast.POSITION.TOP_CENTER,
      });
    }
    if (isSuccess) {
      dispatch(getAllOrganisations({ token }));
      dispatch(resetOrg());
    }
   
  }, [isError, isSuccess, message, dispatch, navigate]);
const addOrganizationField = (formik) => {
  if (formik.values.organizations.length < organisations?.length) {
    formik.setFieldValue("organizations", [
      ...formik.values.organizations,
      { slug: "" },
    ]);
  }
};
// const onSubmit = async (values, actions) => {
//   const orgSlugs = values.organizations.map((org) => org.slug);
//   if (new Set(orgSlugs).size !== orgSlugs.length) {
//     actions.setFieldError(
//       "organizations",
//       "Selected organizations must be unique."
//     );
//     return;
//   }

//   const orgData = {
//     name: values.name,
//     email: values.email,
//     organizations: values.organizations.map((org) => ({ slug: org.slug })),
//     financial_year: moment(values.financial_year, "YYYY").format("YYYY-MM-DD"),
//   };

//   console.log(orgData);

//   // Rest of your code...
// };
const onSubmit = async (values, actions) => {
  const orgSlugs = values.organizations.map((org) => org.slug);

  // Proceed with submitting the form
  const org_data = {
    name: values.name,
    email: values.email,
    organizations: values.organizations.map((org) => ({ slug: org.slug })),
    financial_year: moment(values.financial_year, "YYYY").format("YYYY-MM-DD"),
  };

  if (new Set(orgSlugs).size !== orgSlugs.length) {
    return;
  }
  dispatch(createConsolidateOrganisation({ org_data, token })); 
  toggleModal();
};
  const handleClose = () => {
    toggleModal();
  };
  return (
    <div>
      {toggle && (
        <div className="fixed z-30 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center  bg-black bg-opacity-50   min-h-screen p-4">
            <div className="bg-white rounded-lg w-2/5 p-8">
              <div className="flex flex-row justify-between item-start">
                <div className="flex gap-2 ">
                  <BiBuildings size={42} />
                  <div className="mt-2">
                    <h2 className="text-lg font-bold ">
                      Consolidate Organisations
                    </h2>
                  </div>
                </div>
                <IoIosClose
                  onClick={handleClose}
                  className="cursor-pointer"
                  size={35}
                />
              </div>
              <div className="w-full">
                <Formik initialValues={initialValues} onSubmit={onSubmit}>
                  {(formik) => (
                    <Form className="py-4 ">
                      <div className="flex flex-col items-start  mt-4 w-full ">
                        <FormikControl
                          control="input"
                          type="text"
                          label="Enter Organisation Name"
                          name="name"
                          placeholder="Name"
                        />
                        <div className="mt-4 w-full">
                          <FormikControl
                            control="input"
                            type="text"
                            label="Enter Email of Organisation"
                            name="email"
                            placeholder="Email"
                          />
                        </div>
                        {formik.values.organizations.map((org, index) => (
                          <div
                            className="mt-4 w-full flex flex-col"
                            key={index}
                          >
                            <FormikControl
                              control="select"
                              options={orgList}
                              name={`organizations[${index}].slug`}
                              label={`Select Organization ${index + 1}`}
                              placeholder="Organization"
                            />
                            {formik.values.organizations
                              .filter((_, i) => i !== index) // Filter out the current organization
                              .some((otherOrg) => otherOrg.slug === org.slug) &&
                              org.slug && (
                                <div className="text-rose-600 text-xs">
                                  Selected organizations must be unique.
                                </div>
                              )}
                          </div>
                        ))}
                        {formik.values.organizations.length <
                          organisations.length && (
                          <button
                            type="button"
                            className=" flex flex-row justify-center hover:underline items-center gap-1 self-end rounded-sm font-regular  text-purple p-2 text-[13px]"
                            onClick={() => addOrganizationField(formik)}
                          >
                            <CgAddR size={18} />
                            Add one more
                          </button>
                        )}
                        <div className="mt-4 w-full">
                          <FormikControl
                            control="month"
                            formType="New Org"
                            type="text"
                            name="financial_year"
                            label="Enter Financial Year"
                            placeholder="FY"
                          />
                        </div>
                      </div>
                      <div className="flex flex-col items-end justify-end w-full gap-6 mt-6">
                        <button
                          className="rounded-md md:w-1/5 sm:w-2/5 w-3/5 bg-warning text-black font-bold p-3"
                          type="submit"
                        >
                          Confirm
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ConsolidateOrganisationModal;
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getUserProfile } from "../../../redux-features/slices/auth/authSlice";
import { Link, useLocation, useNavigate } from "react-router-dom";
import xero from "../../../assets/logos/xero_logo.png";
import SpinnerKPI from "../../components/ui-elements/utilis/spinner-kpi";

function XeroSuccessPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const slug = queryParams.get('slug');

    if (slug && token) {
      localStorage.clear();
      dispatch(getUserProfile({ slug, token })).finally(() => {
        const storedUser = localStorage.getItem('user');
        setUser(storedUser);
        setIsLoading(false); // Stop loading after attempting to get the user profile
      });
    } else {
      setIsLoading(false); // Stop loading if no slug or token is found
    }
  }, [location.search, dispatch]);

  return (
    <div className="h-screen w-full flex flex-col justify-center items-center bg-light-white">
      <div className="flex flex-col gap-4 items-center text-center">
        <div className="flex flex-col gap-2 items-center mt-8">
          <img src={xero} alt="Xero Logo" className="w-36 h-36" />
          <h2 className="text-2xl font-bold">Success! Your Xero connection is initiated.</h2>
          <h5 className="text-default">
            We are processing your Xero Data. Click the button below to return to the Qurk Dashboard.
          </h5>
        </div>
        <div className="mt-12">
          {isLoading ? (
            <SpinnerKPI />
          ) : user ? (
            <Link to="/owner/organisation/single">
              <button className="rounded-md bg-light-purple font-bold text-sm w-full text-dark-purple py-3 px-6">
                Go to Dashboard
              </button>
            </Link>
          ) : (
            <div className="text-red-400 text-sm">Oops! Please try again later.</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default XeroSuccessPage;

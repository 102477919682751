import { useEffect, useRef, useState } from "react";

const FilterDropdown = ({ selectedFilter, onFilterChange }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const options = [
        { label: "This Week", value: "This Week" },
        { label: "Last Week", value: "Last Week" },
        { label: "This Month", value: "This Month" },
        { label: "Last Month", value: "Last Month" },
        { label: "This Quarter", value: "This Quarter" },
        { label: "Last Quarter", value: "Last Quarter" },
        { label: "This Year", value: "This Year" },
        { label: "Last Year", value: "Last Year" },
        { label: "Last 365 Days", value: "Last 365 Days" },
      
      ];
    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);
  
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
  
    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);
  
    return (
      <div className="relative text-left z-30">
        <button
          type="button"
          onClick={toggleDropdown}
          className="inline-flex items-center justify-between text-[12px] font-regular w-40 rounded-md border border-gray-300 bg-white px-3 h-8 text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          {selectedFilter || "All"}
          <svg
            className={`-mr-1 ml-2 h-5 w-5 text-gray-400 ${
              isDropdownOpen ? "transform rotate-180" : ""
            }`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        {isDropdownOpen && (
          <div
          ref={dropdownRef}
          className="absolute right-0 z-50 mt-2 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-y-auto " // Adjust the max-h-* class here
        >
            <div
              className="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              {options.map((option) => (
                <label
                  key={option.value}
                  className="block px-3 py-1 text-[12px] text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                >
                  <input
                    type="radio"
                    className="form-radio text-purple h-4 w-4 mr-2"
                    name="dateFilter"
                    value={option.value}
                    checked={selectedFilter === option.value}
                    onChange={() => onFilterChange(option.value)}
                  />
                  {option.label}
                </label>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

export default FilterDropdown
import { compose, configureStore } from "@reduxjs/toolkit";
import authReducer from "../slices/auth/authSlice";
import orgReducer from '../slices/organisation/orgSlice'
import integrationReducer from '../slices/integrations/integrationSlice'
import lookerReducer from '../slices/looker/lookerSlice'
import subscriptionReducer from '../slices/subscription/subscriptionSlice'
import consolidationReducer from "../slices/consolidation/consolidationSlice";
import mfaAuthReducer from '../slices/mfa/mfaAuthSlice'
import kpiReducer from "../slices/kpi/kpiSlice";
import dearKpiReducer from "../slices/kpi/dearSlice";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = configureStore({
  reducer: {
    auth: authReducer,
    organisation: orgReducer,
    integration: integrationReducer,
    looker: lookerReducer,
    subscription: subscriptionReducer,
    consolidatedOrganisation: consolidationReducer,
    mfa: mfaAuthReducer,
    kpi: kpiReducer,
    // dear: dearKpiReducer,
  },
}, composeEnhancers);


export const ENV_URL = `https://www.qurk.uk`;
export const LOCAL_URL = `https://localhost:8000`;
// For authentication 
export const REGISTER_OWNER_API_URL = "/api/v1/accounts/register/business-owner/";
export const REGISTER_WITH_XERO = "/api/v1/accounts/xero-auth-url/";
export const REGISTER_ADVISOR_API_URL = "/api/v1/accounts/register/business-advisor/";
export const LOGIN_USER = "/api/v1/accounts/login/";
export const PASSWORD_REST = "/api/v1/accounts/request/password/reset/";
export const PASSWORD_REST_CONFIRM ="/api/v1/accounts/password-reset/";
export const GET_USER_PROFILE ="/api/v1/accounts/user/";
// For Organisations 
export const LIST_ORGANISATION = "/api/v1/dashboard/connect/organization/list/";
export const CREATE_ORGANISATION = "/api/v1/dashboard/connect/organization/create/";
export const SEND_STAFF_INVITATION = "/api/v1/accounts/invite/";
export const REMOVE_STAFF = "/api/v1/accounts/invite/";
export const SELECT_ORGANISATION = "/api/v1/dashboard/connect/organization/";
// App Integrations 
export const CONNECT_XERO = "/api/v1/dashboard/connect/oauth2/xero-authorize/";
export const DISCONNECT_XERO =
  "/api/v1/dashboard/connect/organization/";
export const CONNECT_CIN7 = "/api/v1/dashboard/connect/cin7/cin7-authorize/";
export const CONNECT_DEAR = "/api/v1/dashboard/connect/dear/dear-authorize/";
export const UPDATE_APP = "/api/v1/atomic/update-trx/";
// Looker Embedment
export const EMBED_LOOKER = "/api/v1/dashboard/connect/looker-embed/";
// Subscription Payments
export const LIST_SUBSCRIPTION_PLANS = "/api/v1/payments/plans/";
export const CREATE_SUBSCRIPTION = "/api/v1/payments/subscription/";
export const PAY_SUBSCRIPTION = "/api/v1/payments/";
export const CANCEL_SUBSCRIPTION = "/api/v1/payments/subscription/";
export const GET_CUSTOMER_SUBSCRIPTION = "/api/v1/payments/subscription/customers/";
// Consolidate Organisations
export const CREATE_CONSOLIDATED_ORGANISATION =
  "/api/v1/dashboard/connect/organization/create/consolidated/";
export const LIST_CONSOLIDATED_ORGANISATION =
  "/api/v1/dashboard/connect/organization/list/consolidated/";
// Multi-factor Authentication
export const CREATE_PHONE_OTP = "/api/v1/mfauth/createotp/";
export const VALIDATE_PHONE_OTP = "/api/v1/mfauth/checkotp/";
export const ENABLE_MFA = "/api/v1/mfauth/enable/";
// KPI Endpoints
export const GET_PROFIT_LOSS = "/api/v1/kpis/get-profit-loss-kpi-data/";
export const GET_DATASET_ID = "/api/v1/kpis/get-datasetId/";

export const GET_TOTAL_INCOME_TRACKING_CATEGORY_ONE =
  "/api/v1/kpis/total-income-tracking-category-one/";
export const GET_LINE_GRAPHS="/api/v1/kpis/line-graph-table-kpi/"
export const GET_TRACKING_CATEGORY_01="/api/v1/kpis/tracking-category-line-graph-table1-kpi/"
export const GET_TRACKING_CATEGORY_02="/api/v1/kpis/tracking-category-line-graph-table2-kpi/"
export const GET_ALL_XERO_KPIS="/api/v1/kpis/kpi-figure/"
export const GET_XERO_BALANCE_SHEET="/api/v1/kpis/balance-sheet/"
export const GET_XERO_PROFIT_AND_LOSS="/api/v1/kpis/profit-loss/"
export const GET_XERO_BANK_TABLE="/api/v1/kpis/bank-table/"

// Dear KPIs
export const GET_DEAR_BRAND_FILTERS="/api/v1/kpis/get-dear-brand-filters/"
export const GET_DEAR_CATEGORY_FILTERS="/api/v1/kpis/get-dear-category-filters/"
export const GET_DEAR_CONTACT_FILTERS="/api/v1/kpis/get-dear-contact-filters/"
export const GET_DEAR_CUSTOMER_TAGS_FILTERS="/api/v1/kpis/get-dear-customer-tags-filters/"
export const GET_DEAR_PRODUCT_FILTERS="/api/v1/kpis/get-dear-product-filters/"
export const GET_DEAR_PRODUCT_TAGS_FILTERS="/api/v1/kpis/get-dear-product-tags-filters/"
export const GET_DEAR_PRODUCT_TYPE_FILTERS="/api/v1/kpis/get-dear-product-type-filters/"
export const GET_DEAR_LOCATION_FILTERS="/api/v1/kpis/get-dear-location-filters/"
// Product Sales KPIs
export const GET_DEAR_PRODUCT_SALES="/api/v1/kpis/get-dear-product-sales/"
export const GET_DEAR_PRODUCT_SALES_LINE_GRAPH="/api/v1/kpis/get-dear-product-sales-line-graph/"
export const GET_DEAR_PRODUCT_SALES_BY_BRAND="/api/v1/kpis/get-dear-sales-by-brand/"
export const GET_DEAR_PRODUCT_SALES_BY_CATEGORY="/api/v1/kpis/get-dear-sales-by-category/"
export const GET_DEAR_PRODUCT_SALES_BY_CUSTOMER="/api/v1/kpis/get-dear-sales-by-customer/"
export const GET_DEAR_PRODUCT_SALES_BY_CUSTOMER_TAG="/api/v1/kpis/get-dear-sales-by-customer-tag/"
export const GET_DEAR_PRODUCT_SALES_BY_PRODUCT="/api/v1/kpis/get-dear-sales-by-product/"
export const GET_DEAR_PRODUCT_SALES_BY_PRODUCT_TAG="/api/v1/kpis/get-dear-sales-by-product-tag/"
export const GET_DEAR_PRODUCT_SALES_BY_PRODUCT_TYPE="/api/v1/kpis/get-dear-sales-by-product-type/"
// Profit KPIs
export const GET_DEAR_PROFIT="/api/v1/kpis/get-dear-profit/"
export const GET_DEAR_PROFIT_LINE_GRAPH="/api/v1/kpis/get-dear-profit-line-graph/"
export const GET_DEAR_PROFIT_BY_BRAND="/api/v1/kpis/get-dear-profit-by-brand/"
export const GET_DEAR_PROFIT_BY_CATEGORY="/api/v1/kpis/get-dear-profit-by-category/"
export const GET_DEAR_PROFIT_BY_CUSTOMER="/api/v1/kpis/get-dear-profit-by-customer/"
export const GET_DEAR_PROFIT_BY_CUSTOMER_TAG="/api/v1/kpis/get-dear-profit-by-customer-tag/"
export const GET_DEAR_PROFIT_BY_PRODUCT="/api/v1/kpis/get-dear-profit-by-product/"
export const GET_DEAR_PROFIT_BY_PRODUCT_TAG="/api/v1/kpis/get-dear-profit-by-product-tag/"
export const GET_DEAR_PROFIT_BY_PRODUCT_TYPE="/api/v1/kpis/get-dear-profit-by-product-type/"
// Units Sold KPIs

export const GET_DEAR_UNITS_SOLD="/api/v1/kpis/get-dear-units-sold/"
export const GET_DEAR_UNITS_SOLD_LINE_GRAPH="/api/v1/kpis/get-dear-units-sold-line-graph/"
export const GET_DEAR_UNITS_SOLD_BY_BRAND="/api/v1/kpis/get-dear-units-sold-by-brand/"
export const GET_DEAR_UNITS_SOLD_BY_CATEGORY="/api/v1/kpis/get-dear-units-sold-by-category/"
export const GET_DEAR_UNITS_SOLD_BY_CUSTOMER="/api/v1/kpis/get-dear-units-sold-by-customer/"
export const GET_DEAR_UNITS_SOLD_BY_CUSTOMER_TAG="/api/v1/kpis/get-dear-units-sold-by-customer-tag/"
export const GET_DEAR_UNITS_SOLD_BY_PRODUCT="/api/v1/kpis/get-dear-units-sold-by-product/"
export const GET_DEAR_UNITS_SOLD_BY_PRODUCT_TAG="/api/v1/kpis/get-dear-units-sold-by-product-tag/"
export const GET_DEAR_UNITS_SOLD_BY_PRODUCT_TYPE="/api/v1/kpis/get-dear-units-sold-by-product-type/"
// Units Shipped KPIs
export const GET_UNITS_SHIPPED="/api/v1/kpis/get-units-shipped/"
export const GET_UNITS_SHIPPED_LINE_GRAPH="/api/v1/kpis/get-units-shipped-line-graph/"
export const GET_UNITS_SHIPPED_BY_BRAND="/api/v1/kpis/get-units-shipped-by-brand/"
export const GET_UNITS_SHIPPED_BY_CATEGORY="/api/v1/kpis/get-units-shipped-by-category/"
export const GET_UNITS_SHIPPED_BY_CUSTOMER="/api/v1/kpis/get-units-shipped-by-customer/"
export const GET_UNITS_SHIPPED_BY_CUSTOMER_TAG="/api/v1/kpis/get-units-shipped-by-customer-tag/"
export const GET_UNITS_SHIPPED_BY_PRODUCT="/api/v1/kpis/get-units-shipped-by-product/"
export const GET_UNITS_SHIPPED_BY_PRODUCT_TAG="/api/v1/kpis/get-units-shipped-by-product-tag/"
export const GET_UNITS_SHIPPED_BY_PRODUCT_TYPE="/api/v1/kpis/get-units-shipped-by-product-type/"


export const GET_LAST_UPDATED_DEAR="/api/v1/kpis/get-last-updated/"
export const GET_LAST_UPDATED_XERO="/api/v1/kpis/last-updated/"
export const GET_PERCENTAGE_CUSTOMER_80_SALES="/api/v1/kpis/get-percentage-customer-80-sales/"
export const GET_PERCENTAGE_ORDER_SHIPPED_LATE="/api/v1/kpis/get-percentage-orders-shipped-late/"
export const GET_PERCENTAGE_PRODUCT_80_SALES="/api/v1/kpis/get-percentage-products-80-sales/"
// Dear Drilldown 1
export const GET_PROFIT_DRILLDOWN_CUSTOMER="/api/v1/kpis/get-profit-drilldown-customer/"
export const GET_PROFIT_DRILLDOWN_PRODUCT="/api/v1/kpis/get-profit-drilldown-product/"
export const GET_SALES_DRILLDOWN_PRODUCT="/api/v1/kpis/get-sales-drilldown-product/"
export const GET_SALES_DRILLDOWN_CUSTOMER="/api/v1/kpis/get-sales-drilldown-customer/"
export const GET_UNITS_SOLD_DRILLDOWN_PRODUCT="/api/v1/kpis/get-units-sold-drilldown-product/"
export const GET_UNITS_SOLD_DRILLDOWN_CUSTOMER="/api/v1/kpis/get-units-sold-drilldown-customer/"
export const GET_UNITS_SHIPPED_DRILLDOWN_PRODUCT="/api/v1/kpis/get-units-shipped-drilldown-product/"
export const GET_UNITS_SHIPPED_DRILLDOWN_CUSTOMER="/api/v1/kpis/get-units-shipped-drilldown-customer/"
// Dear Drilldown 2
export const GET_PROFIT_DRILLDOWN_CUSTOMER_2 = "/api/v1/kpis/get-profits-drilldown-customer2/";
export const GET_PROFIT_DRILLDOWN_PRODUCT_2 = "/api/v1/kpis/get-profits-drilldown-product2/";
export const GET_SALES_DRILLDOWN_PRODUCT_2 = "/api/v1/kpis/get-sales-drilldown-product2/";
export const GET_SALES_DRILLDOWN_CUSTOMER_2 = "/api/v1/kpis/get-sales-drilldown-customer2/";
export const GET_UNITS_SOLD_DRILLDOWN_PRODUCT_2 = "/api/v1/kpis/get-units-sold-drilldown-product2/";
export const GET_UNITS_SOLD_DRILLDOWN_CUSTOMER_2 = "/api/v1/kpis/get-units-sold-drilldown-customer2/";
export const GET_UNITS_SHIPPED_DRILLDOWN_PRODUCT_2 = "/api/v1/kpis/get-units-shipped-drilldown-product2/";
export const GET_UNITS_SHIPPED_DRILLDOWN_CUSTOMER_2 = "/api/v1/kpis/get-units-shipped-drilldown-customer2/";

// On Demand Dear KPIs 
export const GET_ON_DEMAND_EXTRACTION = "/api/v1/dashboard/connect/start-extraction/run-job/";

export const GET_CUSTOMERS_WITHOUT_TAGS='/api/v1/kpis/get-customers-without-tags/'
export const GET_TOP_LATE_SUPPLIERS='/api/v1/kpis/get-late-suppliers/'
export const GET_TOP_TEN_SUPPLIERS_BY_COST='/api/v1/kpis/get-top-10-suppliers-by-cost/'
export const GET_TOP_TEN_SUPPLIERS_BY_VOLUME='/api/v1/kpis/get-top-10-suppliers-by-volume/'
export const GET_DEAR_GROSS_PROFIT_MARGIN='/api/v1/kpis/get-gross-profit-margin/'
export const GET_DEAR_INVENTORY_VALUE='/api/v1/kpis/get-add-inventory-value/'

export const GET_DEAR_NO_SALES_ORDERS_BY_CARRIERS='/api/v1/kpis/get-sales-orders-by-carriers/'
export const GET_DEAR_COST_OF_GOODS_SOLD='/api/v1/kpis/get-cost-of-goods-sold/'
export const GET_SAMPLE_CONVERSION_RATE='/api/v1/kpis/get-sample-conversion-rate/'


export const APPLY_XERO_KPI_FILTERS="/api/v1/kpis/organization/apply-kpi-filter/"


export const GET_LIST_ORGANISATION_KPIS = "/api/v1/kpis/organization_kpis/";
export const ADD_KPI_FAVORITE = "/api/v1/kpis/organization_kpi/";


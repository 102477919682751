import React, { useEffect, useRef, useState } from "react";
import { BiCommentDetail } from "react-icons/bi";
import { BsArrowDownCircleFill, BsFillArrowUpCircleFill } from "react-icons/bs";
import { AiOutlineHeart } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import SpinnerKPI from "../utilis/spinner-kpi";
import SimpleSparkline from "../../graphs/recharts/spark-line";
import xero from "../../../../assets/logos/xero_logo.png";
import Tippy from "@tippyjs/react";
import { IoIosInformationCircle } from "react-icons/io";
import countryToSymbolMap from "../../../../constant/country-code-currency.json";
import { BsFillFilterSquareFill } from "react-icons/bs";
import { saveXeroKPIFilters, sendXeroKPIFilters } from "../../../../redux-features/slices/kpi/kpiSlice";
import { selectOrganisation } from "../../../../redux-features/slices/organisation/orgSlice";
const BoxCard01 = (props) => {
  const { isLoadingKPI } = useSelector((state) => state.kpi);
  const {
    title,
    customSymbol,
    isCustomSymbol,
    data,
    toggleKPISheet,
    sparkLine01Data,
    sparkLine02Data,
    savedFilterA,
    savedFilterB
  } = props;
  const dispatch = useDispatch();
  const [togglePaySubscription, setPaySubscriptionToggle] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { organisation } = useSelector((state) => state.organisation);
  const slug = organisation?.slug
  const token = user?.data?.api_token;
  const user_id = user?.data?.id;
  const saved_filters = organisation?.saved_filters

  // Filters
  // Filters
  const [filterA, setFilterA] = useState(savedFilterA || "Last Month");
  const [filterB, setFilterB] = useState(savedFilterB || "Previous Period");
  const [filteredText, setFilteredText] = useState('Select Filters');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    handleGoButtonClick();
  }, []);
  const handleFilterChange = (filter, value) => {
    if (filter === "filterA") {
      setFilterA(value);
    } else if (filter === "filterB") {
      setFilterB(value);
    }
  };

  useEffect(() => {
    setSelectedValue("Previous Period");
    // setFilteredText(formatDate(lastMonthStartDate));
    setSelectedCurrentPeriodValue(data?.last_month);
    setSelectedPastPeriodValue(data?.last_month_last_month);
    setSelectedChangePeriodValue(data?.last_month_last_month_change);
  }, [data]);

  const handleFavorite = () => {
    // dispatch(addKPIFavorite({ org_id, kpiFavData, token }));
  };
  function formatDate(date) {
    const options = { month: "short", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }

  // Get the current date
  const currentDate = new Date();

  // Calculate dates for this month
  const thisMonthStartDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const thisMonthEndDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );

  // Calculate dates for last month
  const lastMonthStartDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 1,
    1
  );
  const lastMonthEndDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    0
  );

  // Calculate dates for this quarter
  const currentQuarter = Math.floor(currentDate.getMonth() / 3);
  const thisQuarterStartDate = new Date(
    currentDate.getFullYear(),
    currentQuarter * 3,
    1
  );
  const thisQuarterEndDate = new Date(
    currentDate.getFullYear(),
    (currentQuarter + 1) * 3,
    0
  );

  // Calculate dates for last quarter
  const lastQuarterStartDate = new Date(
    currentDate.getFullYear(),
    (currentQuarter - 1) * 3,
    1
  );
  const lastQuarterEndDate = new Date(
    currentDate.getFullYear(),
    currentQuarter * 3,
    0
  );

  // Calculate dates for Year to Date
  const thisYearStartDate = new Date(currentDate.getFullYear(), 0, 1);
  const thisYearEndDate = new Date(currentDate.getFullYear(), 11, 31);

  // Calculate dates for last year
  const lastYearStartDate = new Date(currentDate.getFullYear() - 1, 0, 1);
  const lastYearEndDate = new Date(currentDate.getFullYear() - 1, 11, 31);

  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedCurrentPeriodValue, setSelectedCurrentPeriodValue] = useState(0);
  const [selectedPastPeriodValue, setSelectedPastPeriodValue] = useState(0);
  const [selectedChangePeriodValue, setSelectedChangePeriodValue] = useState(0);
  const [isDropdownFilterOpen, setIsDropdownFilterOpen] = useState(false);

  useEffect(() => {
    const valueKey = `${filterA}_${filterB}`;

    switch (valueKey) {
      case "This Month_Previous Period":
        setSelectedChangePeriodValue(data?.this_month_last_month_change);
        setSelectedCurrentPeriodValue(data?.this_month)
        setSelectedPastPeriodValue(data?.last_month)
        setFilteredText(formatDate(thisMonthStartDate))
        break;
      case "Last Month_Previous Period":
        setSelectedChangePeriodValue(data?.last_month_last_month_change);
        setSelectedCurrentPeriodValue(data?.last_month)
        setSelectedPastPeriodValue(data?.last_month_last_month)
        setFilteredText(formatDate(lastMonthStartDate))
        break;
      case "This Quarter_Previous Period":
        setSelectedChangePeriodValue(data?.this_quarter_last_quarter_change);
        setSelectedCurrentPeriodValue(data?.this_quarter)
        setSelectedPastPeriodValue(data?.last_quarter)
        setFilteredText(formatDate(thisQuarterStartDate) +
          "-" +
          formatDate(thisQuarterEndDate))
        break;
      case "Last Quarter_Previous Period":
        setSelectedChangePeriodValue(data?.last_quarter_last_quarter_change);
        setSelectedCurrentPeriodValue(data?.last_quarter)
        setSelectedPastPeriodValue(data?.last_quarter_last_quarter)
        setFilteredText(formatDate(lastQuarterStartDate) +
        "-" +
        formatDate(lastQuarterEndDate))
        break;
      case "Year to Date_Previous Period":
        setSelectedChangePeriodValue(data?.this_year_last_year_change);
        setSelectedCurrentPeriodValue(data?.this_year)
        setSelectedPastPeriodValue(data?.last_year)
        setFilteredText(formatDate(thisYearStartDate) + "-" + formatDate(thisYearEndDate))
        break;
      case "Last Year_Previous Period":
        setSelectedChangePeriodValue(data?.last_year_last_year_change);
        setSelectedCurrentPeriodValue(data?.last_year)
        setSelectedPastPeriodValue(data?.last_year_last_year)
        setFilteredText(formatDate(lastYearStartDate) + "-" + formatDate(lastYearEndDate))
        break;
      case "This Month_Previous Year":
        setSelectedChangePeriodValue(data?.this_month_last_year_change);
        setSelectedCurrentPeriodValue(data?.this_month)
        setSelectedPastPeriodValue(data?.this_month_last_year)
        setFilteredText(formatDate(thisMonthStartDate) + " - " + formatDate(thisMonthEndDate))
        break;
      case "Last Month_Previous Year":
        setSelectedChangePeriodValue(data?.last_month_last_year_change);
        setSelectedCurrentPeriodValue(data?.last_month)
        setSelectedPastPeriodValue(data?.last_month_last_year)
        setFilteredText(formatDate(lastMonthStartDate) + " - " + formatDate(lastMonthEndDate))
        break;
      case "This Quarter_Previous Year":
        setSelectedChangePeriodValue(data?.this_quarter_last_year_change);
        setSelectedCurrentPeriodValue(data?.this_quarter)
        setSelectedPastPeriodValue(data?.this_quarter_last_year)
        setFilteredText(formatDate(thisQuarterStartDate) +
        "-" +
        formatDate(thisQuarterEndDate))
        break;
      case "Last Quarter_Previous Year":
        setSelectedChangePeriodValue(data?.last_quarter_last_year_change);
        setSelectedCurrentPeriodValue(data?.last_quarter)
        setSelectedPastPeriodValue(data?.last_quarter_last_year)
        setFilteredText(formatDate(lastQuarterStartDate) +
        "-" +
        formatDate(lastQuarterEndDate))
        break;
      case "Year to Date_Previous Year":
        setSelectedChangePeriodValue(data?.this_year_last_year_change);
        setSelectedCurrentPeriodValue(data?.this_year)
        setSelectedPastPeriodValue(data?.last_year)
        setFilteredText(formatDate(thisYearStartDate) + "-" + formatDate(thisYearEndDate))
        break;
      case "Last Year_Previous Year":
        setSelectedChangePeriodValue(data?.last_year_last_year_change);
        setSelectedCurrentPeriodValue(data?.last_year)
        setSelectedPastPeriodValue(data?.last_year_last_year)
        setFilteredText(formatDate(lastYearStartDate) + "-" + formatDate(lastYearEndDate))
        break;
      default:
        setFilteredText(formatDate(thisMonthStartDate) + " - " + formatDate(thisMonthEndDate))
        setSelectedChangePeriodValue(data?.this_month_last_month_change);
        setSelectedCurrentPeriodValue(data?.this_month)
        setSelectedPastPeriodValue(data?.last_month)
    }
  }, [data]);

  // console.log('Outside', data)

  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!dropdownRef?.current?.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [dropdownRef]);


  const countryCode =
    organisation && organisation.country_alpha3
      ? organisation.country_alpha3.replace(/"/g, "")
      : null;
  const currencySymbol = countryCode ? countryToSymbolMap[countryCode] : null;

  const handleGoButtonClick = async () => {
    let text = "";
    let selectedValue = "";
    let selectedCurrentPeriodValue = data?.this_month;
    let selectedPastPeriodValue = data?.last_month;
    let selectedChangePeriodValue = data?.this_month_last_month_change;
    switch (`${filterA}_${filterB}`) {
      case "This Month_Previous Period":
        // Print the results
        text = formatDate(thisMonthStartDate);
        selectedValue = "Previous Period";
        selectedCurrentPeriodValue = data?.this_month;
        selectedPastPeriodValue = data?.last_month;
        selectedChangePeriodValue = data?.this_month_last_month_change;
        break;
      case "Last Month_Previous Period":
        text = formatDate(lastMonthStartDate);
        selectedValue = "Previous Period";
        selectedCurrentPeriodValue = data?.last_month;
        selectedPastPeriodValue = data?.last_month_last_month;
        selectedChangePeriodValue = data?.last_month_last_month_change;
        break;
      case "This Quarter_Previous Period":
        text =
          formatDate(thisQuarterStartDate) +
          "-" +
          formatDate(thisQuarterEndDate);
        selectedValue = "Previous Period";
        selectedCurrentPeriodValue = data?.this_quarter;
        selectedPastPeriodValue = data?.last_quarter;
        selectedChangePeriodValue = data?.this_quarter_last_quarter_change;
        break;
      case "Last Quarter_Previous Period":
        text =
          formatDate(lastQuarterStartDate) +
          "-" +
          formatDate(lastQuarterEndDate);
        selectedValue = "Previous Period";
        selectedCurrentPeriodValue = data?.last_quarter;
        selectedPastPeriodValue = data?.last_quarter_last_quarter;
        selectedChangePeriodValue = data?.last_quarter_last_quarter_change;
        break;
      case "Year to Date_Previous Period":
        text =
          formatDate(thisYearStartDate) + "-" + formatDate(thisYearEndDate);
        selectedValue = "Previous Period";
        selectedCurrentPeriodValue = data?.this_year;
        selectedPastPeriodValue = data?.last_year;
        selectedChangePeriodValue = data?.this_year_last_year_change;
        break;
      case "Last Year_Previous Period":
        selectedValue = "Previous Period";
        text =
          formatDate(lastYearStartDate) + "-" + formatDate(lastYearEndDate);
        selectedCurrentPeriodValue = data?.last_year;
        selectedPastPeriodValue = data?.last_year_last_year;
        selectedChangePeriodValue = data?.last_year_last_year_change;
        break;
      case "This Month_Previous Year":
        setFilteredText(
          formatDate(thisMonthStartDate) + " - " + formatDate(thisMonthEndDate)
        );
        selectedValue = "Previous Year";
        selectedCurrentPeriodValue = data?.this_month;
        selectedPastPeriodValue = data?.this_month_last_year;
        selectedChangePeriodValue = data?.this_month_last_year_change;
        break;
      case "Last Month_Previous Year":
        text =
          formatDate(lastMonthStartDate) + " - " + formatDate(lastMonthEndDate);
        selectedValue = "Previous Year";
        selectedCurrentPeriodValue = data?.last_month;
        selectedPastPeriodValue = data?.last_month_last_year;
        selectedChangePeriodValue = data?.last_month_last_year_change;
        break;
      case "This Quarter_Previous Year":
        text =
          formatDate(thisQuarterStartDate) +
          "-" +
          formatDate(thisQuarterEndDate);
        selectedValue = "Previous Year";
        selectedCurrentPeriodValue = data?.this_quarter;
        selectedPastPeriodValue = data?.this_quarter_last_year;
        selectedChangePeriodValue = data?.this_quarter_last_year_change;
        break;
      case "Last Quarter_Previous Year":
        text =
          formatDate(lastQuarterStartDate) +
          "-" +
          formatDate(lastQuarterEndDate);
        selectedValue = "Previous Year";
        selectedCurrentPeriodValue = data?.last_quarter;
        selectedPastPeriodValue = data?.last_quarter_last_year;
        selectedChangePeriodValue = data?.last_quarter_last_year_change;
        break;
      case "Year to Date_Previous Year":
        text =
          formatDate(thisYearStartDate) + "-" + formatDate(thisYearEndDate);
        selectedValue = "Previous Year";
        selectedCurrentPeriodValue = data?.this_year;
        selectedPastPeriodValue = data?.last_year;
        selectedChangePeriodValue = data?.this_year_last_year_change;
        break;
      case "Last Year_Previous Year":
        // text = `Filtered text based on ${filterA} and ${filterB}`;
        text =
          formatDate(lastYearStartDate) + "-" + formatDate(lastYearEndDate);
        selectedValue = "Previous Year";
        selectedCurrentPeriodValue = data?.last_year;
        selectedPastPeriodValue = data?.last_year_last_year;
        selectedChangePeriodValue = data?.last_year_last_year_change;
        break;
      default:
        text =
          formatDate(thisMonthStartDate) + " - " + formatDate(thisMonthEndDate);
        selectedValue = "Previous Period";
        selectedCurrentPeriodValue = data?.this_month;
        selectedPastPeriodValue = data?.last_month;
        selectedChangePeriodValue = data?.this_month_last_month_change;
    }
    setFilteredText(text);
    setSelectedValue(selectedValue);
    setSelectedCurrentPeriodValue(selectedCurrentPeriodValue);
    setSelectedPastPeriodValue(selectedPastPeriodValue);
    setSelectedChangePeriodValue(selectedChangePeriodValue);
    setIsDropdownOpen(false);
    const savedXeroFilters = {
      "user": user_id,
      "data": {
        [organisation?.name]: [
          {
            "kpi": title,
            "first_filter": filterA,
            "compared_to": filterB
          }
        ]
      }
    };
    try {
      await dispatch(saveXeroKPIFilters({ savedXeroFilters, token }));

      await dispatch(selectOrganisation({ slug, token }));
    } catch (error) {
      // Handle any errors if needed
      console.error("Error:", error);
    }

  };
  const filterOptions = [
    { label: "This Month", value: "This Month" },
    { label: "Last Month", value: "Last Month" },
    { label: "This Quarter", value: "This Quarter" },
    { label: "Last Quarter", value: "Last Quarter" },
    { label: "Year to Date", value: "Year to Date" },
    // { label: "Last Year", value: "Last Year" },
  ];

  return (
    <div className="flex-shrink-0 w-full">
      <div className="flex flex-col min-h-[230px]">
        <div className="flex-grow h-full border-[0.5px] rounded-lg  bg-white shadow-sm border-zinc-200">
          <div className="px-5 py-3 w-full flex flex-col items-start gap-2 justify-between  h-full relative">
            <div className="flex w-full flex-col gap-2 h-full justify-between">
              <div className="flex gap-2 lg:flex-row flex-col w-full lg:items-center items-start justify-between">
                <p className="flex flex-row gap-2 capitalize justify-center items-center text-xs text-dark-purple/60 font-semibold">
                  <div className="rounded-full  h-9 w-9 bg-slate-200">
                    <Tippy
                      theme="custom-white"
                      size="small"
                      placement="top"
                      content="Xero"
                    >
                      <img
                        className="rounded-full object-contain h-full w-full"
                        src={xero}
                      />
                    </Tippy>
                  </div>
                  {title.charAt(0).toUpperCase() + title.slice(1).toLowerCase()}
                  <Tippy
                    theme="custom-white"
                    size="small"
                    placement="top"
                    content={data?.Description}
                  >
                    <div>
                      <IoIosInformationCircle
                        className="  text-grey"
                        size={14}
                      />
                    </div>
                  </Tippy>
                </p>

                {/* <Dropdown></Dropdown> */}
                <div className="relative   text-left">
                  <div>
                    <span className="rounded-md z-10 shadow-sm">
                      <button
                        type="button"
                        className="inline-flex capitalize   justify-center text-[10px]  w-full rounded-md border  border-gray-300 bg-white px-3 py-1  font-medium text-gray-400 hover:bg-gray-50 focus:outline-none  "
                        id="options-menu"
                        aria-haspopup="true"
                        aria-expanded={isDropdownOpen ? "true" : "false"}
                        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                      >
                        {filterA
                          .replace("_", " ")
                          .replace(/^\w/, (c) => c.toUpperCase())}{" "}
                        ({filteredText})
                        <svg
                          className="-mr-1 ml-2 h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 12a1 1 0 01-.7-.29l-4-4a1 1 0 111.41-1.42L10 9.59l3.29-3.3a1 1 0 111.42 1.42l-4 4a1 1 0 01-.71.29z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    </span>
                  </div>
                  <div ref={dropdownRef}>
                    {isDropdownOpen && (
                      <div
                        ref={dropdownRef}
                        className="origin-top-right z-20 absolute text-gray-500 text-xs  w-[155px] rounded-md shadow-md bg-white ring-1 ring-black ring-opacity-5"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="options-menu"
                      >
                        <div
                          className="flex gap-2 text-xs px-2 py-4 flex-col "
                          role="none"
                        >
                          <p className="font-semibold text-xs text-grey">
                            Current Period
                          </p>
                          {filterOptions.map((option) => (
                            <label
                              key={option.value}
                              className="inline-flex items-center"
                            >
                              <input
                                type="radio"
                                className="form-radio text-purple h-4 w-4"
                                name="filterA"
                                value={option.value}
                                checked={filterA === option.value}
                                onChange={() =>
                                  handleFilterChange("filterA", option.value)
                                }
                              />
                              <span className="ml-2">{option.label}</span>
                            </label>
                          ))}
                          <hr className="my-1" />
                          <p className="font-semibold text-xs text-grey">
                            Compare with
                          </p>
                          <label className="inline-flex items-center">
                            <input
                              type="radio"
                              className="form-radio text-purple h-4 w-4"
                              name="filterB"
                              value="Previous Period"
                              checked={filterB === "Previous Period"}
                              onChange={() =>
                                handleFilterChange("filterB", "Previous Period")
                              }
                            />
                            <span className="ml-2">Previous Period</span>
                          </label>
                          <label className="inline-flex items-center">
                            <input
                              type="radio"
                              className="form-radio text-purple h-4 w-4"
                              name="filterB"
                              value="Previous Year"
                              checked={filterB === "Previous Year"}
                              onChange={() =>
                                handleFilterChange("filterB", "Previous Year")
                              }
                            />
                            <span className="ml-2">Previous Year</span>
                          </label>
                        </div>
                        <div className="flex justify-end px-2 py-2">
                          <button
                            type="button"
                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm py-1 bg-purple text-base font-medium text-white hover:bg-purple/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                            onClick={handleGoButtonClick}
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex mt-3 justify-between w-full">
              <p
                className="text-[32px] text-zinc-700 font-bold hover:underline cursor-pointer"
                onClick={toggleKPISheet}
              >
                {isLoadingKPI ? (
                  <SpinnerKPI />
                ) : data ? (
                  <p>
                    {isCustomSymbol ? "" : currencySymbol}{" "}
                    {selectedCurrentPeriodValue === null || selectedCurrentPeriodValue === undefined
                      ? "0"
                      : (title === "Net Profit Margin" || title === "Operating Margin" || title === "Gross Profit Margin"
                        ? (selectedCurrentPeriodValue * 100)
                        : selectedCurrentPeriodValue
                      ).toLocaleString(undefined, {
                        maximumFractionDigits: 0,
                      })}
                    {isCustomSymbol ? customSymbol : ""}{" "}
                  </p>

                ) : (
                  <p className="text-[12px] font-light text-grey italic">
                    No data found, please refresh or try later
                  </p>
                )}
              </p>
              {isLoadingKPI ? (
                <div>{/* Loading spinner or placeholder */}</div>
              ) : data ? (
                <div
                  className={`self-end relative cursor-pointer hover:underline`}
                  onClick={toggleKPISheet}
                >
                  {sparkLine01Data.length > 0 && (
                    <SimpleSparkline
                      color="purple"
                      sparkLineData={sparkLine01Data}
                      className="absolute z-10"
                    />
                  )}
                  {sparkLine02Data.length > 0 && (
                    <SimpleSparkline
                      color="grey"
                      sparkLineData={sparkLine02Data}
                      className="absolute z-20"
                    />
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>

            <div className="flex flex-col mt-3 gap-2 items-start justify-center w-full ">
              <div className="flex flex-row justify-between w-full">
                <div className="flex flex-row items-center gap-2 w-full">
                  <p className="">
                    {isLoadingKPI ? (
                      ""
                    ) : data ? (
                      <p
                        className={`text-[10px] w-22 flex gap-1  flex-row items-center justify-center px-2 py-1 font-semibold rounded-xl ${Math.abs(selectedChangePeriodValue) < 0.001 // Adjust the threshold as needed
                          ? "bg-gray-300 text-gray-500"
                          : title === "Money you Owe" ||
                            title === "Cash Spent" ||
                            title === "Money Owed To You" ||
                            title === "Average Debtor Days" ||
                            title === "Average Creditor Days" ||
                            title === "Operating Expenses" ||
                            title === "Cost of Sales" ||
                            title === "Money you owe"
                            ? selectedChangePeriodValue > 0.001
                              ? "bg-red-200 text-red-700"
                              : "bg-green-200 text-green-700"
                            : selectedChangePeriodValue > 0.001
                              ? "bg-green-200 text-green-700"
                              : "bg-red-200 text-red-700"
                          }`}
                      >
                        {selectedChangePeriodValue < 0 ? "-" : ""}
                        {Math.abs(selectedChangePeriodValue * 100).toFixed(1)}%
                        {selectedChangePeriodValue < 0 ? (
                          <BsArrowDownCircleFill size={16} />
                        ) : (
                          <BsFillArrowUpCircleFill size={16} />
                        )}
                      </p>
                    ) : (
                      <p className="text-[12px] font-light text-grey italic"></p>
                    )}
                  </p>
                  <p className=" flex flex-row text-[11px]  items-start text-gray-500 font-light">
                    {isLoadingKPI ? (
                      ""
                    ) : data ? (
                      <span className="pr-2 ">
                        {isCustomSymbol ? "" : currencySymbol}{" "}
                        {selectedCurrentPeriodValue !== null && selectedPastPeriodValue !== null ?
                          (title === "Net Profit Margin" || title === "Operating Margin" || title === "Gross Profit Margin" ?
                            ((selectedCurrentPeriodValue - selectedPastPeriodValue) * 100).toFixed(2) :
                            (selectedCurrentPeriodValue - selectedPastPeriodValue)
                          ).toLocaleString(undefined, { maximumFractionDigits: 0 }) :
                          "0"
                        }
                        {isCustomSymbol ? customSymbol : ""} Compared to {selectedValue}
                      </span>

                    ) : (
                      <span className="pr-2"></span>
                    )}
                  </p>
                </div>
              </div>

              <div className="flex flex-col gap-2   w-full">
                <div className="flex flex-row justify-between w-full mt-3 gap-2 items-center">
                  <div className="flex flex-row items-center gap-2">
                    {/* <div className="rounded-full  h-9 w-9 bg-slate-200">
                      <Tippy
                        theme="custom-white"
                        size="small"
                        placement="top"
                        content="Xero"
                      >
                        <img
                          className="rounded-full object-contain h-full w-full"
                          src={xero}
                        />
                      </Tippy>
                    </div> */}
                  </div>

                  <div className="flex flex-row gap-2">
                    <AiOutlineHeart
                      size={18}
                      className="text-zinc-400 cursor-pointer hover:text-purple"
                      onClick={handleFavorite}
                    />
                    <BiCommentDetail
                      size={18}
                      className="text-zinc-400 cursor-pointer hover:text-purple "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoxCard01;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import DashboardBanner from "../../../components/ui-elements/banner/dashbaord-banner";
import KPISheetModal from "../../../components/ui-elements/modals/kpis-modals/kpi-sheet-modal";

import BoxTableCard01 from "../../../components/ui-elements/cards/table-cards/box-table-card-01";

function DashboardCustomerPage(props) {
  const { dearCustomerWithoutTags,
    dearLoadingStatuses
     } =
    useSelector((state) => state.kpi);
  const { organisation } = useSelector((state) => state.organisation);

  // BOX KPIs

 
  const [toggleKPI, setKPISheetToggle] = useState(false);
  const [kpiSheetTitle, setKPISheetTitle] = useState(""); 

  const toggleKPISheet = (title) => {
    setKPISheetTitle(title); 
    setKPISheetToggle(!toggleKPI);
  };
  
  const xeroKPIComponent=(
<p></p>
  )
  const dearKPIComponent=(
    <div class="mx-0 mt-5 flex flex-row gap-2">
     <BoxTableCard01
    title={dearCustomerWithoutTags?.results?.[0]?.kpi || 'Customers Without Tags'}
    tooltip={dearCustomerWithoutTags?.results?.[0]?.description || 'Customers who do not have a customer tag in Cin7 Core'}
    isLoading={dearLoadingStatuses.dearOtherKPIs}
    tableData={dearCustomerWithoutTags?.results?.[0]?.data}/>
  </div>
  )
const displayKPIComponent = (item) => {
    switch (item) {
      case 'XERO':
        return xeroKPIComponent
      case 'DEAR':
        return dearKPIComponent
      default:
        return 'Unknown'; 
    }
  };
  return (
    <div className="w-full h-full px-5 py-5 bg-light-white flex flex-col">
      <div className="bg-white flex-1 p-5 border-2 border-light-grey mt-5 justify-center items-start">
        <DashboardBanner title="Customers" isGlobalFilterRequired={false}/>
        <KPISheetModal
          toggle={toggleKPI}
          toggleModal={toggleKPISheet}
          kpiName={kpiSheetTitle}
        >
          <p className="font-bold text-lg">{kpiSheetTitle}</p>
          
        </KPISheetModal>
          {organisation?.connected_apps && organisation.connected_apps.map((item, index) => (
          <div key={index}>
            {displayKPIComponent (item)}
          </div>
        ))}
      </div>
    </div>
  );
}

export default DashboardCustomerPage;

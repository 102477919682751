import axios from "axios";

import {
  ENV_URL,
  LIST_CONSOLIDATED_ORGANISATION,
  CREATE_CONSOLIDATED_ORGANISATION,
} from "../../../constant";

const getAllConsolidateOrganisations = async (token) => {
  const response = await axios.get(`${ENV_URL}${LIST_CONSOLIDATED_ORGANISATION}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

const createConsolidateOrganisation = async (orgData, token) => {
  const response = await axios.post(
    `${ENV_URL}${CREATE_CONSOLIDATED_ORGANISATION}`,
    orgData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};



const consolidateOrgService = {
  getAllConsolidateOrganisations,
  createConsolidateOrganisation
};
export default consolidateOrgService;

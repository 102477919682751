import React from "react";

function Spinner2(props) {
  const { isLooker, note, isOrganisationTable } = props;
  return (
    <div
      className={`flex flex-col w-full h-full
      items-center justify-center`}
    >
      <div
        className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
        role="status"
      ></div>
      <span className="mt-6">{note}</span>
    </div>
  );
}

export default Spinner2;

import React, { useEffect, useState } from "react";
import LookerDashboard from "../../components/looker-components/looker-dashboard";
import { Link } from "react-router-dom";
import Sidebar from "../../../navigation";
import { BsQuestionCircle } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  embedLooker,
  resetEmbed,
  resetLooker,
  setEmbedURL,
} from "../../../redux-features/slices/looker/lookerSlice";
import Select from "react-select";
import {
  getAllOrganisations,
  selectOrganisation,
} from "../../../redux-features/slices/organisation/orgSlice";
import { dropdownIndicatorStyles } from "../../../styles/selectStyle";
import errorPage from "../../../assets/images/errors/no-data.png";
import orgPage from "../../../assets/images/errors/nothing-selected.png";
import Spinner2 from "../../components/ui-elements/utilis/spinner-2";

function LookerDashboardPage() {
  const {
    embedURL,
    lookerURL,
    isErrorLooker,
    isLoadingLooker,
    isSuccessLooker,
    message,
  } = useSelector((state) => state.looker);
  const dispatch = useDispatch();
  const { organisation, organisations } = useSelector(
    (state) => state.organisation
  );
  const { user } = useSelector((state) => state.auth);
  const token = user?.data?.api_token;
  useEffect(() => {
    const fetchOrganisations = async () => {
      if (token) {
        await dispatch(getAllOrganisations({ token }));
      }
    };
    fetchOrganisations();
  }, []);
  useEffect(() => {
    if (!organisation?.slug === "") {
      const slug = organisation?.slug;
      dispatch(embedLooker({ slug, token }));
    }
  }, []);
  const [open, setOpen] = useState(true);
  useEffect(() => {
    if (isErrorLooker) {
      toast.error(message, {
        autoClose: 10000,
        hideProgressBar: true,
        position: toast.POSITION.TOP_CENTER,
      });
    }
    if (isSuccessLooker) {
      // toast.success(
      //   "Qurk is successfully connected to the organisation dashboard!",
      //   {
      //     toastId: "toast1",
      //     autoClose: 3000,
      //     hideProgressBar: true,
      //     position: toast.POSITION.TOP_CENTER,
      //   }
      // );
      dispatch(resetLooker());
    }
  }, [
    lookerURL,
    isErrorLooker,
    isSuccessLooker,
    message,
    organisation,
    dispatch,
  ]);

  // Select
  const [defaultOrganisation, setDefaultOrganisation] = useState({});
  const options = organisations?.map((item) => ({
    value: item.name,
    label: item.name,
    slug: item.slug,
    url: item.xero_embed,
  }));

  useEffect(() => {
    if (organisation || Object.keys(organisation)?.length > 0) {
      setDefaultOrganisation({
        value: organisation?.name,
        label: organisation?.name,
        slug: organisation?.slug,
        owner: organisation?.owner,
        url: organisation?.xero_embed,
      });
    }
  }, [organisation]);

  const handleOrgChange = async (e) => {
    const slug = e.slug;
    const embed = e.url;
    dispatch(selectOrganisation({ slug, token }));
    if (organisation?.connected_apps?.length > 0) {
      dispatch(embedLooker({ slug, token }));
    }
    setDefaultOrganisation(e);
  };

  const handleOrganisationSelectLooker2 = async (slug, token) => {
    try {
      const cookies = document.cookie.split(";");

      cookies.forEach((cookie) => {
        const cookieParts = cookie.split("=");
        const cookieName = cookieParts[0].trim();
        // To remove a cookie, set its expiration to a past date
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
      });

      // Rest of the function code...
    } catch (error) {
      console.error(
        "Error while selecting organisation and embedding Looker:",
        error
      );
      // Handle error if needed
    }
  };

  if (Object.keys(organisation)?.length === 0) {
    return (
      <div className=" ">
       
        <div className="flex flex-col w-full px-6 py-2">
          <div className="flex w-full  items-end justify-between mt-2">
            <div>
              <div className="relative">
                <h2 className="text-xl font-medium ">Dashboard</h2>
              </div>
            </div>
            <div className="flex flex-row gap-6 ">
              <Select
                styles={{
                  dropdownIndicator: dropdownIndicatorStyles,
                }}
                className="basic-single z-20 w-96 border-2 rounded-md border-purple"
                classNamePrefix="select"
                value={defaultOrganisation}
                name="color"
                options={options}
                onChange={(e) => {
                  handleOrgChange(e);
                }}
              />
              <BsQuestionCircle className=" pt-2 text-purple" size={35} />
            </div>
          </div>
          <div className="w-full h-full flex flex-col items-center justify-start pt-16 ">
            <img src={orgPage} alt="" className="w-96 h-96" />
            <p>Hmm...! It seems like you have not selected any organisation</p>
          </div>
        </div>
      </div>
    );
  }
  return (
      
      <div className="flex flex-col h-full w-full px-6 py-2">
        {/* <CookieClearer/> */}
        <div className="flex w-full  items-end justify-between mt-2">
          <div>
            <div className="relative">
              <h2 className="text-xl font-medium ">Dashboard</h2>
            </div>
          </div>
          <div className="flex flex-row gap-6 ">
            <Select
              styles={{
                dropdownIndicator: dropdownIndicatorStyles,
              }}
              className="basic-single z-20 w-96 border-2 rounded-md border-purple"
              classNamePrefix="select"
              value={defaultOrganisation}
              name="color"
              options={options}
              onChange={(e) => {
                handleOrgChange(e);
              }}
            />
            <BsQuestionCircle className=" pt-2 text-purple" size={35} />
          </div>
        </div>
        {/* <div>
          <Looker/>
        </div> */}
        {isLoadingLooker ? (
          <>
            <Spinner2
              isLooker={true}
              note={`Please wait while your dashboard is being fetched..`}
            />
          </>
        ) : (
          <>
            {!organisation?.connected_apps?.length > 0 ? (
              <div className="flex flex-col items-center justify-start w-full h-full">
                <>
                  <img src={errorPage} alt="" className="w-96 h-96" />
                  <p>
                    We are sorry! You have not connected any apps to your
                    organisation.
                  </p>
                  <p>To fix, add your apps in the connected apps section.</p>
                </>
              </div>
            ) : (
              <div className=" z-10 h-full my-6 ">
                {lookerURL && (
                  <LookerDashboard
                    embed={
                      organisation.slug === "louella-belle"
                        ? embedURL
                        : lookerURL?.embed
                    }
                    xero_embed={defaultOrganisation?.xero_embed}
                  />
                )}
              </div>
            )}
          </>
        )}
      </div>
  );
}

export default LookerDashboardPage;

import axios from "axios";
import { CANCEL_SUBSCRIPTION, CREATE_SUBSCRIPTION, ENV_URL, GET_CUSTOMER_SUBSCRIPTION, LIST_SUBSCRIPTION_PLANS, PAY_SUBSCRIPTION } from "../../../constant";

const getAllPlans = async (token) => {
  const response = await axios.get(`${ENV_URL}${LIST_SUBSCRIPTION_PLANS}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.data;
};

const createSubscription = async (data, token) => {
  const response = await axios.post(`${ENV_URL}${CREATE_SUBSCRIPTION}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
const paySubscription = async (data, token) => {
  const response = await axios.post(`${ENV_URL}${PAY_SUBSCRIPTION}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

const getCustomerSubscription = async (slug, token) => {
  const response = await axios.get(`${ENV_URL}${GET_CUSTOMER_SUBSCRIPTION}${slug}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data
};

const deleteSubscription = async (slug, token) => {
  const response = await axios.delete(`${ENV_URL}${CANCEL_SUBSCRIPTION}${slug}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
const subscriptionService = {
getAllPlans,
paySubscription,
deleteSubscription,
createSubscription,
getCustomerSubscription
};
export default subscriptionService;

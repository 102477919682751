import React, { useEffect, useState } from 'react'
import { BsQuestionCircle } from 'react-icons/bs';
import Sidebar from '../../../navigation';
import { Link } from 'react-router-dom';
import Select from "react-select";
import cin7 from '../../../assets/logos/cin7_logo.png'
import xero from "../../../assets/logos/xero_logo.png";
import quickbooks from "../../../assets/logos/quickbooks_logo.png";
import hubspot from "../../../assets/logos/hubspot_logo.png";
import pipedrive from "../../../assets/logos/pipedrive_logo.png";
import katana from "../../../assets/logos/katana_logo.png";
import dearsystems from "../../../assets/logos/cin7-core-teal-1.png";
import mrp from "../../../assets/logos/mrpreasy_logo.png";
import shopify from "../../../assets/logos/shopify-logo.png";
import {AiFillCheckCircle} from 'react-icons/ai'
import { getAllOrganisations, selectOrganisation } from '../../../redux-features/slices/organisation/orgSlice';
import { useDispatch, useSelector } from 'react-redux';
import { dropdownIndicatorStyles } from '../../../styles/selectStyle';
import { embedLooker } from '../../../redux-features/slices/looker/lookerSlice';
import XeroDisconnectModal from '../../components/ui-elements/modals/xero-disconnect';
import Spinner from '../../components/ui-elements/utilis/spinner';
import SpinnerKPI from '../../components/ui-elements/utilis/spinner-kpi';
function AppIntegrationScreen(props) {
    const dispatch = useDispatch();
    const { organisations, organisation, isLoading } = useSelector((state) => state.organisation);
     const { user } = useSelector((state) => state.auth);
     const token = user?.data?.api_token;

     const slug = organisation?.slug;
 const [toggleXeroDisconnect, setXeroDisconnectToggle] = useState(false);
    useEffect(() => {
      dispatch(getAllOrganisations({token}));
      if (organisations?.length > 0) {
        dispatch(selectOrganisation({ slug, token }));
      }
    }, []);
     
      const options = organisations?.map((item) => ({
        value: item.name,
        label: item.name,
        slug:item.slug
      }));
      
      const apps = [
        {
          name: "Cin7",
          logo: cin7,
          category: "Accounting",
          link: "/connected-apps/apps/cin-7/",
        },
        {
          name: "Xero",
          logo: xero,
          category: "Accounting",
          link: "/connected-apps/apps/xero/",
        },
        {
          name: "Quickbooks",
          logo: quickbooks,
          category: "Accounting",
          link: "/connected-apps/apps/",
        },
        {
          name: "MRP Easy",
          logo: mrp,
          category: "MRP/ERP",
          link: "/connected-apps/apps/",
        },
        {
          name: "Katana",
          logo: katana,
          category: "MRP/ERP",
          link: "/connected-apps/apps/",
        },
        {
          name: "Dear",
          name: "Dear",
          logo: dearsystems,
          category: "MRP/ERP",
          link: "/connected-apps/apps/dear/",
        },
        {
          name: "Pipedrive",
          logo: pipedrive,
          category: "CRM",
          link: "/connected-apps/apps/",
        },
        {
          name: "Hubspot",
          logo: hubspot,
          category: "CRM",
          link: "/connected-apps/apps/",
        },
        {
          name: "Shopify",
          logo: shopify,
          category: "Ecommerce",
          link: "/connected-apps/apps/",
        },
      ];
    
    const [selectedCategory, setSelectedCategory] = useState("");
const [defaultOrganisation, setDefaultOrganisation] = useState({});
useEffect(() => {
  if (organisation) {
    setDefaultOrganisation({
      value: organisation?.name,
      label: organisation?.name,
      slug: organisation?.slug,
      owner: organisation?.owner,
    });
  }
}, [organisation]);

const handleOrgChange = async (e) => {
  const slug = e.slug;
  dispatch(selectOrganisation({ slug, token }));
  setDefaultOrganisation(e);

};
    const handleCategoryFilter = (category) => {
      setSelectedCategory(category);
    };

   
    const toggleXeroDisconnectModal = () => {
      setXeroDisconnectToggle(!toggleXeroDisconnect)
    };
  return (
    <div className="w-full bg-light-white overflow-y-scroll scrollbar-thin scrollbar-rounded-lg scrollbar-h-12 scrollbar-thumb-gray-400 scrollbar-track-gray-100 scroll-padding">
      <div
        className={`flex flex-col w-full  justify-start items-start scroll-padding px-8 py-2 `}
      >
        {/* <button className="test">
          test
          <Link to={`/connected-apps/apps/disconnect/xero/:${organisation?.slug}`}>Post 1</Link>
        </button> */}
        
        <XeroDisconnectModal
          token={token}
          toggle={toggleXeroDisconnect}
          toggleModal={toggleXeroDisconnectModal}
          organisation={organisation}
        />
        <div className="flex w-full  items-end justify-between mt-4">
          <div>
            <div className="relative">
              <h2 className="text-xl font-medium ">Connected Apps</h2>
            </div>
          </div>
          {/* <div className="flex flex-row gap-6">
            <div className="relative">
              <Select
                styles={{
                  dropdownIndicator: dropdownIndicatorStyles,
                }}
                className="basic-single z-20 w-96 border-2 rounded-md border-purple"
                classNamePrefix="select"
                value={defaultOrganisation}
                name="color"
                options={options}
                onChange={(e) => {
                  handleOrgChange(e);
                }}
              />
            </div>
            <BsQuestionCircle className=" mt-1 text-purple" size={32} />
          </div> */}
        </div>
        <div className="flex w-full flex-row  items-end justify-between mt-6">
          <div></div>
        </div>
        <div className="flex flex-col w-full  items-start justify-start mt-4">
          <div className="relative ">
            <h2 className="text-md font-bold">
              Apps Connected ({organisation?.connected_apps?.length})
            </h2>
          </div>

            <div className="w-full mt-2 grid grid-flow-col auto-cols-max gap-10 bg-light-grey-2 h-44 p-4">
             {isLoading ?(
              <div className='flex items-center'>
              <SpinnerKPI/>
              </div>
             ):(
              <>
              {organisation?.connected_apps?.length > 0 ? (
                <div className="grid grid-cols-6 gap-10  ">
                  {apps
                    .filter((app) =>
                      organisation?.connected_apps.some(
                        (connectedApp) =>
                          connectedApp.toLowerCase() === app.name.toLowerCase()
                      )
                    )
                    ?.map((app) => (
                      <div className="flex flex-col items-center justify-center">
                        <div 
                        onClick={app?.name === "Xero" ? toggleXeroDisconnectModal:""}
                        className="w-28 flex flex-col cursor-pointer rounded-md h-28 items-center border-2 border-grey  bg-white  ">
                          <div className="flex w-full items-end justify-end p-1 ">
                            <AiFillCheckCircle
                              className="  text-green-400"
                              size={25}
                            />
                          </div>
                          <img src={app.logo} alt="" className={` ${app?.name === "Dear"?"w-8 h-8 mb-2":"w-10 h-10"}`} />
                          <p className="mt-2 text-sm font-medium ">
                            {app?.name === "Cin7"
                              ? "Cin7 Omni"
                              : app?.name === "Dear"
                              ? "Cin7 Core"
                              : app?.name}
                          </p>
                        </div>
                        {/* <p className="mt-2 font-medium text-default text-[12px]">
                          Updated 2s ago
                        </p> */}
                      </div>
                    ))}
                </div>
              ) : (
                <div className="flex items-center  justify-center w-full h-auto p-2">
                  <p>No Apps Connected</p>
                </div>
              )}
              </>
             )}
            </div>
          </div>
          <div className="flex flex-col mt-10 mb-6">
            <div className="relative ">
              <h2 className="text-md font-bold">Connect New Apps:</h2>
            </div>
            <div className="mt-4 flex flex-row items-center gap-4">
              <p className="text-lg">Filter : </p>
              <div className="flex gap-6">
                <button
                  className={`px-8 py-2 rounded ${
                    selectedCategory === ""
                      ? "bg-purple text-white"
                      : "bg-gray-200 text-gray-700"
                  }`}
                  onClick={() => handleCategoryFilter("")}
                >
                  All
                </button>
                <button
                  className={`px-6 py-2 rounded ${
                    selectedCategory === "Ecommerce"
                      ? "bg-purple text-white"
                      : "bg-gray-200 text-gray-700"
                  }`}
                  onClick={() => handleCategoryFilter("Ecommerce")}
                >
                  Ecommerce
                </button>
                <button
                  className={`px-6 py-2 rounded ${
                    selectedCategory === "Accounting"
                      ? "bg-purple text-white"
                      : "bg-gray-200 text-gray-700"
                  }`}
                  onClick={() => handleCategoryFilter("Accounting")}
                >
                  Accounting
                </button>
                <button
                  className={`px-6 py-2 rounded ${
                    selectedCategory === "MRP/ERP"
                      ? "bg-purple text-white"
                      : "bg-gray-200 text-gray-700"
                  }`}
                  onClick={() => handleCategoryFilter("MRP/ERP")}
                >
                  MRP/ERP
                </button>
                <button
                  className={`px-8 py-2 rounded ${
                    selectedCategory === "CRM"
                      ? "bg-purple text-white"
                      : "bg-gray-200 text-gray-700"
                  }`}
                  onClick={() => handleCategoryFilter("CRM")}
                >
                  CRM
                </button>
              </div>
            </div>
            <div className="grid xl:grid-cols-8 grid-cols-6 gap-10  mt-6">
              {apps
                ?.filter(
                  (app) =>
                    selectedCategory === "" || app.category === selectedCategory
                )
                ?.map((app) => (
                  <Link to={app.link}>
                    <div className="w-36 flex flex-col rounded-md h-36 items-center justify-center border-2 border-grey  bg-white  ">
                      <div className="w-16 h-20 flex items-center">
                      <img
  src={app.logo}
  alt=""
  className={`${
    app.name === "Cin7" || app.name === "Xero" || app.name === "Dear"
      ? ""
      : "grayscale"
  } ${
    app.name === "Cin7"
      ? "w-12 ml-2"
      : app.name === "Xero"
      ? "w-full"
      : app.name === "Quickbooks"
      ? "w-12 ml-2"
      : app.name === "MRP Easy"
      ? "w-14 ml-2"
      : app.name === "Dear"
      ? "w-12 ml-2"
      : app.name === "Pipedrive"
      ? "w-full"
      : app.name === "Hubspot"
      ? "w-10 ml-2"
      : ""
  }`}
/>

                      </div>
                      <p className="mt-2 text-sm font-medium ">
                        {app?.name === "Cin7"
                          ? "Cin7 Omni"
                          : app?.name === "Dear"
                          ? "Cin7 Core"
                          : app?.name}
                      </p>
                    </div>
                  </Link>
                ))}
            </div>
          </div>
        </div>
      </div>
  );
}

export default AppIntegrationScreen
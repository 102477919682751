import React from "react";
import { IoIosClose } from "react-icons/io";
import { MdGroups2 } from "react-icons/md";
import moment from "moment";

function OrganisationActiveUsersModal(props) {
  const { toggle,toggleModal, organisation } = props;
  return (
    <div>
      {organisation && toggle && (
        <div className="fixed z-40 inset-0 w-full  overflow-y-auto">
          <div className="flex items-center justify-center 6  bg-black bg-opacity-50 min-h-screen p-4">
            <div className="bg-white rounded-lg md:w-2/5 sm:w-3/5 w-4/5  p-8">
              <div className="flex flex-row justify-between item-start">
                <div className="flex gap-2 ">
                  <MdGroups2 size={42} />
                  <div className="mt-2">
                    <h2 className="text-lg font-medium ">
                      Active Users for{" "}
                      <span className=" font-bold">{organisation?.name}</span>
                    </h2>
                  </div>
                </div>
                <IoIosClose
                  onClick={toggleModal}
                  className="cursor-pointer"
                  size={35}
                />
              </div>
              <div className="mt-8">
                <table className="table-auto w-full border-collapse">
                  <thead className="text-white bg-gray-800">
                    <tr className=" text-left shadow-sm ">
                      <th className="border-b border-gray-300 p-4">
                        User Name
                      </th>
                      <th className="border-b border-gray-300 p-4">
                        User Role
                      </th>
                      <th className="border-b border-gray-300 p-4">
                        Last Updated
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white p-4">
              
                    {organisation?.staffs?.map((r) => (
                      <tr className="justify-end">
                        <td className="border-b-2 border-grey p-4">
                          {r}
                        </td>
                        <td className="border-b-2 border-grey py-4 px-6">
                          {r===organisation.owner ? 'Admin' : 'Guest'}
                        </td>
                        <td className="border-b-2 border-grey py-4 px-6">
                          {moment(organisation?.updated_at).fromNow()}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default OrganisationActiveUsersModal;

import React from "react";
import SpinnerKPI from "../../../../utilis/spinner-kpi";

const LateSuppliersTable = ({ data, isLoading }) => {
  return (
    <div className=" rounded-md border border-gray-300">
      <table className="border-separate border-spacing-0 w-full text-xs text-center">
        <thead className="bg-gray-200 sticky top-0 z-10">
          <tr>
            <th className="border border-gray-300 px-4 py-2">Supplier Name</th>
            <th className="border border-gray-300 px-4 py-2">Current Period</th>
            <th className="border border-gray-300 px-4 py-2">Previous Period</th>
            <th className="border border-gray-300 px-4 py-2">Previous Year</th>
            {/* <th className="border border-gray-300 px-4 py-2">Date Selection</th> */}
          </tr>
        </thead>
        <tbody className="text-gray-600">
        {isLoading? (
        <tr>
        <td className="border border-gray-300 text-center px-4 py-2" colSpan="4">
          <div className="flex items-center justify-center h-full w-full">
            <SpinnerKPI />
          </div>
        </td>
      </tr>
      
      
        ):(
          <>
            {data && data.length > 0 ? (
            data.map((row, index) => (
              <tr key={index}>
                <td className="border border-gray-300 px-4 py-2">{row.supplier}</td>
                <td className="border border-gray-300 px-4 py-2">
                  {(row.percentage_late_PO_current_period * 100).toFixed(2)} %
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {(row.percentage_late_PO_previous_period * 100).toFixed(2)} %
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {(row.percentage_late_PO_previous_year * 100).toFixed(2)} %
                </td>
                {/* <td className="border border-gray-300 px-4 py-2">{row.date_selection}</td> */}
              </tr>
            ))
          ) : (
            <tr>
              <td className="border border-gray-300 px-4 py-2" colSpan="4">
                No data available
              </td>
            </tr>
          )}
          </>
        )}
        </tbody>
      </table>
    </div>
  );
};

export default LateSuppliersTable;

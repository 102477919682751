import React, { useRef } from 'react'
import LineGraph02 from '../../components/graphs/recharts/line-graph-2';
import RevenueTable from '../../components/ui-elements/tables/revenue-table';
import PieChartComponent from '../../components/graphs/recharts/pie-chart';

function RevenueComponent() {
const ref = useRef();

const data = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
  datasets: [
    {
      label: "First dataset",
      data: [33, 53, 85, 41, 44, 65],
      fill: true,
      backgroundColor: "rgba(75,192,192,0.2)",
      borderColor: "rgba(75,192,192,1)",
    },
    {
      label: "Second dataset",
      data: [33, 25, 35, 51, 54, 76],
      fill: false,
      borderColor: "#742774",
    },
  ],
};
const data2 = [
  { name: "Jun", data1: 5, data2: 3 },
  { name: "Jul", data1: 6, data2: 2 },
  { name: "Aug", data1: 7, data2: 1 },
];
  return (
    <div>
      {/* <LineGraph data={data} /> */}
      <div>
        <div className="flex flex-col gap-1 mt-6">
          <p className="font-bold">Revenue</p>
          <p className="text-sm">
            Here are your latest revenue reporting details
          </p>
        </div>

        <div>
          <RevenueTable />
        </div>
      </div>
      <div className="flex flex-row mt-10">
        <div>{/* <LineGraph ref={ref} data={data} /> */}</div>
        <div className="p-2 shadow-md">
          <LineGraph02 data={data2} />
        </div>
        <PieChartComponent />
      </div>
    </div>
  );
}

export default RevenueComponent
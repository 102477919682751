import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BoxCard01 from "../../../components/ui-elements/cards/box-card-01";
import BoxCard03 from "../../../components/ui-elements/cards/box-card-03";
import LineChart from "../../../components/graphs/react-chartjs-two/line-chart";
import DashboardBanner from "../../../components/ui-elements/banner/dashbaord-banner";
import KPISheetModal from "../../../components/ui-elements/modals/kpis-modals/kpi-sheet-modal";
import GraphData from "./graphs";
import countryToSymbolMap from "../../../../constant/country-code-currency.json";
import BoxTableCard01 from "../../../../views/components/ui-elements/cards/table-cards/box-table-card-01";

function DashboardCashFlowPage(props) {
  const { xeroKPIFigures, xeroBankTable, isLoadingKPI } = useSelector(
    (state) => state.kpi
  );
  const { organisation } = useSelector((state) => state.organisation);
  const countryCode =
    organisation && organisation.country_alpha3
      ? organisation.country_alpha3.replace(/"/g, "")
      : null;
  const currencySymbol = countryCode ? countryToSymbolMap[countryCode] : null;

  // GRAPHS
  const {
    totalBankData,
    totalBankLineGraphData,
    moneyYouOweLineData,
    moneyYouOweLineGraphData,
    moneyOwedToYouLineData,
    moneyOwedToYouLineGraphData,
    cashSpentData,
    cashSpentLineGraphData,
    cashReceivedData,
    cashReceivedLineGraphData,
    averageDebtorDaysData,
    averageDebtorDaysLineGraphData,
  } = GraphData();

  // BOX KPIs

  const getKPIFigure = (kpiName) => {
    if (Array.isArray(xeroKPIFigures)) {
      const figure = xeroKPIFigures.find((item) => item.KPI === kpiName);
      return figure || null;
    } else {
      return null;
    }
  };
  const moneyOweFigureData = getKPIFigure("Money you Owe");
  const cashSpentFigureData = getKPIFigure("Cash Spent");
  const cashReceivedFigureData = getKPIFigure("Cash Received");
  const moneyOwedToYouFigureData = getKPIFigure("Money Owed to You");
  const totalBankFigureData = getKPIFigure("Total Bank");
  const currentAssetsLessCurrentLiabilitiesFigureData = getKPIFigure(
    "Current Assets Less Current Liabilities"
  );
  const averageDebtorDaysFigureData = getKPIFigure("Average Debtor Days");
  // Spark Lines
  function filterLastMonthsData(data, months = 6) {
    if (Array.isArray(data)) {
      return data.slice(0, months);
    } else {
      return [];
    }
  }

  const extractSparkLineData = (data, key) =>
    data.map((entry) => entry[key] || 0);
  function generateSparkLineData(rawData, key) {
    if (!Array.isArray(rawData) || rawData.length === 0) {
      return [];
    }
    // Sort the data by month in descending order
    const sortedData = rawData.slice().sort((a, b) => {
      if (a.month < b.month) return 1;
      if (a.month > b.month) return -1;
      return 0;
    });

    // Extract the last 6 months' data and reverse it
    const last6MonthsData = filterLastMonthsData(sortedData).reverse();
    const sparkLine = extractSparkLineData(last6MonthsData, key);
    return sparkLine; // Return sparkLine array directly without adding 0
  }

  const moneyYouOweSpark = moneyYouOweLineData?.money_you_owe || [];
  const sparkLine01MoneyYouOweData = generateSparkLineData(
    moneyYouOweSpark,
    "money_you_owe"
  );

  const sparkLine02MoneyYouOweData = generateSparkLineData(
    moneyYouOweSpark,
    "previous_year_money_you_owe"
  );

  const moneyOwedToYouSpark = moneyOwedToYouLineData?.money_owed_to_you || [];
  const sparkLine01MoneyOwedToYouData = generateSparkLineData(
    moneyOwedToYouSpark,
    "money_owed_to_you"
  );
  const sparkLine02MoneyOwedToYouData = generateSparkLineData(
    moneyOwedToYouSpark,
    "previous_year_money_owed_to_you"
  );

  const totalBankSpark = totalBankData?.total_bank || [];
  const sparkLine01TotalBankData = generateSparkLineData(
    totalBankSpark,
    "total_bank"
  );
  const sparkLine02TotalBankData = generateSparkLineData(
    totalBankSpark,
    "previous_year_total_bank"
  );

  const cashSpentSpark = cashSpentData?.cash_spent || [];
  const sparkLine01CashSpentData = generateSparkLineData(
    cashSpentSpark,
    "cash_spent"
  );
  const sparkLine02CashSpentData = generateSparkLineData(
    cashSpentSpark,
    "previous_year_cash_spent"
  );
  const cashReceivedSpark = cashReceivedData?.cash_received || [];
  const sparkLine01CashReceivedData = generateSparkLineData(
    cashReceivedSpark,
    "cash_received"
  );
  const sparkLine02CashReceivedData = generateSparkLineData(
    cashReceivedSpark,
    "previous_year_cash_received"
  );

  // Function to extract saved filters
  const extractFilters = (kpiTitle) => {
    const savedFilters = organisation?.saved_filters[organisation?.name];
    const filter = savedFilters?.find((filter) => filter.kpi === kpiTitle);
    if (filter) {
      return {
        firstFilter: filter.first_filter,
        comparedTo: filter.compared_to,
      };
    }
    return { firstFilter: null, comparedTo: null };
  };

  // Extract saved filters for each KPI
  const totalBankSavedFilters = extractFilters("Total Bank");
  const moneyYouOweSavedFilters = extractFilters("Money You Owe");
  const moneyOwedToYouSavedFilters = extractFilters("Money Owed To You");
  const cashSpentSavedFilters = extractFilters("Cash Spent");
  const cashReceivedSavedFilters = extractFilters("Cash Received");
  const averageDebtorDaysSavedFilters = extractFilters("Average Debtor Days");

  // Spark Lines for Average Debtor Days
  const averageDebtorDaysSpark =
    averageDebtorDaysData?.average_debtor_days || [];
  const sparkLine01AverageDebtorDaysData = generateSparkLineData(
    averageDebtorDaysSpark,
    "average_debtor_days"
  );
  const sparkLine02AverageDebtorDaysData = generateSparkLineData(
    averageDebtorDaysSpark,
    "previous_year_average_debtor_days"
  );

  // KPI Sheet Modal
  const [toggleKPI, setKPISheetToggle] = useState(false);
  const [kpiSheetTitle, setKPISheetTitle] = useState(""); // Added state to store KPI sheet title

  const toggleKPISheet = (title) => {
    setKPISheetTitle(title); // Set the title in the state
    setKPISheetToggle(!toggleKPI);
  };
  const xeroKPIComponent = (
    <div class="mx-0">
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
        <div class="rounded-md">
          <BoxCard01
            toggleKPISheet={() => toggleKPISheet("Total Bank")}
            title="Total Bank"
            data={totalBankFigureData}
            savedFilterA={totalBankSavedFilters.firstFilter}
            savedFilterB={totalBankSavedFilters.comparedTo}
            sparkLine01Data={sparkLine01TotalBankData}
            sparkLine02Data={sparkLine02TotalBankData}
          />
        </div>
        <div class=" rounded-md">
          <BoxCard01
            isCustomSymbol={false}
            toggleKPISheet={() => toggleKPISheet("Money You Owe")}
            title="Money you owe"
            savedFilterA={moneyYouOweSavedFilters.firstFilter}
            savedFilterB={moneyYouOweSavedFilters.comparedTo}
            data={moneyOweFigureData}
            sparkLine01Data={sparkLine01MoneyYouOweData}
            sparkLine02Data={sparkLine02MoneyYouOweData}
          />
        </div>

        <div class=" rounded-md">
          <BoxCard01
            isCustomSymbol={false}
            toggleKPISheet={() => toggleKPISheet("Money Owed To You")}
            title="Money Owed To You"
            savedFilterA={moneyOwedToYouSavedFilters.firstFilter}
            savedFilterB={moneyOwedToYouSavedFilters.comparedTo}
            data={moneyOwedToYouFigureData}
            sparkLine01Data={sparkLine01MoneyOwedToYouData}
            sparkLine02Data={sparkLine02MoneyOwedToYouData}
          />
        </div>

        <div class=" rounded-md">
          <BoxCard01
            isCustomSymbol={false}
            toggleKPISheet={() => toggleKPISheet("Cash Spent")}
            title="Cash Spent"
            data={cashSpentFigureData}
            savedFilterA={cashSpentSavedFilters.firstFilter}
            savedFilterB={cashSpentSavedFilters.comparedTo}
            sparkLine01Data={sparkLine01CashSpentData}
            sparkLine02Data={sparkLine02CashSpentData}
          />
        </div>

        <div class=" rounded-md">
          <BoxCard01
            isCustomSymbol={false}
            toggleKPISheet={() => toggleKPISheet("Cash Received")}
            title="Cash Received"
            data={cashReceivedFigureData}
            savedFilterA={cashReceivedSavedFilters.firstFilter}
            savedFilterB={cashReceivedSavedFilters.comparedTo}
            sparkLine01Data={sparkLine01CashReceivedData}
            sparkLine02Data={sparkLine02CashReceivedData}
          />
        </div>
        <div class=" rounded-md">
          <BoxCard01
            isCustomSymbol={true}
            customSymbol={" Days"}
            toggleKPISheet={() => toggleKPISheet("Average Debtor Days")}
            title="Average Debtor Days"
            savedFilterA={averageDebtorDaysSavedFilters.firstFilter}
            savedFilterB={averageDebtorDaysSavedFilters.comparedTo}
            data={averageDebtorDaysFigureData}
            sparkLine01Data={sparkLine01AverageDebtorDaysData}
            sparkLine02Data={sparkLine02AverageDebtorDaysData}
          />
        </div>
      </div>
    </div>
  );
  const dearKPIComponent = <p></p>;
  const displayKPIComponent = (item) => {
    switch (item) {
      case "XERO":
        return xeroKPIComponent;
      case "DEAR":
        return dearKPIComponent;
      default:
        return "Unknown";
    }
  };
  return (
    <div className="w-full h-full px-5 py-5 bg-light-white flex flex-col">
      <div className="bg-white flex-1 p-5 border-2 border-light-grey mt-5 justify-center items-start">
        <DashboardBanner title="Cash Flow" isGlobalFilterRequired={false} />
        <KPISheetModal
          toggle={toggleKPI}
          toggleModal={toggleKPISheet}
          kpiName={kpiSheetTitle}
        >
          {kpiSheetTitle === "Money You Owe" && (
            <LineChart
              currencySymbol={currencySymbol}
              companyData={moneyYouOweLineGraphData}
              company={organisation}
              title={"Money You Owe"}
            />
          )}
          {kpiSheetTitle === "Average Debtor Days" && (
            <LineChart
              currencySymbol={""}
              companyData={averageDebtorDaysLineGraphData}
              company={organisation}
              title={"Average Debtor Days"}
            />
          )}
          {kpiSheetTitle === "Money Owed To You" && (
            <LineChart
              currencySymbol={currencySymbol}
              companyData={moneyOwedToYouLineGraphData}
              company={organisation}
              title={"Money Owed To You"}
            />
          )}
          {kpiSheetTitle === "Total Bank" && (
            <>
              <LineChart
                currencySymbol={currencySymbol}
                companyData={totalBankLineGraphData}
                company={organisation}
                title={"Total Bank of"}
              />
              <BoxTableCard01
                title={"Total Bank Value"}
                isLoading={isLoadingKPI}
                isWidthFull={true}
                tableData={xeroBankTable}
              />
            </>
          )}
          {kpiSheetTitle === "Cash Spent" && (
            <LineChart
              currencySymbol={currencySymbol}
              companyData={cashSpentLineGraphData}
              company={organisation}
              title={"Cash Spent of"}
            />
          )}
          {kpiSheetTitle === "Cash Received" && (
            <LineChart
              currencySymbol={currencySymbol}
              companyData={cashReceivedLineGraphData}
              company={organisation}
              title={"Cash Received of"}
            />
          )}
        </KPISheetModal>
        {organisation?.connected_apps &&
          organisation.connected_apps.map((item, index) => (
            <div key={index}>{displayKPIComponent(item)}</div>
          ))}
      </div>
    </div>
  );
}

export default DashboardCashFlowPage;

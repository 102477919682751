import axios from "axios";

import {
  ENV_URL,
  ENABLE_MFA,
  CREATE_PHONE_OTP,
  VALIDATE_PHONE_OTP,
} from "../../../constant";


const createOTP = async (otp_data, token) => {
  const response = await axios.post(
    `${ENV_URL}${CREATE_PHONE_OTP}`,
    otp_data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

const checkOTPValidity = async (otp_data, token) => {
  const response = await axios.post(
    `${ENV_URL}${VALIDATE_PHONE_OTP}`,
    otp_data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
const enableMFAAuth = async (slug, value, token) => {
  const response = await axios.put(
    `${ENV_URL}${ENABLE_MFA}${slug}/`,
    value,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
const mfaAuthService = {
  createOTP,
  checkOTPValidity,
  enableMFAAuth
};
export default mfaAuthService;

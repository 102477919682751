import React from "react";
import SpinnerKPI from "../../../../utilis/spinner-kpi";


const TopTenSuppliersTable = ({ data, currencySymbol, title, isLoading }) => {
  return (
    <div className=" rounded-md   border border-gray-300">
      <table className="border-separate border-spacing-0 w-full text-xs text-center">
  <thead className="sticky-header">
    <tr>
      {/* Conditionally render the Supplier Name header */}
      {title !== "Cost of Goods Sold" && (
        <th className="border border-gray-300 px-4 py-2 bg-gray-200">
          Supplier Name
        </th>
      )}
      <th className="border border-gray-300 px-4 py-2 bg-gray-200">
        Current Period
      </th>
      <th className="border border-gray-300 px-4 py-2 bg-gray-200">
        Previous Period
      </th>
      <th className="border border-gray-300 px-4 py-2 bg-gray-200">
        Previous Year
      </th>
    </tr>
  </thead>
  <tbody className="text-gray-600">
    {isLoading ? (
      <tr>
        <td className="border border-gray-300 text-center px-4 py-2" colSpan={title !== "Cost of Goods Sold" ? 4 : 3}>
          <div className="flex items-center justify-center h-full w-full">
            <SpinnerKPI />
          </div>
        </td>
      </tr>
    ) : (
      <>
        {data?.length > 0 ? (
          data.map((row, index) => (
            <tr key={index}>
              {/* Conditionally render the Supplier Name cell */}
              {title !== "Cost of Goods Sold" && (
                <td className="border border-gray-300 px-4 py-2">
                  {title === "Top 10 Carriers By Sales Orders" ? row.breakdown : row.supplier}
                </td>
              )}
              <td className="border border-gray-300 px-4 py-2">
                {currencySymbol}{" "}
                {title === "Top 10 Suppliers By Volume"
                  ? Math.round(row.current_period).toLocaleString()
                  : row.current_period
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {currencySymbol}{" "}
                {title === "Top 10 Suppliers By Volume"
                  ? Math.round(row.previous_period).toLocaleString()
                  : row.previous_period
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {currencySymbol}{" "}
                {title === "Top 10 Suppliers By Volume"
                  ? Math.round(row.previous_year).toLocaleString()
                  : row.previous_year
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td className="border border-gray-300 px-4 py-2" colSpan={title !== "Cost of Goods Sold" ? 4 : 3}>
              No data available
            </td>
          </tr>
        )}
      </>
    )}
  </tbody>
</table>

    </div>
  );
};

export default TopTenSuppliersTable;

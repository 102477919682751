import React, { useState, useEffect } from "react";
import Spinner2 from "../ui-elements/utilis/spinner-2";
import { useSelector } from "react-redux";

function LookerDashboard(props) {
  const { embed } = props;
  const [isLoad, setIsLoad] = useState(true);

  useEffect(() => {
    if (embed) {
      setIsLoad(false);
    }
  }, [embed]);

  return (
    <div className="relative w-full h-full bg-light-grey-2">
      {isLoad ? (
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
          <Spinner2
            isLooker={true}
            note={`Loading organisation charts and elements...`}
          />
        </div>
      ) : (
        <div className="w-full h-full">
          <iframe
            className="w-full h-full"
            src={embed}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      )}
    </div>
  );
}

export default LookerDashboard;

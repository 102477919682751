import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function StackedBarChart(props) {
  const { companyData, company, title } = props;
  if (typeof companyData !== "object" || companyData === null) {
    console.error("Invalid companyData:", companyData);
    return null; // or render an error message or a default chart
  }
  const options = {
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: `${title} ${company?.name}`,
      },
    },
    responsive: true,
    maintainAspectRatio: false, // Set to false for responsiveness
    aspectRatio: 2, // You can adjust this value based on your preference
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  const chartContainerStyle = {
    minHeight: "300px", // Adjust this value as needed
  };

  return (
    <div 
    style={chartContainerStyle}
    className="mt-5 p-2 border rounded-lg border-light-grey bg-white">
      <Bar options={options} data={companyData} />
    </div>
  );
}

export default StackedBarChart;

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LiaUserEditSolid, LiaUserMinusSolid } from "react-icons/lia";
import Select from "react-select";
import InviteNewUserModal from '../../components/ui-elements/modals/invite-new-user';
import Cookies from 'js-cookie';

function ActiveUsersPage(props) {
  const { organisations } = props;
  const [filteredOrganisation, setFilteredOrganisation] = useState(organisations[0]);

  const sortedStaffs = [...(filteredOrganisation?.staffs || [])].sort((a, b) => {
    if (a.role === 'Owner/Admin' && b.role !== 'Owner/Admin') {
      return -1;
    } else if (a.role !== 'Owner/Admin' && b.role === 'Owner/Admin') {
      return 1;
    }
    return 0;
  });

  useEffect(() => {
    if (organisations && organisations.length > 0) {
      setFilteredOrganisation(organisations[0]);
    }
  }, [organisations]);

  const options = organisations?.map((item) => ({
    value: item.name,
    label: item.name,
  }));

  const handleOrgChange = (e) => {
    const org = organisations.filter(o => o.name === e.value);
    setFilteredOrganisation(org[0]);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Administrator"); // Default selected option

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  // Close the dropdown if the user clicks outside of it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (isOpen && !event.target.closest(".dropdown-container")) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isOpen]);

  const [toggleInviteUser, setInviteUserToggle] = useState(false);
  const toggleInviteUserModal = () => {
    setInviteUserToggle(!toggleInviteUser);
  };

  return (
    <div className="w-full flex flex-col gap-4 justify-between">
      <InviteNewUserModal
        toggle={toggleInviteUser}
        toggleModal={toggleInviteUserModal}
      />
      <div className="flex flex-row border-b-2 pb-4 w-full ">
        <div className="w-1/4">
          <div className="relative">
            <Select
              className="basic-single"
              classNamePrefix="select"
              defaultValue={options[0]}
              name="color"
              options={options}
              onChange={(e) => {
                handleOrgChange(e);
              }}
            />
            <div className="flex flex-col gap-2 mt-6">
              <p className="text-sm font-light">
                Connect your colleagues. You can send invitation to them. 
              </p>
              <button
                className="mt-4 w-1/2 px-4 py-2.5 text-white bg-grey font-medium text-sm rounded"
                onClick={toggleInviteUserModal}
                disabled
              >
                Invite User
              </button>
            </div>
          </div>
        </div>
        <div className="bg-white w-3/4 rounded-sm px-6">
          <div className="mt-2">
            <table className="table-auto w-full border-collapse">
              
            <p className="text-dark-purple text-xl font-bold pl-2">Active Users</p>
                <tbody className="bg-white p-4">
                {sortedStaffs?.map((r, index) => (
                  <tr key={index} className="justify-end">
                    <td className="border-b-2 border-grey p-4">
                      <div className="flex flex-row gap-2">
                        <p className="font-semibold text-xl">{r.name}</p>
                        {/* <p className="text-[12px] bg-light-purple text-purple py-1 px-3 rounded-md">
                          {r.role || "Guest"}
                        </p> */}
                      </div>
                      <p className="font-regular text-sm text-grey">{r.email}</p>
                    </td>
                    <td className="border-b-2 border-grey">
                      <p className={`-mt-8 inline-block font-semibold text-md ${r.role === 'Owner/Admin' ? 'bg-green-200 text-green-700': 'bg-light-purple text-purple'} py-1 px-3 rounded-xl`}>
                        {r.role || "Guest"}
                      </p>
                    </td>
                    <td className="border-b-2 border-grey p-4">
                      <p className="font-semibold text-md">Last Active</p>
                      <p className="font-regular text-grey text-sm">
                        {moment(filteredOrganisation?.updated_at).fromNow()}
                      </p>
                    </td>
                    {/* <td className="border-b-2 border-grey py-4 px-6">
                      <div className="relative inline-block text-left dropdown-container">
                        <div>
                          <button
                            type="button"
                            className="flex items-center justify-center w-44 py-3 border rounded-sm shadow-sm text-md font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-indigo-500"
                            onClick={toggleDropdown}
                          >
                            {r.role || "Guest"}
                          </button>
                        </div>
                        {isOpen && (
                          <div className="origin-top-right absolute right-0 mt-2 w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                            <div
                              className="py-1 px-1"
                              role="menu"
                              aria-orientation="vertical"
                              aria-labelledby="options-menu"
                            >
                              <button
                                onClick={() => handleOptionClick("Owner / Admin")}
                                className={`block px-4 py-3 text-sm font-medium ${selectedOption === "Owner / Admin" ? "bg-gray-100" : "hover:bg-gray-100"} w-full text-left`}
                                role="menuitem"
                              >
                                Owner / Admin
                                {/* <p className="text-[12px] font-light pt-2">
                                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </p> */}
                    {/* </button>
                              <button
                                onClick={() => handleOptionClick("Editor")}
                                className={`block px-4 py-3 text-sm font-medium ${selectedOption === "Editor" ? "bg-gray-100" : "hover:bg-gray-100"} w-full text-left`}
                                role="menuitem"
                              >
                                Editor */}
                    {/* <p className="text-[12px] font-light pt-2">
                                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </p> */}
                    {/* </button>
                              <button
                                onClick={() => handleOptionClick("Viewer")}
                                className={`block px-4 py-3 text-sm font-medium ${selectedOption === "Viewer" ? "bg-gray-100" : "hover:bg-gray-100"} w-full text-left`}
                                role="menuitem"
                              >
                                Viewer */}
                    {/* <p className="text-[12px] font-light pt-2">
                                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </p> */}
                    {/* </button> */}
                    {/* </div> */}
                    {/* </div> */}
                    {/* )} */}
                    {/* </div> */}
                    {/* </td> */}
                    <td className="border-b-2 border-grey">
                      <LiaUserMinusSolid size={28} className=" text-grey" disabled />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActiveUsersPage;

{/* <tr className="justify-end">
                    <td className="border-b-2 border-grey p-4 ">
                      <div className="flex flex-row gap-2">
                        <p className="font-semibold text-xl">Nitish Gautam</p>
                        <p className="text-[12px] bg-light-grey text-grey py-1 px-3 rounded-md">
                          Pending
                        </p>
                      </div>
                      <p className="font-regular text-grey">{r}</p>
                    </td>
                    <td className="border-b-2 border-grey p-4 "></td>
                    <td className="border-b-2 border-grey p-4 "></td>
                    <td className="border-b-2 border-grey p-4 "></td>
                    <td className="border-b-2 border-grey p-4 "></td>
                    <td className="border-b-2 border-grey p-4 "></td>
                    <td className="border-b-2 border-grey p-4 "></td>
                    <td className="border-b-2 border-grey p-4 "></td>
                    <td className="border-b-2 border-grey p-4 "></td>
                    <td className="border-b-2 border-grey p-4 "></td>
                    <td className="border-b-2 border-grey p-4 "></td>
                    <td className="border-b-2 border-grey ">
                      <div className="flex gap-4 flex-row">
                        <button className=" flex flex-row items-center justify-center gap-4 py-2 px-4 border-2 border-purple text-sm text-purple font-bold  rounded">
                          Resend Invite
                        </button>
                        <button className=" flex flex-row items-center justify-center gap-4 py-2 px-4 border-2 border-danger text-sm text-danger font-bold  rounded">
                          Revoke Invite
                        </button>
                      </div>
                    </td>
                  </tr> */}
import axios from 'axios'

import { REGISTER_OWNER_API_URL, REGISTER_ADVISOR_API_URL, LOGIN_USER, PASSWORD_REST, PASSWORD_REST_CONFIRM, ENV_URL, REGISTER_WITH_XERO, GET_USER_PROFILE } from '../../../constant'


// Registration 
const registerOwner= async (userData)=>{
    const response=await axios.post(`${ENV_URL}${REGISTER_OWNER_API_URL}`, userData)
    if(response.data){
        localStorage.setItem('user', JSON.stringify(response.data))
    }
    return response.data
}
const registerAdvisor = async (userData) => {
  const response = await axios.post(
    `${ENV_URL}${REGISTER_ADVISOR_API_URL}`,
    userData
  );
  if (response.data) {
    localStorage.setItem("user", JSON.stringify(response.data));
  }
  return response.data;
};
const registerWithXero= async ()=>{
  const response=await axios.get(`${ENV_URL}${REGISTER_WITH_XERO}`)
  // if(response.data){
  //     localStorage.setItem('user', JSON.stringify(response.data))
  // }
  
  return response.data
}

// Login 
const signInUser = async (userData) => {
  const response = await axios.post(
    `${ENV_URL}${LOGIN_USER}`,
    userData
  );
  if (response.data) {
    localStorage.setItem("user", JSON.stringify(response.data));
  }
  return response.data;
  
};
// Password reset
const getNewPassword = async (userData) => {
  const response = await axios.post(
    `${ENV_URL}${PASSWORD_REST}`,
    userData
  )
  if (response.data) {
    localStorage.setItem("user", JSON.stringify(response.data));
  }
  return response.data;
};
const setNewPassword = async (userData, encoded_pk, token) => {
  const response = await axios.post(
    `${ENV_URL}${PASSWORD_REST_CONFIRM}${encoded_pk}/${token}/`,
    { password: userData }
  );
  return response.data;
};
const signOutUser=()=>{
localStorage.removeItem('user')
localStorage.removeItem("organisation");
}

// Profile Data
const getUserProfile = async (slug, token) => {
  const response = await axios.get(`${ENV_URL}${GET_USER_PROFILE}${slug}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (response) {
    localStorage.setItem("user", JSON.stringify(response));

  }
  return response.data;
};



const authService={
    registerOwner,
    registerAdvisor,
    registerWithXero,
    signInUser,
    signOutUser, 
    getNewPassword,
    setNewPassword,
    getUserProfile
}
export default authService
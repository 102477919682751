import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import integrationService from "./integrationService";
// Initial State of Auth Reducer
const initialState = {
  apps: [],
  app: {},
  xeroConnectionStatus:{},
  isSuccessXero: false,
  isSuccessCin7: false,
  isSuccessDear: false,
  isLoading: false,
  isError: false,
  message: "",
};
// Action Creators
export const connectXero = createAsyncThunk(
  "apps/connect/xero/",
  async ({slug, token}, thunkAPI) => {
    try {
      return await integrationService.connectXero(slug, token);
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const connectDear = createAsyncThunk(
  "apps/connect/dear/",
  async ({org_data, slug, token}, thunkAPI) => {
    try {
      return await integrationService.connectDear(org_data, slug, token);
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const disconnectXero = createAsyncThunk(
  "apps/disconnect/xero/",
  async ({ slug, token }, thunkAPI) => {
    try {
      return await integrationService.disconnectXero(slug, token);
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateAppConnection = createAsyncThunk(
  "apps/connect/app/success",
  async ({ app_data, token }, thunkAPI) => {
    try {
      return await integrationService.updateAppConnection(app_data, token);
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const connectCin7 = createAsyncThunk(
  "apps/connect/cin7/",
  async ({ org_data,slug, token }, thunkAPI) => {
    try {
      return await integrationService.connectCin7( org_data,slug, token);
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const integrationSlice = createSlice({
  name: "integration",
  initialState,
  reducers: {
    resetIntegration: (state) => {
      state.isLoading = false;
      state.isSuccessCin7 = false;
      state.isSuccessXero = false;
      state.isSuccessDear = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      // For Xero
      .addCase(connectXero.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(connectXero.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccessXero = true;
      })
      .addCase(connectXero.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.app = null;
      })
      // Dear Connection
      .addCase(connectDear.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(connectDear.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccessDear = true;
      })
      .addCase(connectDear.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.app = null;
      })
      .addCase(disconnectXero.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(disconnectXero.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccessXero = true;
        state.xeroConnectionStatus=action.payload
      })
      .addCase(disconnectXero.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.xeroConnectionStatus = null;

      })
      //  For Cin 7
      .addCase(connectCin7.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(connectCin7.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccessCin7 = true;
        // state.app = action.payload;
      })
      .addCase(connectCin7.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.app = null;
      });
       
      
  },
});
export default integrationSlice.reducer;
export const { resetIntegration } = integrationSlice.actions;

import React, { useEffect, useState } from "react";
import { BiSolidBusiness } from "react-icons/bi";
import { ImUser, ImUsers } from "react-icons/im";
import SubscriptionPaymentModal from "../modals/subscription-payment";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerSubscription } from "../../../../redux-features/slices/subscription/subscriptionSlice";
const PricingCard = ({
  plan,
  selectedOption,
}) => {
  const [togglePaySubscription, setPaySubscriptionToggle] = useState(false);
  const dispatch=useDispatch()
  const togglePaySubscriptionModal = () => {
    setPaySubscriptionToggle(!togglePaySubscription);
  };
 const {  payment_status } = useSelector(
   (state) => state.subscription
 );
const { user } = useSelector((state) => state.auth);
const token = user?.data?.api_token;
useEffect(() => {
  const fetchOrganisations = async () => {
    if (token) {
      await dispatch(getCustomerSubscription({ token }));
    }
  };

  fetchOrganisations();
}, []);

  return (
    <div className="w-full rounded overflow-hidden border-[1px] hover:border-light-purple border-dark-purple mx-3 my-3 p-5">
      <SubscriptionPaymentModal
      plan={plan}
        toggle={togglePaySubscription}
        toggleModal={togglePaySubscriptionModal}
        payment_status={payment_status}
      />
      <div className="flex flex-col px-4 mt-3 gap-5">
        <div className="flex flex-col gap-2">
          <p className="text-lg font-bold">
            {plan?.unit_amount_decimal === "1000"
              ? "Basic"
              : plan?.unit_amount_decimal === "2000"
              ? "Pro"
              : plan?.unit_amount_decimal === "3000"
              ? "Enterprise"
              : plan?.unit_amount_decimal === "4000" && "Business"}
          </p>
          <p className="text-sm font-regular mb-2">
            {plan?.unit_amount_decimal === "1000"
              ? "Best for one team"
              : plan?.unit_amount_decimal === "2000"
              ? "Best for two teams"
              : plan?.unit_amount_decimal === "3000"
              ? "Best for a whole enterprise"
              : plan?.unit_amount_decimal === "4000" &&
                "Best for large businesses"}
          </p>
        </div>
      </div>
      <div className="flex flex-col items-start justify-start px-4 py-4">
        <div className="text-base h-10">
          €
          <span className="text-3xl font-bold">
            {plan?.unit_amount_decimal === "1000"
              ? "10"
              : plan?.unit_amount_decimal === "2000"
              ? "20"
              : plan?.unit_amount_decimal === "3000"
              ? "30"
              : plan?.unit_amount_decimal === "4000" && "40"}
          </span>
          {/* {selectedOption === "monthly" ? "/month" : "/year"} */}
          /month
        </div>
        <div className="flex flex-row justify-between w-full mt-10">
          <p className="text-md font-bold">Features</p>
          <p className="text-sm text-grey underline cursor-pointer">All</p>
        </div>
        <ul className="mt-4 mb-8 flex gap-3 flex-col h-32">
          <li className="flex items-center ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-gray-400 mr-2 border-2 border-dark-purple rounded-full"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M17.707 5.293a1 1 0 00-1.414 0L8 13.586 4.707 10.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z"
                clipRule="evenodd"
              />
            </svg>
            <p className="text-sm font-light">Feature 01</p>
          </li>
          <li className="flex items-center ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-gray-400 mr-2 border-2 border-dark-purple rounded-full"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M17.707 5.293a1 1 0 00-1.414 0L8 13.586 4.707 10.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z"
                clipRule="evenodd"
              />
            </svg>
            <p className="text-sm font-light">Feature 02</p>
          </li>
        </ul>
      </div>
      <div className="flex flex-col justify-center items-center gap-3">
        <button
          onClick={togglePaySubscriptionModal}
          className="bg-dark-purple w-full hover:bg-white border-2 hover:text-dark-purple hover:border-dark-purple text-white font-semibold py-2 px-4 rounded"
        >
          Get Started
        </button>
        <p className="text-sm font-medium text-dark-purple underline cursor-pointer">
          Contact Support Team
        </p>
      </div>
    </div>
  );
};

export default PricingCard;

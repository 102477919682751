// graphData.js

import { useSelector } from "react-redux";

// Assuming your Redux state has a property named 'lineGraphs'
const GraphData = () => {
  const {
    lineGraphs,
    trackingCategory01,
    trackingCategory02,
    salesByBrandDateFilter,
    salesByBrandCompareFilter,
    salesByProductDateFilter,
    salesByProductCompareFilter,
    salesByCategoryDateFilter,
    salesByCategoryCompareFilter,
    salesByCustomerDateFilter,
    salesByCustomerCompareFilter,
    salesByProductTagDateFilter,
    salesByProductTagCompareFilter,
    salesByProductTypeDateFilter,
    salesByProductTypeCompareFilter,
    salesByCustomerTagCompareFilter,
    salesByCustomerTagDateFilter,
    profitByCategoryDateFilter,
    profitByCategoryCompareFilter,
    profitByProductDateFilter,
    profitByProductCompareFilter,
    profitByBrandDateFilter,
    profitByBrandCompareFilter,
    unitsSoldByBrandDateFilter,
unitsSoldByBrandCompareFilter,
unitsSoldByProductDateFilter,
unitsSoldByProductCompareFilter,
unitsSoldByCategoryDateFilter,
unitsSoldByCategoryCompareFilter,
unitsSoldByCustomerDateFilter,
unitsSoldByCustomerCompareFilter,
unitsSoldByProductTagDateFilter,
unitsSoldByProductTagCompareFilter,
unitsSoldByProductTypeDateFilter,
unitsSoldByProductTypeCompareFilter,
unitsSoldByCustomerTagCompareFilter,
unitsSoldByCustomerTagDateFilter,
// Units Shipped Filters
unitsShippedByBrandDateFilter,
unitsShippedByBrandCompareFilter,
unitsShippedByProductDateFilter,
unitsShippedByProductCompareFilter,
unitsShippedByCategoryDateFilter,
unitsShippedByCategoryCompareFilter,
unitsShippedByCustomerDateFilter,
unitsShippedByCustomerCompareFilter,
unitsShippedByProductTagDateFilter,
unitsShippedByProductTagCompareFilter,
unitsShippedByProductTypeDateFilter,
unitsShippedByProductTypeCompareFilter,
unitsShippedByCustomerTagCompareFilter,
unitsShippedByCustomerTagDateFilter,

    dearProductSalesLineGraph,
    dearProductSalesByBrand,
    dearProductSalesByCategory,
    dearProductSalesByCustomer,
    dearProductSalesByCustomerTag,
    dearProductSalesByProduct,
    dearProductSalesByProductTag,
    dearProductSalesByProductType,
    dearProfitLineGraph,
    dearProfitByBrand,
    dearProfitByCategory,
    dearProfitByCustomer,
    dearProfitByCustomerTag,
    dearProfitByProduct,
    dearProfitByProductTag,
    dearProfitByProductType,
    dearUnitsSoldLineGraph,
dearUnitsSoldByBrand,
dearUnitsSoldByCategory,
dearUnitsSoldByCustomer,
dearUnitsSoldByCustomerTag,
dearUnitsSoldByProduct,
dearUnitsSoldByProductTag,
dearUnitsSoldByProductType,
// Units Shipped
dearUnitsShippedLineGraph,
dearUnitsShippedByBrand,
dearUnitsShippedByCategory,
dearUnitsShippedByCustomer,
dearUnitsShippedByCustomerTag,
dearUnitsShippedByProduct,
dearUnitsShippedByProductTag,
dearUnitsShippedByProductType,
profitByCustomerDateFilter,
profitByCustomerCompareFilter,
profitByCustomerTagDateFilter,
profitByCustomerTagCompareFilter,
profitByProductTagDateFilter,
profitByProductTagCompareFilter,
profitByProductTypeDateFilter,
profitByProductTypeCompareFilter
  } = useSelector((state) => state.kpi);


  
  
  
  // Gross Profit Line Graph
  const grossProfitLineData = Array.isArray(lineGraphs)
    ? lineGraphs.find((graph) => graph.graph_KPI_name === "gross_profit")
    : null;
  let grossProfitLineGraphData = {
    labels: [],
    datasets: [
      {
        label: "Current Year (2023-24)",
        borderColor: "#9C27B0",
        backgroundColor: "#E1BEE7",
        data: [],
      },
      {
        label: "Previous Year (2022-23)",
        borderColor: "#FF9800",
        backgroundColor: "#FFE0B2",
        data: [],
      },
    ],
  };

  if (grossProfitLineData && Array.isArray(grossProfitLineData.gross_profit)) {
    const formattedGrossProfitLineGraphData = grossProfitLineData.gross_profit
      .map((item) => {
        const date = new Date(item.month);
        const monthYearString = new Intl.DateTimeFormat("en", {
          year: "numeric",
          month: "short",
        }).format(date);
        return { ...item, month: monthYearString };
      })
      .sort((a, b) => new Date(a.month) - new Date(b.month));

    grossProfitLineGraphData = {
      labels: formattedGrossProfitLineGraphData.map((item) => item.month),
      datasets: [
        {
          label: "Current Year (2023-24)",
          
          borderColor: "#9C27B0",
        backgroundColor: "#E1BEE7",
          data: formattedGrossProfitLineGraphData.map(
            (item) => item.gross_profit
          ),
        },
        {
          label: "Previous Year (2022-23)",
          borderColor: "#FF9800",
          backgroundColor: "#FFE0B2",
          data: formattedGrossProfitLineGraphData.map(
            (item) => item.previous_year_gross_profit
          ),
        },
      ],
    };
  }

  // Net Profit Line Graph
  const netProfitLineData = Array.isArray(lineGraphs)
    ? lineGraphs.find((graph) => graph.graph_KPI_name === "net_profit")
    : null;
  let netProfitLineGraphData = {
    labels: [],
    datasets: [
      {
        label: "Current Year (2023-24)",
        borderColor: "#9C27B0",
        backgroundColor: "#E1BEE7",
        data: [],
      },
      {
        label: "Previous Year (2022-23)",
        borderColor: "#FF9800",
        backgroundColor: "#FFE0B2",
        data: [],
      },
    ],
  };

  if (netProfitLineData && Array.isArray(netProfitLineData.net_profit)) {
    const formattedNetProfitLineGraphData = netProfitLineData.net_profit
      .map((item) => {
        const date = new Date(item.month);
        const monthYearString = new Intl.DateTimeFormat("en", {
          year: "numeric",
          month: "short",
        }).format(date);
        return { ...item, month: monthYearString };
      })
      .sort((a, b) => new Date(a.month) - new Date(b.month));

    netProfitLineGraphData = {
      labels: formattedNetProfitLineGraphData.map((item) => item.month),
      datasets: [
        {
          label: "Current Year (2023-24)",
          borderColor: "#9C27B0",
        backgroundColor: "#E1BEE7",
          data: formattedNetProfitLineGraphData.map((item) => item.net_profit),
        },
        {
          label: "Previous Year (2022-23)",
          borderColor: "#FF9800",
        backgroundColor: "#FFE0B2",
          data: formattedNetProfitLineGraphData.map(
            (item) => item.previous_year_net_profit
          ),
        },
      ],
    };
  }
  // Net Profit Margin

  const netProfitMarginLineData = Array.isArray(lineGraphs)
    ? lineGraphs.find((graph) => graph.graph_KPI_name === "net_profit_margin")
    : null;
 
// Net Profit Margin Line Graph
let netProfitMarginLineGraphData = {
  labels: [],
  datasets: [
    {
      label: "Current Year (2023-24)",
      borderColor: "#9C27B0",
      backgroundColor: "#E1BEE7",
      data: [],
    },
    {
      label: "Previous Year (2022-23)",
      borderColor: "#FF9800",
      backgroundColor: "#FFE0B2",
      data: [],
    },
  ],
};

if (
  netProfitMarginLineData &&
  Array.isArray(netProfitMarginLineData.net_profit_margin)
) {
  const formattedNetProfitMarginLineGraphData = netProfitMarginLineData.net_profit_margin.map((item) => {
    const date = new Date(item.month);
    const monthYearString = new Intl.DateTimeFormat("en", {
      year: "numeric",
      month: "short",
    }).format(date);
    return { ...item, month: monthYearString };
  }).sort((a, b) => new Date(a.month) - new Date(b.month));

  // Multiply y-axis values by 100
// Multiply y-axis values by 100
const multipliedData = formattedNetProfitMarginLineGraphData.map((item) => ({
  net_profit_margin: item.net_profit_margin * 100, // Multiply by 100
  previous_year_net_profit_margin: item.previous_year_net_profit_margin * 100, // Multiply by 100
}));


  netProfitMarginLineGraphData = {
    
    labels: formattedNetProfitMarginLineGraphData.map((item) => item.month),
    datasets: [
      {
        label: "Current Year (2023-24)",
        borderColor: "#9C27B0",
        backgroundColor: "#E1BEE7",
        data: multipliedData.map((item) => item.net_profit_margin),
      },
      {
        label: "Previous Year (2022-23)",
        borderColor: "#FF9800",
        backgroundColor: "#FFE0B2",
        data: multipliedData.map((item) => item.previous_year_net_profit_margin),
      },
    ],
  };
}

  // Total Cost of Sales Line Graph

  const totalCostOfSalesData = Array.isArray(lineGraphs)
    ? lineGraphs.find((graph) => graph.graph_KPI_name === "total_cost_of_sales")
    : null;
  let totalCostOfSalesLineGraphData = {
    labels: [],
    datasets: [
      {
        label: "Current Year (2023-24)",
        borderColor: "#9C27B0",
        backgroundColor: "#E1BEE7",
        data: [],
      },
      {
        label: "Previous Year (2022-23)",
        borderColor: "#FF9800",
        backgroundColor: "#FFE0B2",
        data: [],
      },
    ],
  };

  if (
    totalCostOfSalesData &&
    Array.isArray(totalCostOfSalesData.total_cost_of_sales)
  ) {
    const formattedTotalCostOfSalesLineGraphData =
      totalCostOfSalesData.total_cost_of_sales
        .map((item) => {
          const date = new Date(item.month);
          const monthYearString = new Intl.DateTimeFormat("en", {
            year: "numeric",
            month: "short",
          }).format(date);
          return { ...item, month: monthYearString };
        })
        .sort((a, b) => new Date(a.month) - new Date(b.month));

    totalCostOfSalesLineGraphData = {
      labels: formattedTotalCostOfSalesLineGraphData.map((item) => item.month),
      datasets: [
        {
          label: "Current Year (2023-24)",
          borderColor: "#9C27B0",
          backgroundColor: "#E1BEE7",
          data: formattedTotalCostOfSalesLineGraphData.map(
            (item) => item.total_cost_of_sales
          ),
        },
        {
          label: "Previous Year (2022-23)",
          borderColor: "#FF9800",
          backgroundColor: "#FFE0B2",
          data: formattedTotalCostOfSalesLineGraphData.map(
            (item) => item.previous_year_total_cost_of_sales
          ),
        },
      ],
    };
  }
  // Total Costs Line Graph

  const totalCostsData = Array.isArray(lineGraphs)
    ? lineGraphs.find((graph) => graph.graph_KPI_name === "total_costs")
    : null;
  let totalCostsLineGraphData = {
    labels: [],
    datasets: [
      {
        label: "Current Year (2023-24)",
        borderColor: "#9C27B0",
        backgroundColor: "#E1BEE7",
        data: [],
      },
      {
        label: "Previous Year (2022-23)",
        borderColor: "#FF9800",
        backgroundColor: "#FFE0B2",
        data: [],
      },
    ],
  };

  if (totalCostsData && Array.isArray(totalCostsData.total_costs)) {
    const formattedTotalCostsLineGraphData = totalCostsData.total_costs
      .map((item) => {
        const date = new Date(item.month);
        const monthYearString = new Intl.DateTimeFormat("en", {
          year: "numeric",
          month: "short",
        }).format(date);
        return { ...item, month: monthYearString };
      })
      .sort((a, b) => new Date(a.month) - new Date(b.month));

    totalCostsLineGraphData = {
      labels: formattedTotalCostsLineGraphData.map((item) => item.month),
      datasets: [
        {
          label: "Current Year (2023-24)",
          borderColor: "#9C27B0",
          backgroundColor: "#E1BEE7",
          data: formattedTotalCostsLineGraphData.map(
            (item) => item.total_costs
          ),
        },
        {
          label: "Previous Year (2022-23)",
          borderColor: "#FF9800",
          backgroundColor: "#FFE0B2",
          data: formattedTotalCostsLineGraphData.map(
            (item) => item.previous_year_total_costs
          ),
        },
      ],
    };
  }
  // Operating Cost Percentage to Revenue Line Graph

  const operatingCostPercentageRevenueData = Array.isArray(lineGraphs)
  ? lineGraphs?.find((graph) => graph.graph_KPI_name === "operating_cost_percentage_revenue")
  : null;

let operatingCostPercentageRevenueLineGraphData = {
  labels: [],
  datasets: [
    {
      label: "Current Year (2023-24)",
      borderColor: "#9C27B0",
      backgroundColor: "#E1BEE7",
      data: [],
    },
    {
      label: "Previous Year (2022-23)",
      borderColor: "#FF9800",
      backgroundColor: "#FFE0B2",
      data: [],
    },
  ],
};

if (operatingCostPercentageRevenueData && Array.isArray(operatingCostPercentageRevenueData.operating_cost_percentage_revenue)) {
  const formattedOperatingCostPercentageRevenueLineGraphData = operatingCostPercentageRevenueData.operating_cost_percentage_revenue.map((item) => {
    const date = new Date(item.month);
    const monthYearString = new Intl.DateTimeFormat("en", {
      year: "numeric",
      month: "short",
    }).format(date);
    return { ...item, month: monthYearString };
  }).sort((a, b) => new Date(a.month) - new Date(b.month));

  // Multiply y-axis values by 100
  const multipliedData = formattedOperatingCostPercentageRevenueLineGraphData.map((item) => ({
    operating_cost_percentage_revenue: item.operating_cost_percentage_revenue * 100,
    previous_year_operating_cost_percentage_revenue: item.previous_year_operating_cost_percentage_revenue * 100,
  }));

  operatingCostPercentageRevenueLineGraphData = {
    labels: formattedOperatingCostPercentageRevenueLineGraphData.map((item) => item.month),
    datasets: [
      {
        label: "Current Year (2023-24)",
        borderColor: "#9C27B0",
        backgroundColor: "#E1BEE7",
        data: multipliedData.map((item) => item.operating_cost_percentage_revenue),
      },
      {
        label: "Previous Year (2022-23)",
        borderColor: "#FF9800",
        backgroundColor: "#FFE0B2",
        data: multipliedData.map((item) => item.previous_year_operating_cost_percentage_revenue),
      },
    ],
  };
}

  // Total Operating Expenses Line Graph

  const totalOperatingExpensesData = Array.isArray(lineGraphs)
    ? lineGraphs.find(
        (graph) => graph.graph_KPI_name === "total_operating_expenses"
      )
    : null;
  let totalOperatingExpensesLineGraphData = {
    labels: [],
    datasets: [
      {
        label: "Current Year (2023-24)",
        borderColor: "#9C27B0",
        backgroundColor: "#E1BEE7",
        data: [],
      },
      {
        label: "Previous Year (2022-23)",
        borderColor: "#FF9800",
        backgroundColor: "#FFE0B2",
        data: [],
      },
    ],
  };

  if (
    totalOperatingExpensesData &&
    Array.isArray(totalOperatingExpensesData.total_operating_expenses)
  ) {
    const formattedTotalOperatingExpensesLineGraphData =
      totalOperatingExpensesData.total_operating_expenses
        .map((item) => {
          const date = new Date(item.month);
          const monthYearString = new Intl.DateTimeFormat("en", {
            year: "numeric",
            month: "short",
          }).format(date);
          return { ...item, month: monthYearString };
        })
        .sort((a, b) => new Date(a.month) - new Date(b.month));

    totalOperatingExpensesLineGraphData = {
      labels: formattedTotalOperatingExpensesLineGraphData.map(
        (item) => item.month
      ),
      datasets: [
        {
          label: "Current Year (2023-24)",
          borderColor: "#9C27B0",
        backgroundColor: "#E1BEE7",
          data: formattedTotalOperatingExpensesLineGraphData.map(
            (item) => item.total_operating_expenses
          ),
        },
        {
          label: "Previous Year (2022-23)",
          borderColor: "#FF9800",
          backgroundColor: "#FFE0B2",
          data: formattedTotalOperatingExpensesLineGraphData.map(
            (item) => item.previous_year_total_operating_expenses
          ),
        },
      ],
    };
  }
  // Gross Profit Margin Line Graph

  // const grossProfitMarginData = Array.isArray(lineGraphs)
  //   ? lineGraphs.find((graph) => graph.graph_KPI_name === "gross_profit_margin")
  //   : { gross_profit_margin: {} }; // Provide default data if not found

  // let grossProfitMarginLineGraphData = {
  //   labels: [],
  //   datasets: [
  //     {
  //       label: "Gross Profit Margin",
  //       borderColor: "#FF5722",
  //       backgroundColor: "#FFCCBC",
  //       data: [],
  //     },
  //     {
  //       label: "Previous Year (2022-23)",
  //       borderColor: "#3F51B5",
  //       backgroundColor: "#C5CAE9",
  //       data: [],
  //     },
  //   ],
  // };

  // if (Array.isArray(grossProfitMarginData.gross_profit_margin)) {
  //   const formattedGrossProfitMarginLineGraphData =
  //     grossProfitMarginData.gross_profit_margin
  //       .map((item) => {
  //         const date = new Date(item.month);
  //         const monthYearString = new Intl.DateTimeFormat("en", {
  //           year: "numeric",
  //           month: "short",
  //         }).format(date);
  //         return { ...item, month: monthYearString };
  //       })
  //       .sort((a, b) => new Date(a.month) - new Date(b.month));

  //   grossProfitMarginLineGraphData = {
  //     labels: formattedGrossProfitMarginLineGraphData.map((item) => item.month),
  //     datasets: [
  //       {
  //         label: "Gross Profit Margin",
  //         borderColor: "#FF5722",
  //         backgroundColor: "#FFCCBC",
  //         data: formattedGrossProfitMarginLineGraphData.map(
  //           (item) => item.gross_profit_margin
  //         ),
  //       },
  //       {
  //         label: "Previous Year (2022-23)",
  //         borderColor: "#3F51B5",
  //         backgroundColor: "#C5CAE9",
  //         data: formattedGrossProfitMarginLineGraphData.map(
  //           (item) => item.previous_year
  //         ),
  //       },
  //     ],
  //   };
  // }

  const grossProfitMarginData = Array.isArray(lineGraphs)
  ? lineGraphs.find((graph) => graph.graph_KPI_name === "gross_profit_margin")
  : { gross_profit_margin: {} }; // Provide default data if not found

let grossProfitMarginLineGraphData = {
  labels: [],
  datasets: [
    {
      label: "Current Year (2023-24)",
      borderColor: "#9C27B0",
      backgroundColor: "#E1BEE7",
      data: [],
    },
    {
      label: "Previous Year (2022-23)",
      borderColor: "#FF9800",
      backgroundColor: "#FFE0B2",
      data: [],
    },
  ],
};

if (Array.isArray(grossProfitMarginData.gross_profit_margin)) {
  const formattedGrossProfitMarginLineGraphData = grossProfitMarginData.gross_profit_margin.map((item) => {
    const date = new Date(item.month);
    const monthYearString = new Intl.DateTimeFormat("en", {
      year: "numeric",
      month: "short",
    }).format(date);
    return { ...item, month: monthYearString };
  }).sort((a, b) => new Date(a.month) - new Date(b.month));

  // Multiply y-axis values by 100
  const multipliedData = formattedGrossProfitMarginLineGraphData.map((item) => ({
    gross_profit_margin: item.gross_profit_margin * 100,
    previous_year: item.previous_year * 100,
  }));

  grossProfitMarginLineGraphData = {
    labels: formattedGrossProfitMarginLineGraphData.map((item) => item.month),
    datasets: [
      {
        label: "Current Year (2023-24)",
        borderColor: "#9C27B0",
        backgroundColor: "#E1BEE7",
        data: multipliedData.map((item) => item.gross_profit_margin),
      },
      {
        label: "Previous Year (2022-23)",
        borderColor: "#FF9800",
        backgroundColor: "#FFE0B2",
        data: multipliedData.map((item) => item.previous_year),
      },
    ],
  };
}

  // Current Assets Line Graph
  const currentAssetsData = Array.isArray(lineGraphs)
    ? lineGraphs.find((graph) => graph.graph_KPI_name === "current_assets")
    : null;

  let currentAssetsLineGraphData = {
    labels: [],
    datasets: [
      {
        label: "Current Year (2023-24)",
        borderColor: "#9C27B0",
        backgroundColor: "#E1BEE7",
        data: [],
      },
      {
        label: "Previous Year (2022-23)",
        borderColor: "#FF9800",
        backgroundColor: "#FFE0B2",
        data: [],
      },
    ],
  };

  if (currentAssetsData && Array.isArray(currentAssetsData.current_assets)) {
    const formattedCurrentAssetsLineGraphData = currentAssetsData.current_assets
      .map((item) => {
        const date = new Date(item.month);
        const monthYearString = new Intl.DateTimeFormat("en", {
          year: "numeric",
          month: "short",
        }).format(date);
        return { ...item, month: monthYearString };
      })
      .sort((a, b) => new Date(a.month) - new Date(b.month));

    currentAssetsLineGraphData = {
      labels: formattedCurrentAssetsLineGraphData.map((item) => item.month),
      datasets: [
        {
          label: "Current Year (2023-24)",
          borderColor: "#9C27B0",
        backgroundColor: "#E1BEE7",
          data: formattedCurrentAssetsLineGraphData.map(
            (item) => item.current_assets
          ),
        },
        {
          label: "Previous Year (2022-23)",
          borderColor: "#FF9800",
          backgroundColor: "#FFE0B2",
          data: formattedCurrentAssetsLineGraphData.map(
            (item) => item.previous_year_current_assets
          ),
        },
      ],
    };
  }

  // Current Liabilities Line Graph

  const currentLiabilitiesData = Array.isArray(lineGraphs)
    ? lineGraphs.find((graph) => graph.graph_KPI_name === "current_liabilities")
    : null;
  let currentLiabilitiesLineGraphData = {
    labels: [],
    datasets: [
      {
        label: "Current Year (2023-24)",
        borderColor: "#9C27B0",
        backgroundColor: "#E1BEE7",
        data: [],
      },
      {
        label: "Previous Year (2022-23)",
        borderColor: "#FF9800",
        backgroundColor: "#FFE0B2",
        data: [],
      },
    ],
  };

  if (
    currentLiabilitiesData &&
    Array.isArray(currentLiabilitiesData.current_liabilities)
  ) {
    const formattedCurrentLiabilitiesLineGraphData =
      currentLiabilitiesData.current_liabilities
        .map((item) => {
          const date = new Date(item.month);
          const monthYearString = new Intl.DateTimeFormat("en", {
            year: "numeric",
            month: "short",
          }).format(date);
          return { ...item, month: monthYearString };
        })
        .sort((a, b) => new Date(a.month) - new Date(b.month));

    currentLiabilitiesLineGraphData = {
      labels: formattedCurrentLiabilitiesLineGraphData.map(
        (item) => item.month
      ),
      datasets: [
        {
          label: "Current Year (2023-24)",
          borderColor: "#9C27B0",
        backgroundColor: "#E1BEE7",
          data: formattedCurrentLiabilitiesLineGraphData.map(
            (item) => item.current_liabilities
          ),
        },
        {
          label: "Previous Year (2022-23)",
          borderColor: "#FF9800",
        backgroundColor: "#FFE0B2",
          data: formattedCurrentLiabilitiesLineGraphData.map(
            (item) => item.previous_year_current_liabilities
          ),
        },
      ],
    };
  }

  // Combined Assets and Liabilities
  let combinedAssetsLiabilitiesLineGraphData = {
    labels: [],
    datasets: [
      {
        label: "Current Assets",
        borderColor: "#4CAF50",
        backgroundColor: "#C8E6C9",
        data: [],
      },
      {
        label: "Current Liabilities",
        borderColor: "#2196F3",
        backgroundColor: "#BBDEFB",
        data: [],
      },
    ],
  };

  if (
    currentAssetsData &&
    Array.isArray(currentAssetsData.current_assets) &&
    currentLiabilitiesData &&
    Array.isArray(currentLiabilitiesData.current_liabilities)
  ) {
    const formattedCurrentAssetsData = currentAssetsData.current_assets
      .map((item) => {
        const date = new Date(item.month);
        const monthYearString = new Intl.DateTimeFormat("en", {
          year: "numeric",
          month: "short",
        }).format(date);
        return { ...item, month: monthYearString };
      })
      .sort((a, b) => new Date(a.month) - new Date(b.month));

    const formattedCurrentLiabilitiesData =
      currentLiabilitiesData.current_liabilities
        .map((item) => {
          const date = new Date(item.month);
          const monthYearString = new Intl.DateTimeFormat("en", {
            year: "numeric",
            month: "short",
          }).format(date);
          return { ...item, month: monthYearString };
        })
        .sort((a, b) => new Date(a.month) - new Date(b.month));

    // Assuming that the months for both Current Assets and Current Liabilities match
    combinedAssetsLiabilitiesLineGraphData = {
      labels: formattedCurrentAssetsData.map((item) => item.month),
      datasets: [
        {
          label: "Current Assets",
          borderColor: "#4CAF50",
          backgroundColor: "#C8E6C9",
          data: formattedCurrentAssetsData.map((item) => item.current_assets),
        },
        {
          label: "Current Liabilities",
          borderColor: "#2196F3",
          backgroundColor: "#BBDEFB",
          data: formattedCurrentLiabilitiesData.map(
            (item) => item.current_liabilities
          ),
        },
      ],
    };
  }
  // Average Creditor Days Line Graph

  const averageCreditorDaysData = Array.isArray(lineGraphs)
    ? lineGraphs.find(
        (graph) => graph.graph_KPI_name === "average_creditor_days"
      )
    : null;

  let averageCreditorDaysLineGraphData = {
    labels: [],
    datasets: [
      {
        label: "Current Year (2023-24)",
        borderColor: "#9C27B0",
        backgroundColor: "#E1BEE7",
        data: [],
      },
      {
        label: "Previous Year (2022-23)",
        borderColor: "#FF9800",
        backgroundColor: "#FFE0B2",
        data: [],
      },
    ],
  };

  if (
    averageCreditorDaysData &&
    Array.isArray(averageCreditorDaysData.average_creditor_days)
  ) {
    const formattedAverageCreditorDaysLineGraphData =
      averageCreditorDaysData.average_creditor_days
        .map((item) => {
          const date = new Date(item.month);
          const monthYearString = new Intl.DateTimeFormat("en", {
            year: "numeric",
            month: "short",
          }).format(date);
          return { ...item, month: monthYearString };
        })
        .sort((a, b) => new Date(a.month) - new Date(b.month));

    averageCreditorDaysLineGraphData = {
      labels: formattedAverageCreditorDaysLineGraphData.map(
        (item) => item.month
      ),
      datasets: [
        {
          label: "Current Year (2023-24)",
          borderColor: "#9C27B0",
        backgroundColor: "#E1BEE7",
          data: formattedAverageCreditorDaysLineGraphData.map(
            (item) => item.average_creditor_days
          ),
        },
        {
          label: "Previous Year (2022-23)",
          borderColor: "#FF9800",
          backgroundColor: "#FFE0B2",
          data: formattedAverageCreditorDaysLineGraphData.map(
            (item) => item.previous_year_average_creditor_days
          ),
        },
      ],
    };
  }

  const totalBankData = Array.isArray(lineGraphs)
    ? lineGraphs.find((graph) => graph.graph_KPI_name === "total_bank")
    : null;
  // Total Bank Line Graph
  let totalBankLineGraphData = {
    labels: [],
    datasets: [
      {
        label: "Current Year (2023-24)",
        borderColor: "#9C27B0",
        backgroundColor: "#E1BEE7",
        data: [],
      },
      {
        label: "Previous Year (2022-23)",
        borderColor: "#FF9800",
        backgroundColor: "#FFE0B2",
        data: [],
      },
    ],
  };

  if (totalBankData && Array.isArray(totalBankData.total_bank)) {
    const formattedTotalBankLineGraphData = totalBankData.total_bank
      .map((item) => {
        const date = new Date(item.month);
        const monthYearString = new Intl.DateTimeFormat("en", {
          year: "numeric",
          month: "short",
        }).format(date);
        return { ...item, month: monthYearString };
      })
      .sort((a, b) => new Date(a.month) - new Date(b.month));

    totalBankLineGraphData = {
      labels: formattedTotalBankLineGraphData.map((item) => item.month),
      datasets: [
        {
          label: "Current Year (2023-24)",
          borderColor: "#9C27B0",
          backgroundColor: "#E1BEE7",
          data: formattedTotalBankLineGraphData.map((item) => item.total_bank),
        },
        {
          label: "Previous Year (2022-23)",
          borderColor: "#FF9800",
          backgroundColor: "#FFE0B2",
          data: formattedTotalBankLineGraphData.map(
            (item) => item.previous_year_total_bank
          ),
        },
      ],
    };
  }
  // Money You Owe Line Graph

  const moneyYouOweLineData = Array.isArray(lineGraphs)
    ? lineGraphs.find((graph) => graph.graph_KPI_name === "money_you_owe")
    : null;
  let moneyYouOweLineGraphData = {
    labels: [],
    datasets: [
      {
        label: "Current Year (2023-24)",
        borderColor: "#9C27B0",
        backgroundColor: "#E1BEE7",
        data: [],
      },
      {
        label: "Previous Year (2022-23)",
        borderColor: "#FF9800",
        backgroundColor: "#FFE0B2",
        data: [],
      },
    ],
  };

  if (moneyYouOweLineData && Array.isArray(moneyYouOweLineData.money_you_owe)) {
    const formattedMoneyYouOweLineGraphData = moneyYouOweLineData.money_you_owe
      .map((item) => {
        const date = new Date(item.month);
        const monthYearString = new Intl.DateTimeFormat("en", {
          year: "numeric",
          month: "short",
        }).format(date);
        return { ...item, month: monthYearString };
      })
      .sort((a, b) => new Date(a.month) - new Date(b.month));

    moneyYouOweLineGraphData = {
      labels: formattedMoneyYouOweLineGraphData.map((item) => item.month),
      datasets: [
        {
          label: "Current Year (2023-24)",
          borderColor: "#9C27B0",
        backgroundColor: "#E1BEE7",
          data: formattedMoneyYouOweLineGraphData.map(
            (item) => item.money_you_owe
          ),
        },
        {
          label: "Previous Year (2022-23)",
          borderColor: "#FF9800",
          backgroundColor: "#FFE0B2",
          data: formattedMoneyYouOweLineGraphData.map(
            (item) => item.previous_year_money_you_owe
          ),
        },
      ],
    };
  }
  // Money Owed To You Line Graph
  const moneyOwedToYouLineData = Array.isArray(lineGraphs)
    ? lineGraphs.find((graph) => graph.graph_KPI_name === "money_owed_to_you")
    : null;
  let moneyOwedToYouLineGraphData = {
    labels: [],
    datasets: [
      {
        label: "Current Year (2023-24)",
        borderColor: "#9C27B0",
        backgroundColor: "#E1BEE7",
        data: [],
      },
      {
        label: "Previous Year (2022-23)",
        borderColor: "#FF9800",
        backgroundColor: "#FFE0B2",
        data: [],
      },
    ],
  };

  if (
    moneyOwedToYouLineData &&
    Array.isArray(moneyOwedToYouLineData.money_owed_to_you)
  ) {
    const formattedMoneyOwedToYouLineGraphData =
      moneyOwedToYouLineData.money_owed_to_you
        .map((item) => {
          const date = new Date(item.month);
          const monthYearString = new Intl.DateTimeFormat("en", {
            year: "numeric",
            month: "short",
          }).format(date);
          return { ...item, month: monthYearString };
        })
        .sort((a, b) => new Date(a.month) - new Date(b.month));

    moneyOwedToYouLineGraphData = {
      labels: formattedMoneyOwedToYouLineGraphData.map((item) => item.month),
      datasets: [
        {
          label: "Current Year (2023-24)",
          borderColor: "#9C27B0",
          backgroundColor: "#E1BEE7",
          data: formattedMoneyOwedToYouLineGraphData.map(
            (item) => item.money_owed_to_you
          ),
        },
        {
          label: "Previous Year (2022-23)",
          borderColor: "#FF9800",
        backgroundColor: "#FFE0B2",
          data: formattedMoneyOwedToYouLineGraphData.map(
            (item) => item.previous_year_money_owed_to_you
          ),
        },
      ],
    };
  }
  // Total Income

  const totalIncomeData = Array.isArray(lineGraphs)
    ? lineGraphs.find((graph) => graph.graph_KPI_name === "total_income")
    : null;
  // Total Income Line Graph
  let totalIncomeLineGraphData = {
    labels: [],
    datasets: [
      {
        label: "Current Year (2023-24)",
        borderColor: "#9C27B0",
        backgroundColor: "#E1BEE7",
        data: [],
      },
      {
        label: "Previous Year (2022-23)",
        borderColor: "#FF9800",
        backgroundColor: "#FFE0B2",
        data: [],
      },
    ],
  };

  if (totalIncomeData && Array.isArray(totalIncomeData.total_income)) {
    const formattedTotalIncomeLineGraphData = totalIncomeData.total_income
      .map((item) => {
        const date = new Date(item.month);
        const monthYearString = new Intl.DateTimeFormat("en", {
          year: "numeric",
          month: "short",
        }).format(date);
        return { ...item, month: monthYearString };
      })
      .sort((a, b) => new Date(a.month) - new Date(b.month));

    totalIncomeLineGraphData = {
      labels: formattedTotalIncomeLineGraphData.map((item) => item.month),
      datasets: [
        {
          label: "Current Year (2023-24)",
          borderColor: "#9C27B0",
          backgroundColor: "#E1BEE7",
          data: formattedTotalIncomeLineGraphData.map(
            (item) => item.total_income
          ),
        },
        {
          label: "Previous Year (2022-23)",
          borderColor: "#FF9800",
          backgroundColor: "#FFE0B2",
          data: formattedTotalIncomeLineGraphData.map(
            (item) => item.previous_year_total_income
          ),
        },
      ],
    };
  }

  // Other Revenue Graph
  const otherRevenueData = Array.isArray(lineGraphs)
    ? lineGraphs.find((graph) => graph.graph_KPI_name === "other_revenue")
    : null;
  // Other Revenue Line Graph
  let otherRevenueLineGraphData = {
    labels: [],
    datasets: [
      {
        label: "Current Year (2023-24)",
        borderColor: "#9C27B0",
        backgroundColor: "#E1BEE7",
        data: [],
      },
      {
        label: "Previous Year (2022-23)",
        borderColor: "#FF9800",
        backgroundColor: "#FFE0B2",
        data: [],
      },
    ],
  };

  if (otherRevenueData && Array.isArray(otherRevenueData.other_revenue)) {
    const formattedOtherRevenueLineGraphData = otherRevenueData.other_revenue
      .map((item) => {
        const date = new Date(item.month);
        const monthYearString = new Intl.DateTimeFormat("en", {
          year: "numeric",
          month: "short",
        }).format(date);
        return { ...item, month: monthYearString };
      })
      .sort((a, b) => new Date(a.month) - new Date(b.month));

    otherRevenueLineGraphData = {
      labels: formattedOtherRevenueLineGraphData.map((item) => item.month),
      datasets: [
        {
          label: "Current Year (2023-24)",
          borderColor: "#9C27B0",
          backgroundColor: "#E1BEE7",
          data: formattedOtherRevenueLineGraphData.map(
            (item) => item.other_revenue
          ),
        },
        {
          label: "Previous Year (2022-23)",
          borderColor: "#FF9800",
          backgroundColor: "#FFE0B2",
          data: formattedOtherRevenueLineGraphData.map(
            (item) => item.previous_year_other_revenue
          ),
        },
      ],
    };
  }
  // Average Debtor Days Line Graph

  const averageDebtorDaysData = Array.isArray(lineGraphs)
    ? lineGraphs.find((graph) => graph.graph_KPI_name === "average_debtor_days")
    : null;

  let averageDebtorDaysLineGraphData = {
    labels: [],
    datasets: [
      {
        label: "Current Year (2023-24)",
        borderColor: "#9C27B0",
        backgroundColor: "#E1BEE7",
        data: [],
      },
      {
        label: "Previous Year (2022-23)",
        borderColor: "#FF9800",
        backgroundColor: "#FFE0B2",
        data: [],
      },
    ],
  };

  if (
    averageDebtorDaysData &&
    Array.isArray(averageDebtorDaysData.average_debtor_days)
  ) {
    const formattedAverageDebtorDaysLineGraphData =
      averageDebtorDaysData.average_debtor_days
        .map((item) => {
          const date = new Date(item.month);
          const monthYearString = new Intl.DateTimeFormat("en", {
            year: "numeric",
            month: "short",
          }).format(date);
          return { ...item, month: monthYearString };
        })
        .sort((a, b) => new Date(a.month) - new Date(b.month));

    averageDebtorDaysLineGraphData = {
      labels: formattedAverageDebtorDaysLineGraphData.map((item) => item.month),
      datasets: [
        {
          label: "Current Year (2023-24)",
          borderColor: "#9C27B0",
        backgroundColor: "#E1BEE7",
          data: formattedAverageDebtorDaysLineGraphData.map(
            (item) => item.average_debtor_days
          ),
        },
        {
          label: "Previous Year (2022-23)",
          borderColor: "#FF9800",
          backgroundColor: "#FFE0B2",
          data: formattedAverageDebtorDaysLineGraphData.map(
            (item) => item.previous_year_average_debtor_days
          ),
        },
      ],
    };
  }
  // Tracking Category 01
  // Total Income Stacked Bar Chart
  const predefinedColors = [
    "#FF6384", // Red
    "#36A2EB", // Light Blue
    "#FFCE56", // Yellow
    "#4CAF50", // Green
    "#FF9800", // Orange
    "#8E24AA", // Purple
    "#d48817", // Dark Brown
    "#FF5722", // Deep Orange
    "#607D8B", // Blue Grey
    "#9C27B0", // Dark Purple
    // Add more colors as needed
  ];

  function getRandomColor(index) {
    return predefinedColors[index % predefinedColors.length];
  }
  const totalIncomeBarChartData = Array.isArray(trackingCategory01)
    ? trackingCategory01.find((graph) => graph.KPI_name === "total_income")
    : null;

  let totalIncomeStackedBarChartData = {
    labels: [],
    datasets: [],
  };

  if (
    totalIncomeBarChartData &&
    Array.isArray(totalIncomeBarChartData.total_income)
  ) {
    const uniqueCategories = [
      ...new Set(
        totalIncomeBarChartData.total_income.map(
          (item) => item.tracking_categories
        )
      ),
    ];

    const dates = [
      ...new Set(
        totalIncomeBarChartData.total_income.map((item) => item.month)
      ),
    ];

    totalIncomeStackedBarChartData = {
      labels: dates.map((date) => date),
      datasets: uniqueCategories.map((category, categoryIndex) => {
        return {
          label: category,
          backgroundColor: getRandomColor(categoryIndex),
          data: Array(dates.length).fill(0),
        };
      }),
    };

    totalIncomeBarChartData.total_income.forEach((item) => {
      const dateIndex = dates.indexOf(item.month);
      const categoryIndex = uniqueCategories.indexOf(item.tracking_categories);

      totalIncomeStackedBarChartData.datasets[categoryIndex].data[dateIndex] +=
        item.total_income;
    });
  }
  // Tracking Category 02
  // Total Income for trackingCategory02
  const productSales02 = Array.isArray(trackingCategory02)
    ? trackingCategory02.find((graph) => graph.KPI_name === "total_income")
    : null;

  let totalIncomeStackedBarChartData02 = {
    labels: [],
    datasets: [],
  };

  if (productSales02 && Array.isArray(productSales02.total_income)) {
    const uniqueCategories02 = [
      ...new Set(
        productSales02.total_income.map((item) => item.tracking_categories)
      ),
    ];

    const dates02 = [
      ...new Set(productSales02.total_income.map((item) => item.month)),
    ];

    totalIncomeStackedBarChartData02 = {
      labels: dates02.map((date) => date),
      datasets: uniqueCategories02.map((category, categoryIndex) => {
        return {
          label: category,
          backgroundColor: getRandomColor(categoryIndex),
          data: Array(dates02.length).fill(0),
        };
      }),
    };

    productSales02.total_income.forEach((item) => {
      const dateIndex = dates02.indexOf(item.month);
      const categoryIndex = uniqueCategories02.indexOf(
        item.tracking_categories
      );

      totalIncomeStackedBarChartData02.datasets[categoryIndex].data[
        dateIndex
      ] += item.total_income;
    });
  }
  // Total Cost of Sales for trackingCategory02
  const totalCostOfSalesData02 = Array.isArray(trackingCategory02)
    ? trackingCategory02.find(
        (graph) => graph.KPI_name === "total_cost_of_sales"
      )
    : null;

  let totalCostOfSalesStackedBarChartData02 = {
    labels: [],
    datasets: [],
  };

  if (
    totalCostOfSalesData02 &&
    Array.isArray(totalCostOfSalesData02.total_cost_of_sales)
  ) {
    const uniqueCategories02 = [
      ...new Set(
        totalCostOfSalesData02.total_cost_of_sales.map(
          (item) => item.tracking_categories
        )
      ),
    ];

    const dates02 = [
      ...new Set(
        totalCostOfSalesData02.total_cost_of_sales.map((item) => item.month)
      ),
    ];

    totalCostOfSalesStackedBarChartData02 = {
      labels: dates02.map((date) => date),
      datasets: uniqueCategories02.map((category, categoryIndex) => {
        return {
          label: category,
          backgroundColor: getRandomColor(categoryIndex),
          data: Array(dates02.length).fill(0),
        };
      }),
    };

    totalCostOfSalesData02.total_cost_of_sales.forEach((item) => {
      const dateIndex = dates02.indexOf(item.month);
      const categoryIndex = uniqueCategories02.indexOf(
        item.tracking_categories
      );

      totalCostOfSalesStackedBarChartData02.datasets[categoryIndex].data[
        dateIndex
      ] += item.total_cost_of_sales;
    });
  }

  const cashSpentData = Array.isArray(lineGraphs)
    ? lineGraphs.find((graph) => graph.graph_KPI_name === "cash_spent")
    : null;

  let cashSpentLineGraphData = {
    labels: [],
    datasets: [
      {
        label: "Current Year (2023-24)",
        borderColor: "#9C27B0",
        backgroundColor: "#E1BEE7",
        data: [],
      },
      {
        label: "Previous Year (2022-23)",
        borderColor: "#FF9800",
        backgroundColor: "#FFE0B2",
        data: [],
      },
    ],
  };

  if (cashSpentData && Array.isArray(cashSpentData.cash_spent)) {
    const formattedCashSpentLineGraphData = cashSpentData.cash_spent
      .map((item) => {
        const date = new Date(item.month);
        const monthYearString = new Intl.DateTimeFormat("en", {
          year: "numeric",
          month: "short",
        }).format(date);
        return { ...item, month: monthYearString };
      })
      .sort((a, b) => new Date(a.month) - new Date(b.month));

    cashSpentLineGraphData = {
      labels: formattedCashSpentLineGraphData.map((item) => item.month),
      datasets: [
        {
          label: "Current Year (2023-24)",
          borderColor: "#9C27B0",
          backgroundColor: "#E1BEE7",
          data: formattedCashSpentLineGraphData.map((item) => item.cash_spent),
        },
        {
          label: "Previous Year (2022-23)",
          borderColor: "#FF9800",
          backgroundColor: "#FFE0B2",
          data: formattedCashSpentLineGraphData.map(
            (item) => item.previous_year_cash_spent
          ),
        },
      ],
    };
  }
  const cashReceivedData = Array.isArray(lineGraphs)
    ? lineGraphs.find((graph) => graph.graph_KPI_name === "cash_received")
    : null;

  let cashReceivedLineGraphData = {
    labels: [],
    datasets: [
      {
        label: "Current Year (2023-24)",
        borderColor: "#9C27B0",
        backgroundColor: "#E1BEE7",
        data: [],
      },
      {
        label: "Previous Year (2022-23)",
        borderColor: "#FF9800",
        backgroundColor: "#FFE0B2",
        data: [],
      },
    ],
  };

  if (cashReceivedData && Array.isArray(cashReceivedData.cash_received)) {
    const formattedCashReceivedLineGraphData = cashReceivedData.cash_received
      .map((item) => {
        const date = new Date(item.month);
        const monthYearString = new Intl.DateTimeFormat("en", {
          year: "numeric",
          month: "short",
        }).format(date);
        return { ...item, month: monthYearString };
      })
      .sort((a, b) => new Date(a.month) - new Date(b.month));

    cashReceivedLineGraphData = {
      labels: formattedCashReceivedLineGraphData.map((item) => item.month),
      datasets: [
        {
          label: "Current Year (2023-24)",
          borderColor: "#9C27B0",
        backgroundColor: "#E1BEE7",
          data: formattedCashReceivedLineGraphData.map(
            (item) => item.cash_received
          ),
        },
        {
          label: "Previous Year (2022-23)",
          borderColor: "#FF9800",
          backgroundColor: "#FFE0B2",
          data: formattedCashReceivedLineGraphData.map(
            (item) => item.previous_year_cash_received
          ),
        },
      ],
    };
  }

// DEAR KPIs
const dearProductSalesLineData=dearProductSalesLineGraph?.results?.length > 0
? dearProductSalesLineGraph?.results?.[0]?.data
: [];
let productSalesLineGraphData = {
  labels: [],
  datasets: [
    {
      label: "Current Year (2023-24)",
      borderColor: "#9C27B0",
        backgroundColor: "#E1BEE7",
      data: [],
    },
    {
      label: "Previous Year (2022-23)",
      borderColor: "#FF9800",
      backgroundColor: "#FFE0B2",
      data: [],
    },
  ],
};

if (dearProductSalesLineData && Array.isArray(dearProductSalesLineData)) {
  const formattedProductSalesData = dearProductSalesLineData.map((item) => {
    const monthYearString = item.month ? item.month.slice(0, 7) : "Unknown";
    return { ...item, month: monthYearString };
  }).sort((a, b) => {
    if (a.month && b.month) {
      return new Date(a.month) - new Date(b.month);
    } else {
      return 0;
    }
  });

  productSalesLineGraphData = {
    labels: formattedProductSalesData.map((item) => item.month),
    datasets: [
      {
        label: "Current Year (2023-24)",
        borderColor: "#9C27B0",
        backgroundColor: "#E1BEE7",
        data: formattedProductSalesData.map((item) => item.current_year_sales || 0),
      },
      {
        label: "Previous Year (2022-23)",
        borderColor: "#FF9800",
        backgroundColor: "#FFE0B2",
        data: formattedProductSalesData.map((item) => item.previous_year_sales || 0),
      },
    ],
  };
}


  // Dear Product Sales BY Brand
const salesByBrand=dearProductSalesByBrand?.results?.length > 0
? dearProductSalesByBrand?.results[0]?.data
: []
  let salesByBrandsChartData = {
    labels: [],
    datasets: [
      {
        label: "Current Period",
        backgroundColor: "#202035",
        data: [],
      },
      {
        label: "Previous Period",
        backgroundColor: "#101431",
        data: [],
      },
    ],
  };
  if (salesByBrand && Array.isArray(salesByBrand)) {
    const filteredData = salesByBrand.filter(
      (item) => item.date_selection === salesByBrandDateFilter.toLowerCase().replace(/\s+/g, "_")
    );

    const formattedSalesByBrandsData = filteredData.sort((a, b) => {});

    salesByBrandsChartData = {
      labels: formattedSalesByBrandsData.map((item) => item.breakdown),
      datasets: [
        {
          label: "Current Period",
          backgroundColor: "#58339F",
          data: formattedSalesByBrandsData.map((item) =>
            parseFloat(item.current_period)
          ),
        },
        {
          label: salesByBrandCompareFilter,
          backgroundColor: "#FAC952",
          data: formattedSalesByBrandsData.map((item) =>
            parseFloat(
              item[salesByBrandCompareFilter.toLowerCase().replace(/\s+/g, "_")]
            )
          ),
        },
      ],
    };
  }

  // Product Sales By Product

  const salesByProducts = dearProductSalesByProduct?.results?.length > 0
  ? dearProductSalesByProduct?.results[0]?.data
  : [];
  let salesByProductChartData = {
    labels: [],
    datasets: [
      {
        label: "Current Period",
        backgroundColor: "#202035",
        data: [],
      },
      {
        label: "Previous Period",
        backgroundColor: "#101431",
        data: [],
      },
    ],
  };

  if (salesByProducts && Array.isArray(salesByProducts)) {
    const filteredData = salesByProducts.filter(
      (item) => item.date_selection === salesByProductDateFilter.toLowerCase().replace(/\s+/g, "_")
    );

    const formattedSalesByProductsData = filteredData.sort((a, b) => {
      // Sorting logic if needed
    });

    salesByProductChartData = {
      labels: formattedSalesByProductsData.map((item) => item.breakdown),
      datasets: [
        {
          label: "Current Period",
          backgroundColor: "#58339F",
          data: formattedSalesByProductsData.map((item) =>
            parseFloat(item.current_period)
          ),
        },
        {
          label: salesByProductCompareFilter,
          backgroundColor: "#FAC952",
          data: formattedSalesByProductsData.map((item) =>
            parseFloat(
              item[
                salesByProductCompareFilter.toLowerCase().replace(/\s+/g, "_")
              ]
            )
          ),
        },
      ],
    };
  }

  // Sales By Category
  const salesByCategory = dearProductSalesByCategory?.results?.length > 0
  ? dearProductSalesByCategory?.results[0]?.data
  : []
  let salesByCategoryChartData = {
    labels: [],
    datasets: [
      {
        label: "Current Period",
        backgroundColor: "#202035",
        data: [],
      },
      {
        label: "Previous Period",
        backgroundColor: "#101431",
        data: [],
      },
    ],
  };

  if (salesByCategory && Array.isArray(salesByCategory)) {
    const filteredData = salesByCategory.filter(
      (item) => item.date_selection === salesByCategoryDateFilter.toLowerCase().replace(/\s+/g, "_")
    );

    const formattedSalesByCategoryData = filteredData.sort((a, b) => {
      // Sorting logic if needed
    });

    salesByCategoryChartData = {
      labels: formattedSalesByCategoryData.map((item) => item.breakdown),
      datasets: [
        {
          label: "Current Period",
          backgroundColor: "#58339F",
          data: formattedSalesByCategoryData.map((item) =>
            parseFloat(item.current_period)
          ),
        },
        {
          label: salesByCategoryCompareFilter,
          backgroundColor: "#FAC952",
          data: formattedSalesByCategoryData.map((item) =>
            parseFloat(
              item[
                salesByCategoryCompareFilter.toLowerCase().replace(/\s+/g, "_")
              ]
            )
          ),
        },
      ],
    };
  }

  
  const salesByCustomer = dearProductSalesByCustomer?.results?.length > 0
  ? dearProductSalesByCustomer?.results[0]?.data
  : [];
  // Sales By Customers

  let salesByCustomerChartData = {
    labels: [],
    datasets: [
      {
        label: "Current Period",
        backgroundColor: "#202035",
        data: [],
      },
      {
        label: salesByCustomerCompareFilter,
        backgroundColor: "#101431",
        data: [],
      },
    ],
  };

  if (salesByCustomer && Array.isArray(salesByCustomer)) {
    const filteredData = salesByCustomer.filter(
      (item) => item.date_selection === salesByCustomerDateFilter.toLowerCase().replace(/\s+/g, "_")
    );

    const formattedSalesByCustomerData = filteredData.sort((a, b) => {
      // Sorting logic if needed
    });

    salesByCustomerChartData = {
      labels: formattedSalesByCustomerData.map((item) => item.breakdown),
      datasets: [
        {
          label: "Current Period",
          backgroundColor: "#58339F",
          data: formattedSalesByCustomerData.map(
            (item) => parseFloat(item.current_period) || 0
          ),
        },
        {
          label: salesByCustomerCompareFilter,
          backgroundColor: "#FAC952",
          data: formattedSalesByCustomerData.map(
            (item) =>
              parseFloat(
                item[
                  salesByCustomerCompareFilter
                    .toLowerCase()
                    .replace(/\s+/g, "_")
                ]
              ) || 0
          ),
        },
      ],
    };
  }

  const salesByProductTag = dearProductSalesByProductTag?.results?.length > 0
  ? dearProductSalesByProductTag?.results[0]?.data
  : [];
  // Sales for Product Tag
  let salesByProductTagChartData = {
    labels: [],
    datasets: [
      {
        label: "Current Period",
        backgroundColor: "#202035",
        data: [],
      },
      {
        label: salesByProductTagCompareFilter,
        backgroundColor: "#101431",
        data: [],
      },
    ],
  };

  if (salesByProductTag && Array.isArray(salesByProductTag)) {
    const filteredData = salesByProductTag.filter(
      (item) => item.date_selection === salesByProductTagDateFilter.toLowerCase().replace(/\s+/g, "_")
    );

    const formattedSalesByProductTagData = filteredData.sort((a, b) => {
      // Sorting logic if needed
    });

    salesByProductTagChartData = {
      labels: formattedSalesByProductTagData.map((item) => item.breakdown),
      datasets: [
        {
          label: "Current Period",
          backgroundColor: "#58339F",
          data: formattedSalesByProductTagData.map(
            (item) => parseFloat(item.current_period) || 0
          ),
        },
        {
          label: salesByProductTagCompareFilter,
          backgroundColor: "#FAC952",
          data: formattedSalesByProductTagData.map(
            (item) =>
              parseFloat(
                item[
                  salesByProductTagCompareFilter
                    .toLowerCase()
                    .replace(/\s+/g, "_")
                ]
              ) || 0
          ),
        },
      ],
    };
  }

  // Sales By Product Type

  const salesByProductType = dearProductSalesByProductType?.results?.length > 0
  ? dearProductSalesByProductType?.results[0]?.data
  : [];
  let salesByProductTypeChartData = {
    labels: [],
    datasets: [
      {
        label: "Current Period",
        backgroundColor: "#202035",
        data: [],
      },
      {
        label: salesByProductTypeCompareFilter,
        backgroundColor: "#101431",
        data: [],
      },
    ],
  };

  if (salesByProductType && Array.isArray(salesByProductType)) {
    const filteredData = salesByProductType.filter(
      (item) => item.date_selection === salesByProductTypeDateFilter.toLowerCase().replace(/\s+/g, "_")
    );

    const formattedSalesByProductTypeData = filteredData.sort((a, b) => {
      // Sorting logic if needed
    });

    salesByProductTypeChartData = {
      labels: formattedSalesByProductTypeData.map((item) => item.breakdown),
      datasets: [
        {
          label: "Current Period",
          backgroundColor: "#58339F",
          data: formattedSalesByProductTypeData.map(
            (item) => parseFloat(item.current_period) || 0
          ),
        },
        {
          label: salesByProductTypeCompareFilter,
          backgroundColor: "#FAC952",
          data: formattedSalesByProductTypeData.map(
            (item) =>
              parseFloat(
                item[
                  salesByProductTypeCompareFilter
                    .toLowerCase()
                    .replace(/\s+/g, "_")
                ]
              ) || 0
          ),
        },
      ],
    };
  }

  // Sales By Customer Tag
  const salesByCustomerTag = dearProductSalesByCustomerTag?.results?.length > 0
  ? dearProductSalesByCustomerTag?.results[0]?.data
  : [];
  let salesByCustomerTagChartData = {
    labels: [],
    datasets: [
      {
        label: "Current Period",
        backgroundColor: "#202035",
        data: [],
      },
      {
        label: salesByCustomerTagCompareFilter,
        backgroundColor: "#101431",
        data: [],
      },
    ],
  };

  if (salesByCustomerTag && Array.isArray(salesByCustomerTag)) {
    const filteredData = salesByCustomerTag.filter(
      (item) => item.date_selection === salesByCustomerTagDateFilter.toLowerCase().replace(/\s+/g, "_")
    );

    const formattedSalesByCustomerTagData = filteredData.sort((a, b) => {
      // Sorting logic if needed
    });

    salesByCustomerTagChartData = {
      labels: formattedSalesByCustomerTagData.map((item) => item.breakdown),
      datasets: [
        {
          label: "Current Period",
          backgroundColor: "#58339F",
          data: formattedSalesByCustomerTagData.map(
            (item) => parseFloat(item.current_period) || 0
          ),
        },
        {
          label: salesByCustomerTagCompareFilter,
          backgroundColor: "#FAC952",
          data: formattedSalesByCustomerTagData.map(
            (item) =>
              parseFloat(
                item[
                  salesByCustomerTagCompareFilter
                    .toLowerCase()
                    .replace(/\s+/g, "_")
                ]
              ) || 0
          ),
        },
      ],
    };
  }

  // Dear Profit By Graphs
// Line Graph
const dearProfitLineData=dearProfitLineGraph?.results?.length > 0
? dearProfitLineGraph?.results?.[0]?.data
: [];
let profitLineGraphData = {
  labels: [],
  datasets: [
    {
      label: "Current Year (2023-24)",
      borderColor: "#9C27B0",
      backgroundColor: "#E1BEE7",
      data: [],
    },
    {
      label: "Previous Year (2022-23) ",
      borderColor: "#FF9800",
        backgroundColor: "#FFE0B2",
      data: [],
    },
  ],
};

if (dearProfitLineData && Array.isArray(dearProfitLineData )) {
  const formattedProfitData = dearProfitLineData.map((item) => {
    const monthYearString = item.month ? item.month.slice(0, 7) : "Unknown";
    return { ...item, month: monthYearString };
  }).sort((a, b) => {
    if (a.month && b.month) {
      return new Date(a.month) - new Date(b.month);
    } else {
      return 0;
    }
  });

  profitLineGraphData = {
    labels: formattedProfitData.map((item) => item.month),
    datasets: [
      {
        label: "Current Year (2023-24)",
        borderColor: "#9C27B0",
        backgroundColor: "#E1BEE7",
        data: formattedProfitData.map((item) => item.current_year_profit || 0),
      },
      {
        label: "Previous Year (2022-23) ",
        borderColor: "#FF9800",
        backgroundColor: "#FFE0B2",
        data: formattedProfitData.map((item) => item.previous_year_profit || 0),
      },
    ],
  };
}



  // Profit By Category
  const profitByCategory = dearProfitByCategory?.results?.length > 0
  ? dearProfitByCategory?.results[0]?.data
  : [];
  let profitByCategoryChartData = {
    labels: [],
    datasets: [
      {
        label: "Current Period",
        backgroundColor: "#202035",
        data: [],
      },
      {
        label: profitByCategoryCompareFilter,
        backgroundColor: "#101431",
        data: [],
      },
    ],
  };

  if (profitByCategory && Array.isArray(profitByCategory)) {
    const filteredData = profitByCategory.filter(
      (item) => item.date_selection === profitByCategoryDateFilter.toLowerCase().replace(/\s+/g, "_")
    );

    const formattedProductsByCategoryData = filteredData.sort((a, b) => {
      // Sorting logic if needed
    });

    profitByCategoryChartData = {
      labels: formattedProductsByCategoryData.map((item) => item.breakdown),
      datasets: [
        {
          label: "Current Period",
          backgroundColor: "#58339F",
          data: formattedProductsByCategoryData.map(
            (item) => parseFloat(item.current_period) || 0
          ),
        },
        {
          label: profitByCategoryCompareFilter,
          backgroundColor: "#FAC952",
          data: formattedProductsByCategoryData.map(
            (item) =>
              parseFloat(
                item[
                  profitByCategoryCompareFilter
                    ?.toLowerCase()
                    .replace(/\s+/g, "_")
                ]
              ) || 0
          ),
        },
      ],
    };
  }

  const profitByProduct = dearProfitByProduct?.results?.length > 0
  ? dearProfitByProduct?.results[0]?.data
  : [];

  let profitByProductChartData = {
    labels: [],
    datasets: [
      {
        label: "Current Period",
        backgroundColor: "#202035",
        data: [],
      },
      {
        label: profitByProductCompareFilter,
        backgroundColor: "#101431",
        data: [],
      },
    ],
  };

  if (profitByProduct && Array.isArray(profitByProduct)) {
    const filteredData = profitByProduct.filter(
      (item) => item.date_selection === profitByProductDateFilter.toLowerCase().replace(/\s+/g, "_")
    );

    const formattedProfitByProductData = filteredData.sort((a, b) => {
      // Sorting logic if needed
    });

    profitByProductChartData = {
      labels: formattedProfitByProductData.map((item) => item.breakdown),
      datasets: [
        {
          label: "Current Period",
          backgroundColor: "#58339F",
          data: formattedProfitByProductData.map(
            (item) => parseFloat(item.current_period) || 0
          ),
        },
        {
          label: profitByProductCompareFilter,
          backgroundColor: "#FAC952",
          data: formattedProfitByProductData.map(
            (item) =>
              parseFloat(
                item[
                  profitByProductCompareFilter
                    ?.toLowerCase()
                    .replace(/\s+/g, "_")
                ]
              ) || 0
          ),
        },
      ],
    };
  }

  const profitByBrand = dearProfitByBrand?.results?.length > 0
  ? dearProfitByBrand?.results[0]?.data
  : [];
  let profitByBrandChartData = {
    labels: [],
    datasets: [
      {
        label: "Current Period",
        backgroundColor: "#202035",
        data: [],
      },
      {
        label: profitByBrandCompareFilter,
        backgroundColor: "#101431",
        data: [],
      },
    ],
  };

  if (profitByBrand && Array.isArray(profitByBrand)) {
    const filteredData = profitByBrand.filter(
      (item) => item.date_selection === profitByBrandDateFilter.toLowerCase().replace(/\s+/g, "_")
    );

    const formattedProfitByBrandData = filteredData.sort((a, b) => {
      // Sorting logic if needed
    });

    profitByBrandChartData = {
      labels: formattedProfitByBrandData.map((item) => item.breakdown),
      datasets: [
        {
          label: "Current Period",
          backgroundColor: "#58339F",
          data: formattedProfitByBrandData.map(
            (item) => parseFloat(item.current_period) || 0
          ),
        },
        {
          label: profitByBrandCompareFilter,
          backgroundColor: "#FAC952",
          data: formattedProfitByBrandData.map(
            (item) =>
              parseFloat(
                item[
                  profitByBrandCompareFilter?.toLowerCase().replace(/\s+/g, "_")
                ]
              ) || 0
          ),
        },
      ],
    };
  }
// Profit By Customer 
const profitByCustomer=dearProfitByCustomer?.results?.length > 0
? dearProfitByCustomer?.results[0]?.data
: [];
  let profitByCustomerChartData = {
    labels: [],
    datasets: [
      {
        label: "Current Period",
        backgroundColor: "#202035",
        data: [],
      },
      {
        label: profitByCustomerCompareFilter,
        backgroundColor: "#101431",
        data: [],
      },
    ],
  };
  
  if (profitByCustomer && Array.isArray(profitByCustomer)) {
    const filteredData = profitByCustomer.filter(
      (item) => item.date_selection === profitByCustomerDateFilter.toLowerCase().replace(/\s+/g, "_")
    );
  
    const formattedProfitByCustomerData = filteredData.sort((a, b) => {
    });
  
    profitByCustomerChartData = {
      labels: formattedProfitByCustomerData.map((item) => item.breakdown),
      datasets: [
        {
          label: "Current Period",
          backgroundColor: "#58339F",
          data: formattedProfitByCustomerData.map(
            (item) => parseFloat(item.current_period) || 0
          ),
        },
        {
          label: profitByCustomerCompareFilter,
          backgroundColor: "#FAC952",
          data: formattedProfitByCustomerData.map(
            (item) =>
              parseFloat(
                item[profitByCustomerCompareFilter?.toLowerCase().replace(/\s+/g, "_")]
              ) || 0
          ),
        },
      ],
    };
  }
  
// Profit By Customer Tag
const profitByCustomerTag=dearProfitByCustomerTag?.results?.length > 0
? dearProfitByCustomerTag?.results[0]?.data
: [];
let profitByCustomerTagChartData = {
  labels: [],
  datasets: [
    {
      label: "Current Period",
      backgroundColor: "#202035",
      data: [],
    },
    {
      label: profitByCustomerTagCompareFilter,
      backgroundColor: "#101431",
      data: [],
    },
  ],
};

if (profitByCustomerTag && Array.isArray(profitByCustomerTag)) {
  const filteredData = profitByCustomerTag.filter(
    (item) => item.date_selection === profitByCustomerTagDateFilter.toLowerCase().replace(/\s+/g, "_")
  );

  const formattedProfitByCustomerTagData = filteredData.sort((a, b) => {
    // Sorting logic if needed
  });

  profitByCustomerTagChartData = {
    labels: formattedProfitByCustomerTagData.map((item) => item.breakdown),
    datasets: [
      {
        label: "Current Period",
        backgroundColor: "#58339F",
        data: formattedProfitByCustomerTagData.map(
          (item) => parseFloat(item.current_period) || 0
        ),
      },
      {
        label: profitByCustomerTagCompareFilter,
        backgroundColor: "#FAC952",
        data: formattedProfitByCustomerTagData.map(
          (item) =>
            parseFloat(
              item[profitByCustomerTagCompareFilter?.toLowerCase().replace(/\s+/g, "_")]
            ) || 0
        ),
      },
    ],
  };
}
// Profit By Product Tag
const profitByProductTag=dearProfitByProductTag?.results?.length > 0
? dearProfitByProductTag?.results[0]?.data
: [];
let profitByProductTagChartData = {
  labels: [],
  datasets: [
    {
      label: "Current Period",
      backgroundColor: "#202035",
      data: [],
    },
    {
      label: profitByProductTagCompareFilter,
      backgroundColor: "#101431",
      data: [],
    },
  ],
};

if (profitByProductTag && Array.isArray(profitByProductTag)) {
  const filteredData = profitByProductTag.filter(
    (item) => item.date_selection === profitByProductTagDateFilter.toLowerCase().replace(/\s+/g, "_")
  );

  const formattedProfitByProductTagData = filteredData.sort((a, b) => {
    // Sorting logic if needed
  });

  profitByProductTagChartData = {
    labels: formattedProfitByProductTagData.map((item) => item.breakdown),
    datasets: [
      {
        label: "Current Period",
        backgroundColor: "#58339F",
        data: formattedProfitByProductTagData.map(
          (item) => parseFloat(item.current_period) || 0
        ),
      },
      {
        label: profitByProductTagCompareFilter,
        backgroundColor: "#FAC952",
        data: formattedProfitByProductTagData.map(
          (item) =>
            parseFloat(
              item[profitByProductTagCompareFilter?.toLowerCase().replace(/\s+/g, "_")]
            ) || 0
        ),
      },
    ],
  };
}

// Dear Units Sold By ...
const unitsSoldByBrand=dearUnitsSoldByBrand?.results?.length > 0
? dearUnitsSoldByBrand?.results[0]?.data
: [];

let unitsSoldByBrandsChartData = {
  labels: [],
  datasets: [
    {
      label: "Current Period",
      backgroundColor: "#202035",
      data: [],
    },
    {
      label: "Previous Period",
      backgroundColor: "#101431",
      data: [],
    },
  ],
};

if (unitsSoldByBrand && Array.isArray(unitsSoldByBrand)) {
  const filteredData = unitsSoldByBrand.filter(
    (item) => item.date_selection === unitsSoldByBrandDateFilter.toLowerCase().replace(/\s+/g, "_")
  );

  const formattedUnitsSoldByBrandsData = filteredData.sort((a, b) => {});

  unitsSoldByBrandsChartData = {
    labels: formattedUnitsSoldByBrandsData.map((item) => item.breakdown),
    datasets: [
      {
        label: "Current Period",
        backgroundColor: "#58339F",
        data: formattedUnitsSoldByBrandsData.map((item) =>
          parseFloat(item.current_period)
        ),
      },
      {
        label: unitsSoldByBrandCompareFilter,
        backgroundColor: "#FAC952",
        data: formattedUnitsSoldByBrandsData.map((item) =>
          parseFloat(
            item[unitsSoldByBrandCompareFilter.toLowerCase().replace(/\s+/g, "_")]
          )
        ),
      },
    ],
  };
}

// Units SOld by Category 
const unitsSoldByCategory=dearUnitsSoldByCategory?.results?.length > 0
? dearUnitsSoldByCategory?.results[0]?.data
: [];

let unitsSoldByCategoryChartData = {
  labels: [],
  datasets: [
    {
      label: "Current Period",
      backgroundColor: "#202035",
      data: [],
    },
    {
      label: "Previous Period",
      backgroundColor: "#101431",
      data: [],
    },
  ],
};

if (unitsSoldByCategory && Array.isArray(unitsSoldByCategory)) {
  const filteredData = unitsSoldByCategory.filter(
    (item) => item.date_selection === unitsSoldByCategoryDateFilter.toLowerCase().replace(/\s+/g, "_")
  );

  const formattedUnitsSoldByCategoryData = filteredData.sort((a, b) => {});

  unitsSoldByCategoryChartData = {
    labels: formattedUnitsSoldByCategoryData.map((item) => item.breakdown),
    datasets: [
      {
        label: "Current Period",
        backgroundColor: "#58339F",
        data: formattedUnitsSoldByCategoryData.map((item) =>
          parseFloat(item.current_period)
        ),
      },
      {
        label: unitsSoldByCategoryCompareFilter,
        backgroundColor: "#FAC952",
        data: formattedUnitsSoldByCategoryData.map((item) =>
          parseFloat(
            item[unitsSoldByCategoryCompareFilter.toLowerCase().replace(/\s+/g, "_")]
          )
        ),
      },
    ],
  };
}


// Dear Units Sold By Customer
const unitsSoldByCustomer=dearUnitsSoldByCustomer?.results?.length > 0
? dearUnitsSoldByCustomer?.results[0]?.data
: [];

let unitsSoldByCustomerChartData = {
  labels: [],
  datasets: [
    {
      label: "Current Period",
      backgroundColor: "#202035",
      data: [],
    },
    {
      label: "Previous Period",
      backgroundColor: "#101431",
      data: [],
    },
  ],
};

if (unitsSoldByCustomer && Array.isArray(unitsSoldByCustomer)) {
  const filteredData = unitsSoldByCustomer.filter(
    (item) => item.date_selection === unitsSoldByCustomerDateFilter.toLowerCase().replace(/\s+/g, "_")
  );

  const formattedUnitsSoldByCustomerData = filteredData.sort((a, b) => {});

  unitsSoldByCustomerChartData = {
    labels: formattedUnitsSoldByCustomerData.map((item) => item.breakdown),
    datasets: [
      {
        label: "Current Period",
        backgroundColor: "#58339F",
        data: formattedUnitsSoldByCustomerData.map((item) =>
          parseFloat(item.current_period)
        ),
      },
      {
        label: unitsSoldByCustomerCompareFilter,
        backgroundColor: "#FAC952",
        data: formattedUnitsSoldByCustomerData.map((item) =>
          parseFloat(
            item[unitsSoldByCustomerCompareFilter.toLowerCase().replace(/\s+/g, "_")]
          )
        ),
      },
    ],
  };
}

// Dear Units Sold By Product
const unitsSoldByProduct=dearUnitsSoldByProduct?.results?.length > 0
? dearUnitsSoldByProduct?.results[0]?.data
: [];
let unitsSoldByProductChartData = {
  labels: [],
  datasets: [
    {
      label: "Current Period",
      backgroundColor: "#202035",
      data: [],
    },
    {
      label: "Previous Period",
      backgroundColor: "#101431",
      data: [],
    },
  ],
};

if (unitsSoldByProduct && Array.isArray(unitsSoldByProduct)) {
  const filteredData = unitsSoldByProduct.filter(
    (item) => item.date_selection === unitsSoldByProductDateFilter.toLowerCase().replace(/\s+/g, "_")
  );

  const formattedUnitsSoldByProductData = filteredData.sort((a, b) => {});

  unitsSoldByProductChartData = {
    labels: formattedUnitsSoldByProductData.map((item) => item.breakdown),
    datasets: [
      {
        label: "Current Period",
        backgroundColor: "#58339F",
        data: formattedUnitsSoldByProductData.map((item) =>
          parseFloat(item.current_period)
        ),
      },
      {
        label: unitsSoldByProductCompareFilter,
        backgroundColor: "#FAC952",
        data: formattedUnitsSoldByProductData.map((item) =>
          parseFloat(
            item[unitsSoldByProductCompareFilter.toLowerCase().replace(/\s+/g, "_")]
          )
        ),
      },
    ],
  };
}


// Dear Units Sold By Customer Tag
const unitsSoldByCustomerTag=dearUnitsSoldByCustomerTag?.results?.length > 0
? dearUnitsSoldByCustomerTag?.results[0]?.data
: [];

let unitsSoldByCustomerTagChartData = {
  labels: [],
  datasets: [
    {
      label: "Current Period",
      backgroundColor: "#202035",
      data: [],
    },
    {
      label: "Previous Period",
      backgroundColor: "#101431",
      data: [],
    },
  ],
};

if (unitsSoldByCustomerTag && Array.isArray(unitsSoldByCustomerTag)) {
  const filteredData = unitsSoldByCustomerTag.filter(
    (item) => item.date_selection === unitsSoldByCustomerTagDateFilter.toLowerCase().replace(/\s+/g, "_")
  );

  const formattedUnitsSoldByCustomerTagData = filteredData.sort((a, b) => {});

  unitsSoldByCustomerTagChartData = {
    labels: formattedUnitsSoldByCustomerTagData.map((item) => item.breakdown),
    datasets: [
      {
        label: "Current Period",
        backgroundColor: "#58339F",
        data: formattedUnitsSoldByCustomerTagData.map((item) =>
          parseFloat(item.current_period)
        ),
      },
      {
        label: unitsSoldByCustomerTagCompareFilter,
        backgroundColor: "#FAC952",
        data: formattedUnitsSoldByCustomerTagData.map((item) =>
          parseFloat(
            item[unitsSoldByCustomerTagCompareFilter.toLowerCase().replace(/\s+/g, "_")]
          )
        ),
      },
    ],
  };
}


// Dear Units Sold By Product Tag
const unitsSoldByProductTag=dearUnitsSoldByProductTag?.results?.length > 0
? dearUnitsSoldByProductTag?.results[0]?.data
: [];

let unitsSoldByProductTagChartData = {
  labels: [],
  datasets: [
    {
      label: "Current Period",
      backgroundColor: "#202035",
      data: [],
    },
    {
      label: "Previous Period",
      backgroundColor: "#101431",
      data: [],
    },
  ],
};

if (unitsSoldByProductTag && Array.isArray(unitsSoldByProductTag)) {
  const filteredData = unitsSoldByProductTag.filter(
    (item) => item.date_selection === unitsSoldByProductTagDateFilter.toLowerCase().replace(/\s+/g, "_")
  );

  const formattedUnitsSoldByProductTagData = filteredData.sort((a, b) => {});

  unitsSoldByProductTagChartData = {
    labels: formattedUnitsSoldByProductTagData.map((item) => item.breakdown),
    datasets: [
      {
        label: "Current Period",
        backgroundColor: "#58339F",
        data: formattedUnitsSoldByProductTagData.map((item) =>
          parseFloat(item.current_period)
        ),
      },
      {
        label: unitsSoldByProductTagCompareFilter,
        backgroundColor: "#FAC952",
        data: formattedUnitsSoldByProductTagData.map((item) =>
          parseFloat(
            item[unitsSoldByProductTagCompareFilter.toLowerCase().replace(/\s+/g, "_")]
          )
        ),
      },
    ],
  };
}


// Dear Units Sold By Product Type
const unitsSoldByProductType=dearUnitsSoldByProductType?.results?.length > 0
? dearUnitsSoldByProductType?.results[0]?.data
: [];
let unitsSoldByProductTypeChartData = {
  labels: [],
  datasets: [
    {
      label: "Current Period",
      backgroundColor: "#202035",
      data: [],
    },
    {
      label: "Previous Period",
      backgroundColor: "#101431",
      data: [],
    },
  ],
};

if (unitsSoldByProductType && Array.isArray(unitsSoldByProductType)) {
  const filteredData = unitsSoldByProductType.filter(
    (item) => item.date_selection === unitsSoldByProductTypeDateFilter.toLowerCase().replace(/\s+/g, "_")
  );

  const formattedUnitsSoldByProductTypeData = filteredData.sort((a, b) => {});

  unitsSoldByProductTypeChartData = {
    labels: formattedUnitsSoldByProductTypeData.map((item) => item.breakdown),
    datasets: [
      {
        label: "Current Period",
        backgroundColor: "#58339F",
        data: formattedUnitsSoldByProductTypeData.map((item) =>
          parseFloat(item.current_period)
        ),
      },
      {
        label: unitsSoldByProductTypeCompareFilter,
        backgroundColor: "#FAC952",
        data: formattedUnitsSoldByProductTypeData.map((item) =>
          parseFloat(
            item[unitsSoldByProductTypeCompareFilter.toLowerCase().replace(/\s+/g, "_")]
          )
        ),
      },
    ],
  };
}

const dearUnitsSoldLineData = dearUnitsSoldLineGraph?.results?.length > 0
? dearUnitsSoldLineGraph?.results?.[0]?.data
: [];;
let unitsSoldLineGraphData = {
  labels: [],
  datasets: [
    {
      label: "Current Year (2023-24)",
      borderColor: "#9C27B0",
        backgroundColor: "#E1BEE7",
      data: [],
    },
    {
      label: "Previous Year (2022-23)",
      borderColor: "#FF9800",
      backgroundColor: "#FFE0B2",
      data: [],
    },
  ],
};

if (dearUnitsSoldLineData && Array.isArray(dearUnitsSoldLineData)) {
  const formattedUnitsSoldData = dearUnitsSoldLineData.map((item) => {
    const monthYearString = item.month ? item.month.slice(0, 7) : "Unknown";
    return { ...item, month: monthYearString };
  }).sort((a, b) => {
    if (a.month && b.month) {
      return new Date(a.month) - new Date(b.month);
    } else {
      return 0;
    }
  });

  unitsSoldLineGraphData = {
    labels: formattedUnitsSoldData.map((item) => item.month),
    datasets: [
      {
        label: "Current Year (2023-24)",
        borderColor: "#9C27B0",
        backgroundColor: "#E1BEE7",
        data: formattedUnitsSoldData.map((item) => item.current_year_units_sold || 0),
      },
      {
        label: "Previous Year (2022-23)",
        borderColor: "#FF9800",
        backgroundColor: "#FFE0B2",
        data: formattedUnitsSoldData.map((item) => item.previous_year_units_sold || 0),
      },
    ],
  };
}

// Profit By Product Type
const profitByProductType=dearProfitByProductType?.results?.length > 0
? dearProfitByProductType?.results[0]?.data
: []; 
let profitByProductTypeChartData = {
  labels: [],
  datasets: [
    {
      label: "Current Period",
      backgroundColor: "#202035",
      data: [],
    },
    {
      label: profitByProductTypeCompareFilter,
      backgroundColor: "#101431",
      data: [],
    },
  ],
};

if (profitByProductType && Array.isArray(profitByProductType)) {
  const filteredData = profitByProductType.filter(
    (item) => item.date_selection === profitByProductTypeDateFilter.toLowerCase().replace(/\s+/g, "_")
  );

  const formattedProfitByProductTypeData = filteredData.sort((a, b) => {
    // Sorting logic if needed
  });

  profitByProductTypeChartData = {
    labels: formattedProfitByProductTypeData.map((item) => item.breakdown),
    datasets: [
      {
        label: "Current Period",
        backgroundColor: "#58339F",
        data: formattedProfitByProductTypeData.map(
          (item) => parseFloat(item.current_period) || 0
        ),
      },
      {
        label: profitByProductTypeCompareFilter,
        backgroundColor: "#FAC952",
        data: formattedProfitByProductTypeData.map(
          (item) =>
            parseFloat(
              item[profitByProductTypeCompareFilter?.toLowerCase().replace(/\s+/g, "_")]
            ) || 0
        ),
      },
    ],
  };
}

// Dear Units Shipped By
const dearUnitsShippedLineData = dearUnitsShippedLineGraph?.results?.length > 0
? dearUnitsShippedLineGraph?.results?.[0]?.data
: [];;
let unitsShippedLineGraphData = {
  labels: [],
  datasets: [
    {
      label: "Current Year (2023-24)",
      borderColor: "#9C27B0",
      backgroundColor: "#E1BEE7",
      data: [],
    },
    {
      label: "Previous Year (2022-23)",
      borderColor: "#FF9800",
      backgroundColor: "#FFE0B2",
      data: [],
    },
  ],
};

if (dearUnitsShippedLineData && Array.isArray(dearUnitsShippedLineData)) {
  const formattedUnitsShippedData = dearUnitsShippedLineData.map((item) => {
    const monthYearString = item.month ? item.month.slice(0, 7) : "Unknown";
    return { ...item, month: monthYearString };
  }).sort((a, b) => {
    if (a.month && b.month) {
      return new Date(a.month) - new Date(b.month);
    } else {
      return 0;
    }
  });

  unitsShippedLineGraphData = {
    labels: formattedUnitsShippedData.map((item) => item.month),
    datasets: [
      {
        label: "Current Year (2023-24)",
        borderColor: "#9C27B0",
        backgroundColor: "#E1BEE7",
        data: formattedUnitsShippedData.map((item) => item.current_year_units_shipped || 0),
      },
      {
        label: "Previous Year (2022-23)",
        borderColor: "#FF9800",
        backgroundColor: "#FFE0B2",
        data: formattedUnitsShippedData.map((item) => item.previous_year_units_shipped || 0),
      },
    ],
  };
}

// Units Shipped By Brand
const unitsShippedByBrand = dearUnitsShippedByBrand?.results?.length > 0
? dearUnitsShippedByBrand?.results[0]?.data
: [];

let unitsShippedByBrandsChartData = {
  labels: [],
  datasets: [
    {
      label: "Current Period",
      backgroundColor: "#202035",
      data: [],
    },
    {
      label: "Previous Period",
      backgroundColor: "#101431",
      data: [],
    },
  ],
};

if (unitsShippedByBrand && Array.isArray(unitsShippedByBrand)) {
  const filteredData = unitsShippedByBrand.filter(
    (item) => item.date_selection === unitsShippedByBrandDateFilter.toLowerCase().replace(/\s+/g, "_")
  );

  const formattedUnitsShippedByBrandsData = filteredData.sort((a, b) => {});

  unitsShippedByBrandsChartData = {
    labels: formattedUnitsShippedByBrandsData.map((item) => item.breakdown),
    datasets: [
      {
        label: "Current Period",
        backgroundColor: "#58339F",
        data: formattedUnitsShippedByBrandsData.map((item) =>
          parseFloat(item.current_period)
        ),
      },
      {
        label: unitsShippedByBrandCompareFilter,
        backgroundColor: "#FAC952",
        data: formattedUnitsShippedByBrandsData.map((item) =>
          parseFloat(
            item[unitsShippedByBrandCompareFilter.toLowerCase().replace(/\s+/g, "_")]
          )
        ),
      },
    ],
  };
}

// Units Shipped By Category
const unitsShippedByCategory = dearUnitsShippedByCategory?.results?.length > 0
? dearUnitsShippedByCategory?.results[0]?.data
: [];

let unitsShippedByCategoryChartData = {
  labels: [],
  datasets: [
    {
      label: "Current Period",
      backgroundColor: "#202035",
      data: [],
    },
    {
      label: "Previous Period",
      backgroundColor: "#101431",
      data: [],
    },
  ],
};

if (unitsShippedByCategory && Array.isArray(unitsShippedByCategory)) {
  const filteredData = unitsShippedByCategory.filter(
    (item) => item.date_selection === unitsShippedByCategoryDateFilter.toLowerCase().replace(/\s+/g, "_")
  );

  const formattedUnitsShippedByCategoryData = filteredData.sort((a, b) => {});

  unitsShippedByCategoryChartData = {
    labels: formattedUnitsShippedByCategoryData.map((item) => item.breakdown),
    datasets: [
      {
        label: "Current Period",
        backgroundColor: "#58339F",
        data: formattedUnitsShippedByCategoryData.map((item) =>
          parseFloat(item.current_period)
        ),
      },
      {
        label: unitsShippedByCategoryCompareFilter,
        backgroundColor: "#FAC952",
        data: formattedUnitsShippedByCategoryData.map((item) =>
          parseFloat(
            item[unitsShippedByCategoryCompareFilter.toLowerCase().replace(/\s+/g, "_")]
          )
        ),
      },
    ],
  };
}

// Units Shipped By Customer
const unitsShippedByCustomer = dearUnitsShippedByCustomer?.results?.length > 0
? dearUnitsShippedByCustomer?.results[0]?.data
: [];

let unitsShippedByCustomerChartData = {
  labels: [],
  datasets: [
    {
      label: "Current Period",
      backgroundColor: "#202035",
      data: [],
    },
    {
      label: "Previous Period",
      backgroundColor: "#101431",
      data: [],
    },
  ],
};

if (unitsShippedByCustomer && Array.isArray(unitsShippedByCustomer)) {
  const filteredData = unitsShippedByCustomer.filter(
    (item) => item.date_selection === unitsShippedByCustomerDateFilter.toLowerCase().replace(/\s+/g, "_")
  );

  const formattedUnitsShippedByCustomerData = filteredData.sort((a, b) => {});

  unitsShippedByCustomerChartData = {
    labels: formattedUnitsShippedByCustomerData.map((item) => item.breakdown),
    datasets: [
      {
        label: "Current Period",
        backgroundColor: "#58339F",
        data: formattedUnitsShippedByCustomerData.map((item) =>
          parseFloat(item.current_period)
        ),
      },
      {
        label: unitsShippedByCustomerCompareFilter,
        backgroundColor: "#FAC952",
        data: formattedUnitsShippedByCustomerData.map((item) =>
          parseFloat(
            item[unitsShippedByCustomerCompareFilter.toLowerCase().replace(/\s+/g, "_")]
          )
        ),
      },
    ],
  };
}

// Units Shipped By Product
const unitsShippedByProduct = dearUnitsShippedByProduct?.results?.length > 0
? dearUnitsShippedByProduct?.results[0]?.data
: [];

let unitsShippedByProductChartData = {
  labels: [],
  datasets: [
    {
      label: "Current Period",
      backgroundColor: "#202035",
      data: [],
    },
    {
      label: "Previous Period",
      backgroundColor: "#101431",
      data: [],
    },
  ],
};

if (unitsShippedByProduct && Array.isArray(unitsShippedByProduct)) {
  const filteredData = unitsShippedByProduct.filter(
    (item) => item.date_selection === unitsShippedByProductDateFilter.toLowerCase().replace(/\s+/g, "_")
  );

  const formattedUnitsShippedByProductData = filteredData.sort((a, b) => {});

  unitsShippedByProductChartData = {
    labels: formattedUnitsShippedByProductData.map((item) => item.breakdown),
    datasets: [
      {
        label: "Current Period",
        backgroundColor: "#58339F",
        data: formattedUnitsShippedByProductData.map((item) =>
          parseFloat(item.current_period)
        ),
      },
      {
        label: unitsShippedByProductCompareFilter,
        backgroundColor: "#FAC952",
        data: formattedUnitsShippedByProductData.map((item) =>
          parseFloat(
            item[unitsShippedByProductCompareFilter.toLowerCase().replace(/\s+/g, "_")]
          )
        ),
      },
    ],
  };
}

// Units Shipped By Customer Tag
const unitsShippedByCustomerTag = dearUnitsShippedByCustomerTag?.results?.length > 0
? dearUnitsShippedByCustomerTag?.results[0]?.data
: [];

let unitsShippedByCustomerTagChartData = {
  labels: [],
  datasets: [
    {
      label: "Current Period",
      backgroundColor: "#202035",
      data: [],
    },
    {
      label: "Previous Period",
      backgroundColor: "#101431",
      data: [],
    },
  ],
};

if (unitsShippedByCustomerTag && Array.isArray(unitsShippedByCustomerTag)) {
  const filteredData = unitsShippedByCustomerTag.filter(
    (item) => item.date_selection === unitsShippedByCustomerTagDateFilter.toLowerCase().replace(/\s+/g, "_")
  );

  const formattedUnitsShippedByCustomerTagData = filteredData.sort((a, b) => {});

  unitsShippedByCustomerTagChartData = {
    labels: formattedUnitsShippedByCustomerTagData.map((item) => item.breakdown),
    datasets: [
      {
        label: "Current Period",
        backgroundColor: "#58339F",
        data: formattedUnitsShippedByCustomerTagData.map((item) =>
          parseFloat(item.current_period)
        ),
      },
      {
        label: unitsShippedByCustomerTagCompareFilter,
        backgroundColor: "#FAC952",
        data: formattedUnitsShippedByCustomerTagData.map((item) =>
          parseFloat(
            item[unitsShippedByCustomerTagCompareFilter.toLowerCase().replace(/\s+/g, "_")]
          )
        ),
      },
    ],
  };
}

// Units Shipped By Product Tag
const unitsShippedByProductTag = dearUnitsShippedByProductTag?.results?.length > 0
? dearUnitsShippedByProductTag?.results[0]?.data
: [];

let unitsShippedByProductTagChartData = {
  labels: [],
  datasets: [
    {
      label: "Current Period",
      backgroundColor: "#202035",
      data: [],
    },
    {
      label: "Previous Period",
      backgroundColor: "#101431",
      data: [],
    },
  ],
};

if (unitsShippedByProductTag && Array.isArray(unitsShippedByProductTag)) {
  const filteredData = unitsShippedByProductTag.filter(
    (item) => item.date_selection === unitsShippedByProductTagDateFilter.toLowerCase().replace(/\s+/g, "_")
  );

  const formattedUnitsShippedByProductTagData = filteredData.sort((a, b) => {});

  unitsShippedByProductTagChartData = {
    labels: formattedUnitsShippedByProductTagData.map((item) => item.breakdown),
    datasets: [
      {
        label: "Current Period",
        backgroundColor: "#58339F",
        data: formattedUnitsShippedByProductTagData.map((item) =>
          parseFloat(item.current_period)
        ),
      },
      {
        label: unitsShippedByProductTagCompareFilter,
        backgroundColor: "#FAC952",
        data: formattedUnitsShippedByProductTagData.map((item) =>
          parseFloat(
            item[unitsShippedByProductTagCompareFilter.toLowerCase().replace(/\s+/g, "_")]
          )
        ),
      },
    ],
  };
}

// Units Shipped By Product Type
const unitsShippedByProductType = dearUnitsShippedByProductType?.results?.length > 0
? dearUnitsShippedByProductType?.results[0]?.data
: [];

let unitsShippedByProductTypeChartData = {
  labels: [],
  datasets: [
    {
      label: "Current Period",
      backgroundColor: "#202035",
      data: [],
    },
    {
      label: "Previous Period",
      backgroundColor: "#101431",
      data: [],
    },
  ],
};

if (unitsShippedByProductType && Array.isArray(unitsShippedByProductType)) {
  const filteredData = unitsShippedByProductType.filter(
    (item) => item.date_selection === unitsShippedByProductTypeDateFilter.toLowerCase().replace(/\s+/g, "_")
  );

  const formattedUnitsShippedByProductTypeData = filteredData.sort((a, b) => {});

  unitsShippedByProductTypeChartData = {
    labels: formattedUnitsShippedByProductTypeData.map((item) => item.breakdown),
    datasets: [
      {
        label: "Current Period",
        backgroundColor: "#58339F",
        data: formattedUnitsShippedByProductTypeData.map((item) =>
          parseFloat(item.current_period)
        ),
      },
      {
        label: unitsShippedByProductTypeCompareFilter,
        backgroundColor: "#FAC952",
        data: formattedUnitsShippedByProductTypeData.map((item) =>
          parseFloat(
            item[unitsShippedByProductTypeCompareFilter.toLowerCase().replace(/\s+/g, "_")]
          )
        ),
      },
    ],
  };
}





  // Spark Lines
  function filterLastMonthsData(data, months = 6) {
    return data.slice(0, months);
  }
  const extractSparkLineData = (data, key) =>
    data.map((entry) => entry[key] || 0);
  function generateSparkLineData(rawData, key) {
    const last6MonthsData = filterLastMonthsData(rawData);
    const sparkLine = extractSparkLineData(last6MonthsData, key);
    return [0, ...sparkLine];
  }

  return {
    grossProfitLineData,
    netProfitLineData,
    netProfitMarginLineData,
    netProfitMarginLineGraphData,
    grossProfitLineGraphData,
    netProfitLineGraphData,
    totalCostOfSalesData,
    totalCostOfSalesLineGraphData,
    totalCostsData,
    totalCostsLineGraphData,
    operatingCostPercentageRevenueData,
    operatingCostPercentageRevenueLineGraphData,
    totalOperatingExpensesData,
    totalOperatingExpensesLineGraphData,
    grossProfitMarginData,
    grossProfitMarginLineGraphData,
    currentAssetsData,
    currentAssetsLineGraphData,
    currentLiabilitiesData,
    currentLiabilitiesLineGraphData,
    averageCreditorDaysData,
    averageCreditorDaysLineGraphData,
    totalBankData,
    totalBankLineGraphData,
    moneyYouOweLineData,
    moneyYouOweLineGraphData,
    moneyOwedToYouLineData,
    moneyOwedToYouLineGraphData,
    totalIncomeData,
    totalIncomeLineGraphData,
    otherRevenueData,
    otherRevenueLineGraphData,
    averageDebtorDaysData,
    averageDebtorDaysLineGraphData,
    totalIncomeBarChartData,
    totalIncomeStackedBarChartData,
    productSales02,
    totalIncomeStackedBarChartData02,
    cashSpentData,
    cashSpentLineGraphData,
    cashReceivedData,
    cashReceivedLineGraphData,
    combinedAssetsLiabilitiesLineGraphData,
    salesByBrandsChartData,
    salesByProductChartData,
    salesByCategoryChartData,
    salesByCustomerChartData,
    salesByProductTagChartData,
    salesByProductTypeChartData,
    salesByCustomerTagChartData,
    profitByCategoryChartData,
    profitByProductChartData,
    profitByBrandChartData,
    profitByCustomerChartData,
    profitByCustomerTagChartData,
    profitByProductTagChartData,
    profitByProductTypeChartData,
    profitLineGraphData,
  dearProfitLineData,
      productSalesLineGraphData,
      dearProductSalesLineData,
      unitsSoldLineGraphData,
      dearUnitsSoldLineData,
     unitsSoldByCategoryChartData,
     unitsSoldByBrandsChartData,
     unitsSoldByCustomerChartData,
     unitsSoldByCustomerTagChartData,
     unitsSoldByProductChartData,
     unitsSoldByProductTypeChartData,
     unitsSoldByProductTagChartData,
    //  Units Shipped By
    unitsShippedLineGraphData,
      dearUnitsShippedLineData,
     unitsShippedByCategoryChartData,
     unitsShippedByBrandsChartData,
     unitsShippedByCustomerChartData,
     unitsShippedByCustomerTagChartData,
     unitsShippedByProductChartData,
     unitsShippedByProductTypeChartData,
     unitsShippedByProductTagChartData,
    
  };
};

export default GraphData;

import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import FormikControl from '../../form-elements/formik-control';
import { IoIosClose } from 'react-icons/io';



const ForecastingRevenueModal = (props) => {
  const { isOpen, closeModal, saveData } = props;
  const options = [
    { value: "Forecast", key: "Forecast" },
    { value: "Increase from previous year", key: "Increase from previous year" },
    { value: "Manual", key: "Manual" },
  ];
  const initialValues = {
   revenue_method:'',
   value_x:''

  };
    const onSubmit = async (values, actions) => {
     
    };
  return (
    <div
      className={`z-50 ${
        isOpen ? "block" : "hidden"
      } fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center`}
    >
      <div className="bg-white p-4 rounded-lg w-1/2">
        <div className="flex flex-row justify-between item-start">
          <div className="flex gap-2 ">
            <div className="mt-2">
              <h2 className="text-lg font-bold ">Add new revenue data</h2>
            </div>
          </div>
          <IoIosClose
            onClick={closeModal}
            className="cursor-pointer"
            size={35}
          />
        </div>
        <Formik
          className="sign-up form "
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          {(formik) => (
            <Form className="py-4 ">
              <div className="flex flex-row items-start gap-5  mt-4 w-full ">
                
                <FormikControl
                  control="select"
                  options={options}
                  name="revenue_method"
                  label="Select Revenue Method"
                  placeholder="Revenue Method"
                />
                <FormikControl
                  control="input"
                  type="text"
                  label="Value of x (%)"
                  name="value_x"
                  placeholder="x"
                />
              </div>
              <div className="flex flex-col items-end justify-end w-full gap-6 mt-6">
                <button
                  className="rounded-md md:w-1/5 sm:w-2/5 w-3/5 bg-warning text-black font-bold px-4 py-2"
                  type="submit"
                >
                  Confirm
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ForecastingRevenueModal;

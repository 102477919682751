import React from 'react'
import error from '../../../assets/images/errors/error-404.png'
import { FaEnvelope, FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

function ErrorPage() {
  const { user } = useSelector((state) => state.auth);

    const handleSubscribe = (e) => {
      e.preventDefault();
      // Implement your subscribe logic here
    };
  return (
    <div className="flex flex-col items-center justify-center gap-10 h-full w-full ">
      {/* Countdown Timer */}
      <img src={error} alt="" />
      <div className="flex flex-col mt-auto justify-center items-center">
        <h1 className="text-4xl text-purple font-semibold mb-4 ">Error Page</h1>
        <p className="text-lg text-gray-600">
          Sorry, this page does not exist!
        </p>
        <Link to={`${user ? '/organisation/owner':'/'}`}>
          <button className="bg-dark-purple mt-6 text-white py-2 px-8 rounded-md  hover:bg-white hover:border-2 hover:border-dark-purple hover:text-dark-purple transition-colors">
            Go to home
          </button>
        </Link>
      </div>

      {/* Email Subscription Form */}

      <div className="mt-auto mb-6 flex flex-col items-center justify-center">
        <p className="mt-8 justify-end text-gray-600">
          For inquiries, please email us at{" "}
          <a
            href="https://qurk.app/"
            className="text-purple font-medium hover:underline"
          >
            qurk.com
          </a>
        </p>

        {/* Social Media Icons */}
        <div className="flex mt-2 gap-4">
          <a
            href="https://www.facebook.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook className="text-2xl text-purple hover:text-warning transition-colors" />
          </a>
          <a
            href="https://www.twitter.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter className="text-2xl text-purple hover:text-warning transition-colors" />
          </a>
          <a
            href="https://www.instagram.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram className="text-2xl text-purple hover:text-warning transition-colors" />
          </a>
          <a href="mailto:info@example.com">
            <FaEnvelope className="text-2xl text-purple hover:text-warning transition-colors" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default ErrorPage
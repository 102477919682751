import { Form, Formik } from "formik";
import React, { useState } from "react";
import FormikControl from "../../form-elements/formik-control";
import { IoIosClose } from "react-icons/io";

const DirectCostsModal = (props) => {
  const { isOpen, closeModal, saveData } = props;
  const options = [
    {
      value: "Increase from previous year",
      key: "Increase from previous year",
    },
    { value: "Manual", key: "Manual" },
  ];

  // State to keep track of the selected 'cos' value
  const [selectedCos, setSelectedCos] = useState("");

  const initialValues = {
    cos: "",
    value_x: "",
  };

  const onSubmit = async (values, actions) => {
    // Handle form submission
    // ...
  };

  // Function to handle changes in the 'cos' select field
  const handleCosChange = (value) => {
    setSelectedCos(value);
  };

  return (
    <div
      className={`z-50 ${
        isOpen ? "block" : "hidden"
      } fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center`}
    >
      <div className="bg-white p-4 rounded-lg w-1/2">
        <div className="flex flex-row justify-between item-start">
          <div className="flex gap-2 ">
            <div className="mt-2">
              <h2 className="text-lg font-bold ">Add new direct costs data</h2>
            </div>
          </div>
          <IoIosClose onClick={closeModal} className="cursor-pointer" size={35} />
        </div>
        <Formik className="form" initialValues={initialValues} onSubmit={onSubmit}>
          {(formik) => (
            <Form className="py-4 ">
              <div className="flex flex-row items-start gap-5  mt-4 w-full ">
                <FormikControl
                  control="select"
                  options={options}
                  name="cos"
                  label="Cost of Sales Method"
                  placeholder="Sales Method"
                  // Call the handleCosChange function when the select value changes
                  onChange={handleCosChange}
                />
                {/* Show the 'Value of x' input field if 'Manual' is selected */}
                {selectedCos === "Manual" && (
                  <FormikControl
                    control="input"
                    type="text"
                    label="Value of x (%)"
                    name="value_x"
                    placeholder="x"
                  />
                )}
              </div>
              <div className="flex flex-col items-end justify-end w-full gap-6 mt-6">
                <button
                  className="rounded-md md:w-1/5 sm:w-2/5 w-3/5 bg-warning text-black font-bold px-4 py-2"
                  type="submit"
                >
                  Confirm
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default DirectCostsModal;

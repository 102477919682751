import React, { useEffect, useState } from "react";
import FormikControl from "../../form-elements/formik-control";
import { Form, Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { IoIosClose } from "react-icons/io";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import countryList from "react-select-country-list";
import currencyToSymbolMap from "currency-symbol-map/map";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  createOrganisation,
  getAllOrganisations,
  resetOrg,
  updateOrganisation,
} from "../../../../redux-features/slices/organisation/orgSlice";
import moment from "moment";
import { CreateNewOrganisation } from "../../../../validation-schemas";
function OrganisationModal(props) {
  const { toggle, toggleModal, isEdit, token } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    organisation,
    isLoading,
    isError,
    isSuccess,
    isSuccessEdit,
    message,
  } = useSelector((state) => state.organisation);
  const initialValues = {
    name: "",
    // email: "",
    role: "",
    country: "",
    currency: "",
    address: "",
    identification_number: null,
    industry: "",
    financial_year: "",
    // tenant_id:''
  };
  const initialValuesEdit = {
    name: organisation?.name,
    email: organisation?.email ? organisation?.email : "Not defined",
    logo: "",
    industry: organisation?.industry,
    identification_number: organisation?.identification_number ?organisation?.identification_number: 'Not Defined',
    address: organisation?.address ? organisation?.address : "Not defined",
  };
  const countries = countryList()
    .getData()
    .map((country) => ({
      value: country.value,
      key: country.label,
    }));


    const industries = [
      { value: "Agriculture/Farming", key: "Agriculture/Farming" },
      { value: "Automotive", key: "Automotive" },
      { value: "Banking/Finance", key: "Banking/Finance" },
      { value: "Construction/Engineering", key: "Construction/Engineering" },
      { value: "Consulting", key: "Consulting" },
      { value: "Education/Training", key: "Education/Training" },
      { value: "Energy/Utilities", key: "Energy/Utilities" },
      { value: "Food and Beverage", key: "Food and Beverage" },
      { value: "Government/Non-Profit", key: "Government/Non-Profit" },
      { value: "Pharmaceuticals", key: "Pharmaceuticals" },
      { value: "Hospitality", key: "Hospitality" },
      { value: "Information Technology", key: "Information Technology" },
      { value: "Insurance", key: " Insurance" },
      { value: "Manufacturing", key: "Manufacturing" },
      { value: "Marketing", key: "Marketing" },
      { value: "Real Estate", key: "Real Estate" },
      { value: "Retail/E-commerce", key: "Retail/E-commerce" },
      { value: "Telecommunications", key: "Telecommunications" },
      { value: "Transportation/Logistics", key: "Transportation/Logistics" },
      { value: "Other", key: "Other" },
    ];

  const roles = [
    { value: "Executive/Management", key: "Executive/Management" },
    { value: "Finance/Accounting", key: "Finance/Accounting" },
    { value: "Sales/Business Development", key: "Sales/Business Development" },
    { value: "Human Resources", key: "Human Resources" },
    { value: " Operations", key: " Operations" },
    { value: "Marketing", key: "Marketing" },
  ];
  const options = Object.entries(currencyToSymbolMap).map(([code, symbol]) => ({
    value: code,
    key: `${code}: ${symbol}`,
  }));

  useEffect(() => {
    if (isError) {
      toast.error(message, {
        toastId: "toast2",
        autoClose: 10000,
        hideProgressBar: true,
        position: toast.POSITION.TOP_CENTER,
      });
    }
    if (isSuccess) {
      // toast.success("New organisation successfully created", {
      //   toastId: "toast1",
      //   autoClose: 3000,
      //   hideProgressBar: true,
      //   position: toast.POSITION.TOP_CENTER,
      // });
      dispatch(getAllOrganisations({ token }));
      dispatch(resetOrg());
    }
    if (isSuccessEdit) {
      // toast.success("Organisation successfully updated", {
      //   toastId: "toast3",
      //   autoClose: 3000,
      //   hideProgressBar: true,
      //   position: toast.POSITION.TOP_CENTER,
      // });
      dispatch(getAllOrganisations({ token }));
      dispatch(resetOrg());
    }
  }, [isError, isSuccess, isSuccessEdit, message, dispatch, navigate]);
  const onSubmitEdit = async (values, actions) => {
    const slug = organisation?.slug;
    const org_data = {
      name: values.name,
      email: values.email,
      logo: "",
      industry: values.industry,
      identification_number: "",
      address: values.address,
    };
    if (org_data) {
      dispatch(updateOrganisation({ slug, org_data, token }));
    }
    await toggleModal();
    await new Promise((resolve) => setTimeout(resolve, 10000));
    actions.resetForm();
  };
  const onSubmit = async (values, actions) => {
    const org_data = {
      name: values.name,
      // email: values.email,
      role: values.role,
      financial_year: moment(values.financial_year, "YYYY").format(
        "YYYY-MM-DD"
      ),
      country: values.country,
      industry: values.industry,
      identification_number: values.identification_number,
      address: "",
      // tenant_id:values.tenant_id
    };
    if (org_data && token) {
      dispatch(createOrganisation({ org_data, token }));
    }
    await toggleModal();
    await new Promise((resolve) => setTimeout(resolve, 10000));
    actions.resetForm();
  };
  const handleClose = (slug) => {
    toggleModal();
  };
  return (
    <div>
      {toggle && organisation && (
        <div className="fixed z-30 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center  bg-black bg-opacity-50 min-h-screen p-4">
            <div className="bg-white rounded-lg w-2/5 p-8">
              <div className="flex flex-row justify-between item-start">
                <div className="flex gap-2 ">
                  <HiOutlineOfficeBuilding size={42} />
                  <div className="mt-2">
                    <h2 className="text-lg font-bold ">
                      {isEdit
                        ? `Edit  ${organisation?.name}`
                        : "Add Organisation Details"}
                    </h2>
                  </div>
                </div>
                <IoIosClose
                  onClick={() => handleClose(organisation?.slug)}
                  className="cursor-pointer"
                  size={35}
                />
              </div>
              <div className="w-full">
                {isEdit ? (
                  <Formik
                    className=" form "
                    initialValues={initialValuesEdit}
                    onSubmit={onSubmitEdit}
                    enableReinitialize
                    validateOnBlur
                  >
                    {(formik) => (
                      <Form className="py-4 ">
                        <div className="flex flex-col items-start gap-5  mt-4 w-full ">
                          <FormikControl
                            control="input"
                            type="text"
                            label="Organisation Name"
                            name="name"
                            placeholder="Name"
                          />
                          <FormikControl
                            control="input"
                            type="text"
                            label="Email Address"
                            name="email"
                            placeholder="Email"
                          />
                          <FormikControl
                            control="input"
                            type="text"
                            name="address"
                            label="Address"
                            placeholder="Address"
                          />
                          <FormikControl
                            control="select"
                            options={industries}
                            value={organisation?.industry}
                            name="industry"
                            label="Select Industry"
                            placeholder="Country"
                            onChange={formik.handleChange}
                          />
                        </div>
                        <div className="flex flex-col items-end justify-end w-full gap-6 mt-6">
                          <button
                            className="rounded-md md:w-1/5 sm:w-2/5 w-3/5 bg-warning text-black font-bold p-3"
                            type="submit"
                          >
                            Update
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                ) : (
                  <Formik
                    className="sign-up form "
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={CreateNewOrganisation}
                    
                  >
                    {(formik) => (
                      <Form className="py-4 ">
                        <div className="flex flex-col items-start gap-5  mt-4 w-full ">
                          <FormikControl
                            control="input"
                            type="text"
                            label="Enter Organisation Name"
                            name="name"
                            placeholder="Name"
                          />
                          
                          <FormikControl
                            control="select"
                            options={industries}
                            name="industry"
                            label="Select Industry"
                            placeholder="Country"
                          />
                          <FormikControl
                            control="select"
                            options={roles}
                            name="role"
                            label="Select Role in Organisation"
                            placeholder="Role"
                          />
                          <div className="flex md:flex-row flex-col gap-2 w-full">
                            <div className="md:w-3/5 w-full">
                              <FormikControl
                                control="select"
                                options={countries}
                                name="country"
                                label="Select Country of Organisation"
                                placeholder="Country"
                              />
                            </div>
                            <div className="md:w-2/5 w-full">
                              <FormikControl
                                control="select"
                                options={options}
                                name="currency"
                                label="Select Currency"
                                placeholder="Currency"
                              />
                            </div>
                          </div>
                          {/* <Select options={options} name='country' /> */}
                          <FormikControl
                            control="month"
                            formType="New Org"
                            type="text"
                            name="financial_year"
                            label="Enter Financial Year"
                            placeholder="FY"
                          />
                          
                        </div>
                        <div className="flex flex-col items-end justify-end w-full gap-6 mt-6">
                          <button
                            className="rounded-md md:w-1/5 sm:w-2/5 w-3/5 bg-warning text-black font-bold p-3"
                            type="submit"
                          >
                            Confirm
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default OrganisationModal;

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import FormikControl from "../../../components/form-elements/formik-control";
import { Form, Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  resetAuth,
  registerOwner,
  registerAdvisor,
  registerWithXero,
  resetXeroRedirect,
} from "../../../../redux-features/slices/auth/authSlice";
import { SignUpSchema } from "../../../../validation-schemas";
import Spinner from "../../../components/ui-elements/utilis/spinner";
import { BsArrowDown } from "react-icons/bs";
import { RiAccountPinCircleFill } from "react-icons/ri";

import logo from "../../../../assets/logos/qurk_logo.png";
import icons from "../../../../assets/logos/group_icons_1.png";
import dashboard from "../../../../assets/images/auth/dashboard.png";
import xerologo from '../../../../assets/logos/xero_logo.png'
import SpinnerKPI from "../../../components/ui-elements/utilis/spinner-kpi";
import { FaEye, FaEyeSlash } from "react-icons/fa";


function SignUpScreen() {
  // Store configuration and hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { user, isLoading, isError, isSuccess, isSuccessXero, message, xeroRedirect, isLoadingXero } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (isError) {
      toast.error(message, {
        autoClose: 10000,
        hideProgressBar: true,
        position: toast.POSITION.TOP_CENTER,
      });
    }
    if (isSuccess && user) {
      // toast.success("Account credentials has been received!", {
      //   toastId: "toast1",
      //   autoClose: 3000,
      //   hideProgressBar: true,
      //   position: toast.POSITION.TOP_CENTER,
      // });
      navigate("/verify-email");
    }

    dispatch(resetAuth());
  }, [user, isError, isSuccess, message, dispatch, navigate]);


  useEffect(() => {
    if (isSuccessXero && xeroRedirect.redirect_url) {
      window.open(xeroRedirect.redirect_url);
      dispatch(resetXeroRedirect())
    }
  }, [isSuccessXero, xeroRedirect, dispatch]);


  // Component functions
  const onSubmit = async (values, actions) => {
    const user_data = {
      firstName: values.first_name,
      lastName: values.last_name,
      email: values.email,
      password: values.password,
    };
    if (values.user_type === "Owner") {
      dispatch(registerOwner(user_data));
    }
    if (values.user_type === "Advisor") {
      dispatch(registerAdvisor(user_data));
    }
    await new Promise((resolve) => setTimeout(resolve, 5000));
    actions.resetForm();
  };

  // Initial values and variables
  const initialValues = {
    email: "",
    first_name: "",
    last_name: "",
    password: "",
    confirm_password: "",
    user_type: "",
    terms: "",
  };
  const userOptions = [
    {
      value: "Owner",
      key: "Company",
      tooltip: "I am a manager or work internally inside of a company.",
    },
    {
      value: "Advisor",
      key: "Advisor",
      tooltip:
        "I am an accountant or business consultant who manages multiple clients ",
    },
  ];
  const termOption = [
    {
      value: "Terms & Conditions",
    },
  ];

  if (isLoading) {
    return <Spinner />;
  }


  const handleSignUpWithXero = () => {
    dispatch(registerWithXero())
  }
  return (
    <div className="flex flex-col md:flex-row  md:min-h-screen h-screen w-screen bg-dark-purple  overflow-y-auto">
      <div
        className=" w-full  h-full lg:w-3/4"
        style={{
          background:
            "linear-gradient(0deg, rgba(32,32,53,1) 0%, rgba(46,28,90,1) 30%, rgba(91,72,135,1) 100%)",
        }}
      >
        <div className=" flex flex-col w-full justify-start items-center">
          <div className="w-full h-full pt-3">
            <div className="flex  justify-between pl-4 ">
              <img src={logo} alt="" className="w-12 h-12 " />
              <div className=" flex  flex-row gap-2 items-center pt-4 pr-16 text-sm text-white ">
                Already have an account?
                <Link to="/signin">
                  <button className="rounded-full border-warning border-2 font-bold text-warning py-2 px-6">
                    Login
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="flex flex-col h-full mt-8 w-1/2 justify-center items-start rounded-md  ">
            <div className="flex flex-row gap-2 items-center ">
              <div className="">
                {/* <RiAccountPinCircleFill size="70px" className="text-primary" /> */}
              </div>
              <div className=" flex flex-col text-white items-start   ">
                <h5 className="text-sm">Welcome!</h5>
                <h2 className="text-2xl font-extrabold">Create new account</h2>
              </div>
            </div>

            <div className="w-full h-full">
              <Formik
                className="sign-up form "
                initialValues={initialValues}
                validationSchema={SignUpSchema}
                onSubmit={onSubmit}
              >
                {(formik) => (
                  <Form className="py-2 px-2 ">
                    <div className=" mt-6 mb-4">
                      <FormikControl
                        formType="sign-up"
                        control="radio"
                        name="user_type"
                        options={userOptions}
                      />
                    </div>
                    <div className="flex flex-col items-start gap-4  mt-3 w-full ">
                      <FormikControl
                        formType="auth"
                        control="input"
                        type="text"
                        label="First Name"
                        name="first_name"
                        placeholder="Enter first name"
                      />
                      <FormikControl
                        formType="auth"
                        control="input"
                        type="text"
                        name="last_name"
                        label="Last Name"
                        placeholder="Enter last name"
                      />
                      <FormikControl
                        control="input"
                        type="email"
                        name="email"
                        label="Email Address"
                        placeholder="Enter email address"
                      />
                      <div className="relative w-full">
                        <FormikControl
                          control="input"
                          type={showPassword ? "text" : "password"}
                          name="password"
                          label="Password"
                          placeholder="Enter password"
                        />
                        <button
                          type="button"
                          onClick={() => setShowPassword(!showPassword)}
                          className="absolute top-4 right-1 pr-3"
                        >
                          {showPassword ? (
                            <FaEyeSlash size={20} className="text-gray-500" />
                          ) : (
                            <FaEye size={20} className="text-gray-500" />
                          )}
                        </button>
                      </div>
                      <div className="relative w-full">
                        <FormikControl
                          control="input"
                          type={showConfirmPassword ? "text" : "password"}
                          name="confirm_password"
                          label="Repeat Password"
                          placeholder="Confirm password"
                        />
                        <button
                          type="button"
                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                          className="absolute top-4 right-1 pr-3"
                        >
                          {showConfirmPassword ? (
                            <FaEyeSlash size={20} className="text-gray-500" />
                          ) : (
                            <FaEye size={20} className="text-gray-500" />
                          )}
                        </button>
                      </div>
                      <div className="text-md flex flex-row my-4 ">
                        <FormikControl
                          formType="signup"
                          control="checkbox"
                          name="terms"
                          options={termOption}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col items justify-center w-full gap-6 mt-4">
                      <button
                        className="rounded-full bg-warning text-md font-bold text-dark-purple w-full py-3"
                        type="submit"
                      >
                        Create new account
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="flex flex-col justify-center items-center w-full mt-6 gap-4">
              <p className="mb-4 text-white ">Or</p>
              {isLoadingXero ? (
                <SpinnerKPI />
              ) : (
                <button
                  onClick={handleSignUpWithXero}
                  className=" flex rounded-full justify-center items-center bg-white text-md text-xero-text text-md font-bold w-full py-2"
                >
                  <img src={xerologo} alt="" className="w-8 h-8 mx-4" />
                  Sign up with Xero
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/2 hidden lg:block h-full overflow-hidden">
        <div className="flex flex-col w-full h-full justify-start items-center bg-light-bg rounded-md px-4  py-20">
          <h5 className="text-md text-center text-dark-purple font-semibold">
            Integrate, consolidate and validate your data and build reports in
            minutes
          </h5>
          <img src={icons} className="w- h-52 mt-8" alt="" />
          <BsArrowDown size={32} className="text-purple" />
          <img src={dashboard} className="w-full max-w-sm " alt="" />
        </div>
      </div>
    </div>
  );
}

export default SignUpScreen;













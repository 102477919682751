import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import authService from './authService';

const user = JSON.parse(localStorage.getItem('user'));

const initialState = {
  user: user ? user : null,
  xeroRedirect: [],
  isSuccess: false,
  isSuccessXero: false,
  isLoadingXero: false,
  isLoading: false,
  isError: false,
  message: ''
};

export const registerOwner = createAsyncThunk('auth/register/business-owner', async (user, thunkAPI) => {
  try {
    return await authService.registerOwner(user);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const registerAdvisor = createAsyncThunk('auth/register/business-advisor', async (user, thunkAPI) => {
  try {
    return await authService.registerAdvisor(user);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const registerWithXero = createAsyncThunk('auth/register-with-xero', async (thunkAPI) => {
  try {
    return await authService.registerWithXero();
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const signInUser = createAsyncThunk('auth/sign-in', async (user, thunkAPI) => {
  try {
    return await authService.signInUser(user);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getNewPassword = createAsyncThunk('auth/password/reset', async (user, thunkAPI) => {
  try {
    return await authService.getNewPassword(user);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const setNewPassword = createAsyncThunk('auth/password/reset/confirm', async (user, thunkAPI) => {
  try {
    return await authService.setNewPassword(user.password, user.encoded_pk, user.token);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const signOutUser = createAsyncThunk('auth/sign-out', async () => {
  await authService.signOutUser();
});

export const getUserProfile = createAsyncThunk('auth/user-profile', async ({ slug, token }, thunkAPI) => {
  try {
    return await authService.getUserProfile(slug, token);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const authSlice = createSlice({
  name: 'authorization',
  initialState,
  reducers: {
    resetAuth: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
    resetXeroRedirect:(state)=>{
      state.xeroRedirect=''
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerOwner.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerOwner.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
      })
      .addCase(registerOwner.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
      })
      .addCase(registerAdvisor.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerAdvisor.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
      })
      .addCase(registerAdvisor.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
      })
      .addCase(registerWithXero.pending, (state) => {
        state.isLoadingXero = true;
      })
      .addCase(registerWithXero.fulfilled, (state, action) => {
        state.isLoadingXero = false;
        state.isSuccessXero = true;
        state.xeroRedirect = action.payload;
      })
      .addCase(registerWithXero.rejected, (state, action) => {
        state.isLoadingXero = false;
        state.isSuccessXero = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
      })
      .addCase(signInUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(signInUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
      })
      .addCase(signInUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
      })
      .addCase(signOutUser.fulfilled, (state) => {
        state.user = null;
      })
      .addCase(getNewPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getNewPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
      })
      .addCase(getNewPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
      })
      .addCase(setNewPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(setNewPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
      })
      .addCase(setNewPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = {
          success: true,
          data: action.payload
        };
      });
  }
});

export default authSlice.reducer;
export const { resetAuth,resetXeroRedirect } = authSlice.actions;

import React, { useEffect, useState } from "react";
import FormikControl from "../../form-elements/formik-control";
import { Form, Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { IoIosClose } from "react-icons/io";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import countryList from "react-select-country-list";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {LiaUserPlusSolid} from 'react-icons/lia'
import moment from "moment";
function InviteNewUserModal(props) {
  const { toggle, toggleModal, isEdit,  } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
const initialValues={
    first_name:'',
    last_name:'',
    email:"",
    message:""
}
const userOptions = [
  {
    value: "Owner",
    key: "Company",
    description: "I am a manager or work internally inside of a company.",
  },
  {
    value: "Advisor",
    key: "Advisor",
    description:
      "I am an accountant or business consultant who manages multiple clients ",
  },
];

 const onSubmit=()=>{}
  const handleClose = (slug) => {
    toggleModal();
  };
  return (
    <div>
      {toggle && (
        <div className="fixed z-30 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center  bg-black bg-opacity-50 min-h-screen p-4">
            <div className="bg-white rounded-lg w-2/5 p-8">
              <div className="flex flex-row justify-between item-start">
                <div className="flex gap-2 ">
                  <LiaUserPlusSolid size={42} />
                  <div className="mt-2">
                    <h2 className="text-lg font-bold ">Invite New User</h2>
                  </div>
                </div>
                <IoIosClose
                  onClick={handleClose}
                  className="cursor-pointer"
                  size={35}
                />
              </div>
              <div className="w-full">
                <Formik
                  className="sign-up form "
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                >
                  {(formik) => (
                    <Form className="py-4 ">
                      <div className="flex flex-col items-start gap-5  mt-4 w-full ">
                        <div className="flex flex-row gap-2 w-full">
                          <FormikControl
                            control="input"
                            type="text"
                            label="Enter First Name"
                            name="first_name"
                            placeholder="Name"
                          />
                          <FormikControl
                            control="input"
                            type="text"
                            label="Enter Last Name"
                            name="last_name"
                            placeholder="Email"
                          />
                        </div>
                        <FormikControl
                          control="input"
                          type="text"
                          label="Enter Email Address"
                          name="email"
                          placeholder="Email"
                        />
                        <FormikControl
                          control="radio"
                          name="user_type"
                          formType='invite-user'
                          options={userOptions}
                        />
                        <FormikControl
                          control="input"
                          type="text"
                          label="Enter Message (Optional)"
                          name="message"
                          placeholder="Email"
                        />
                      </div>
                      <div className="flex flex-col items-end justify-end w-full gap-6 mt-6">
                        <button
                          className="rounded-md md:w-1/5 sm:w-2/5 w-3/5 bg-warning text-black font-bold p-4"
                          type="submit"
                        >
                          Confirm
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default InviteNewUserModal;

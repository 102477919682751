import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import KPIService, { getXeroLastUpdated } from "./kpiService";
const initialState = {
  lineGraphs: {},
  trackingCategory01: {},
  trackingCategory02: {},
  xeroKPIFigures: {},
  xeroBankTable: {},
  xeroLastUpdated: [],
  dashboardOrg: {},
  isSuccessKPI: false,
  isLoadingKPI: false,
  isErrorKPI: false,
  isSuccessDearKPI: false,
  isLoadingDearKPI: false,
  isErrorDearKPI: false,
  isLoeadingOnDemandExtraction: false,
  savedXeroFiltersMessage: {},
  // Dear KPIs
  dearProductSalesFilteredData: [],
  dearBrandFilters: [],
  dearCategoryFilters: [],
  dearContactFilters: [],
  dearCustomerTagsFilters: [],
  dearProductFilters: [],
  dearProductTagsFilters: [],
  dearProductTypeFilters: [],
  dearLocationFilters: [],
  // Dear Sales By...
  dearProductSales: {},
  dearProductSalesCategory: Array(12).fill(null),
  dearProductSalesLineGraph: [],
  dearProductSalesByBrand: [],
  dearProductSalesByCategory: [],
  dearProductSalesByCustomer: [],
  dearProductSalesByCustomerTag: [],
  dearProductSalesByProduct: [],
  dearProductSalesByProductTag: [],
  dearProductSalesByProductType: [],
  dearSalesDrilldownTableByCustomer: [],
  dearSalesDrilldownTableByProduct: [],
  // Dear Sales By Filters....
  salesByBrandDateFilter: "This Month",
  salesByBrandCompareFilter: "Previous Period",
  salesByProductDateFilter: "This Month",
  salesByProductCompareFilter: "Previous Period",
  salesByCategoryDateFilter: "This Month",
  salesByCategoryCompareFilter: "Previous Period",
  salesByCustomerDateFilter: "This Month",
  salesByCustomerCompareFilter: "Previous Period",
  salesByProductTagDateFilter: "This Month",
  salesByProductTagCompareFilter: "Previous Period",
  salesByProductTypeDateFilter: "This Month",
  salesByProductTypeCompareFilter: "Previous Period",
  salesByCustomerTagDateFilter: "This Month",
  salesByCustomerTagCompareFilter: "Previous Period",
  // Dear Profit By ...
  dearProfit: [],
  dearProfitLineGraph: [],
  dearProfitByBrand: [],
  dearProfitByCategory: [],
  dearProfitByCustomer: [],
  dearProfitByCustomerTag: [],
  dearProfitByProduct: [],
  dearProfitByProductTag: [],
  dearProfitByProductType: [],
  dearProfitDrilldownTableByCustomer: [],
  dearProfitDrilldownTableByProduct: [],

  // Dear Profit By Filters...
  profitByCategoryDateFilter: "This Month",
  profitByCategoryCompareFilter: "Previous Period",
  profitByProductDateFilter: "This Month",
  profitByProductCompareFilter: "Previous Period",
  profitByBrandDateFilter: "This Month",
  profitByBrandCompareFilter: "Previous Period",
  profitByCustomerDateFilter: "This Month",
  profitByCustomerCompareFilter: "Previous Period",
  profitByCustomerTagDateFilter: "This Month",
  profitByCustomerTagCompareFilter: "Previous Period",
  profitByProductTagDateFilter: "This Month",
  profitByProductTagCompareFilter: "Previous Period",
  profitByProductTypeDateFilter: "This Month",
  profitByProductTypeCompareFilter: "Previous Period",
  // Dear Units Sold By...
  dearUnitsSold: {},
  dearUnitsSoldLineGraph: [],
  dearUnitsSoldByBrand: [],
  dearUnitsSoldByCategory: [],
  dearUnitsSoldByCustomer: [],
  dearUnitsSoldByCustomerTag: [],
  dearUnitsSoldByProduct: [],
  dearUnitsSoldByProductTag: [],
  dearUnitsSoldByProductType: [],
  // Dear Units Sold By Filters....
  unitsSoldByBrandDateFilter: "This Month",
  unitsSoldByBrandCompareFilter: "Previous Period",
  unitsSoldByProductDateFilter: "This Month",
  unitsSoldByProductCompareFilter: "Previous Period",
  unitsSoldByCategoryDateFilter: "This Month",
  unitsSoldByCategoryCompareFilter: "Previous Period",
  unitsSoldByCustomerDateFilter: "This Month",
  unitsSoldByCustomerCompareFilter: "Previous Period",
  unitsSoldByProductTagDateFilter: "This Month",
  unitsSoldByProductTagCompareFilter: "Previous Period",
  unitsSoldByProductTypeDateFilter: "This Month",
  unitsSoldByProductTypeCompareFilter: "Previous Period",
  unitsSoldByCustomerTagDateFilter: "This Month",
  unitsSoldByCustomerTagCompareFilter: "Previous Period",
  // Dear Units Sold By
  dearUnitsShipped: {},
  dearUnitsShippedLineGraph: [],
  dearUnitsShippedByBrand: [],
  dearUnitsShippedByCategory: [],
  dearUnitsShippedByCustomer: [],
  dearUnitsShippedByCustomerTag: [],
  dearUnitsShippedByProduct: [],
  dearUnitsShippedByProductTag: [],
  dearUnitsShippedByProductType: [],
  dearUnitsSoldDrilldownTableByCustomer: [],
  dearUnitsSoldDrilldownTableByProduct: [],
  // Dear Units Shipped By Filters
  unitsShippedByBrandDateFilter: "This Month",
  unitsShippedByBrandCompareFilter: "Previous Period",
  unitsShippedByProductDateFilter: "This Month",
  unitsShippedByProductCompareFilter: "Previous Period",
  unitsShippedByCategoryDateFilter: "This Month",
  unitsShippedByCategoryCompareFilter: "Previous Period",
  unitsShippedByCustomerDateFilter: "This Month",
  unitsShippedByCustomerCompareFilter: "Previous Period",
  unitsShippedByProductTagDateFilter: "This Month",
  unitsShippedByProductTagCompareFilter: "Previous Period",
  unitsShippedByProductTypeDateFilter: "This Month",
  unitsShippedByProductTypeCompareFilter: "Previous Period",
  unitsShippedByCustomerTagDateFilter: "This Month",
  unitsShippedByCustomerTagCompareFilter: "Previous Period",
  dearUnitsShippedDrilldownTableByCustomer: [],
  dearUnitsShippedDrilldownTableByProduct: [],
  // Dear Other KPIs
  dearDrilldown2Data: [],
  dearCustomerWithoutTags: [],
  dearGrossProfitMargin: [],
  dearGrossProfitMarginCategory: [],
  dearInventoryValue: [],
  dearCostOfGoodsSold: [],
  dearNoSalesOrderByCarriers: [],
  dearInventoryValueCategory: [],
  dearLastUpdated: [],
  dearSampleConversionRate: [],
  dearPercentageCustomer80Sales: [],
  dearPercentageOrderShippedLate: [],
  dearPercentageProduct80Sales: [],

  // Dear Top KPIs
  dearTopLateSuppliers: [],
  dearTopTenSuppliersByCost: [],
  dearTopTenSuppliersByVolume: [],
  message: "",

  dearLoadingStatuses: {
    dearBrandFilters: false,
    dearProductSales: false,
    dearProfit: false,
    dearUnitsSold: false,
    dearUnitsShipped: false,
    dearOtherKPIs: false,
  },
  dearProductSalesCategoryLoading: [],
  dearInventoryValueCategoryLoading: [],
  dearGrossProfitMarginCategoryLoading: []
};
const categoryMap = {
  "Superior": 0,
  "Superior Deluxe Panels": 1,
  "Ultimate Panels": 2,
  "Aluminium Posts": 3,
  "Concrete Posts": 4,
  "Composite Gates": 5,
  "Lights": 6,
  "Pergolas": 7,
  "3D Wall Panels": 8,
  "Composite Posts": 9,
  "Aluminium Gates": 10,
  "Screens": 11
};
const handleKPIRequest = async (requestFunction, dataset_id, token) => {
  try {
    const result = await requestFunction(dataset_id, token);
    return result;
  } catch (error) {
    return {};
  }
};
export const saveXeroKPIFilters = createAsyncThunk(
  "kpi/dashboard/xero/save-filters",
  async ({ savedXeroFilters, token }, thunkAPI) => {
    try {
      return await KPIService.saveXeroKPIFilters(savedXeroFilters, token);
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const handleKPIRequestWithParams = async (
  requestFunction,
  dataset_id,
  token,
  params
) => {
  try {
    const result = await requestFunction(dataset_id, token, params);
    return result;
  } catch (error) {
    return {};
  }
};

export const getDashboardXeroLineGraphs = createAsyncThunk(
  "kpi/dashboard/xero/line-graphs",
  async ({ dataset_id, token }, thunkAPI) => {
    try {
      const lineGraphData = await handleKPIRequest(
        KPIService.getLineGraphs,
        dataset_id,
        token
      );
      return {
        lineGraphData,
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getDashboardXeroTrackingCategory01 = createAsyncThunk(
  "kpi/dashboard/xero/tracking-category-one",
  async ({ dataset_id, token }, thunkAPI) => {
    try {
      const trackingCategory01Data = await handleKPIRequest(
        KPIService.getTrackingCategory01,
        dataset_id,
        token
      );
      return {
        trackingCategory01Data,
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getDashboardXeroTrackingCategory02 = createAsyncThunk(
  "kpi/dashboard/xero/tracking-category-two",
  async ({ dataset_id, token }, thunkAPI) => {
    try {
      const trackingCategory02Data = await handleKPIRequest(
        KPIService.getTrackingCategory02,
        dataset_id,
        token
      );
      return {
        trackingCategory02Data,
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getDashboardXeroTables = createAsyncThunk(
  "kpi/dashboard/xero/tables",
  async ({ dataset_id, token }, thunkAPI) => {
    try {

      const xeroBankTable = await handleKPIRequest(
        KPIService.getXeroBankTable,
        dataset_id,
        token
      );

      return {
        xeroBankTable
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getDashboardXeroAllKPIs = createAsyncThunk(
  "kpi/dashboard/xero/kpi-figure",
  async ({ dataset_id, token }, thunkAPI) => {
    try {
      const xeroLastUpdated = await KPIService.getXeroLastUpdated(
        dataset_id,
        token
      );
      const allXeroKPIs = await handleKPIRequest(
        KPIService.getAllXeroKPIs,
        dataset_id,
        token
      );

      return {
        allXeroKPIs,
        xeroLastUpdated,
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// export const getDashboardXeroData = createAsyncThunk(
//   "kpi/dashboard/xero",
//   async ({ slug, xero_dataset_id, token }, thunkAPI) => {
//     try {
//       // const response = await KPIService.getDatasetId(slug, token);
//       const dataset_id = xero_dataset_id;

//       // Dispatching Line Graphs
//       const lineGraphsDataAction = await thunkAPI.dispatch(
//         getDashboardXeroLineGraphs({ dataset_id, token })
//       );
//       const lineGraphData = lineGraphsDataAction.payload;
//       // Dispatching Category One
//       const trackingCategory01Action = await thunkAPI.dispatch(
//         getDashboardXeroTrackingCategory01({ dataset_id, token })
//       );
//       const trackingCategory01Data = trackingCategory01Action.payload;
//       // Dispatching Category Two
//       const trackingCategory02Action = await thunkAPI.dispatch(
//         getDashboardXeroTrackingCategory02({ dataset_id, token })
//       );
//       const trackingCategory02Data = trackingCategory02Action.payload;

//       // Dispatching KPI Figure
//       const xeroKPIFigureData = await thunkAPI.dispatch(
//         getDashboardXeroAllKPIs({ dataset_id, token })
//       );
//       const xeroKPIFigure = xeroKPIFigureData.payload;

//       // Combine the data as needed
//       const combinedData = {
//         lineGraphData,
//         trackingCategory01Data,
//         trackingCategory02Data,
//         xeroKPIFigure,
//       };

//       return combinedData;
//     } catch (error) {
//       const message =
//         (error.message && error.response.data && error.response.data.message) ||
//         error.message ||
//         error.toString();
//       return thunkAPI.rejectWithValue(message);
//     }
//   }
// );
export const getDashboardXeroData = createAsyncThunk(
  "kpi/dashboard/xero",
  async ({ slug, xero_dataset_id, token }, thunkAPI) => {
    try {
      const dataset_id = xero_dataset_id;

      // Dispatch all actions concurrently
      const [
        lineGraphsDataAction,
        trackingCategory01Action,
        trackingCategory02Action,
        xeroKPIFigureData,
        xeroTablesData
      ] = await Promise.all([
        thunkAPI.dispatch(getDashboardXeroLineGraphs({ dataset_id, token })),
        thunkAPI.dispatch(getDashboardXeroTrackingCategory01({ dataset_id, token })),
        thunkAPI.dispatch(getDashboardXeroTrackingCategory02({ dataset_id, token })),
        thunkAPI.dispatch(getDashboardXeroAllKPIs({ dataset_id, token })),
        thunkAPI.dispatch(getDashboardXeroTables({ dataset_id, token })),
      ]);

      // Extract the payloads
      const lineGraphData = lineGraphsDataAction.payload;
      const trackingCategory01Data = trackingCategory01Action.payload;
      const trackingCategory02Data = trackingCategory02Action.payload;
      const xeroKPIFigure = xeroKPIFigureData.payload;
      const xeroTables = xeroTablesData;

      // Combine the data as needed
      const combinedData = {
        lineGraphData,
        trackingCategory01Data,
        trackingCategory02Data,
        xeroKPIFigure,
        xeroTables
      };

      return combinedData;
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDashboardDearFilters = createAsyncThunk(
  "kpi/dashboard/dear/filters",
  async ({ dataset_id, token }, thunkAPI) => {
    try {
      const dearBrandFilters = await handleKPIRequest(
        KPIService.getDearBrandFilters,
        dataset_id,
        token
      );
      const dearCategoryFilters = await handleKPIRequest(
        KPIService.getDearCategoryFilters,
        dataset_id,
        token
      );
      const dearContactFilters = await handleKPIRequest(
        KPIService.getDearContactFilters,
        dataset_id,
        token
      );
      const dearCustomerTagsFilters = await handleKPIRequest(
        KPIService.getDearCustomerTagsFilters,
        dataset_id,
        token
      );
      const dearProductFilters = await handleKPIRequest(
        KPIService.getDearProductFilters,
        dataset_id,
        token
      );
      const dearProductTagsFilters = await handleKPIRequest(
        KPIService.getDearProductTagsFilters,
        dataset_id,
        token
      );
      const dearProductTypeFilters = await handleKPIRequest(
        KPIService.getDearProductTypeFilters,
        dataset_id,
        token
      );
      const dearLocationFilters = await handleKPIRequest(
        KPIService.getDearLocationFilters,
        dataset_id,
        token
      );
      return {
        dearBrandFilters,
        dearCategoryFilters,
        dearContactFilters,
        dearCustomerTagsFilters,
        dearProductFilters,
        dearCustomerTagsFilters,
        dearProductTagsFilters,
        dearProductTypeFilters,
        dearLocationFilters,
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Dear Filtered Data
export const getDearProductSalesKPIFilteredData = createAsyncThunk(
  "kpi/dashboard/dear/filtered/profit/category",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
      const dearProductSalesKPI = await KPIService.getDearProductSalesKPI(
        dataset_id,
        token,
        params
      );
      return {
        dearProductSalesKPI,
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Dear Filtered Data
export const getDearProductSalesKPIFilteredDataCategory = createAsyncThunk(
  "kpi/dashboard/dear/filtered/profit",
  async ({ dataset_id, token, params, categoryFilter }, thunkAPI) => {
    try {
      const dearProductSalesKPICategory =
        await KPIService.getDearProductSalesKPI(dataset_id, token, params);
      return { dearProductSalesKPICategory, categoryFilter };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDearGrossProfitMarginKPIFilteredDataCategory = createAsyncThunk(
  "kpi/dashboard/dear/filtered/gross-profit",
  async ({ dataset_id, token, params, categoryFilter }, thunkAPI) => {
    try {
      const dearGrossProfitMarginKPICategory =
        await KPIService.getDearGrossProfitMarginKPI(dataset_id, token, params);
      return {
        dearGrossProfitMarginKPICategory,
        categoryFilter,
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getDearInventoryValueKPIFilteredDataCategory = createAsyncThunk(
  "kpi/dashboard/dear/filtered/inventoryvalue",
  async ({ dataset_id, token, params, categoryFilter }, thunkAPI) => {
    try {
      const dearInventoryValueKPICategory =
        await KPIService.getDearInventoryValue(dataset_id, token, params);
      return {
        dearInventoryValueKPICategory,
        categoryFilter,
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getDearUnitsSoldKPIFilteredData = createAsyncThunk(
  "kpi/dashboard/dear/filtered/units-sold",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
      const dearUnitsSoldKPI = await KPIService.getDearUnitsSold(
        dataset_id,
        token,
        params
      );
      return {
        dearUnitsSoldKPI,
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getDearUnitsShippedKPIFilteredData = createAsyncThunk(
  "kpi/dashboard/dear/filtered/units-shipped",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
      const dearUnitsShippedKPI = await KPIService.getUnitsShipped(
        dataset_id,
        token,
        params
      );
      return {
        dearUnitsShippedKPI,
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getDearGrossProfitMarginKPIFilteredData = createAsyncThunk(
  "kpi/dashboard/dear/filtered/gross-profit-margin",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
      const dearGrossProfitMarginKPI =
        await KPIService.getDearGrossProfitMarginKPI(dataset_id, token, params);
      return {
        dearGrossProfitMarginKPI,
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getDearInventoryValueKPIFilteredData = createAsyncThunk(
  "kpi/dashboard/dear/filtered/inventory-value",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
      const dearInventoryValueKPI =
        await KPIService.getDearInventoryValue(dataset_id, token, params);
      return {
        dearInventoryValueKPI,
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getDearPercentageCustomer80SalesKPIFilteredData = createAsyncThunk(
  "kpi/dashboard/dear/filtered/percentage-customer-80-sales",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
      const dearPercentageCustomer80Sales =
        await KPIService.getPercentageCustomer80Sales(
          dataset_id,
          token,
          params
        );
      return {
        dearPercentageCustomer80Sales,
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getDearPercentageProduct80SalesKPIFilteredData = createAsyncThunk(
  "kpi/dashboard/dear/filtered/percentage-product-80-sales",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
      const dearPercentageProduct80Sales =
        await KPIService.getPercentageProduct80Sales(dataset_id, token, params);
      return {
        dearPercentageProduct80Sales,
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getDearPercentageOrderShippedLateKPIFilteredData =
  createAsyncThunk(
    "kpi/dashboard/dear/filtered/percentage-order-shipped-late",
    async ({ dataset_id, token, params }, thunkAPI) => {
      try {
        const dearPercentageOrderShippedLate =
          await KPIService.getPercentageOrderShippedLate(
            dataset_id,
            token,
            params
          );
        return {
          dearPercentageOrderShippedLate,
        };
      } catch (error) {
        const message =
          (error.message &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
export const getDearProfitKPIFilteredData = createAsyncThunk(
  "kpi/dashboard/dear/filtered/product-sales",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
      const dearProfitKPI = await KPIService.getDearProfit(
        dataset_id,
        token,
        params
      );
      return {
        dearProfitKPI,
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getDashboardDearAllProductSalesKPIs = createAsyncThunk(
  "kpi/dashboard/dear/product-sales",
  async ({ dataset_id, token, params }, thunkAPI) => {
    const requests = [
      KPIService.getDearProductSalesKPI(dataset_id, token, params),
      KPIService.getDearProductSalesLineGraph(dataset_id, token, params),
      KPIService.getDearProductSalesByBrand(dataset_id, token, params),
      KPIService.getDearProductSalesByCategory(dataset_id, token, params),
      KPIService.getDearProductSalesByCustomer(dataset_id, token, params),
      KPIService.getDearProductSalesByCustomerTag(dataset_id, token, params),
      KPIService.getDearProductSalesByProduct(dataset_id, token, params),
      KPIService.getDearProductSalesByProductTag(dataset_id, token, params),
      KPIService.getDearProductSalesByProductType(dataset_id, token, params),
      KPIService.getSalesDrilldownCustomer(dataset_id, token, params),
      KPIService.getSalesDrilldownProduct(dataset_id, token, params),
    ];

    const results = await Promise.allSettled(requests);

    const [
      dearProductSalesKPI,
      dearProductSalesLineGraph,
      dearProductSalesByBrand,
      dearProductSalesByCategory,
      dearProductSalesByCustomer,
      dearProductSalesByCustomerTag,
      dearProductSalesByProduct,
      dearProductSalesByProductTag,
      dearProductSalesByProductType,
      dearSalesDrilldownTableByCustomer,
      dearSalesDrilldownTableByProduct,
    ] = results.map((result) => (result.status === "fulfilled" ? result.value : null));

    return {
      dearProductSalesKPI,
      dearProductSalesLineGraph,
      dearProductSalesByBrand,
      dearProductSalesByCategory,
      dearProductSalesByCustomer,
      dearProductSalesByCustomerTag,
      dearProductSalesByProduct,
      dearProductSalesByProductTag,
      dearProductSalesByProductType,
      dearSalesDrilldownTableByCustomer,
      dearSalesDrilldownTableByProduct,
    };
  }
);

export const getDashboardDearAllOtherKPIs = createAsyncThunk(
  "kpi/dashboard/dear/other",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
      const dearLastUpdated = await KPIService.getDearLastUpdated(
        dataset_id,
        token
      );
      const dearPercentageCustomer80Sales =
        await KPIService.getPercentageCustomer80Sales(
          dataset_id,
          token,
          params
        );
      const dearPercentageOrderShippedLate =
        await KPIService.getPercentageOrderShippedLate(
          dataset_id,
          token,
          params
        );
      const dearPercentageProduct80Sales =
        await KPIService.getPercentageProduct80Sales(dataset_id, token, params);

      const dearTopLateSuppliers = await KPIService.getDearTopLateSuppliers(
        dataset_id,
        token
      );
      const dearTopTenSuppliersByCost =
        await KPIService.getDearTopTenSuppliersByCost(dataset_id, token);
      const dearTopTenSuppliersByVolume =
        await KPIService.getDearTopTenSuppliersByVolume(dataset_id, token);
      const dearCustomerWithoutTags =
        await KPIService.getDearCustomerWithoutTags(dataset_id, token);
      const dearGrossProfitMargin =
        await KPIService.getDearGrossProfitMarginKPI(dataset_id, token, params);
      const dearInventoryValue = await KPIService.getDearInventoryValue(
        dataset_id,
        token,
        params
      );

      const dearCostOfGoodsSold = await KPIService.getDearCostOfGoodsSold(
        dataset_id,
        token,
        params
      );
      const dearNoSalesOrderByCarriers = await KPIService.getDearNoSalesOrderByCarriers(
        dataset_id,
        token,
        params
      );
      const dearSampleConversionRate = await KPIService.getDearSampleConversionRate(
        dataset_id,
        token,
      );
      return {
        dearLastUpdated,
        dearPercentageCustomer80Sales,
        dearPercentageOrderShippedLate,
        dearPercentageProduct80Sales,
        dearTopLateSuppliers,
        dearCustomerWithoutTags,
        dearTopTenSuppliersByCost,
        dearTopTenSuppliersByVolume,
        dearGrossProfitMargin,
        dearInventoryValue,

        dearCostOfGoodsSold,
        dearNoSalesOrderByCarriers,

        dearSampleConversionRate,

      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getDashboardDearAllUnitsSoldKPIs = createAsyncThunk(
  "kpi/dashboard/dear/units-sold",
  async ({ dataset_id, token, params }, thunkAPI) => {
    const requests = [
      KPIService.getDearUnitsSold(dataset_id, token, params),
      KPIService.getDearUnitsSoldLineGraph(dataset_id, token, params),
      KPIService.getDearUnitsSoldByBrand(dataset_id, token, params),
      KPIService.getDearUnitsSoldByCategory(dataset_id, token, params),
      KPIService.getDearUnitsSoldByCustomer(dataset_id, token, params),
      KPIService.getDearUnitsSoldByCustomerTag(dataset_id, token, params),
      KPIService.getDearUnitsSoldByProduct(dataset_id, token, params),
      KPIService.getDearUnitsSoldByProductTag(dataset_id, token, params),
      KPIService.getDearUnitsSoldByProductType(dataset_id, token, params),
      KPIService.getUnitsSoldDrilldownCustomer(dataset_id, token, params),
      KPIService.getUnitsSoldDrilldownProduct(dataset_id, token, params),
    ];

    const results = await Promise.allSettled(requests);

    const [
      dearUnitsSold,
      dearUnitsSoldLineGraph,
      dearUnitsSoldByBrand,
      dearUnitsSoldByCategory,
      dearUnitsSoldByCustomer,
      dearUnitsSoldByCustomerTag,
      dearUnitsSoldByProduct,
      dearUnitsSoldByProductTag,
      dearUnitsSoldByProductType,
      dearUnitsSoldDrilldownTableByCustomer,
      dearUnitsSoldDrilldownTableByProduct,
    ] = results.map((result) => (result.status === "fulfilled" ? result.value : null));

    return {
      dearUnitsSold,
      dearUnitsSoldLineGraph,
      dearUnitsSoldByBrand,
      dearUnitsSoldByCategory,
      dearUnitsSoldByCustomer,
      dearUnitsSoldByCustomerTag,
      dearUnitsSoldByProduct,
      dearUnitsSoldByProductTag,
      dearUnitsSoldByProductType,
      dearUnitsSoldDrilldownTableByCustomer,
      dearUnitsSoldDrilldownTableByProduct,
    };
  }
);

export const getDashboardDearAllUnitsShippedKPIs = createAsyncThunk(
  "kpi/dashboard/dear/units-shipped",
  async ({ dataset_id, token, params }, thunkAPI) => {
    const requests = [
      KPIService.getUnitsShipped(dataset_id, token, params),
      KPIService.getUnitsShippedLineGraph(dataset_id, token, params),
      KPIService.getUnitsShippedByBrand(dataset_id, token, params),
      KPIService.getUnitsShippedByCategory(dataset_id, token, params),
      KPIService.getUnitsShippedByCustomer(dataset_id, token, params),
      KPIService.getUnitsShippedByCustomerTag(dataset_id, token, params),
      KPIService.getUnitsShippedByProduct(dataset_id, token, params),
      KPIService.getUnitsShippedByProductTag(dataset_id, token, params),
      KPIService.getUnitsShippedByProductType(dataset_id, token, params),
      KPIService.getUnitsShippedDrilldownCustomer(dataset_id, token, params),
      KPIService.getUnitsShippedDrilldownProduct(dataset_id, token, params),
    ];

    const results = await Promise.allSettled(requests);

    const [
      dearUnitsShipped,
      dearUnitsShippedLineGraph,
      dearUnitsShippedByBrand,
      dearUnitsShippedByCategory,
      dearUnitsShippedByCustomer,
      dearUnitsShippedByCustomerTag,
      dearUnitsShippedByProduct,
      dearUnitsShippedByProductTag,
      dearUnitsShippedByProductType,
      dearUnitsShippedDrilldownTableByCustomer,
      dearUnitsShippedDrilldownTableByProduct,
    ] = results.map(result => result.status === "fulfilled" ? result.value : null);

    return {
      dearUnitsShipped,
      dearUnitsShippedLineGraph,
      dearUnitsShippedByBrand,
      dearUnitsShippedByCategory,
      dearUnitsShippedByCustomer,
      dearUnitsShippedByCustomerTag,
      dearUnitsShippedByProduct,
      dearUnitsShippedByProductTag,
      dearUnitsShippedByProductType,
      dearUnitsShippedDrilldownTableByCustomer,
      dearUnitsShippedDrilldownTableByProduct,
    };
  }
);




export const getDearProfitDrilldown01TableByCustomer = createAsyncThunk(
  "kpi/dashboard/dear/profit/drilldown-01/customer",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
      const dearProfitDrilldownTableByCustomer =
        await KPIService.getProfitDrilldownCustomer(dataset_id, token, params);

      return {
        dearProfitDrilldownTableByCustomer,
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getDearProfitDrilldown01TableByProduct = createAsyncThunk(
  "kpi/dashboard/dear/profit/drilldown-01/product",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
      const dearProfitDrilldownTableByProduct =
        await KPIService.getProfitDrilldownProduct(dataset_id, token, params);
      return {
        dearProfitDrilldownTableByProduct,
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Dear Drilldowns
export const getDearSalesDrilldown01TableByCustomer = createAsyncThunk(
  "kpi/dashboard/dear/sales/drilldown-01/customer",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
      const dearSalesDrilldownTableByCustomer =
        await KPIService.getSalesDrilldownCustomer(dataset_id, token, params);

      return {
        dearSalesDrilldownTableByCustomer,
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDearSalesDrilldown01TableByProduct = createAsyncThunk(
  "kpi/dashboard/dear/sales/drilldown-01/product",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
      const dearSalesDrilldownTableByProduct =
        await KPIService.getSalesDrilldownProduct(dataset_id, token, params);
      return {
        dearSalesDrilldownTableByProduct,
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDearUnitsSoldDrilldown01TableByCustomer = createAsyncThunk(
  "kpi/dashboard/dear/unitsSold/drilldown-01/customer",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
      const dearUnitsSoldDrilldownTableByCustomer =
        await KPIService.getUnitsSoldDrilldownCustomer(
          dataset_id,
          token,
          params
        );

      return {
        dearUnitsSoldDrilldownTableByCustomer,
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDearUnitsSoldDrilldown01TableByProduct = createAsyncThunk(
  "kpi/dashboard/dear/unitsSold/drilldown-01/product",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
      const dearUnitsSoldDrilldownTableByProduct =
        await KPIService.getUnitsSoldDrilldownProduct(
          dataset_id,
          token,
          params
        );
      return {
        dearUnitsSoldDrilldownTableByProduct,
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDearUnitsShippedDrilldown01TableByCustomer = createAsyncThunk(
  "kpi/dashboard/dear/unitsShipped/drilldown-01/customer",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
      const dearUnitsShippedDrilldownTableByCustomer =
        await KPIService.getUnitsShippedDrilldownCustomer(
          dataset_id,
          token,
          params
        );

      return {
        dearUnitsShippedDrilldownTableByCustomer,
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDearUnitsShippedDrilldown01TableByProduct = createAsyncThunk(
  "kpi/dashboard/dear/unitsShipped/drilldown-01/product",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
      const dearUnitsShippedDrilldownTableByProduct =
        await KPIService.getUnitsShippedDrilldownProduct(
          dataset_id,
          token,
          params
        );
      return {
        dearUnitsShippedDrilldownTableByProduct,
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Drilldown 2
export const getDearProfitDrilldown02TableByCustomer = createAsyncThunk(
  "kpi/dashboard/dear/profit/drilldown-02/customer",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
      const dearProfitDrilldownTableByCustomer =
        await KPIService.getProfitDrilldownCustomer2(dataset_id, token, params);

      return {
        dearProfitDrilldownTableByCustomer,
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDearProfitDrilldown02TableByProduct = createAsyncThunk(
  "kpi/dashboard/dear/profit/drilldown-02/product",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
      const dearProfitDrilldownTableByProduct =
        await KPIService.getProfitDrilldownProduct2(dataset_id, token, params);

      return {
        dearProfitDrilldownTableByProduct,
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDearSalesDrilldown02TableByCustomer = createAsyncThunk(
  "kpi/dashboard/dear/sales/drilldown-02/customer",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
      const dearSalesDrilldownTableByCustomer =
        await KPIService.getSalesDrilldownCustomer2(dataset_id, token, params);

      return {
        dearSalesDrilldownTableByCustomer,
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDearSalesDrilldown02TableByProduct = createAsyncThunk(
  "kpi/dashboard/dear/sales/drilldown-02/product",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
      const dearSalesDrilldownTableByProduct =
        await KPIService.getSalesDrilldownProduct2(dataset_id, token, params);

      return {
        dearSalesDrilldownTableByProduct,
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDearUnitsSoldDrilldown02TableByCustomer = createAsyncThunk(
  "kpi/dashboard/dear/unitsSold/drilldown-02/customer",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
      const dearUnitsSoldDrilldownTableByCustomer =
        await KPIService.getUnitsSoldDrilldownCustomer2(
          dataset_id,
          token,
          params
        );

      return {
        dearUnitsSoldDrilldownTableByCustomer,
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDearUnitsSoldDrilldown02TableByProduct = createAsyncThunk(
  "kpi/dashboard/dear/unitsSold/drilldown-02/product",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
      const dearUnitsSoldDrilldownTableByProduct =
        await KPIService.getUnitsSoldDrilldownProduct2(
          dataset_id,
          token,
          params
        );

      return {
        dearUnitsSoldDrilldownTableByProduct,
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDearUnitsShippedDrilldown02TableByCustomer = createAsyncThunk(
  "kpi/dashboard/dear/unitsShipped/drilldown-02/customer",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
      const dearUnitsShippedDrilldownTableByCustomer =
        await KPIService.getUnitsShippedDrilldownCustomer2(
          dataset_id,
          token,
          params
        );

      return {
        dearUnitsShippedDrilldownTableByCustomer,
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDearUnitsShippedDrilldown02TableByProduct = createAsyncThunk(
  "kpi/dashboard/dear/unitsShipped/drilldown-02/product",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
      const dearUnitsShippedDrilldownTableByProduct =
        await KPIService.getUnitsShippedDrilldownProduct2(
          dataset_id,
          token,
          params
        );

      return {
        dearUnitsShippedDrilldownTableByProduct,
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDashboardDearAllProfitKPIs = createAsyncThunk(
  "kpi/dashboard/dear/profit",
  async ({ dataset_id, token, params }, thunkAPI) => {
    const requests = [
      KPIService.getDearProfit(dataset_id, token, params),
      KPIService.getDearProfitLineGraph(dataset_id, token, params),
      KPIService.getDearProfitByBrand(dataset_id, token, params),
      KPIService.getDearProfitByCategory(dataset_id, token, params),
      KPIService.getDearProfitByCustomer(dataset_id, token, params),
      KPIService.getDearProfitByCustomerTag(dataset_id, token, params),
      KPIService.getDearProfitByProduct(dataset_id, token, params),
      KPIService.getDearProfitByProductTag(dataset_id, token, params),
      KPIService.getDearProfitByProductType(dataset_id, token, params),
      KPIService.getProfitDrilldownCustomer(dataset_id, token, params),
      KPIService.getProfitDrilldownProduct(dataset_id, token, params),
    ];

    const results = await Promise.allSettled(requests);

    const [
      dearProfit,
      dearProfitLineGraph,
      dearProfitByBrand,
      dearProfitByCategory,
      dearProfitByCustomer,
      dearProfitByCustomerTag,
      dearProfitByProduct,
      dearProfitByProductTag,
      dearProfitByProductType,
      dearProfitDrilldownTableByCustomer,
      dearProfitDrilldownTableByProduct,
    ] = results.map((result) => (result.status === "fulfilled" ? result.value : null));

    return {
      dearProfit,
      dearProfitLineGraph,
      dearProfitByBrand,
      dearProfitByCategory,
      dearProfitByCustomer,
      dearProfitByCustomerTag,
      dearProfitByProduct,
      dearProfitByProductTag,
      dearProfitByProductType,
      dearProfitDrilldownTableByCustomer,
      dearProfitDrilldownTableByProduct,
    };
  }
);


export const getDashboardDearData = createAsyncThunk(
  "kpi/dashboard/dear",
  async ({ slug, dear_dataset_id, token, params }, thunkAPI) => {
    try {
      const dataset_id = dear_dataset_id;

      const [
        dearBrandFiltersData,
        dearProductSalesKPIData,
        dearProfitKPIData,
        dearUnitsSoldKPIData,
        dearUnitsShippedKPIData,
        dearOtherKPIsData,
      ] = await Promise.all([
        thunkAPI.dispatch(getDashboardDearFilters({ dataset_id, token })),
        thunkAPI.dispatch(getDashboardDearAllProductSalesKPIs({ dataset_id, token, params })),
        thunkAPI.dispatch(getDashboardDearAllProfitKPIs({ dataset_id, token, params })),
        thunkAPI.dispatch(getDashboardDearAllUnitsSoldKPIs({ dataset_id, token, params })),
        thunkAPI.dispatch(getDashboardDearAllUnitsShippedKPIs({ dataset_id, token, params })),
        thunkAPI.dispatch(getDashboardDearAllOtherKPIs({ dataset_id, token, params })),
      ]);

      const dearBrandFilters = dearBrandFiltersData.payload;
      const dearAllProductSales = dearProductSalesKPIData.payload;
      const dearProfit = dearProfitKPIData.payload;
      const dearUnitsSold = dearUnitsSoldKPIData.payload;
      const dearUnitsShipped = dearUnitsShippedKPIData.payload;
      const dearOtherKPIs = dearOtherKPIsData.payload;

      const combinedData = {
        dearBrandFilters,
        dearAllProductSales,
        dearProfit,
        dearUnitsSold,
        dearUnitsShipped,
        dearOtherKPIs,
      };

      return combinedData;
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);



export const addKPIFavorite = createAsyncThunk(
  "kpi/favorite",
  async ({ org_id, kpiFavData, token }, thunkAPI) => {
    try {
      return await KPIService.addKPIFavorite(org_id, kpiFavData, token);
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// On Demand Dear Extraction
export const getOnDemandDearData = createAsyncThunk(
  "kpi/get/on-demand/",
  async ({ slug, refresh_payload, token }, thunkAPI) => {
    try {
      return await KPIService.getOnDemandDearDataExtraction(
        slug,
        refresh_payload,
        token
      );
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export function setDashboardOrg(state, action) {
  const { org } = action.payload;
  state.dashboardOrg = org;
}
export function setDearSalesByBrandDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.salesByBrandDateFilter = dateFilter;
}
export function setDearSalesByBrandComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.salesByBrandCompareFilter = comparisonFilter;
}
export function setDearSalesByProductDateFilter(state, action) {
  const { dateFilter } = action.payload;

  state.salesByProductDateFilter = dateFilter;
}
export function setDearSalesByProductComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.salesByProductCompareFilter = comparisonFilter;
}
export function setDearSalesByCategoryDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.salesByCategoryDateFilter = dateFilter;
}
export function setDearSalesByCategoryComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.salesByCategoryCompareFilter = comparisonFilter;
}
export function setDearSalesByCustomerDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.salesByCustomerDateFilter = dateFilter;
}
export function setDearSalesByCustomerComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.salesByCustomerCompareFilter = comparisonFilter;
}
export function setDearSalesByProductTagDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.salesByProductTagDateFilter = dateFilter;
}
export function setDearSalesByProductTagComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.salesByProductTagCompareFilter = comparisonFilter;
}
export function setDearSalesByProductTypeDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.salesByProductTypeDateFilter = dateFilter;
}
export function setDearSalesByProductTypeComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.salesByProductTypeCompareFilter = comparisonFilter;
}
export function setDearSalesByCustomerTagDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.salesByCustomerTagDateFilter = dateFilter;
}
export function setDearSalesByCustomerTagComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.salesByCustomerTagCompareFilter = comparisonFilter;
}
// Dear Products By
export function setDearProfitByCategoryDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.profitByCategoryDateFilter = dateFilter;
}
export function setDearProfitByCategoryComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.profitByCategoryCompareFilter = comparisonFilter;
}
export function setDearProfitByProductDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.profitByProductDateFilter = dateFilter;
}
export function setDearProfitByProductComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.profitByProductCompareFilter = comparisonFilter;
}
export function setDearProfitByBrandDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.profitByBrandDateFilter = dateFilter;
}
export function setDearProfitByBrandComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.profitByBrandCompareFilter = comparisonFilter;
}
// Dear Products By Customer
export function setDearProfitByCustomerDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.profitByCustomerDateFilter = dateFilter;
}

export function setDearProfitByCustomerComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.profitByCustomerComparisonFilter = comparisonFilter;
}

// Dear Products By Customer Tag
export function setDearProfitByCustomerTagDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.profitByCustomerTagDateFilter = dateFilter;
}

export function setDearProfitByCustomerTagComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.profitByCustomerTagComparisonFilter = comparisonFilter;
}

// Dear Products By Product Tag
export function setDearProfitByProductTagDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.profitByProductTagDateFilter = dateFilter;
}

export function setDearProfitByProductTagComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.profitByProductTagComparisonFilter = comparisonFilter;
}

// Dear Products By Product Type
export function setDearProfitByProductTypeDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.profitByProductTypeDateFilter = dateFilter;
}

export function setDearProfitByProductTypeComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.profitByProductTypeComparisonFilter = comparisonFilter;
}
// Units Sold
export function setDearUnitsSoldByBrandDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.unitsSoldByBrandDateFilter = dateFilter;
}
export function setDearUnitsSoldByBrandComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.unitsSoldByBrandCompareFilter = comparisonFilter;
}
export function setDearUnitsSoldByProductDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.unitsSoldByProductDateFilter = dateFilter;
}
export function setDearUnitsSoldByProductComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.unitsSoldByProductCompareFilter = comparisonFilter;
}
export function setDearUnitsSoldByCategoryDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.unitsSoldByCategoryDateFilter = dateFilter;
}
export function setDearUnitsSoldByCategoryComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.unitsSoldByCategoryCompareFilter = comparisonFilter;
}
export function setDearUnitsSoldByCustomerDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.unitsSoldByCustomerDateFilter = dateFilter;
}
export function setDearUnitsSoldByCustomerComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.unitsSoldByCustomerCompareFilter = comparisonFilter;
}
export function setDearUnitsSoldByProductTagDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.unitsSoldByProductTagDateFilter = dateFilter;
}
export function setDearUnitsSoldByProductTagComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.unitsSoldByProductTagCompareFilter = comparisonFilter;
}
export function setDearUnitsSoldByProductTypeDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.unitsSoldByProductTypeDateFilter = dateFilter;
}
export function setDearUnitsSoldByProductTypeComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.unitsSoldByProductTypeCompareFilter = comparisonFilter;
}
export function setDearUnitsSoldByCustomerTagDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.unitsSoldByCustomerTagDateFilter = dateFilter;
}
export function setDearUnitsSoldByCustomerTagComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.unitsSoldByCustomerTagCompareFilter = comparisonFilter;
}
// Units Shipped
export function setUnitsShippedByBrandDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.unitsShippedByBrandDateFilter = dateFilter;
}
export function setUnitsShippedByBrandComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.unitsShippedByBrandCompareFilter = comparisonFilter;
}
export function setUnitsShippedByProductDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.unitsShippedByProductDateFilter = dateFilter;
}
export function setUnitsShippedByProductComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.unitsShippedByProductCompareFilter = comparisonFilter;
}
export function setUnitsShippedByCategoryDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.unitsShippedByCategoryDateFilter = dateFilter;
}
export function setUnitsShippedByCategoryComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.unitsShippedByCategoryCompareFilter = comparisonFilter;
}
export function setUnitsShippedByCustomerDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.unitsShippedByCustomerDateFilter = dateFilter;
}
export function setUnitsShippedByCustomerComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.unitsShippedByCustomerCompareFilter = comparisonFilter;
}
export function setUnitsShippedByProductTagDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.unitsShippedByProductTagDateFilter = dateFilter;
}
export function setUnitsShippedByProductTagComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.unitsShippedByProductTagCompareFilter = comparisonFilter;
}
export function setUnitsShippedByProductTypeDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.unitsShippedByProductTypeDateFilter = dateFilter;
}
export function setUnitsShippedByProductTypeComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.unitsShippedByProductTypeCompareFilter = comparisonFilter;
}
export function setUnitsShippedByCustomerTagDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.unitsShippedByCustomerTagDateFilter = dateFilter;
}
export function setUnitsShippedByCustomerTagComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.unitsShippedByCustomerTagCompareFilter = comparisonFilter;
}

export const KPISlice = createSlice({
  name: "kpi",
  initialState,
  reducers: {
    resetKPI: (state) => {
      state.isLoadingKPI = false;
      state.isSuccessKPI = false;
      state.isErrorKPI = false;
      state.message = "";
    },
    setDashboardOrganisation: setDashboardOrg,
    // Dear Sales By...
    updateDearSalesByBrandDateFilter: setDearSalesByBrandDateFilter,
    updateDearSalesByBrandComparisonFilter: setDearSalesByBrandComparisonFilter,
    updateDearSalesByProductDateFilter: setDearSalesByProductDateFilter,
    updateDearSalesByProductComparisonFilter:
      setDearSalesByProductComparisonFilter,
    updateDearSalesByCategoryDateFilter: setDearSalesByCategoryDateFilter,
    updateDearSalesByCategoryComparisonFilter:
      setDearSalesByCategoryComparisonFilter,
    updateDearSalesByCustomerDateFilter: setDearSalesByCustomerDateFilter,
    updateDearSalesByCustomerComparisonFilter:
      setDearSalesByCustomerComparisonFilter,
    updateDearSalesByProductTagDateFilter: setDearSalesByProductTagDateFilter,
    updateDearSalesByProductTagComparisonFilter:
      setDearSalesByProductTagComparisonFilter,
    updateDearSalesByProductTypeDateFilter: setDearSalesByProductTypeDateFilter,
    updateDearSalesByProductTypeComparisonFilter:
      setDearSalesByProductTypeComparisonFilter,
    updateDearSalesByCustomerTagDateFilter: setDearSalesByCustomerTagDateFilter,
    updateDearSalesByCustomerTagComparisonFilter:
      setDearSalesByCustomerTagComparisonFilter,
    // Dear Products By....
    updateDearProfitByCategoryDateFilter: setDearProfitByCategoryDateFilter,
    updateDearProfitByCategoryComparisonFilter:
      setDearProfitByCategoryComparisonFilter,
    updateDearProfitByProductDateFilter: setDearProfitByProductDateFilter,
    updateDearProfitByProductComparisonFilter:
      setDearProfitByProductComparisonFilter,
    updateDearProfitByBrandDateFilter: setDearProfitByBrandDateFilter,
    updateDearProfitByBrandComparisonFilter:
      setDearProfitByBrandComparisonFilter,
    // Dear Products By Customer
    updateDearProfitByCustomerDateFilter: setDearProfitByCustomerDateFilter,
    updateDearProfitByCustomerComparisonFilter:
      setDearProfitByCustomerComparisonFilter,

    // Dear Products By Customer Tag
    updateDearProfitByCustomerTagDateFilter:
      setDearProfitByCustomerTagDateFilter,
    updateDearProfitByCustomerTagComparisonFilter:
      setDearProfitByCustomerTagComparisonFilter,

    // Dear Products By Product Tag
    updateDearProfitByProductTagDateFilter: setDearProfitByProductTagDateFilter,
    updateDearProfitByProductTagComparisonFilter:
      setDearProfitByProductTagComparisonFilter,

    // Dear Products By Product Type
    updateDearProfitByProductTypeDateFilter:
      setDearProfitByProductTypeDateFilter,
    updateDearProfitByProductTypeComparisonFilter:
      setDearProfitByProductTypeComparisonFilter,

    // Dear Units Sold By...
    updateDearUnitsSoldByBrandDateFilter: setDearUnitsSoldByBrandDateFilter,
    updateDearUnitsSoldByBrandComparisonFilter:
      setDearUnitsSoldByBrandComparisonFilter,
    updateDearUnitsSoldByProductDateFilter: setDearUnitsSoldByProductDateFilter,
    updateDearUnitsSoldByProductComparisonFilter:
      setDearUnitsSoldByProductComparisonFilter,
    updateDearUnitsSoldByCategoryDateFilter:
      setDearUnitsSoldByCategoryDateFilter,
    updateDearUnitsSoldByCategoryComparisonFilter:
      setDearUnitsSoldByCategoryComparisonFilter,
    updateDearUnitsSoldByCustomerDateFilter:
      setDearUnitsSoldByCustomerDateFilter,
    updateDearUnitsSoldByCustomerComparisonFilter:
      setDearUnitsSoldByCustomerComparisonFilter,
    updateDearUnitsSoldByProductTagDateFilter:
      setDearUnitsSoldByProductTagDateFilter,
    updateDearUnitsSoldByProductTagComparisonFilter:
      setDearUnitsSoldByProductTagComparisonFilter,
    updateDearUnitsSoldByProductTypeDateFilter:
      setDearUnitsSoldByProductTypeDateFilter,
    updateDearUnitsSoldByProductTypeComparisonFilter:
      setDearUnitsSoldByProductTypeComparisonFilter,
    updateDearUnitsSoldByCustomerTagDateFilter:
      setDearUnitsSoldByCustomerTagDateFilter,
    updateDearUnitsSoldByCustomerTagComparisonFilter:
      setDearUnitsSoldByCustomerTagComparisonFilter,
    // Dear Units Shipped By...
    updateDearUnitsShippedByBrandDateFilter: setUnitsShippedByBrandDateFilter,
    updateDearUnitsShippedByBrandComparisonFilter:
      setUnitsShippedByBrandComparisonFilter,
    updateDearUnitsShippedByProductDateFilter:
      setUnitsShippedByProductDateFilter,
    updateDearUnitsShippedByProductComparisonFilter:
      setUnitsShippedByProductComparisonFilter,
    updateDearUnitsShippedByCategoryDateFilter:
      setUnitsShippedByCategoryDateFilter,
    updateDearUnitsShippedByCategoryComparisonFilter:
      setUnitsShippedByCategoryComparisonFilter,
    updateDearUnitsShippedByCustomerDateFilter:
      setUnitsShippedByCustomerDateFilter,
    updateDearUnitsShippedByCustomerComparisonFilter:
      setUnitsShippedByCustomerComparisonFilter,
    updateDearUnitsShippedByProductTagDateFilter:
      setUnitsShippedByProductTagDateFilter,
    updateDearUnitsShippedByProductTagComparisonFilter:
      setUnitsShippedByProductTagComparisonFilter,
    updateDearUnitsShippedByProductTypeDateFilter:
      setUnitsShippedByProductTypeDateFilter,
    updateDearUnitsShippedByProductTypeComparisonFilter:
      setUnitsShippedByProductTypeComparisonFilter,
    updateDearUnitsShippedByCustomerTagDateFilter:
      setUnitsShippedByCustomerTagDateFilter,
    updateDearUnitsShippedByCustomerTagComparisonFilter:
      setUnitsShippedByCustomerTagComparisonFilter,
    setIsLoadingOnDemandExtraction: (state) => {
      state.isLoeadingOnDemandExtraction = false;

    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOnDemandDearData.pending, (state) => {
        state.isLoeadingOnDemandExtraction = true;
      })
      .addCase(getOnDemandDearData.fulfilled, (state) => {
        state.isLoeadingOnDemandExtraction = true;
      })
      .addCase(getOnDemandDearData.rejected, (state) => {
        state.isLoeadingOnDemandExtraction = false;
      })
      .addCase(getDashboardXeroData.pending, (state) => {
        state.isLoadingKPI = true;
        state.isSuccessKPI = false;
      })
      .addCase(getDashboardXeroData.fulfilled, (state, action) => {
        state.isLoadingKPI = false;
        state.isSuccessKPI = true;
      })
      .addCase(getDashboardXeroData.rejected, (state, action) => {
        state.isLoadingKPI = false;
        state.isErrorKPI = true;
        state.message = action.payload;
      })

      .addCase(saveXeroKPIFilters.fulfilled, (state, action) => {
        state.savedXeroFiltersMessage = action.payload;
      })
      .addCase(saveXeroKPIFilters.rejected, (state, action) => {
        state.isError = true;
        state.savedXeroFiltersMessage = action.payload;
      })

      // .addCase(getDashboardDearData.pending, (state) => {
      //   state.isLoadingDearKPI = true;
      //   state.isSuccessDearKPI = false;
      // })
      // .addCase(getDashboardDearData.fulfilled, (state, action) => {
      //   state.isLoadingDearKPI = false;
      //   state.isSuccessDearKPI = true;
      // })
      // .addCase(getDashboardDearData.rejected, (state, action) => {
      //   state.isLoadingDearKPI = false;
      //   state.isErrorDearKPI = true;
      //   state.message = action.payload;
      // })
      // .addCase(getDashboardDearData.pending, (state, action) => {
      //   state.dearLoadingStatuses = {
      //     dearBrandFilters: true,
      //     dearProductSales: true,
      //     dearProfit: true,
      //     dearUnitsSold: true,
      //     dearUnitsShipped: true,
      //     dearOtherKPIs: true,
      //   };
      //   state.isErrorDearKPI = false;
      // })
      // .addCase(getDashboardDearData.fulfilled, (state, action) => {
      //   state.dearLoadingStatuses = {
      //     dearBrandFilters: false,
      //     dearProductSales: false,
      //     dearProfit: false,
      //     dearUnitsSold: false,
      //     dearUnitsShipped: false,
      //     dearOtherKPIs: false,
      //   };
      // })
      .addCase(getDashboardDearData.rejected, (state, action) => {
        state.dearLoadingStatuses = {
          dearBrandFilters: false,
          dearProductSales: false,
          dearProfit: false,
          dearUnitsSold: false,
          dearUnitsShipped: false,
          dearOtherKPIs: false,
        };
        state.isErrorDearKPI = true;
        state.message = action.payload;
      })
      // Dear KPIs
      .addCase(getDashboardDearFilters.pending, (state, action) => {
        state.dearLoadingStatuses = {
          ...state.dearLoadingStatuses,
          dearBrandFilters: true,
        };
      })
      .addCase(getDashboardDearFilters.rejected, (state, action) => {
        state.dearLoadingStatuses = {
          ...state.dearLoadingStatuses,
          dearBrandFilters: false,
        };
      })
      .addCase(getDashboardDearFilters.fulfilled, (state, action) => {
        state.dearBrandFilters = action.payload.dearBrandFilters;
        state.dearCategoryFilters = action.payload.dearCategoryFilters;
        state.dearContactFilters = action.payload.dearContactFilters;
        state.dearCustomerTagsFilters = action.payload.dearCustomerTagsFilters;
        state.dearProductFilters = action.payload.dearProductFilters;
        state.dearProductTagsFilters = action.payload.dearProductTagsFilters;
        state.dearProductTypeFilters = action.payload.dearProductTypeFilters;
        state.dearLocationFilters = action.payload.dearLocationFilters;
        state.dearLoadingStatuses = {
          ...state.dearLoadingStatuses,
          dearBrandFilters: false,
        };
      })

      .addCase(
        getDashboardDearAllProductSalesKPIs.pending,
        (state, action) => {
          state.dearLoadingStatuses = {
            ...state.dearLoadingStatuses,
            dearProductSales: true,
          };
        }
      )
      .addCase(
        getDashboardDearAllProductSalesKPIs.rejected,
        (state, action) => {
          state.dearLoadingStatuses = {
            ...state.dearLoadingStatuses,
            dearProductSales: false,
          };
        }
      )
      .addCase(
        getDashboardDearAllProductSalesKPIs.fulfilled,
        (state, action) => {
          state.dearProductSales = action.payload.dearProductSalesKPI;
          state.dearProductSalesLineGraph =
            action.payload.dearProductSalesLineGraph;
          state.dearProductSalesByBrand =
            action.payload.dearProductSalesByBrand;
          state.dearProductSalesByCategory =
            action.payload.dearProductSalesByCategory;
          state.dearProductSalesByCustomer =
            action.payload.dearProductSalesByCustomer;
          state.dearProductSalesByCustomerTag =
            action.payload.dearProductSalesByCustomerTag;
          state.dearProductSalesByProduct =
            action.payload.dearProductSalesByProduct;
          state.dearProductSalesByProductTag =
            action.payload.dearProductSalesByProductTag;
          state.dearProductSalesByProductType =
            action.payload.dearProductSalesByProductType;
          state.dearSalesDrilldownTableByCustomer =
            action.payload.dearSalesDrilldownTableByCustomer;
          state.dearSalesDrilldownTableByProduct =
            action.payload.dearSalesDrilldownTableByProduct;
          state.dearLoadingStatuses = {
            ...state.dearLoadingStatuses,
            dearProductSales: false,
          };
        }
      )
      // Drilldowns
      .addCase(
        getDearSalesDrilldown01TableByCustomer.fulfilled,
        (state, action) => {
          state.dearSalesDrilldownTableByCustomer =
            action.payload.dearSalesDrilldownTableByCustomer;
        }
      )
      .addCase(
        getDearSalesDrilldown01TableByProduct.fulfilled,
        (state, action) => {
          state.dearSalesDrilldownTableByProduct =
            action.payload.dearSalesDrilldownTableByProduct;
        }
      )
      .addCase(
        getDearProfitDrilldown01TableByProduct.fulfilled,
        (state, action) => {
          state.dearProfitDrilldownTableByProduct =
            action.payload.dearProfitDrilldownTableByProduct;
        }
      )
      .addCase(
        getDearProfitDrilldown01TableByCustomer.fulfilled,
        (state, action) => {
          state.dearProfitDrilldownTableByCustomer =
            action.payload.dearProfitDrilldownTableByCustomer;
        }
      )
      .addCase(
        getDearUnitsShippedDrilldown01TableByProduct.fulfilled,
        (state, action) => {
          state.dearUnitsShippedDrilldownTableByProduct =
            action.payload.dearUnitsShippedDrilldownTableByProduct;
        }
      )
      .addCase(
        getDearUnitsShippedDrilldown01TableByCustomer.fulfilled,
        (state, action) => {
          state.dearUnitsSoldDrilldownTableByCustomer =
            action.payload.dearUnitsShippedDrilldownTableByCustomer;
        }
      )
      .addCase(
        getDearUnitsSoldDrilldown01TableByProduct.fulfilled,
        (state, action) => {
          state.dearUnitsSoldDrilldownTableByProduct =
            action.payload.dearUnitsSoldDrilldownTableByProduct;
        }
      )
      .addCase(
        getDearUnitsSoldDrilldown01TableByCustomer.fulfilled,
        (state, action) => {
          state.dearUnitsSoldDrilldownTableByCustomer =
            action.payload.dearUnitsSoldDrilldownTableByCustomer;
        }
      )
      // Sub drilldowns 2
      .addCase(
        getDearSalesDrilldown02TableByProduct.fulfilled,
        (state, action) => {
          state.dearDrilldown2Data =
            action.payload.dearSalesDrilldownTableByProduct;
        }
      )
      .addCase(
        getDearSalesDrilldown02TableByCustomer.fulfilled,
        (state, action) => {
          state.dearDrilldown2Data =
            action.payload.dearSalesDrilldownTableByCustomer;
        }
      )
      .addCase(
        getDearProfitDrilldown02TableByProduct.fulfilled,
        (state, action) => {
          state.dearDrilldown2Data =
            action.payload.dearProfitDrilldownTableByProduct;
        }
      )
      .addCase(
        getDearProfitDrilldown02TableByCustomer.fulfilled,
        (state, action) => {
          state.dearDrilldown2Data =
            action.payload.dearProfitDrilldownTableByCustomer;
        }
      )
      .addCase(
        getDearUnitsSoldDrilldown02TableByProduct.fulfilled,
        (state, action) => {
          state.dearDrilldown2Data =
            action.payload.dearUnitsSoldDrilldownTableByProduct;
        }
      )
      .addCase(
        getDearUnitsSoldDrilldown02TableByCustomer.fulfilled,
        (state, action) => {
          state.dearDrilldown2Data =
            action.payload.dearUnitsSoldDrilldownTableByCustomer;
        }
      )
      .addCase(
        getDearUnitsShippedDrilldown02TableByProduct.fulfilled,
        (state, action) => {
          state.dearDrilldown2Data =
            action.payload.dearUnitsShippedDrilldownTableByProduct;
        }
      )
      .addCase(
        getDearUnitsShippedDrilldown02TableByCustomer.fulfilled,
        (state, action) => {
          state.dearDrilldown2Data =
            action.payload.dearUnitsShippedDrilldownTableByCustomer;
        }
      )
      .addCase(
        getDearProductSalesKPIFilteredData.fulfilled,
        (state, action) => {
          state.dearProductSales = action.payload.dearProductSalesKPI;
        }
      )
      .addCase(
        getDearProductSalesKPIFilteredDataCategory.fulfilled,
        (state, action) => {
          const { dearProductSalesKPICategory, categoryFilter } = action.payload;

          if (categoryFilter === "Superior") {
            state.dearProductSalesCategory[0] = dearProductSalesKPICategory;
            state.dearProductSalesCategoryLoading[0] = false;
          } else if (categoryFilter === "Superior Deluxe Panels") {
            state.dearProductSalesCategory[1] = dearProductSalesKPICategory;
            state.dearProductSalesCategoryLoading[1] = false;
          } else if (categoryFilter === "Ultimate Panels") {
            state.dearProductSalesCategory[2] = dearProductSalesKPICategory;
            state.dearProductSalesCategoryLoading[2] = false;
          } else if (categoryFilter === "Aluminium Posts") {
            state.dearProductSalesCategory[3] = dearProductSalesKPICategory;
            state.dearProductSalesCategoryLoading[3] = false;
          } else if (categoryFilter === "Concrete Posts") {
            state.dearProductSalesCategory[4] = dearProductSalesKPICategory;
            state.dearProductSalesCategoryLoading[4] = false;
          } else if (categoryFilter === "Composite Gates") {
            state.dearProductSalesCategory[5] = dearProductSalesKPICategory;
            state.dearProductSalesCategoryLoading[5] = false;
          } else if (categoryFilter === "Lights") {
            state.dearProductSalesCategory[6] = dearProductSalesKPICategory;
            state.dearProductSalesCategoryLoading[6] = false;
          } else if (categoryFilter === "Pergolas") {
            state.dearProductSalesCategory[7] = dearProductSalesKPICategory;
            state.dearProductSalesCategoryLoading[7] = false;
          } else if (categoryFilter === "3D Wall Panels") {
            state.dearProductSalesCategory[8] = dearProductSalesKPICategory;
            state.dearProductSalesCategoryLoading[8] = false;
          } else if (categoryFilter === "Composite Posts") {
            state.dearProductSalesCategory[9] = dearProductSalesKPICategory;
            state.dearProductSalesCategoryLoading[9] = false;
          } else if (categoryFilter === "Aluminium Gates") {
            state.dearProductSalesCategory[10] = dearProductSalesKPICategory;
            state.dearProductSalesCategoryLoading[10] = false;
          } else if (categoryFilter === "Screens") {
            state.dearProductSalesCategory[11] = dearProductSalesKPICategory;
            state.dearProductSalesCategoryLoading[11] = false;
          }
        }
      )
      .addCase(
        getDearProductSalesKPIFilteredDataCategory.pending,
        (state, action) => {
          const { categoryFilter } = action.meta.arg;

          if (categoryFilter === "Superior") {
            state.dearProductSalesCategoryLoading[0] = true;
          } else if (categoryFilter === "Superior Deluxe Panels") {
            state.dearProductSalesCategoryLoading[1] = true;
          } else if (categoryFilter === "Ultimate Panels") {
            state.dearProductSalesCategoryLoading[2] = true;
          } else if (categoryFilter === "Aluminium Posts") {
            state.dearProductSalesCategoryLoading[3] = true;
          } else if (categoryFilter === "Concrete Posts") {
            state.dearProductSalesCategoryLoading[4] = true;
          } else if (categoryFilter === "Composite Gates") {
            state.dearProductSalesCategoryLoading[5] = true;
          } else if (categoryFilter === "Lights") {
            state.dearProductSalesCategoryLoading[6] = true;
          } else if (categoryFilter === "Pergolas") {
            state.dearProductSalesCategoryLoading[7] = true;
          } else if (categoryFilter === "3D Wall Panels") {
            state.dearProductSalesCategoryLoading[8] = true;
          } else if (categoryFilter === "Composite Posts") {
            state.dearProductSalesCategoryLoading[9] = true;
          } else if (categoryFilter === "Aluminium Gates") {
            state.dearProductSalesCategoryLoading[10] = true;
          } else if (categoryFilter === "Screens") {
            state.dearProductSalesCategoryLoading[11] = true;
          }
        }
      )
      .addCase(
        getDearProductSalesKPIFilteredDataCategory.rejected,
        (state, action) => {
          const { categoryFilter } = action.payload || action.meta.arg;

          if (categoryFilter === "Superior") {
            state.dearProductSalesCategoryLoading[0] = false;
          } else if (categoryFilter === "Superior Deluxe Panels") {
            state.dearProductSalesCategoryLoading[1] = false;
          } else if (categoryFilter === "Ultimate Panels") {
            state.dearProductSalesCategoryLoading[2] = false;
          } else if (categoryFilter === "Aluminium Posts") {
            state.dearProductSalesCategoryLoading[3] = false;
          } else if (categoryFilter === "Concrete Posts") {
            state.dearProductSalesCategoryLoading[4] = false;
          } else if (categoryFilter === "Composite Gates") {
            state.dearProductSalesCategoryLoading[5] = false;
          } else if (categoryFilter === "Lights") {
            state.dearProductSalesCategoryLoading[6] = false;
          } else if (categoryFilter === "Pergolas") {
            state.dearProductSalesCategoryLoading[7] = false;
          } else if (categoryFilter === "3D Wall Panels") {
            state.dearProductSalesCategoryLoading[8] = false;
          } else if (categoryFilter === "Composite Posts") {
            state.dearProductSalesCategoryLoading[9] = false;
          } else if (categoryFilter === "Aluminium Gates") {
            state.dearProductSalesCategoryLoading[10] = false;
          } else if (categoryFilter === "Screens") {
            state.dearProductSalesCategoryLoading[11] = false;
          }
        }
      )

      .addCase(
        getDearInventoryValueKPIFilteredDataCategory.fulfilled,
        (state, action) => {
          const { dearInventoryValueKPICategory, categoryFilter } = action.payload;

          if (categoryFilter === "Superior") {
            state.dearInventoryValueCategory[0] = dearInventoryValueKPICategory;
            state.dearInventoryValueCategoryLoading[0] = false;
          } else if (categoryFilter === "Superior Deluxe Panels") {
            state.dearInventoryValueCategory[1] = dearInventoryValueKPICategory;
            state.dearInventoryValueCategoryLoading[1] = false;
          } else if (categoryFilter === "Ultimate Panels") {
            state.dearInventoryValueCategory[2] = dearInventoryValueKPICategory;
            state.dearInventoryValueCategoryLoading[2] = false;
          } else if (categoryFilter === "Aluminium Posts") {
            state.dearInventoryValueCategory[3] = dearInventoryValueKPICategory;
            state.dearInventoryValueCategoryLoading[3] = false;
          } else if (categoryFilter === "Concrete Posts") {
            state.dearInventoryValueCategory[4] = dearInventoryValueKPICategory;
            state.dearInventoryValueCategoryLoading[4] = false;
          } else if (categoryFilter === "Composite Gates") {
            state.dearInventoryValueCategory[5] = dearInventoryValueKPICategory;
            state.dearInventoryValueCategoryLoading[5] = false;
          } else if (categoryFilter === "Lights") {
            state.dearInventoryValueCategory[6] = dearInventoryValueKPICategory;
            state.dearInventoryValueCategoryLoading[6] = false;
          } else if (categoryFilter === "Pergolas") {
            state.dearInventoryValueCategory[7] = dearInventoryValueKPICategory;
            state.dearInventoryValueCategoryLoading[7] = false;
          } else if (categoryFilter === "3D Wall Panels") {
            state.dearInventoryValueCategory[8] = dearInventoryValueKPICategory;
            state.dearInventoryValueCategoryLoading[8] = false;
          } else if (categoryFilter === "Composite Posts") {
            state.dearInventoryValueCategory[9] = dearInventoryValueKPICategory;
            state.dearInventoryValueCategoryLoading[9] = false;
          } else if (categoryFilter === "Aluminium Gates") {
            state.dearInventoryValueCategory[10] = dearInventoryValueKPICategory;
            state.dearInventoryValueCategoryLoading[10] = false;
          } else if (categoryFilter === "Screens") {
            state.dearInventoryValueCategory[11] = dearInventoryValueKPICategory;
            state.dearInventoryValueCategoryLoading[11] = false;
          }
        }
      )
      .addCase(
        getDearInventoryValueKPIFilteredDataCategory.pending,
        (state, action) => {
          const { categoryFilter } = action.meta.arg;

          if (categoryFilter === "Superior") {
            state.dearInventoryValueCategoryLoading[0] = true;
          } else if (categoryFilter === "Superior Deluxe Panels") {
            state.dearInventoryValueCategoryLoading[1] = true;
          } else if (categoryFilter === "Ultimate Panels") {
            state.dearInventoryValueCategoryLoading[2] = true;
          } else if (categoryFilter === "Aluminium Posts") {
            state.dearInventoryValueCategoryLoading[3] = true;
          } else if (categoryFilter === "Concrete Posts") {
            state.dearInventoryValueCategoryLoading[4] = true;
          } else if (categoryFilter === "Composite Gates") {
            state.dearInventoryValueCategoryLoading[5] = true;
          } else if (categoryFilter === "Lights") {
            state.dearInventoryValueCategoryLoading[6] = true;
          } else if (categoryFilter === "Pergolas") {
            state.dearInventoryValueCategoryLoading[7] = true;
          } else if (categoryFilter === "3D Wall Panels") {
            state.dearInventoryValueCategoryLoading[8] = true;
          } else if (categoryFilter === "Composite Posts") {
            state.dearInventoryValueCategoryLoading[9] = true;
          } else if (categoryFilter === "Aluminium Gates") {
            state.dearInventoryValueCategoryLoading[10] = true;
          } else if (categoryFilter === "Screens") {
            state.dearInventoryValueCategoryLoading[11] = true;
          }
        }
      )
      .addCase(
        getDearInventoryValueKPIFilteredDataCategory.rejected,
        (state, action) => {
          const { categoryFilter } = action.payload || action.meta.arg;

          if (categoryFilter === "Superior") {
            state.dearInventoryValueCategoryLoading[0] = false;
          } else if (categoryFilter === "Superior Deluxe Panels") {
            state.dearInventoryValueCategoryLoading[1] = false;
          } else if (categoryFilter === "Ultimate Panels") {
            state.dearInventoryValueCategoryLoading[2] = false;
          } else if (categoryFilter === "Aluminium Posts") {
            state.dearInventoryValueCategoryLoading[3] = false;
          } else if (categoryFilter === "Concrete Posts") {
            state.dearInventoryValueCategoryLoading[4] = false;
          } else if (categoryFilter === "Composite Gates") {
            state.dearInventoryValueCategoryLoading[5] = false;
          } else if (categoryFilter === "Lights") {
            state.dearInventoryValueCategoryLoading[6] = false;
          } else if (categoryFilter === "Pergolas") {
            state.dearInventoryValueCategoryLoading[7] = false;
          } else if (categoryFilter === "3D Wall Panels") {
            state.dearInventoryValueCategoryLoading[8] = false;
          } else if (categoryFilter === "Composite Posts") {
            state.dearInventoryValueCategoryLoading[9] = false;
          } else if (categoryFilter === "Aluminium Gates") {
            state.dearInventoryValueCategoryLoading[10] = false;
          } else if (categoryFilter === "Screens") {
            state.dearInventoryValueCategoryLoading[11] = false;
          }
        }
      )


      .addCase(
        getDearGrossProfitMarginKPIFilteredDataCategory.fulfilled,
        (state, action) => {
          const { dearGrossProfitMarginKPICategory, categoryFilter } = action.payload;

          if (categoryFilter === "Superior") {
            state.dearGrossProfitMarginCategory[0] = dearGrossProfitMarginKPICategory;
            state.dearGrossProfitMarginCategoryLoading[0] = false;
          } else if (categoryFilter === "Superior Deluxe Panels") {
            state.dearGrossProfitMarginCategory[1] = dearGrossProfitMarginKPICategory;
            state.dearGrossProfitMarginCategoryLoading[1] = false;
          } else if (categoryFilter === "Ultimate Panels") {
            state.dearGrossProfitMarginCategory[2] = dearGrossProfitMarginKPICategory;
            state.dearGrossProfitMarginCategoryLoading[2] = false;
          } else if (categoryFilter === "Aluminium Posts") {
            state.dearGrossProfitMarginCategory[3] = dearGrossProfitMarginKPICategory;
            state.dearGrossProfitMarginCategoryLoading[3] = false;
          } else if (categoryFilter === "Concrete Posts") {
            state.dearGrossProfitMarginCategory[4] = dearGrossProfitMarginKPICategory;
            state.dearGrossProfitMarginCategoryLoading[4] = false;
          } else if (categoryFilter === "Composite Gates") {
            state.dearGrossProfitMarginCategory[5] = dearGrossProfitMarginKPICategory;
            state.dearGrossProfitMarginCategoryLoading[5] = false;
          } else if (categoryFilter === "Lights") {
            state.dearGrossProfitMarginCategory[6] = dearGrossProfitMarginKPICategory;
            state.dearGrossProfitMarginCategoryLoading[6] = false;
          } else if (categoryFilter === "Pergolas") {
            state.dearGrossProfitMarginCategory[7] = dearGrossProfitMarginKPICategory;
            state.dearGrossProfitMarginCategoryLoading[7] = false;
          } else if (categoryFilter === "3D Wall Panels") {
            state.dearGrossProfitMarginCategory[8] = dearGrossProfitMarginKPICategory;
            state.dearGrossProfitMarginCategoryLoading[8] = false;
          } else if (categoryFilter === "Composite Posts") {
            state.dearGrossProfitMarginCategory[9] = dearGrossProfitMarginKPICategory;
            state.dearGrossProfitMarginCategoryLoading[9] = false;
          } else if (categoryFilter === "Aluminium Gates") {
            state.dearGrossProfitMarginCategory[10] = dearGrossProfitMarginKPICategory;
            state.dearGrossProfitMarginCategoryLoading[10] = false;
          } else if (categoryFilter === "Screens") {
            state.dearGrossProfitMarginCategory[11] = dearGrossProfitMarginKPICategory;
            state.dearGrossProfitMarginCategoryLoading[11] = false;
          }
        }
      )
      .addCase(
        getDearGrossProfitMarginKPIFilteredDataCategory.pending,
        (state, action) => {
          const { categoryFilter } = action.meta.arg;

          if (categoryFilter === "Superior") {
            state.dearGrossProfitMarginCategoryLoading[0] = true;
          } else if (categoryFilter === "Superior Deluxe Panels") {
            state.dearGrossProfitMarginCategoryLoading[1] = true;
          } else if (categoryFilter === "Ultimate Panels") {
            state.dearGrossProfitMarginCategoryLoading[2] = true;
          } else if (categoryFilter === "Aluminium Posts") {
            state.dearGrossProfitMarginCategoryLoading[3] = true;
          } else if (categoryFilter === "Concrete Posts") {
            state.dearGrossProfitMarginCategoryLoading[4] = true;
          } else if (categoryFilter === "Composite Gates") {
            state.dearGrossProfitMarginCategoryLoading[5] = true;
          } else if (categoryFilter === "Lights") {
            state.dearGrossProfitMarginCategoryLoading[6] = true;
          } else if (categoryFilter === "Pergolas") {
            state.dearGrossProfitMarginCategoryLoading[7] = true;
          } else if (categoryFilter === "3D Wall Panels") {
            state.dearGrossProfitMarginCategoryLoading[8] = true;
          } else if (categoryFilter === "Composite Posts") {
            state.dearGrossProfitMarginCategoryLoading[9] = true;
          } else if (categoryFilter === "Aluminium Gates") {
            state.dearGrossProfitMarginCategoryLoading[10] = true;
          } else if (categoryFilter === "Screens") {
            state.dearGrossProfitMarginCategoryLoading[11] = true;
          }
        }
      )
      .addCase(
        getDearGrossProfitMarginKPIFilteredDataCategory.rejected,
        (state, action) => {
          const { categoryFilter } = action.payload || action.meta.arg;

          if (categoryFilter === "Superior") {
            state.dearGrossProfitMarginCategoryLoading[0] = false;
          } else if (categoryFilter === "Superior Deluxe Panels") {
            state.dearGrossProfitMarginCategoryLoading[1] = false;
          } else if (categoryFilter === "Ultimate Panels") {
            state.dearGrossProfitMarginCategoryLoading[2] = false;
          } else if (categoryFilter === "Aluminium Posts") {
            state.dearGrossProfitMarginCategoryLoading[3] = false;
          } else if (categoryFilter === "Concrete Posts") {
            state.dearGrossProfitMarginCategoryLoading[4] = false;
          } else if (categoryFilter === "Composite Gates") {
            state.dearGrossProfitMarginCategoryLoading[5] = false;
          } else if (categoryFilter === "Lights") {
            state.dearGrossProfitMarginCategoryLoading[6] = false;
          } else if (categoryFilter === "Pergolas") {
            state.dearGrossProfitMarginCategoryLoading[7] = false;
          } else if (categoryFilter === "3D Wall Panels") {
            state.dearGrossProfitMarginCategoryLoading[8] = false;
          } else if (categoryFilter === "Composite Posts") {
            state.dearGrossProfitMarginCategoryLoading[9] = false;
          } else if (categoryFilter === "Aluminium Gates") {
            state.dearGrossProfitMarginCategoryLoading[10] = false;
          } else if (categoryFilter === "Screens") {
            state.dearGrossProfitMarginCategoryLoading[11] = false;
          }
        }
      )


      .addCase(getDearProfitKPIFilteredData.fulfilled, (state, action) => {
        state.dearProfit = action.payload.dearProfitKPI;
      })
      .addCase(getDearUnitsSoldKPIFilteredData.fulfilled, (state, action) => {
        state.dearUnitsSold = action.payload.dearUnitsSoldKPI;
      })
      .addCase(
        getDearUnitsShippedKPIFilteredData.fulfilled,
        (state, action) => {
          state.dearUnitsShipped = action.payload.dearUnitsShippedKPI;
        }
      )
      .addCase(
        getDearGrossProfitMarginKPIFilteredData.fulfilled,
        (state, action) => {
          state.dearGrossProfitMargin = action.payload.dearUnitsShippedKPI;
        }
      )
      .addCase(
        getDearInventoryValueKPIFilteredData.fulfilled,
        (state, action) => {
          state.dearInventoryValue = action.payload.dearInventoryValueKPI;
        }
      )
      .addCase(
        getDearPercentageCustomer80SalesKPIFilteredData.fulfilled,
        (state, action) => {
          state.dearPercentageCustomer80Sales =
            action.payload.dearPercentageCustomer80Sales;
        }
      )
      .addCase(
        getDearPercentageProduct80SalesKPIFilteredData.fulfilled,
        (state, action) => {
          state.dearPercentageProduct80Sales =
            action.payload.dearPercentageProduct80Sales;
        }
      )
      .addCase(
        getDearPercentageOrderShippedLateKPIFilteredData.fulfilled,
        (state, action) => {
          state.dearPercentageOrderShippedLate =
            action.payload.dearPercentageOrderShippedLate;
        }
      )
      // Profit KPIs
      .addCase(getDashboardDearAllProfitKPIs.pending, (state, action) => {
        state.dearLoadingStatuses = {
          ...state.dearLoadingStatuses,
          dearProfit: true,
        }
      })
      .addCase(getDashboardDearAllProfitKPIs.rejected, (state, action) => {
        state.dearLoadingStatuses = {
          ...state.dearLoadingStatuses,
          dearProfit: false,
        }
      })
      .addCase(getDashboardDearAllProfitKPIs.fulfilled, (state, action) => {
        state.dearLoadingStatuses = {
          ...state.dearLoadingStatuses,
          dearProfit: false,
        };
        state.dearProfit = action.payload.dearProfit;
        state.dearProfitLineGraph = action.payload.dearProfitLineGraph;
        state.dearProfitByBrand = action.payload.dearProfitByBrand;
        state.dearProfitByCategory = action.payload.dearProfitByCategory;
        state.dearProfitByCustomer = action.payload.dearProfitByCustomer;
        state.dearProfitByCustomerTag = action.payload.dearProfitByCustomerTag;
        state.dearProfitByProduct = action.payload.dearProfitByProduct;
        state.dearProfitByProductTag = action.payload.dearProfitByProductTag;
        state.dearProfitByProductType = action.payload.dearProfitByProductType;
        state.dearProfitDrilldownTableByCustomer =
          action.payload.dearProfitDrilldownTableByCustomer;
        state.dearProfitDrilldownTableByProduct =
          action.payload.dearProfitDrilldownTableByProduct;
      })
      // Units Sold KPIs

      .addCase(getDashboardDearAllUnitsSoldKPIs.pending, (state, action) => {
        state.dearLoadingStatuses = {
          ...state.dearLoadingStatuses,
          dearUnitsSold: true,
        }
      })
      .addCase(getDashboardDearAllUnitsSoldKPIs.rejected, (state, action) => {
        state.dearLoadingStatuses = {
          ...state.dearLoadingStatuses,
          dearUnitsSold: false,
        }
      })
      .addCase(getDashboardDearAllUnitsSoldKPIs.fulfilled, (state, action) => {
        state.dearLoadingStatuses = {
          ...state.dearLoadingStatuses,
          dearUnitsSold: false,
        }
        state.dearUnitsSold = action.payload.dearUnitsSold;
        state.dearUnitsSoldLineGraph = action.payload.dearUnitsSoldLineGraph;
        state.dearUnitsSoldByBrand = action.payload.dearUnitsSoldByBrand;
        state.dearUnitsSoldByCategory = action.payload.dearUnitsSoldByCategory;
        state.dearUnitsSoldByCustomer = action.payload.dearUnitsSoldByCustomer;
        state.dearUnitsSoldByCustomerTag =
          action.payload.dearUnitsSoldByCustomerTag;
        state.dearUnitsSoldByProduct = action.payload.dearUnitsSoldByProduct;
        state.dearUnitsSoldByProductTag =
          action.payload.dearUnitsSoldByProductTag;
        state.dearUnitsSoldByProductType =
          action.payload.dearUnitsSoldByProductType;
        state.dearUnitsSoldDrilldownTableByProduct =
          action.payload.dearUnitsSoldDrilldownTableByProduct;
        state.dearUnitsSoldDrilldownTableByCustomer =
          action.payload.dearUnitsSoldDrilldownTableByCustomer;
      })
      .addCase(
        getDashboardDearAllUnitsShippedKPIs.pending,
        (state, action) => {
          state.dearLoadingStatuses = {
            ...state.dearLoadingStatuses,
            dearUnitsShipped: true,
          }
        })
      .addCase(
        getDashboardDearAllUnitsShippedKPIs.rejected,
        (state, action) => {
          state.dearLoadingStatuses = {
            ...state.dearLoadingStatuses,
            dearUnitsShipped: false,
          }
        })
      // .addCase(
      //   getDashboardDearAllUnitsShippedKPIs.fulfilled,
      //   (state, action) => {
      //     state.dearLoadingStatuses = {
      //       ...state.dearLoadingStatuses,
      //       dearUnitsShipped: false,
      //     }
      //     state.dearUnitsShipped = action.payload.dearUnitsShipped;
      //     state.dearUnitsShippedLineGraph =
      //       action.payload.dearUnitsShippedLineGraph;
      //     state.dearUnitsShippedByBrand =
      //       action.payload.dearUnitsShippedByBrand;
      //     state.dearUnitsShippedByCategory =
      //       action.payload.dearUnitsShippedByCategory;
      //     state.dearUnitsShippedByCustomer =
      //       action.payload.dearUnitsShippedByCustomer;
      //     state.dearUnitsShippedByCustomerTag =
      //       action.payload.dearUnitsShippedByCustomerTag;
      //     state.dearUnitsShippedByProduct =
      //       action.payload.dearUnitsShippedByProduct;
      //     state.dearUnitsShippedByProductTag =
      //       action.payload.dearUnitsShippedByProductTag;
      //     state.dearUnitsShippedByProductType =
      //       action.payload.dearUnitsShippedByProductType;
      //     state.dearUnitsShippedDrilldownTableByCustomer =
      //       action.payload.dearUnitsShippedDrilldownTableByCustomer;
      //     state.dearUnitsShippedDrilldownTableByProduct =
      //       action.payload.dearUnitsShippedDrilldownTableByProduct;
      //   }
      // )
      .addCase(
        getDashboardDearAllUnitsShippedKPIs.fulfilled,
        (state, action) => {
          state.dearLoadingStatuses = {
            ...state.dearLoadingStatuses,
            dearUnitsShipped: false,
          };

          const {
            dearUnitsShipped,
            dearUnitsShippedLineGraph,
            dearUnitsShippedByBrand,
            dearUnitsShippedByCategory,
            dearUnitsShippedByCustomer,
            dearUnitsShippedByCustomerTag,
            dearUnitsShippedByProduct,
            dearUnitsShippedByProductTag,
            dearUnitsShippedByProductType,
            dearUnitsShippedDrilldownTableByCustomer,
            dearUnitsShippedDrilldownTableByProduct,
          } = action.payload;

          if (dearUnitsShipped) state.dearUnitsShipped = dearUnitsShipped;
          if (dearUnitsShippedLineGraph) state.dearUnitsShippedLineGraph = dearUnitsShippedLineGraph;
          if (dearUnitsShippedByBrand) state.dearUnitsShippedByBrand = dearUnitsShippedByBrand;
          if (dearUnitsShippedByCategory) state.dearUnitsShippedByCategory = dearUnitsShippedByCategory;
          if (dearUnitsShippedByCustomer) state.dearUnitsShippedByCustomer = dearUnitsShippedByCustomer;
          if (dearUnitsShippedByCustomerTag) state.dearUnitsShippedByCustomerTag = dearUnitsShippedByCustomerTag;
          if (dearUnitsShippedByProduct) state.dearUnitsShippedByProduct = dearUnitsShippedByProduct;
          if (dearUnitsShippedByProductTag) state.dearUnitsShippedByProductTag = dearUnitsShippedByProductTag;
          if (dearUnitsShippedByProductType) state.dearUnitsShippedByProductType = dearUnitsShippedByProductType;
          if (dearUnitsShippedDrilldownTableByCustomer)
            state.dearUnitsShippedDrilldownTableByCustomer = dearUnitsShippedDrilldownTableByCustomer;
          if (dearUnitsShippedDrilldownTableByProduct)
            state.dearUnitsShippedDrilldownTableByProduct = dearUnitsShippedDrilldownTableByProduct;
        }
      )
      .addCase(getDashboardDearAllOtherKPIs.pending, (state, action) => {
        state.dearLoadingStatuses = {
          ...state.dearLoadingStatuses,
          dearOtherKPIs: true,
        }
      })
      .addCase(getDashboardDearAllOtherKPIs.rejected, (state, action) => {
        state.dearLoadingStatuses = {
          ...state.dearLoadingStatuses,
          dearOtherKPIs: false,
        }
      })
      .addCase(getDashboardDearAllOtherKPIs.fulfilled, (state, action) => {
        state.dearLoadingStatuses = {
          ...state.dearLoadingStatuses,
          dearOtherKPIs: false,
        }
        state.dearLastUpdated = action.payload.dearLastUpdated;
        state.dearPercentageCustomer80Sales =
          action.payload.dearPercentageCustomer80Sales;
        state.dearPercentageProduct80Sales =
          action.payload.dearPercentageProduct80Sales;
        state.dearPercentageOrderShippedLate =
          action.payload.dearPercentageOrderShippedLate;
        state.dearTopLateSuppliers = action.payload.dearTopLateSuppliers;
        state.dearTopTenSuppliersByCost =
          action.payload.dearTopTenSuppliersByCost;
        state.dearTopTenSuppliersByVolume =
          action.payload.dearTopTenSuppliersByVolume;
        state.dearCustomerWithoutTags = action.payload.dearCustomerWithoutTags;
        state.dearGrossProfitMargin = action.payload.dearGrossProfitMargin;
        state.dearInventoryValue = action.payload.dearInventoryValue;

        state.dearCostOfGoodsSold = action.payload.dearCostOfGoodsSold;
        state.dearNoSalesOrderByCarriers = action.payload.dearNoSalesOrderByCarriers;

        state.dearSampleConversionRate = action.payload.dearSampleConversionRate;
      })

      // Xero Graphs
      .addCase(getDashboardXeroLineGraphs.fulfilled, (state, action) => {
        state.lineGraphs = action.payload.lineGraphData;
      })

      .addCase(
        getDashboardXeroTrackingCategory01.fulfilled,
        (state, action) => {
          state.trackingCategory01 = action.payload.trackingCategory01Data;
        }
      )

      .addCase(
        getDashboardXeroTrackingCategory02.fulfilled,
        (state, action) => {
          state.trackingCategory02 = action.payload.trackingCategory02Data;
        }
      )

      .addCase(getDashboardXeroAllKPIs.fulfilled, (state, action) => {
        state.xeroKPIFigures = action.payload.allXeroKPIs;
        state.xeroLastUpdated = action.payload.xeroLastUpdated;
      })
      .addCase(getDashboardXeroTables.fulfilled, (state, action) => {
        state.xeroBankTable = action.payload.xeroBankTable;
      });
  },
});
export default KPISlice.reducer;
export const {
  resetKPI,
  setDashboardOrganisation,
  updateDearSalesByBrandDateFilter,
  updateDearSalesByBrandComparisonFilter,
  updateDearSalesByProductComparisonFilter,
  updateDearSalesByProductDateFilter,
  updateDearSalesByCategoryDateFilter,
  updateDearSalesByCategoryComparisonFilter,
  updateDearSalesByCustomerComparisonFilter,
  updateDearSalesByCustomerDateFilter,
  updateDearSalesByProductTagComparisonFilter,
  updateDearSalesByProductTagDateFilter,
  updateDearSalesByProductTypeComparisonFilter,
  updateDearSalesByProductTypeDateFilter,
  updateDearSalesByCustomerTagComparisonFilter,
  updateDearSalesByCustomerTagDateFilter,
  updateDearProfitByCategoryComparisonFilter,
  updateDearProfitByCategoryDateFilter,
  updateDearProfitByProductComparisonFilter,
  updateDearProfitByProductDateFilter,
  updateDearProfitByBrandComparisonFilter,
  updateDearProfitByBrandDateFilter,
  updateDearProfitByCustomerTagComparisonFilter,
  updateDearProfitByCustomerTagDateFilter,
  updateDearProfitByCustomerComparisonFilter,
  updateDearProfitByCustomerDateFilter,
  updateDearProfitByProductTagComparisonFilter,
  updateDearProfitByProductTagDateFilter,
  updateDearProfitByProductTypeComparisonFilter,
  updateDearProfitByProductTypeDateFilter,
  updateDearUnitsSoldByBrandDateFilter,
  updateDearUnitsSoldByBrandComparisonFilter,
  updateDearUnitsSoldByProductDateFilter,
  updateDearUnitsSoldByProductComparisonFilter,
  updateDearUnitsSoldByCategoryDateFilter,
  updateDearUnitsSoldByCategoryComparisonFilter,
  updateDearUnitsSoldByCustomerDateFilter,
  updateDearUnitsSoldByCustomerComparisonFilter,
  updateDearUnitsSoldByProductTagDateFilter,
  updateDearUnitsSoldByProductTagComparisonFilter,
  updateDearUnitsSoldByProductTypeDateFilter,
  updateDearUnitsSoldByProductTypeComparisonFilter,
  updateDearUnitsSoldByCustomerTagDateFilter,
  updateDearUnitsSoldByCustomerTagComparisonFilter,
  updateDearUnitsShippedByBrandDateFilter,
  updateDearUnitsShippedByBrandComparisonFilter,
  updateDearUnitsShippedByProductDateFilter,
  updateDearUnitsShippedByProductComparisonFilter,
  updateDearUnitsShippedByCategoryDateFilter,
  updateDearUnitsShippedByCategoryComparisonFilter,
  updateDearUnitsShippedByCustomerDateFilter,
  updateDearUnitsShippedByCustomerComparisonFilter,
  updateDearUnitsShippedByProductTagDateFilter,
  updateDearUnitsShippedByProductTagComparisonFilter,
  updateDearUnitsShippedByProductTypeDateFilter,
  updateDearUnitsShippedByProductTypeComparisonFilter,
  updateDearUnitsShippedByCustomerTagDateFilter,
  updateDearUnitsShippedByCustomerTagComparisonFilter,
  setIsLoadingOnDemandExtraction
} = KPISlice.actions;

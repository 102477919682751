import React from 'react'
import { useDispatch, useSelector } from 'react-redux';

const XeroDisconnectPage = () => {
 const dispatch = useDispatch();
 const { organisations, organisation } = useSelector(
   (state) => state.organisation
 );
 const { user } = useSelector((state) => state.auth);
 const token = user?.data?.api_token;
  return <div>XeroDisconnectPage</div>;
};

export default XeroDisconnectPage
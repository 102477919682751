import React, { useState } from "react";
import ForecastingRevenueModal from "../modals/forecasting-revenue";

const months = [
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
  "January",
  "February",
  "April",
  "May",
];

const tableData = [
  {
    name: "John",
    revenueType: "Type A",
    costCenter: "Center A",
    total: 1000,
    deleteOption: true,
    values: [50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150],
  },
  {
    name: "Jane",
    revenueType: "Type B",
    costCenter: "Center B",
    total: 2000,
    deleteOption: false,
    values: [70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170],
  },
  {
    name: "Bob",
    revenueType: "Type C",
    costCenter: "Center C",
    total: 3000,
    deleteOption: true,
    values: [90, 100, 110, 120, 130, 140, 150, 160, 170, 180, 190],
  },
  {
    name: "Alice",
    revenueType: "Type A",
    costCenter: "Center D",
    total: 4000,
    deleteOption: false,
    values: [110, 120, 130, 140, 150, 160, 170, 180, 190, 200, 210],
  },
  {
    name: "Tom",
    revenueType: "Type B",
    costCenter: "Center E",
    total: 5000,
    deleteOption: true,
    values: [130, 140, 150, 160, 170, 180, 190, 200, 210, 220, 230],
  },
];

const RevenueTable = () => {
  const [startMonth, setStartMonth] = useState("June");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState(tableData);

  const handleMonthSelect = (event) => {
    setStartMonth(event.target.value);
  };

  const handleSaveData = (newData) => {
    setData([...data, newData]);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleDelete = (index) => {
    const updatedData = [...data];
    updatedData.splice(index, 1);
    setData(updatedData);
  };

  const startIndex = months.findIndex((month) => month === startMonth);
  const displayedMonths = [
    ...months.slice(startIndex),
    ...months.slice(0, startIndex),
  ];

  return (
    <div className="mt-5">
      <button
        className="bg-purple text-sm text-white font-medium py-2 px-14 rounded"
        onClick={openModal}
      >
        Add New Revenue Stream
      </button>

      <ForecastingRevenueModal
        isOpen={isModalOpen}
        closeModal={closeModal}
        saveData={handleSaveData}
      />

      <div className="overflow-x-auto">
        <table className="w-full text-[12px] table-auto mt-4">
          <thead>
            <tr className="text-left">
              <th className="px-4 py-2">Name</th>
              <th className="px-4 py-2">Revenue Type</th>
              <th className="px-4 py-2">Cost Center</th>
              <th className="px-4 py-2">Total</th>
              <th className="px-4 py-2">Delete Option</th>
              {displayedMonths.map((month) => (
                <th key={month} className="px-4 py-2">
                  {month}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr
                key={index}
                className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}
              >
                <td className="border px-4 py-2">{row.name}</td>
                <td className="border px-4 py-2">{row.revenueType}</td>
                <td className="border px-4 py-2">{row.costCenter}</td>
                <td className="border px-4 py-2">{row.total}</td>
                <td className="border px-4 py-2">
                  <button
                    className="hover:bg-dark-purple hover:text-white bg-light-grey-2 text-sm text-black font-medium py-2 px-6 rounded"
                    onClick={() => handleDelete(index)}
                  >
                    Delete
                  </button>
                </td>
                {months.map((month) => (
                  <td key={month} className="border px-4 py-2">
                    {row.values[month] || 0}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RevenueTable;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoIosCheckmarkCircle } from "react-icons/io/index";
import logo from "../../../../assets/logos/qurk_logo.png";

function VerifyPasswordResetScreen() {
  return (
    <div
      className="h-screen flex flex-col justify-between"
      style={{
        background:
          "linear-gradient(0deg, rgba(32,32,53,1) 0%, rgba(46,28,90,1) 30%, rgba(91,72,135,1) 100%)",
      }}
    >
      <div className=" text-white py-4 flex justify-between items-between">
        <div className="w-full h-full   ">
          <div className="flex  justify-between pl-4 ">
            <img src={logo} alt="" className="w-12 h-12 " />
            <div className="  justify-self-end pt-4 pr-16  ">
              <Link to="/customer-support">
                <h3 className="text-md font-regular text-white">Help</h3>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className=" text-white py-24 h-full  flex flex-col items-center justify-start gap-4">
        <div className="">
          <IoIosCheckmarkCircle size="120px" className="text-green-400" />
        </div>
        <h2 className="text-2xl font-bold text-white">Password Reset</h2>
        <h5 className="text-white text-center font-light text-sm w-1/3">
          Your password has been successfully reset. Please proceed to the sign
          in page and enter your new password to access your account.
        </h5>
        <Link to="/signin" className="mt-6 ">
          <button
            className="rounded-md bg-warning text-lg font-semibold  text-dark-purple py-2 px-16"
            type="submit"
          >
            Sign into account
          </button>
        </Link>
      </div>

      <div className="  py-4 flex flex-col items-center justify-center gap-4 text-white mb-6 ">
        <Link to="/customer-support">
          <button className="rounded-md">
            Having any issues?{" "}
            <span className="font-bold underline text-warning">
              Customer Support
            </span>
          </button>
        </Link>
      </div>
    </div>
  );
}

export default VerifyPasswordResetScreen;

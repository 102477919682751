// websockets.js
import { toast } from "react-toastify";

export const setupTransformSocket = (onOpenCallback, onMessageCallback, onErrorCallback) => {
  const socket = new WebSocket("wss://qurk.uk/ws/transform-notifications/");

  socket.onopen = onOpenCallback;

  socket.onmessage = (event) => {
    try {
      const data = JSON.parse(event.data);
      onMessageCallback(data);
    } catch (error) {
      // Error parsing WebSocket message
    }
  };

  socket.onerror = (error) => {
    onErrorCallback(error);
  };

  return socket;
};

export const setupXeroDisconnectSocket = (onOpenCallback, onMessageCallback, onErrorCallback) => {
  const socket = new WebSocket("wss://qurk.uk/ws/xero-disconnect-notifications/");

  socket.onopen = onOpenCallback;

  socket.onmessage = (event) => {
    try {
      const data = JSON.parse(event.data);
      onMessageCallback(data);
    } catch (error) {
      // Error parsing WebSocket message
    }
  };

  socket.onerror = (error) => {
    onErrorCallback(error);
  };

  return socket;
};

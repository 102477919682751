
import React, { useEffect, useState } from "react";
import { IoIosClose } from "react-icons/io";
import { MdGroups2 } from "react-icons/md";
import moment from "moment";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import FormikControl from "../../form-elements/formik-control";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import PaymentForm from "../stripe/checkout";
import { createSubscription, paySubscription, resetPaymentMethodId, resetSubscription } from "../../../../redux-features/slices/subscription/subscriptionSlice";
import { toast } from "react-toastify";

function SubscriptionPaymentModal(props) {
  const { toggle, toggleModal, plan, payment_status } = props;
  const [isPaymentSuccess, setPaymentSuccess] = useState(false);
  const stripePromise = loadStripe(
    "pk_test_51Neju7AMXRuhsYiAzNM6j7D4bC2EtaJYnAuHsvozsXcBwExUo8x0FlMvTwNxINV8QFH5k6AEBLIRpTKTCoRP6s4S00sFP4P00i"
  );
  const dispatch = useDispatch();
 const { user } = useSelector((state) => state.auth);
 const { paymentMethodId, isSuccess, subscription, isError } = useSelector((state) => state.subscription);
  const token = user?.data?.api_token; 
  const [currentStep, setCurrentStep] = useState(1);
const handleNextStep = () => {
  setCurrentStep(currentStep + 1);
};

const handlePreviousStep = () => {
  setCurrentStep(currentStep - 1);
};

useEffect(() => {
  if (isError) {
    toast.error("Oops! The subscription could not be applied!", {
      autoClose: 10000,
      hideProgressBar: true,
        toastId: "toast1",
    });
  }
  if (isSuccess && subscription) {
    handleNextStep()
  }
}, [isError, isSuccess, subscription, dispatch]);

const initialValues = {
  email: "",
  price_id: "",
  payment_method_id: "",
};
const onSubmit = async (values, actions) => {
  const sub_data = {
    email: values.email,
    price_id: plan?.id,
    payment_method_id:paymentMethodId,
  };
 
if(sub_data && paymentMethodId){
dispatch(createSubscription({ sub_data, token }));
} 
};

const handlePlanPayment = async () => {
  const sub_data = { subscription_id: subscription?.subscription?.id };
  dispatch(paySubscription({ sub_data, token }));
  await dispatch(resetPaymentMethodId());
};

useEffect(() => {
  if (payment_status.status === "SUCCESS") {
    setPaymentSuccess(true); // Update payment success status
    handleNextStep();
    toast.success("You have successfully subscribed to plan", {
      autoClose: 10000,
      hideProgressBar: true,
      toastId: "toast2",
    });
  }
}, [payment_status]);

 const closeAndResetModal = () => {
   toggleModal();
   setCurrentStep(0);
   dispatch(resetSubscription());
   setPaymentSuccess(false); // Reset payment success status
 };
  const renderStep2 = () => {
    return (
      <div>
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {(formik) => (
            <Form className=" ">
              <div className="flex flex-col items-start w-full ">
                <FormikControl
                  control="input"
                  type="text"
                  label="Enter Email Address"
                  name="email"
                  placeholder="Name"
                />
              </div>
              <div className="flex flex-row items-end justify-end w-full gap-4 mt-3">
                <button
                  className="self-end mt-6 p-3 text-sm bg-purple text-white rounded-md"
                  type="submit"
                >
                  Confirm
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  };
  const renderStep1 = () => {
    return (
      <div>
        <Elements stripe={stripePromise}>
          <PaymentForm
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          />
        </Elements>
      </div>
    );
  };
  const renderStep3 = () => {
    return (
      <div>
        <div className="flex flex-col gap-2 ">
          <div className="">
            <h2 className="flex flex-col gap-3 text-sm font-semibold">
              Thanks for subscribing
              <span className=" font-medium ">
                You total charges are €
                {plan?.unit_amount_decimal === "1000"
                  ? "10"
                  : plan.unit_amount_decimal === "2000"
                  ? "20"
                  : plan.unit_amount_decimal === "3000"
                  ? "30"
                  : plan.unit_amount_decimal === "4000" && "40"}
              </span>
            </h2>
          </div>
          <button
            className="self-end mt-2 p-3 text-sm bg-purple text-white rounded-md"
            onClick={handlePlanPayment}
          >
            Pay total
          </button>
        </div>
      </div>
    );
  };
  const renderStep4 = () => {
    return (
      <div>
        <div className="flex flex-col gap-2 ">
          <div className="">
            <h2 className="flex flex-col gap-3 text-sm font-semibold">
              Your payment is successful!
            </h2>
          </div>
         
        </div>
      </div>
    );
  };
  let currentStepContent;
  switch (currentStep) {
    case 1:
      currentStepContent = renderStep1();
      break;
    case 2:
      currentStepContent = renderStep2();
      break;
    case 3:
      currentStepContent = renderStep3();
      break;
    case 4:
      currentStepContent = renderStep4();
      break;
    default:
      currentStepContent = renderStep1();
  }

  return (
    <div>
      {toggle && (
        <div className="fixed z-40 inset-0 w-full  overflow-y-auto">
          <div className="flex items-center justify-center 6  bg-black bg-opacity-50 min-h-screen p-4">
            <div className="bg-white rounded-lg md:w-2/5 sm:w-3/5 w-4/5  p-8">
              <div className="flex flex-row justify-between item-start">
                <div className="flex gap-2 ">
                  <MdGroups2 size={42} />
                  <div className="mt-2">
                    <h2 className="text-lg font-medium">
                      Subscribe for
                      <span className=" px-2 font-bold">
                        {plan?.unit_amount_decimal === "1000"
                          ? "Basic"
                          : plan.unit_amount_decimal === "2000"
                          ? "Pro"
                          : plan.unit_amount_decimal === "3000"
                          ? "Enterprise"
                          : plan.unit_amount_decimal === "4000" && "Business"}
                      </span>
                      Plan
                    </h2>
                  </div>
                </div>
                <IoIosClose
                  onClick={closeAndResetModal} // Use the new function to close the modal
                  className="cursor-pointer"
                  size={35}
                />
              </div>
              <div className="mt-8">{currentStepContent}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SubscriptionPaymentModal;

import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

function PieChart(props) {
  const { chartData, title, company } = props;

  const options = {
    maintainAspectRatio: false,
    aspectRatio: 2, // You can adjust this value based on your preference
    plugins: {  
      legend: {
        position: 'bottom',
      },
      title: {
        display: true,
        text: `${title} ${company?.name}`,
      },
    },
  };

  const chartContainerStyle = {
    minHeight: '300px', // Adjust this value as needed
  };

  return (
    <div
      className="mt-5 p-2 border rounded-lg border-light-grey bg-white"
      style={chartContainerStyle}
    >
      <Pie data={chartData} options={options} />
    </div>
  );
}

export default PieChart;


import React, { useState } from 'react'
import { BsQuestionCircle } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import Sidebar from '../../../navigation';
import ComingSoonPage from '../../pages/utilis-pages/coming-soon';

function ReportsPage() {

  return (
   
      <div className="flex flex-col w-full px-6 py-2">
        <div className="flex w-full  items-end justify-between mt-2">
          <div>
            <div className="relative">
              <h2 className="text-xl font-medium ">
                Reports Page
              </h2>
            </div>
          </div>
          <div className="flex flex-row gap-6">
            
            <BsQuestionCircle className=" pt-2 text-purple" size={35} />
          </div>
        </div>
        <div className='h-full w-full'>
          <ComingSoonPage/>
        </div>
        
      </div>
  );
}

export default ReportsPage
import React, { useEffect } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getUserProfile, signInUser } from '../redux-features/slices/auth/authSlice';

export const AuthUser = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const user = localStorage.getItem('user');

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const status = queryParams.get('status');
    const token = queryParams.get('token');
    const email = queryParams.get('email');
    const slug = queryParams.get('slug');

    const user_data = {
      email: email,
      password: 'pass=123',
      rememberMe: true,
    };

    if (user_data && email && token) {
    localStorage.clear();
      dispatch(signInUser(user_data));
    }
  }, [location.search, dispatch]);

  if (!user) {
    return <Navigate to="/signin" replace={true} />;
  }

  return children;
};

import axios from "axios";

import {
 CONNECT_CIN7,
 CONNECT_DEAR,
 CONNECT_XERO,
 DISCONNECT_XERO,
 ENV_URL,
 UPDATE_APP
} from "../../../constant";
const connectXero =  (slug, token) => {
   axios.get(
    `${ENV_URL}${CONNECT_XERO}${slug}/`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  
};
const updateAppConnection = async (appData, token) => {
  const response = await axios.post(`${ENV_URL}${UPDATE_APP}`, appData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
const connectCin7 = async ( appData,slug, token) => {
  const response = await axios.post(
    `${ENV_URL}${CONNECT_CIN7}${slug}/`,
    appData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
const connectDear = async ( appData,slug, token) => {
  const response = await axios.post(
    `${ENV_URL}${CONNECT_DEAR}${slug}/`,
    appData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

const disconnectXero = async (slug, token) => {
  console.log('Making GET request to disconnect Xero');
  const response = await axios.get(
    `${ENV_URL}${DISCONNECT_XERO}${slug}/xero/disconnect/`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.data;
};


const integrationService = {
connectCin7,
connectXero,
updateAppConnection,
disconnectXero,
connectDear
};
export default integrationService;

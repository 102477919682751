import React, { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { setPaymentMethodId } from "../../../../redux-features/slices/subscription/subscriptionSlice";
import { useDispatch } from "react-redux";

const PaymentForm = (props) => {
  const { currentStep, setCurrentStep } = props;
  const stripe = useStripe();
  const elements = useElements();
  const dispatch=useDispatch()

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (error) {
      // console.log("Error occured while creating paymentmethod");
      console.error(error);
    } else {
      dispatch(setPaymentMethodId(paymentMethod.id))
      await setCurrentStep(currentStep + 1);
    }
  };

  return (
    <form className="flex flex-col" onSubmit={handleSubmit}>
      <CardElement />
      <button className="self-end mt-6 p-3 text-sm bg-purple text-white rounded-md" type="submit" disabled={!stripe}>
       Add card
      </button>
    </form>
  );
};

export default PaymentForm;

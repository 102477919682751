import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import KPIService, { getDearCustomerWithoutTags, getDearTopLateSuppliers } from "./kpiService";

const initialState = {
  dashboardOrg: {},
  isSuccessKPI: false,
  isLoadingKPI: false,
  isErrorKPI: false,
  isSuccessDearKPI: false,
  isLoadingDearKPI: false,
  isErrorDearKPI: false,
  // Dear KPIs
  dearProductSalesFilteredData: [],
  dearBrandFilters: [],
  dearCategoryFilters: [],
  dearContactFilters: [],
  dearCustomerTagsFilters: [],
  dearProductFilters: [],
  dearProductTagsFilters: [],
  dearProductTypeFilters: [],
  // Dear Sales By...
  dearProductSales: {},
  dearProductSalesLineGraph: [],
  dearProductSalesByBrand: [],
  dearProductSalesByCategory: [],
  dearProductSalesByCustomer: [],
  dearProductSalesByCustomerTag: [],
  dearProductSalesByProduct: [],
  dearProductSalesByProductTag: [],
  dearProductSalesByProductType: [],
  dearSalesDrilldownTableByCustomer:[],
  dearSalesDrilldownTableByProduct:[],
  // Dear Sales By Filters....
  salesByBrandDateFilter: "This Month",
  salesByBrandCompareFilter: "Previous Period",
  salesByProductDateFilter: "This Month",
  salesByProductCompareFilter: "Previous Period",
  salesByCategoryDateFilter: "This Month",
  salesByCategoryCompareFilter: "Previous Period",
  salesByCustomerDateFilter: "This Month",
  salesByCustomerCompareFilter: "Previous Period",
  salesByProductTagDateFilter: "This Month",
  salesByProductTagCompareFilter: "Previous Period",
  salesByProductTypeDateFilter: "This Month",
  salesByProductTypeCompareFilter: "Previous Period",
  salesByCustomerTagDateFilter: "This Month",
  salesByCustomerTagCompareFilter: "Previous Period",
  // Dear Profit By ...
  dearProfit: [],
  dearProfitLineGraph: [],
  dearProfitByBrand: [],
  dearProfitByCategory: [],
  dearProfitByCustomer: [],
  dearProfitByCustomerTag: [],
  dearProfitByProduct: [],
  dearProfitByProductTag: [],
  dearProfitByProductType: [],
  dearProfitDrilldownTableByCustomer:[],
  dearProfitDrilldownTableByProduct:[],
  // Dear Profit By Filters...
  profitByCategoryDateFilter: "This Month",
  profitByCategoryCompareFilter: "Previous Period",
  profitByProductDateFilter: "This Month",
  profitByProductCompareFilter: "Previous Period",
  profitByBrandDateFilter: "This Month",
  profitByBrandCompareFilter: "Previous Period",
  profitByCustomerDateFilter: "This Month",
  profitByCustomerCompareFilter: "Previous Period",
  profitByCustomerTagDateFilter: "This Month",
  profitByCustomerTagCompareFilter: "Previous Period",
  profitByProductTagDateFilter: "This Month",
  profitByProductTagCompareFilter: "Previous Period",
  profitByProductTypeDateFilter: "This Month",
  profitByProductTypeCompareFilter: "Previous Period",
  // Dear Units Sold By...
  dearUnitsSold: {},
  dearUnitsSoldLineGraph: [],
  dearUnitsSoldByBrand: [],
  dearUnitsSoldByCategory: [],
  dearUnitsSoldByCustomer: [],
  dearUnitsSoldByCustomerTag: [],
  dearUnitsSoldByProduct: [],
  dearUnitsSoldByProductTag: [],
  dearUnitsSoldByProductType: [],
  // Dear Units Sold By Filters....
  unitsSoldByBrandDateFilter: "This Month",
  unitsSoldByBrandCompareFilter: "Previous Period",
  unitsSoldByProductDateFilter: "This Month",
  unitsSoldByProductCompareFilter: "Previous Period",
  unitsSoldByCategoryDateFilter: "This Month",
  unitsSoldByCategoryCompareFilter: "Previous Period",
  unitsSoldByCustomerDateFilter: "This Month",
  unitsSoldByCustomerCompareFilter: "Previous Period",
  unitsSoldByProductTagDateFilter: "This Month",
  unitsSoldByProductTagCompareFilter: "Previous Period",
  unitsSoldByProductTypeDateFilter: "This Month",
  unitsSoldByProductTypeCompareFilter: "Previous Period",
  unitsSoldByCustomerTagDateFilter: "This Month",
  unitsSoldByCustomerTagCompareFilter: "Previous Period",
  // Dear Units Sold By
  dearUnitsShipped: {},
  dearUnitsShippedLineGraph: [],
  dearUnitsShippedByBrand: [],
  dearUnitsShippedByCategory: [],
  dearUnitsShippedByCustomer: [],
  dearUnitsShippedByCustomerTag: [],
  dearUnitsShippedByProduct: [],
  dearUnitsShippedByProductTag: [],
  dearUnitsShippedByProductType: [],
  dearUnitsSoldDrilldownTableByCustomer:[],
  dearUnitsSoldDrilldownTableByProduct:[],
  // Dear Units Shipped By Filters
  unitsShippedByBrandDateFilter: "This Month",
  unitsShippedByBrandCompareFilter: "Previous Period",
  unitsShippedByProductDateFilter: "This Month",
  unitsShippedByProductCompareFilter: "Previous Period",
  unitsShippedByCategoryDateFilter: "This Month",
  unitsShippedByCategoryCompareFilter: "Previous Period",
  unitsShippedByCustomerDateFilter: "This Month",
  unitsShippedByCustomerCompareFilter: "Previous Period",
  unitsShippedByProductTagDateFilter: "This Month",
  unitsShippedByProductTagCompareFilter: "Previous Period",
  unitsShippedByProductTypeDateFilter: "This Month",
  unitsShippedByProductTypeCompareFilter: "Previous Period",
  unitsShippedByCustomerTagDateFilter: "This Month",
  unitsShippedByCustomerTagCompareFilter: "Previous Period",
  dearUnitsShippedDrilldownTableByCustomer:[],
        dearUnitsShippedDrilldownTableByProduct:[],
  // Dear Other KPIs
  dearLastUpdated:[],

  dearPercentageCustomer80Sales: [],
  dearPercentageOrderShippedLate: [],
  dearPercentageProduct80Sales: [],
  message: "",
};

const handleKPIRequest = async (requestFunction, dataset_id, token) => {
  try {
    const result = await requestFunction(dataset_id, token);
    return result;
  } catch (error) {
    return {};
  }
};

const handleKPIRequestWithParams = async (
  requestFunction,
  dataset_id,
  token,
  params
) => {
  try {
    const result = await requestFunction(dataset_id, token, params);
    return result;
  } catch (error) {
    return {};
  }
};




export const getDashboardDearFilters = createAsyncThunk(
  "kpi/dashboard/dear/filters",
  async ({ dataset_id, token }, thunkAPI) => {
    try {
      const dearBrandFilters = await handleKPIRequest(
        KPIService.getDearBrandFilters,
        dataset_id,
        token
      );
      const dearCategoryFilters = await handleKPIRequest(
        KPIService.getDearCategoryFilters,
        dataset_id,
        token
      );
      const dearContactFilters = await handleKPIRequest(
        KPIService.getDearContactFilters,
        dataset_id,
        token
      );
      const dearCustomerTagsFilters = await handleKPIRequest(
        KPIService.getDearCustomerTagsFilters,
        dataset_id,
        token
      );
      const dearProductFilters = await handleKPIRequest(
        KPIService.getDearProductFilters,
        dataset_id,
        token
      );
      const dearProductTagsFilters = await handleKPIRequest(
        KPIService.getDearProductTagsFilters,
        dataset_id,
        token
      );
      const dearProductTypeFilters = await handleKPIRequest(
        KPIService.getDearProductTypeFilters,
        dataset_id,
        token
      );
      return {
        dearBrandFilters,
        dearCategoryFilters,
        dearContactFilters,
        dearCustomerTagsFilters,
        dearProductFilters,
        dearCustomerTagsFilters,
        dearProductTagsFilters,
        dearProductTypeFilters,
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Dear Filtered Data
export const getDearProductSalesKPIFilteredData = createAsyncThunk(
  "kpi/dashboard/dear/filtered/profit",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
      const dearProductSalesKPI = await KPIService.getDearProductSalesKPI(
        dataset_id,
        token,
        params
      );
      return {
        dearProductSalesKPI,
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getDearUnitsSoldKPIFilteredData = createAsyncThunk(
  "kpi/dashboard/dear/filtered/units-sold",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
      const dearUnitsSoldKPI = await KPIService.getDearUnitsSold(
        dataset_id,
        token,
        params
      );
      return {
        dearUnitsSoldKPI,
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getDearUnitsShippedKPIFilteredData = createAsyncThunk(
  "kpi/dashboard/dear/filtered/units-shipped",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
      const dearUnitsShippedKPI = await KPIService.getDearUnitsShipped(
        dataset_id,
        token,
        params
      );
      return {
        dearUnitsShippedKPI,
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getDearPercentageCustomer80SalesKPIFilteredData = createAsyncThunk(
  "kpi/dashboard/dear/filtered/percentage-customer-80-sales",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
      const dearPercentageCustomer80Sales = await KPIService.getPercentageCustomer80Sales(
        dataset_id,
        token,
        params
      );
      return {
        dearPercentageCustomer80Sales,
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getDearPercentageProduct80SalesKPIFilteredData = createAsyncThunk(
  "kpi/dashboard/dear/filtered/percentage-product-80-sales",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
      const dearPercentageProduct80Sales = await KPIService.getPercentageProduct80Sales(
        dataset_id,
        token,
        params
      );
      return {
        dearPercentageProduct80Sales,
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getDearPercentageOrderShippedLateKPIFilteredData = createAsyncThunk(
  "kpi/dashboard/dear/filtered/percentage-order-shipped-late",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
      const dearPercentageOrderShippedLate = await KPIService.getPercentageOrderShippedLate(
        dataset_id,
        token,
        params
      );
      return {
        dearPercentageOrderShippedLate,
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getDearProfitKPIFilteredData = createAsyncThunk(
  "kpi/dashboard/dear/filtered/product-sales",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
      const dearProfitKPI = await KPIService.getDearProfit(
        dataset_id,
        token,
        params
      );
      return {
        dearProfitKPI,
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getDashboardDearAllProductSalesKPIs = createAsyncThunk(
  "kpi/dashboard/dear/product-sales",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
      // Fetch data from different product sales endpoints
      const dearProductSalesKPI = await KPIService.getDearProductSalesKPI(
        dataset_id,
        token,
        params
      );
      const dearProductSalesLineGraph =
        await KPIService.getDearProductSalesLineGraph(
          dataset_id,
          token,
          params
        );
      const dearProductSalesByBrand =
        await KPIService.getDearProductSalesByBrand(dataset_id, token, params);
      const dearProductSalesByCategory =
        await KPIService.getDearProductSalesByCategory(
          dataset_id,
          token,
          params
        );
      const dearProductSalesByCustomer =
        await KPIService.getDearProductSalesByCustomer(
          dataset_id,
          token,
          params
        );
      const dearProductSalesByCustomerTag =
        await KPIService.getDearProductSalesByCustomerTag(
          dataset_id,
          token,
          params
        );
      const dearProductSalesByProduct =
        await KPIService.getDearProductSalesByProduct(
          dataset_id,
          token,
          params
        );
      const dearProductSalesByProductTag =
        await KPIService.getDearProductSalesByProductTag(
          dataset_id,
          token,
          params
        );
      const dearProductSalesByProductType =
        await KPIService.getDearProductSalesByProductType(
          dataset_id,
          token,
          params
        );
        const dearSalesDrilldownTableByCustomer =
        await KPIService.getSalesDrilldownCustomer(dataset_id, token, params);
        const dearSalesDrilldownTableByProduct =
        await KPIService.getSalesDrilldownProduct(dataset_id, token, params);
      // Return all fetched data
      return {
        dearProductSalesKPI,
        dearProductSalesLineGraph,
        dearProductSalesByBrand,
        dearProductSalesByCategory,
        dearProductSalesByCustomer,
        dearProductSalesByCustomerTag,
        dearProductSalesByProduct,
        dearProductSalesByProductTag,
        dearProductSalesByProductType,
        dearSalesDrilldownTableByCustomer,
        dearSalesDrilldownTableByProduct
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getDashboardDearAllOtherKPIs = createAsyncThunk(
  "kpi/dashboard/dear/other",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
      const dearLastUpdated = await KPIService.getDearLastUpdated(
        dataset_id,
        token,
      );
      const dearPercentageCustomer80Sales =
        await KPIService.getPercentageCustomer80Sales(
          dataset_id,
          token,
          params
        );
      const dearPercentageOrderShippedLate =
        await KPIService.getPercentageOrderShippedLate(
          dataset_id,
          token,
          params
        );
      const dearPercentageProduct80Sales =
        await KPIService.getPercentageProduct80Sales(dataset_id, token, params);
      
        const dearTopLateSuppliers = await KPIService.getDearTopLateSuppliers({ dataset_id, token})
        const dearCustomerWithoutTags = await KPIService.getDearCustomerWithoutTags({ dataset_id, token })
      return {
        dearLastUpdated,
        dearPercentageCustomer80Sales,
        dearPercentageOrderShippedLate,
        dearPercentageProduct80Sales,
        dearTopLateSuppliers,
        dearCustomerWithoutTags
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getDashboardDearAllUnitsSoldKPIs = createAsyncThunk(
  "kpi/dashboard/dear/units-sold",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
      // Fetch data from different units sold endpoints
      const dearUnitsSold = await KPIService.getDearUnitsSold(
        dataset_id,
        token,
        params
      );
      const dearUnitsSoldLineGraph = await KPIService.getDearUnitsSoldLineGraph(
        dataset_id,
        token,
        params
      );
      const dearUnitsSoldByBrand = await KPIService.getDearUnitsSoldByBrand(
        dataset_id,
        token,
        params
      );
      const dearUnitsSoldByCategory =
        await KPIService.getDearUnitsSoldByCategory(dataset_id, token, params);
      const dearUnitsSoldByCustomer =
        await KPIService.getDearUnitsSoldByCustomer(dataset_id, token, params);
      const dearUnitsSoldByCustomerTag =
        await KPIService.getDearUnitsSoldByCustomerTag(
          dataset_id,
          token,
          params
        );
      const dearUnitsSoldByProduct = await KPIService.getDearUnitsSoldByProduct(
        dataset_id,
        token,
        params
      );
      const dearUnitsSoldByProductTag =
        await KPIService.getDearUnitsSoldByProductTag(
          dataset_id,
          token,
          params
        );
      const dearUnitsSoldByProductType =
        await KPIService.getDearUnitsSoldByProductType(
          dataset_id,
          token,
          params
        );
        const dearUnitsSoldDrilldownTableByCustomer =
        await KPIService.getUnitsSoldDrilldownCustomer(dataset_id, token, params);
        const dearUnitsSoldDrilldownTableByProduct =
        await KPIService.getUnitsSoldDrilldownProduct(dataset_id, token, params);
      // Return all fetched data
      return {
        dearUnitsSold,
        dearUnitsSoldLineGraph,
        dearUnitsSoldByBrand,
        dearUnitsSoldByCategory,
        dearUnitsSoldByCustomer,
        dearUnitsSoldByCustomerTag,
        dearUnitsSoldByProduct,
        dearUnitsSoldByProductTag,
        dearUnitsSoldByProductType,
        dearUnitsSoldDrilldownTableByProduct,
        dearUnitsSoldDrilldownTableByCustomer
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getDashboardDearAllUnitsShippedKPIs = createAsyncThunk(
  "kpi/dashboard/dear/units-shipped",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
      // Fetch data from different units shipped endpoints
      const dearUnitsShipped = await KPIService.getUnitsShipped(
        dataset_id,
        token,
        params
      );
      const dearUnitsShippedLineGraph =
        await KPIService.getUnitsShippedLineGraph(dataset_id, token, params);
      const dearUnitsShippedByBrand = await KPIService.getUnitsShippedByBrand(
        dataset_id,
        token,
        params
      );
      const dearUnitsShippedByCategory =
        await KPIService.getUnitsShippedByCategory(dataset_id, token, params);
      const dearUnitsShippedByCustomer =
        await KPIService.getUnitsShippedByCustomer(dataset_id, token, params);
      const dearUnitsShippedByCustomerTag =
        await KPIService.getUnitsShippedByCustomerTag(
          dataset_id,
          token,
          params
        );
      const dearUnitsShippedByProduct =
        await KPIService.getUnitsShippedByProduct(dataset_id, token, params);
      const dearUnitsShippedByProductTag =
        await KPIService.getUnitsShippedByProductTag(dataset_id, token, params);
      const dearUnitsShippedByProductType =
        await KPIService.getUnitsShippedByProductType(
          dataset_id,
          token,
          params
        );
        const dearUnitsShippedDrilldownTableByCustomer =
        await KPIService.getUnitsShippedDrilldownCustomer(dataset_id, token, params);
        const dearUnitsShippedDrilldownTableByProduct =
        await KPIService.getUnitsShippedDrilldownProduct(dataset_id, token, params);
      // Return all fetched data
      return {
        dearUnitsShipped,
        dearUnitsShippedLineGraph,
        dearUnitsShippedByBrand,
        dearUnitsShippedByCategory,
        dearUnitsShippedByCustomer,
        dearUnitsShippedByCustomerTag,
        dearUnitsShippedByProduct,
        dearUnitsShippedByProductTag,
        dearUnitsShippedByProductType,
        dearUnitsShippedDrilldownTableByCustomer,
        dearUnitsShippedDrilldownTableByProduct
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getDearProfitDrilldown01TableByCustomer = createAsyncThunk(
  "kpi/dashboard/dear/profit/drilldown-01/customer",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
      const dearProfitDrilldownTableByCustomer =
      await KPIService.getProfitDrilldownCustomer(dataset_id, token, params);
       
      return {
        dearProfitDrilldownTableByCustomer,
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getDearProfitDrilldown01TableByProduct = createAsyncThunk(
  "kpi/dashboard/dear/profit/drilldown-01/product",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
   
        const dearProfitDrilldownTableByProduct =
        await KPIService.getProfitDrilldownProduct(dataset_id, token, params);
      return {
       
        dearProfitDrilldownTableByProduct
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Dear Drilldowns
export const getDearSalesDrilldown01TableByCustomer = createAsyncThunk(
  "kpi/dashboard/dear/sales/drilldown-01/customer",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
        const dearSalesDrilldownTableByCustomer =
        await KPIService.getSalesDrilldownCustomer(dataset_id, token, params);
       
      return {
        dearSalesDrilldownTableByCustomer,
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDearSalesDrilldown01TableByProduct = createAsyncThunk(
  "kpi/dashboard/dear/sales/drilldown-01/product",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
   
        const dearSalesDrilldownTableByProduct =
        await KPIService.getSalesDrilldownProduct(dataset_id, token, params);
      return {
       
        dearSalesDrilldownTableByProduct
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDearUnitsSoldDrilldown01TableByCustomer = createAsyncThunk(
  "kpi/dashboard/dear/unitsSold/drilldown-01/customer",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
        const dearUnitsSoldDrilldownTableByCustomer =
        await KPIService.getUnitsSoldDrilldownCustomer(dataset_id, token, params);
       
      return {
        dearUnitsSoldDrilldownTableByCustomer,
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDearUnitsSoldDrilldown01TableByProduct = createAsyncThunk(
  "kpi/dashboard/dear/unitsSold/drilldown-01/product",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
   
        const dearUnitsSoldDrilldownTableByProduct =
        await KPIService.getUnitsSoldDrilldownProduct(dataset_id, token, params);
      return {
       
        dearUnitsSoldDrilldownTableByProduct
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDearUnitsShippedDrilldown01TableByCustomer = createAsyncThunk(
  "kpi/dashboard/dear/unitsShipped/drilldown-01/customer",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
        const dearUnitsShippedDrilldownTableByCustomer =
        await KPIService.getUnitsShippedDrilldownCustomer(dataset_id, token, params);
       
      return {
        dearUnitsShippedDrilldownTableByCustomer,
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDearUnitsShippedDrilldown01TableByProduct = createAsyncThunk(
  "kpi/dashboard/dear/unitsShipped/drilldown-01/product",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
   
        const dearUnitsShippedDrilldownTableByProduct =
        await KPIService.getUnitsShippedDrilldownProduct(dataset_id, token, params);
      return {
       
        dearUnitsShippedDrilldownTableByProduct
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);


export const getDashboardDearAllProfitKPIs = createAsyncThunk(
  "kpi/dashboard/dear/profit",
  async ({ dataset_id, token, params }, thunkAPI) => {
    try {
      const dearProfit = await KPIService.getDearProfit(
        dataset_id,
        token,
        params
      );
      const dearProfitLineGraph = await KPIService.getDearProfitLineGraph(
        dataset_id,
        token,
        params
      );
      const dearProfitByBrand = await KPIService.getDearProfitByBrand(
        dataset_id,
        token,
        params
      );
      const dearProfitByCategory = await KPIService.getDearProfitByCategory(
        dataset_id,
        token,
        params
      );
      const dearProfitByCustomer = await KPIService.getDearProfitByCustomer(
        dataset_id,
        token,
        params
      );
      const dearProfitByCustomerTag =
        await KPIService.getDearProfitByCustomerTag(dataset_id, token, params);
      const dearProfitByProduct = await KPIService.getDearProfitByProduct(
        dataset_id,
        token,
        params
      );
      const dearProfitByProductTag = await KPIService.getDearProfitByProductTag(
        dataset_id,
        token,
        params
      );
      const dearProfitByProductType =
        await KPIService.getDearProfitByProductType(dataset_id, token, params);

      
        const dearProfitDrilldownTableByCustomer =
        await KPIService.getProfitDrilldownCustomer(dataset_id, token, params);
        const dearProfitDrilldownTableByProduct =
        await KPIService.getProfitDrilldownProduct(dataset_id, token, params);
      return {
        dearProfit,
        dearProfitLineGraph,
        dearProfitByBrand,
        dearProfitByCategory,
        dearProfitByCustomer,
        dearProfitByCustomerTag,
        dearProfitByProduct,
        dearProfitByProductTag,
        dearProfitByProductType,
        dearProfitDrilldownTableByCustomer,
        dearProfitDrilldownTableByProduct
      };
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDashboardDearData = createAsyncThunk(
  "kpi/dashboard/dear",
  async ({ slug,dear_dataset_id, token, params }, thunkAPI) => {
    try {
      const dataset_id = dear_dataset_id;
      // Dispatching Brand Filters
      const dearBrandFiltersData = await thunkAPI.dispatch(
        getDashboardDearFilters({ dataset_id, token })
      );
      const dearBrandFilters = dearBrandFiltersData.payload;
      const dearProductSalesKPIData = await thunkAPI.dispatch(
        getDashboardDearAllProductSalesKPIs({ dataset_id, token, params })
      );
      const dearAllProductSales = dearProductSalesKPIData.payload;
      const dearProfitKPIData = await thunkAPI.dispatch(
        getDashboardDearAllProfitKPIs({ dataset_id, token, params })
      );
      const dearProfit = dearProfitKPIData.payload;
      const dearUnitsSoldKPIData = await thunkAPI.dispatch(
        getDashboardDearAllUnitsSoldKPIs({ dataset_id, token, params })
      );
      const dearUnitsSold = dearUnitsSoldKPIData.payload;
      const dearUnitsShippedKPIData = await thunkAPI.dispatch(
        getDashboardDearAllUnitsShippedKPIs({ dataset_id, token, params })
      );
      const dearUnitsShipped = dearUnitsShippedKPIData.payload;

      const dearOtherKPIsData = await thunkAPI.dispatch(
        getDashboardDearAllOtherKPIs({ dataset_id, token, params })
      );
      const dearOtherKPIs = dearOtherKPIsData.payload;
      

      const combinedData = {
        dearBrandFilters,
        dearAllProductSales,
        dearProfit,
        dearUnitsSold,
        dearUnitsShipped,
        dearOtherKPIs,
    
      };

      return combinedData;
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const addKPIFavorite = createAsyncThunk(
  "kpi/favorite",
  async ({ org_id, kpiFavData, token }, thunkAPI) => {
    try {
      return await KPIService.addKPIFavorite(org_id, kpiFavData, token);
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export function setDashboardOrg(state, action) {
  const { org } = action.payload;
  state.dashboardOrg = org;
}
export function setDearSalesByBrandDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.salesByBrandDateFilter = dateFilter;
}
export function setDearSalesByBrandComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.salesByBrandCompareFilter = comparisonFilter;
}
export function setDearSalesByProductDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.salesByProductDateFilter = dateFilter;
}
export function setDearSalesByProductComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.salesByProductCompareFilter = comparisonFilter;
}
export function setDearSalesByCategoryDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.salesByCategoryDateFilter = dateFilter;
}
export function setDearSalesByCategoryComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.salesByCategoryCompareFilter = comparisonFilter;
}
export function setDearSalesByCustomerDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.salesByCustomerDateFilter = dateFilter;
}
export function setDearSalesByCustomerComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.salesByCustomerCompareFilter = comparisonFilter;
}
export function setDearSalesByProductTagDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.salesByProductTagDateFilter = dateFilter;
}
export function setDearSalesByProductTagComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.salesByProductTagCompareFilter = comparisonFilter;
}
export function setDearSalesByProductTypeDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.salesByProductTypeDateFilter = dateFilter;
}
export function setDearSalesByProductTypeComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.salesByProductTypeCompareFilter = comparisonFilter;
}
export function setDearSalesByCustomerTagDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.salesByCustomerTagDateFilter = dateFilter;
}
export function setDearSalesByCustomerTagComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.salesByCustomerTagCompareFilter = comparisonFilter;
}
// Dear Products By
export function setDearProfitByCategoryDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.profitByCategoryDateFilter = dateFilter;
}
export function setDearProfitByCategoryComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.profitByCategoryCompareFilter = comparisonFilter;
}
export function setDearProfitByProductDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.profitByProductDateFilter = dateFilter;
}
export function setDearProfitByProductComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.profitByProductCompareFilter = comparisonFilter;
}
export function setDearProfitByBrandDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.profitByBrandDateFilter = dateFilter;
}
export function setDearProfitByBrandComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.profitByBrandCompareFilter = comparisonFilter;
}
// Dear Products By Customer
export function setDearProfitByCustomerDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.profitByCustomerDateFilter = dateFilter;
}

export function setDearProfitByCustomerComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.profitByCustomerComparisonFilter = comparisonFilter;
}

// Dear Products By Customer Tag
export function setDearProfitByCustomerTagDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.profitByCustomerTagDateFilter = dateFilter;
}

export function setDearProfitByCustomerTagComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.profitByCustomerTagComparisonFilter = comparisonFilter;
}

// Dear Products By Product Tag
export function setDearProfitByProductTagDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.profitByProductTagDateFilter = dateFilter;
}

export function setDearProfitByProductTagComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.profitByProductTagComparisonFilter = comparisonFilter;
}

// Dear Products By Product Type
export function setDearProfitByProductTypeDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.profitByProductTypeDateFilter = dateFilter;
}

export function setDearProfitByProductTypeComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.profitByProductTypeComparisonFilter = comparisonFilter;
}
// Units Sold
export function setDearUnitsSoldByBrandDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.unitsSoldByBrandDateFilter = dateFilter;
}
export function setDearUnitsSoldByBrandComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.unitsSoldByBrandCompareFilter = comparisonFilter;
}
export function setDearUnitsSoldByProductDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.unitsSoldByProductDateFilter = dateFilter;
}
export function setDearUnitsSoldByProductComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.unitsSoldByProductCompareFilter = comparisonFilter;
}
export function setDearUnitsSoldByCategoryDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.unitsSoldByCategoryDateFilter = dateFilter;
}
export function setDearUnitsSoldByCategoryComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.unitsSoldByCategoryCompareFilter = comparisonFilter;
}
export function setDearUnitsSoldByCustomerDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.unitsSoldByCustomerDateFilter = dateFilter;
}
export function setDearUnitsSoldByCustomerComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.unitsSoldByCustomerCompareFilter = comparisonFilter;
}
export function setDearUnitsSoldByProductTagDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.unitsSoldByProductTagDateFilter = dateFilter;
}
export function setDearUnitsSoldByProductTagComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.unitsSoldByProductTagCompareFilter = comparisonFilter;
}
export function setDearUnitsSoldByProductTypeDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.unitsSoldByProductTypeDateFilter = dateFilter;
}
export function setDearUnitsSoldByProductTypeComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.unitsSoldByProductTypeCompareFilter = comparisonFilter;
}
export function setDearUnitsSoldByCustomerTagDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.unitsSoldByCustomerTagDateFilter = dateFilter;
}
export function setDearUnitsSoldByCustomerTagComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.unitsSoldByCustomerTagCompareFilter = comparisonFilter;
}
// Units Shipped
export function setUnitsShippedByBrandDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.unitsShippedByBrandDateFilter = dateFilter;
}
export function setUnitsShippedByBrandComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.unitsShippedByBrandCompareFilter = comparisonFilter;
}
export function setUnitsShippedByProductDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.unitsShippedByProductDateFilter = dateFilter;
}
export function setUnitsShippedByProductComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.unitsShippedByProductCompareFilter = comparisonFilter;
}
export function setUnitsShippedByCategoryDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.unitsShippedByCategoryDateFilter = dateFilter;
}
export function setUnitsShippedByCategoryComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.unitsShippedByCategoryCompareFilter = comparisonFilter;
}
export function setUnitsShippedByCustomerDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.unitsShippedByCustomerDateFilter = dateFilter;
}
export function setUnitsShippedByCustomerComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.unitsShippedByCustomerCompareFilter = comparisonFilter;
}
export function setUnitsShippedByProductTagDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.unitsShippedByProductTagDateFilter = dateFilter;
}
export function setUnitsShippedByProductTagComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.unitsShippedByProductTagCompareFilter = comparisonFilter;
}
export function setUnitsShippedByProductTypeDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.unitsShippedByProductTypeDateFilter = dateFilter;
}
export function setUnitsShippedByProductTypeComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.unitsShippedByProductTypeCompareFilter = comparisonFilter;
}
export function setUnitsShippedByCustomerTagDateFilter(state, action) {
  const { dateFilter } = action.payload;
  state.unitsShippedByCustomerTagDateFilter = dateFilter;
}
export function setUnitsShippedByCustomerTagComparisonFilter(state, action) {
  const { comparisonFilter } = action.payload;
  state.unitsShippedByCustomerTagCompareFilter = comparisonFilter;
}

export const DearKPISlice = createSlice({
  name: "dear-kpi",
  initialState,
  reducers: {
    resetKPI: (state) => {
      state.isLoadingKPI = false;
      state.isSuccessKPI = false;
      state.isErrorKPI = false;
      state.message = "";
    },
    setDashboardOrganisation: setDashboardOrg,
    // Dear Sales By...
    updateDearSalesByBrandDateFilter: setDearSalesByBrandDateFilter,
    updateDearSalesByBrandComparisonFilter: setDearSalesByBrandComparisonFilter,
    updateDearSalesByProductDateFilter: setDearSalesByProductDateFilter,
    updateDearSalesByProductComparisonFilter:
      setDearSalesByProductComparisonFilter,
    updateDearSalesByCategoryDateFilter: setDearSalesByCategoryDateFilter,
    updateDearSalesByCategoryComparisonFilter:
      setDearSalesByCategoryComparisonFilter,
    updateDearSalesByCustomerDateFilter: setDearSalesByCustomerDateFilter,
    updateDearSalesByCustomerComparisonFilter:
      setDearSalesByCustomerComparisonFilter,
    updateDearSalesByProductTagDateFilter: setDearSalesByProductTagDateFilter,
    updateDearSalesByProductTagComparisonFilter:
      setDearSalesByProductTagComparisonFilter,
    updateDearSalesByProductTypeDateFilter: setDearSalesByProductTypeDateFilter,
    updateDearSalesByProductTypeComparisonFilter:
      setDearSalesByProductTypeComparisonFilter,
    updateDearSalesByCustomerTagDateFilter: setDearSalesByCustomerTagDateFilter,
    updateDearSalesByCustomerTagComparisonFilter:
      setDearSalesByCustomerTagComparisonFilter,
    // Dear Products By....
    updateDearProfitByCategoryDateFilter: setDearProfitByCategoryDateFilter,
    updateDearProfitByCategoryComparisonFilter:
      setDearProfitByCategoryComparisonFilter,
    updateDearProfitByProductDateFilter: setDearProfitByProductDateFilter,
    updateDearProfitByProductComparisonFilter:
      setDearProfitByProductComparisonFilter,
    updateDearProfitByBrandDateFilter: setDearProfitByBrandDateFilter,
    updateDearProfitByBrandComparisonFilter:
      setDearProfitByBrandComparisonFilter,
    // Dear Products By Customer
    updateDearProfitByCustomerDateFilter: setDearProfitByCustomerDateFilter,
    updateDearProfitByCustomerComparisonFilter:
      setDearProfitByCustomerComparisonFilter,

    // Dear Products By Customer Tag
    updateDearProfitByCustomerTagDateFilter:
      setDearProfitByCustomerTagDateFilter,
    updateDearProfitByCustomerTagComparisonFilter:
      setDearProfitByCustomerTagComparisonFilter,

    // Dear Products By Product Tag
    updateDearProfitByProductTagDateFilter: setDearProfitByProductTagDateFilter,
    updateDearProfitByProductTagComparisonFilter:
      setDearProfitByProductTagComparisonFilter,

    // Dear Products By Product Type
    updateDearProfitByProductTypeDateFilter:
      setDearProfitByProductTypeDateFilter,
    updateDearProfitByProductTypeComparisonFilter:
      setDearProfitByProductTypeComparisonFilter,

    // Dear Units Sold By...
    updateDearUnitsSoldByBrandDateFilter: setDearUnitsSoldByBrandDateFilter,
    updateDearUnitsSoldByBrandComparisonFilter:
      setDearUnitsSoldByBrandComparisonFilter,
    updateDearUnitsSoldByProductDateFilter: setDearUnitsSoldByProductDateFilter,
    updateDearUnitsSoldByProductComparisonFilter:
      setDearUnitsSoldByProductComparisonFilter,
    updateDearUnitsSoldByCategoryDateFilter:
      setDearUnitsSoldByCategoryDateFilter,
    updateDearUnitsSoldByCategoryComparisonFilter:
      setDearUnitsSoldByCategoryComparisonFilter,
    updateDearUnitsSoldByCustomerDateFilter:
      setDearUnitsSoldByCustomerDateFilter,
    updateDearUnitsSoldByCustomerComparisonFilter:
      setDearUnitsSoldByCustomerComparisonFilter,
    updateDearUnitsSoldByProductTagDateFilter:
      setDearUnitsSoldByProductTagDateFilter,
    updateDearUnitsSoldByProductTagComparisonFilter:
      setDearUnitsSoldByProductTagComparisonFilter,
    updateDearUnitsSoldByProductTypeDateFilter:
      setDearUnitsSoldByProductTypeDateFilter,
    updateDearUnitsSoldByProductTypeComparisonFilter:
      setDearUnitsSoldByProductTypeComparisonFilter,
    updateDearUnitsSoldByCustomerTagDateFilter:
      setDearUnitsSoldByCustomerTagDateFilter,
    updateDearUnitsSoldByCustomerTagComparisonFilter:
      setDearUnitsSoldByCustomerTagComparisonFilter,
    // Dear Units Shipped By...
    updateDearUnitsShippedByBrandDateFilter: setUnitsShippedByBrandDateFilter,
    updateDearUnitsShippedByBrandComparisonFilter:
      setUnitsShippedByBrandComparisonFilter,
    updateDearUnitsShippedByProductDateFilter: setUnitsShippedByProductDateFilter,
    updateDearUnitsShippedByProductComparisonFilter:
      setUnitsShippedByProductComparisonFilter,
    updateDearUnitsShippedByCategoryDateFilter: setUnitsShippedByCategoryDateFilter,
    updateDearUnitsShippedByCategoryComparisonFilter:
      setUnitsShippedByCategoryComparisonFilter,
    updateDearUnitsShippedByCustomerDateFilter: setUnitsShippedByCustomerDateFilter,
    updateDearUnitsShippedByCustomerComparisonFilter:
      setUnitsShippedByCustomerComparisonFilter,
    updateDearUnitsShippedByProductTagDateFilter:
      setUnitsShippedByProductTagDateFilter,
    updateDearUnitsShippedByProductTagComparisonFilter:
      setUnitsShippedByProductTagComparisonFilter,
    updateDearUnitsShippedByProductTypeDateFilter:
      setUnitsShippedByProductTypeDateFilter,
    updateDearUnitsShippedByProductTypeComparisonFilter:
      setUnitsShippedByProductTypeComparisonFilter,
    updateDearUnitsShippedByCustomerTagDateFilter:
      setUnitsShippedByCustomerTagDateFilter,
    updateDearUnitsShippedByCustomerTagComparisonFilter:
      setUnitsShippedByCustomerTagComparisonFilter,
  },
  extraReducers: (builder) => {
    builder
     
      .addCase(getDashboardDearData.pending, (state) => {
        state.isLoadingDearKPI = true;
        state.isSuccessDearKPI = false;
      })
      .addCase(getDashboardDearData.fulfilled, (state, action) => {
        state.isLoadingDearKPI = false;
        state.isSuccessDearKPI = true;
      })
      .addCase(getDashboardDearData.rejected, (state, action) => {
        state.isLoadingDearKPI = false;
        state.isErrorDearKPI = true;
        state.message = action.payload;
      })
      // Dear KPIs
      .addCase(getDashboardDearFilters.fulfilled, (state, action) => {
        state.dearBrandFilters = action.payload.dearBrandFilters;
        state.dearCategoryFilters = action.payload.dearCategoryFilters;
        state.dearContactFilters = action.payload.dearContactFilters;
        state.dearCustomerTagsFilters = action.payload.dearCustomerTagsFilters;
        state.dearProductFilters = action.payload.dearProductFilters;
        state.dearProductTagsFilters = action.payload.dearProductTagsFilters;
        state.dearProductTypeFilters = action.payload.dearProductTypeFilters;
      })
      .addCase(getDashboardDearFilters.rejected, (state, action) => {
        state.message = action.payload;
      })
      .addCase(
        getDashboardDearAllProductSalesKPIs.fulfilled,
        (state, action) => {
          state.dearProductSales = action.payload.dearProductSalesKPI;
          state.dearProductSalesLineGraph =
            action.payload.dearProductSalesLineGraph;
          state.dearProductSalesByBrand =
            action.payload.dearProductSalesByBrand;
          state.dearProductSalesByCategory =
            action.payload.dearProductSalesByCategory;
          state.dearProductSalesByCustomer =
            action.payload.dearProductSalesByCustomer;
          state.dearProductSalesByCustomerTag =
            action.payload.dearProductSalesByCustomerTag;
          state.dearProductSalesByProduct =
            action.payload.dearProductSalesByProduct;
          state.dearProductSalesByProductTag =
            action.payload.dearProductSalesByProductTag;
          state.dearProductSalesByProductType =
            action.payload.dearProductSalesByProductType;
            state.dearSalesDrilldownTableByCustomer= action.payload.dearSalesDrilldownTableByCustomer;
        state.dearSalesDrilldownTableByProduct= action.payload.dearSalesDrilldownTableByProduct
        }
      )
      // Drilldowns
      .addCase(
        getDearSalesDrilldown01TableByCustomer.fulfilled,
        (state, action) => {
            state.dearSalesDrilldownTableByCustomer= action.payload.dearSalesDrilldownTableByCustomer;
        }
      )
      .addCase(
        getDearSalesDrilldown01TableByProduct.fulfilled,
        (state, action) => {
         
        state.dearSalesDrilldownTableByProduct= action.payload.dearSalesDrilldownTableByProduct
        }
      )
      .addCase(getDearProfitDrilldown01TableByProduct.fulfilled, (state, action) => {
        state.dearProfitDrilldownTableByProduct = action.payload.dearProfitDrilldownTableByProduct;
      })
      .addCase(getDearProfitDrilldown01TableByCustomer.fulfilled, (state, action) => {
        state.dearProfitDrilldownTableByCustomer = action.payload.dearProfitDrilldownTableByCustomer;
      })
      .addCase(getDearUnitsShippedDrilldown01TableByProduct.fulfilled, (state, action) => {
        state.dearUnitsShippedDrilldownTableByProduct = action.payload.dearUnitsShippedDrilldownTableByProduct;
      })
     .addCase(getDearUnitsShippedDrilldown01TableByCustomer.fulfilled, (state, action) => {
        state.dearUnitsSoldDrilldownTableByCustomer = action.payload.dearUnitsShippedDrilldownTableByCustomer;
      })
      .addCase(getDearUnitsSoldDrilldown01TableByProduct.fulfilled, (state, action) => {
        state.dearUnitsSoldDrilldownTableByProduct = action.payload.dearUnitsSoldDrilldownTableByProduct;
      })
      .addCase(getDearUnitsSoldDrilldown01TableByCustomer.fulfilled, (state, action) => {
        state.dearUnitsSoldDrilldownTableByCustomer = action.payload.dearUnitsSoldDrilldownTableByCustomer;
      })
      .addCase(
        getDearProductSalesKPIFilteredData.fulfilled,
        (state, action) => {
          state.dearProductSales = action.payload.dearProductSalesKPI;
        }
      )
      .addCase(getDearProfitKPIFilteredData.fulfilled, (state, action) => {
        state.dearProfit = action.payload.dearProfitKPI;
      })
      .addCase(getDearUnitsSoldKPIFilteredData.fulfilled, (state, action) => {
        state.dearUnitsSold = action.payload.dearUnitsSoldKPI;
      })
      .addCase(getDearUnitsShippedKPIFilteredData.fulfilled, (state, action) => {
        state.dearUnitsShipped = action.payload.dearUnitsShippedKPI;
      })
      .addCase(getDearPercentageCustomer80SalesKPIFilteredData.fulfilled, (state, action) => {
        state.dearPercentageCustomer80Sales = action.payload.dearPercentageCustomer80Sales;
      })
      .addCase(getDearPercentageProduct80SalesKPIFilteredData.fulfilled, (state, action) => {
        state.dearPercentageProduct80Sales = action.payload.dearPercentageProduct80Sales;
      })
      .addCase(getDearPercentageOrderShippedLateKPIFilteredData.fulfilled, (state, action) => {
        state.dearPercentageOrderShippedLate = action.payload.dearPercentageOrderShippedLate;
      })
      // Profit KPIs
      .addCase(getDashboardDearAllProfitKPIs.fulfilled, (state, action) => {
        state.dearProfit = action.payload.dearProfit;
        state.dearProfitLineGraph = action.payload.dearProfitLineGraph;
        state.dearProfitByBrand = action.payload.dearProfitByBrand;
        state.dearProfitByCategory = action.payload.dearProfitByCategory;
        state.dearProfitByCustomer = action.payload.dearProfitByCustomer;
        state.dearProfitByCustomerTag = action.payload.dearProfitByCustomerTag;
        state.dearProfitByProduct = action.payload.dearProfitByProduct;
        state.dearProfitByProductTag = action.payload.dearProfitByProductTag;
        state.dearProfitByProductType = action.payload.dearProfitByProductType;
        state.dearProfitDrilldownTableByCustomer=action.payload.dearProfitDrilldownTableByCustomer;
        state.dearProfitDrilldownTableByProduct=action.payload.dearProfitDrilldownTableByProduct
      })
 // Units Sold KPIs

      // Units Sold KPIs
      .addCase(getDashboardDearAllUnitsSoldKPIs.fulfilled, (state, action) => {
        state.dearUnitsSold = action.payload.dearUnitsSold;
        state.dearUnitsSoldLineGraph = action.payload.dearUnitsSoldLineGraph;
        state.dearUnitsSoldByBrand = action.payload.dearUnitsSoldByBrand;
        state.dearUnitsSoldByCategory = action.payload.dearUnitsSoldByCategory;
        state.dearUnitsSoldByCustomer = action.payload.dearUnitsSoldByCustomer;
        state.dearUnitsSoldByCustomerTag =
          action.payload.dearUnitsSoldByCustomerTag;
        state.dearUnitsSoldByProduct = action.payload.dearUnitsSoldByProduct;
        state.dearUnitsSoldByProductTag =
          action.payload.dearUnitsSoldByProductTag;
        state.dearUnitsSoldByProductType =
          action.payload.dearUnitsSoldByProductType;
          state.dearUnitsSoldDrilldownTableByProduct=action.payload.dearUnitsSoldDrilldownTableByProduct;
          state.dearUnitsSoldDrilldownTableByCustomer=action.payload.dearUnitsSoldDrilldownTableByCustomer
      })
      .addCase(
        getDashboardDearAllUnitsShippedKPIs.fulfilled,
        (state, action) => {
          state.dearUnitsShipped = action.payload.dearUnitsShipped;
          state.dearUnitsShippedLineGraph =
            action.payload.dearUnitsShippedLineGraph;
          state.dearUnitsShippedByBrand =
            action.payload.dearUnitsShippedByBrand;
          state.dearUnitsShippedByCategory =
            action.payload.dearUnitsShippedByCategory;
          state.dearUnitsShippedByCustomer =
            action.payload.dearUnitsShippedByCustomer;
          state.dearUnitsShippedByCustomerTag =
            action.payload.dearUnitsShippedByCustomerTag;
          state.dearUnitsShippedByProduct =
            action.payload.dearUnitsShippedByProduct;
          state.dearUnitsShippedByProductTag =
            action.payload.dearUnitsShippedByProductTag;
          state.dearUnitsShippedByProductType =
            action.payload.dearUnitsShippedByProductType;
            state.dearUnitsShippedDrilldownTableByCustomer=action.payload.dearUnitsShippedDrilldownTableByCustomer;
            state.dearUnitsShippedDrilldownTableByProduct=action.payload.dearUnitsShippedDrilldownTableByProduct
        }
      )
      .addCase(getDashboardDearAllOtherKPIs.fulfilled, (state, action) => {
        state.dearLastUpdated = action.payload.dearLastUpdated;
        state.dearPercentageCustomer80Sales =
          action.payload.dearPercentageCustomer80Sales;
        state.dearPercentageProduct80Sales =
          action.payload.dearPercentageProduct80Sales;
        state.dearPercentageOrderShippedLate =
          action.payload.dearPercentageOrderShippedLate;
          state.dearTopLateSuppliers=action.payload.dearTopLateSuppliers;
          state.dearCustomerWithoutTags=action.payload.dearCustomerWithoutTags
         
      })

      

  },
});
export default DearKPISlice.reducer;
export const {
  resetKPI,
  setDashboardOrganisation,
  updateDearSalesByBrandDateFilter,
  updateDearSalesByBrandComparisonFilter,
  updateDearSalesByProductComparisonFilter,
  updateDearSalesByProductDateFilter,
  updateDearSalesByCategoryDateFilter,
  updateDearSalesByCategoryComparisonFilter,
  updateDearSalesByCustomerComparisonFilter,
  updateDearSalesByCustomerDateFilter,
  updateDearSalesByProductTagComparisonFilter,
  updateDearSalesByProductTagDateFilter,
  updateDearSalesByProductTypeComparisonFilter,
  updateDearSalesByProductTypeDateFilter,
  updateDearSalesByCustomerTagComparisonFilter,
  updateDearSalesByCustomerTagDateFilter,
  updateDearProfitByCategoryComparisonFilter,
  updateDearProfitByCategoryDateFilter,
  updateDearProfitByProductComparisonFilter,
  updateDearProfitByProductDateFilter,
  updateDearProfitByBrandComparisonFilter,
  updateDearProfitByBrandDateFilter,
  updateDearProfitByCustomerTagComparisonFilter,
  updateDearProfitByCustomerTagDateFilter,
  updateDearProfitByCustomerComparisonFilter,
  updateDearProfitByCustomerDateFilter,
  updateDearProfitByProductTagComparisonFilter,
  updateDearProfitByProductTagDateFilter,
  updateDearProfitByProductTypeComparisonFilter,
  updateDearProfitByProductTypeDateFilter,
  updateDearUnitsSoldByBrandDateFilter,
  updateDearUnitsSoldByBrandComparisonFilter,
  updateDearUnitsSoldByProductDateFilter,
  updateDearUnitsSoldByProductComparisonFilter,
  updateDearUnitsSoldByCategoryDateFilter,
  updateDearUnitsSoldByCategoryComparisonFilter,
  updateDearUnitsSoldByCustomerDateFilter,
  updateDearUnitsSoldByCustomerComparisonFilter,
  updateDearUnitsSoldByProductTagDateFilter,
  updateDearUnitsSoldByProductTagComparisonFilter,
  updateDearUnitsSoldByProductTypeDateFilter,
  updateDearUnitsSoldByProductTypeComparisonFilter,
  updateDearUnitsSoldByCustomerTagDateFilter,
  updateDearUnitsSoldByCustomerTagComparisonFilter,
  updateDearUnitsShippedByBrandDateFilter,
  updateDearUnitsShippedByBrandComparisonFilter,
  updateDearUnitsShippedByProductDateFilter,
  updateDearUnitsShippedByProductComparisonFilter,
  updateDearUnitsShippedByCategoryDateFilter,
  updateDearUnitsShippedByCategoryComparisonFilter,
  updateDearUnitsShippedByCustomerDateFilter,
  updateDearUnitsShippedByCustomerComparisonFilter,
  updateDearUnitsShippedByProductTagDateFilter,
  updateDearUnitsShippedByProductTagComparisonFilter,
  updateDearUnitsShippedByProductTypeDateFilter,
  updateDearUnitsShippedByProductTypeComparisonFilter,
  updateDearUnitsShippedByCustomerTagDateFilter,
  updateDearUnitsShippedByCustomerTagComparisonFilter,
} = DearKPISlice.actions;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import mfaAuthService from "./mfaAuthService";
const initialState = {
  otp: {},
  enable2fa:{},
  otp_response: {},
  isSuccess: false,
  isSuccessEnable: false,
  isLoading: false,
  isError: false,
  message: "",
};

// Action Creators
export const createPhoneOTP = createAsyncThunk(
  "otp/create",
  async ({ otp_data, token }, thunkAPI) => {
    try {
      return await mfaAuthService.createOTP(otp_data, token);
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const validatePhoneOTP = createAsyncThunk(
  "otp/validate",
  async ({ otp_data, token }, thunkAPI) => {
    try {
      return await mfaAuthService.checkOTPValidity(otp_data, token);
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const enableMFAAuth = createAsyncThunk(
  "otp/enable",
  async ({ slug, otp_data, token }, thunkAPI) => {
    try {
      return await mfaAuthService.enableMFAAuth(slug, otp_data, token);
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const mfaAuthSlice = createSlice({
  name: "mfa",
  initialState,
  reducers: {
    resetMFA: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPhoneOTP.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createPhoneOTP.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.otp_response = action.payload;
      })
      .addCase(createPhoneOTP.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.otp_response = null;
      })
      .addCase(enableMFAAuth.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(enableMFAAuth.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccessEnable = true;
        state.enable2fa=action.payload
      })
      .addCase(enableMFAAuth.rejected, (state, action) => {
        state.isLoading = false;
        state.isErrorEnable = true;
        state.message = action.payload;
      });
  },
  },
);
export default mfaAuthSlice.reducer;
export const { resetMFA } = mfaAuthSlice.actions;

import React, { useEffect, useState } from "react";
import Sidebar from "../../../navigation";
import { BsQuestionCircle } from "react-icons/bs";
import Select from "react-select";
import logo from "../../../assets/logos/klogo.png";
import cin7 from "../../../assets/logos/cin7_logo.png";
import { Form, Formik, useFormik } from "formik";
import FormikControl from "../../components/form-elements/formik-control";
import XeroAppPage from "../app-integrations/xero";
import { NavLink, useNavigate } from "react-router-dom";
import Cin7AppPage from "../app-integrations/cin-7";
import ActiveUsersPage from "./active-users";
import UserProfilePage from "./user-profile";
import SubscriptionPage from "./subscription";
import { getAllOrganisations, resetOrganisationStorage, selectOrganisation } from "../../../redux-features/slices/organisation/orgSlice";
import { useDispatch, useSelector } from "react-redux";
import { dropdownIndicatorStyles } from "../../../styles/selectStyle";
import { resetAuth, signOutUser } from "../../../redux-features/slices/auth/authSlice";
import { ImSwitch } from "react-icons/im";
import SecurityMFAComponent from "./security-mfa";


function SettingsPage() {
    const [activeComponent, setActiveComponent] = useState("General");
    const [isIdle, setIsIdle] = useState(false);
 const dispatch = useDispatch();
 const navigate=useNavigate()
  const { user } = useSelector((state) => state.auth);
  const token = user?.data?.api_token;

  useEffect(() => {
    dispatch(getAllOrganisations({token}));
  }, []);
  const {
    organisations,
  
  } = useSelector((state) => state.organisation)
 
  const options = organisations?.map((item) => ({
    value: item.name,
    label: item.name,
  }));
const { organisation } = useSelector(
  (state) => state.organisation
);
useEffect(() => {
  const fetchOrganisations = async () => {
    if (token) {
      await dispatch(getAllOrganisations({ token }));
    }
  };

  fetchOrganisations();
}, []);
// For Select
const [defaultOrganisation, setDefaultOrganisation] = useState({});
const optionsOrg = organisations?.map((item) => ({
  value: item.name,
  label: item.name,
  slug: item.slug,
}));

useEffect(() => {
  if (organisation) {
    setDefaultOrganisation({
      value: organisation?.name,
      label: organisation?.name,
      slug: organisation?.slug,
      owner: organisation?.owner,
    });
  }
}, [organisation]);

const handleOrgChange = async (e) => {
  const slug = e.slug;
  dispatch(selectOrganisation({ slug, token }));
  setDefaultOrganisation(e);
};
  const onSubmit = async (values, actions) => {
    const user_data = {
      password: values.new_password,
    };
    if (user_data) {
    }
    await new Promise((resolve) => setTimeout(resolve, 1000));
    actions.resetForm();
  };

  const initialValues = {
    new_password: "",
    confirm_password: "",
  };
    const handleClick = (component) => {
      setActiveComponent(component);
    };

  const handleSignOut = async () => {
    dispatch(signOutUser());
    await dispatch(resetAuth());
    await dispatch(resetOrganisationStorage());
    await navigate("/signin");
    // await toast.success("You have successfully signed out!", {
    //   toastId: "toast2",
    //   autoClose: 3000,
    //   hideProgressBar: true,
    //   position: toast.POSITION.TOP_CENTER,
    // });
  };
  
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsIdle(true);
      handleSignOut(); // Sign out after 5 minutes of inactivity
    }, 1 * 20 * 1000); // 5 minutes in milliseconds

    // Reset timeout on user activity
    const resetTimeout = () => {
      setIsIdle(false);
      clearTimeout(timeout);
    };

    window.addEventListener("mousemove", resetTimeout);
    window.addEventListener("keydown", resetTimeout);

    return () => {
      window.removeEventListener("mousemove", resetTimeout);
      window.removeEventListener("keydown", resetTimeout);
      clearTimeout(timeout);
    };
  }, []);

  return (
 
      <div className="w-full h-full  bg-light-white overflow-y-scroll scrollbar-thin scrollbar-rounded-lg scrollbar-h-12 scrollbar-thumb-gray-400 scrollbar-track-gray-100 scroll-padding">
        <div
          className={` flex flex-col w-full h-full  justify-start items-start scroll-padding px-6 py-2 `}
        >
          <div className="flex w-full  items-end justify-between mt-4">
            <div>
              <div className="relative">
                <h2 className="text-xl font-medium ">Settings</h2>
              </div>
            </div>
            <div className="flex flex-row gap-6">
              <button
                onClick={handleSignOut}
                className=" flex flex-row items-center justify-center gap-4 py-2 px-4 bg-warning text-sm text-black font-bold  rounded"
              >
                Sign Out
              </button>
              <BsQuestionCircle className=" mt-1 text-purple" size={32} />
            </div>
          </div>

          <div className="flex bg-white mb-6 p-5 border-2 border-light-grey flex-col w-full h-full  mt-10 justify-center items-start ">
            <div className="flex flex-row text-grey border-b-2 font-medium border-b-grey w-full justify-between">
              <div className="space-x-4 text-sm font-regular">
                <button
                  className={`${
                    activeComponent === "General"
                      ? " border-b-2 border-0 border-b-purple font-semibold text-purple"
                      : "border-0"
                  }  px-4 py-2 `}
                  onClick={() => handleClick("General")}
                >
                  General
                </button>
                <button
                  className={`${
                    activeComponent === "Access"
                      ? " border-b-2 border-0 border-b-purple font-semibold text-purple"
                      : "border-0"
                  }  px-4 py-2 `}
                  onClick={() => handleClick("Access")}
                >
                  User Access
                </button>
                {/* <button
                  className={`${
                    activeComponent === "Subscription"
                      ? " border-b-2 border-0 border-b-purple font-semibold text-purple"
                      : "border-0  "
                  }  px-4 py-2 `}
                  onClick={() => handleClick("Subscription")}
                >
                  Subscription
                </button>
                <button
                  className={`${
                    activeComponent === "Security"
                      ? " border-b-2 border-0 border-b-purple font-semibold text-purple"
                      : "border-0  "
                  }  px-4 py-2 `}
                  onClick={() => handleClick("Security")}
                >
                  Security
                </button>
                <button
                  className={`${
                    activeComponent === "Notifications"
                      ? " border-b-2 border-0 border-b-purple font-semibold text-purple"
                      : "border-0  "
                  }  px-4 py-2 `}
                  onClick={() => handleClick("Notifications")}
                >
                  Notifications
                </button>
                <button
                  className={`${
                    activeComponent === "Other"
                      ? " border-b-2 border-0 border-b-purple font-semibold text-purple"
                      : "border-0  "
                  }  px-4 py-2 `}
                  onClick={() => handleClick("Other")}
                >
                  Other
                </button> */}
              </div>
            </div>

            <div className="w-full mt-6 h-full ">
              {activeComponent === "General" && <UserProfilePage user={user} />}
              {activeComponent === "Access" && (
                <ActiveUsersPage organisations={organisations} />
              )}

              {activeComponent === "Subscription" && <SubscriptionPage />}
              {activeComponent === "Security" && <SecurityMFAComponent />}
            </div>
          </div>
        </div>
      </div>
  );
}

export default SettingsPage;

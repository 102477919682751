import React, { useEffect, useState } from "react";
import Sidebar from "../../../../../navigation";
import OrganisationTable from "../../../../components/ui-elements/tables/organisation-table";
import { Link, useNavigate } from "react-router-dom";
import { BsQuestionCircle } from "react-icons/bs";
import xero from "../../../../../assets/logos/xero_logo.png";
import cin7 from "../../../../../assets/logos/cin7_logo.png";
import { useDispatch, useSelector } from "react-redux";
import { getAllConsolidateOrganisations } from "../../../../../redux-features/slices/consolidation/consolidationSlice";
import ConsolidateOrganisationsTable from "../../../../components/ui-elements/tables/consolidated-organisations-table";
import ConsolidateOrganisationModal from "../../../../components/ui-elements/modals/consolidate-organisations";
import { getAllOrganisations } from "../../../../../redux-features/slices/organisation/orgSlice";
function ConsolidateOrganisationsOwner() {
  const [toggleOrganisation, setOrganisationToggle] = useState(false);
  const [toggleConsolidateOrganisation, setConsolidateToggle] = useState(false);
  const toggleOrganisationModal = () => {
    setOrganisationToggle(!toggleOrganisation);
  };
  const toggleConsolidateOrganisationModal = () => {
    setConsolidateToggle(!toggleConsolidateOrganisation);
  };
    const [isDisplay, setIsDisplay] = useState(false);
    const { user } = useSelector((state) => state.auth);
    const { consolidatedOrganisations } = useSelector(
      (state) => state.consolidatedOrganisation
    );
    const { organisation, organisations, isLoadingAll, isErrorAll } =
      useSelector((state) => state.organisation);
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchOrganisations = async () => {
      if (token) {
        await dispatch(getAllConsolidateOrganisations(({token})));
        await dispatch(getAllOrganisations({ token }));
      }
    };

    fetchOrganisations();
  }, []);


  const token = user?.data?.api_token;
  useEffect(() => {
    if (!isLoadingAll) {
      setIsDisplay(true);
    } else {
      setIsDisplay(false);
    }
  }, [isLoadingAll]);
  const [open, setOpen] = useState(true);
  const connected_apps = [
    {
      name: "Xero",
      logo: xero,
      category: "Accounting",
      link: "/",
    },
    {
      name: "Cin7",
      logo: cin7,
      category: "Accounting",
      link: "/",
    },
  ];

  return (
    <div className="w-full bg-light-white overflow-y-scroll scrollbar-thin scrollbar-rounded-lg scrollbar-h-12 scrollbar-thumb-gray-400 scrollbar-track-gray-100 scroll-padding">
      <div
        className={` flex flex-col w-full  justify-start items-start scroll-padding  px-6 py-2 `}
      >
        <div className="flex w-full  items-start justify-between mt-4 ">
          <div>
            <h2 className="text-xl font-medium ">
              Consolidated Organisations Overview
            </h2>
          </div>
          <div className="flex  flex-row gap-6">
            <BsQuestionCircle className=" text-purple" size={32} />
          </div>
        </div>
        <div className="w-full h-full  p-6">
          <div className="flex w-full  items-end justify-between ">
            <div></div>
            <div className="flex  w-full flex-row mt-6 justify-end items-end ">
              {consolidatedOrganisations?.length ? (
                <>
                  <button
                    onClick={toggleConsolidateOrganisationModal}
                    className="bg-dark-purple hover:border-gray-900 hover:border-2 hover:text-gray-900 hover:bg-white text-white border-2 border-transparent  text-sm font-bold py-2 px-4 rounded "
                  >
                    + Add new organisation
                  </button>
                </>
              ) : (
                <>
                  <button
                    onClick={toggleOrganisationModal}
                    disabled
                    className={`
                         bg-grey
                     text-white text-sm font-bold py-2 px-4 border-2 border-transparent rounded mr-5`}
                  >
                    + Add new organisation
                  </button>
                </>
              )}
              <ConsolidateOrganisationModal
                token={token}
                toggle={toggleConsolidateOrganisation}
                toggleModal={toggleConsolidateOrganisationModal}
                organisations={organisations}
              />
             
            </div>
          </div>
          <div className="mt-6 w-full">
            <ConsolidateOrganisationsTable
              token={token}
              organisation={organisation}
              consolidatedOrganisations={consolidatedOrganisations}
              connected_apps={connected_apps}
              isErrorAll={isErrorAll}
              isLoadingAll={isLoadingAll}
              isDisplay={isDisplay}
            />
          </div>
          {isDisplay ? (
            <div className="flex w-full text-sm items-end justify-end mt-4 p-2">
              <div className=" gap-4 hover:text-primary hover:underline">
                <Link>Premium Subscription Activated</Link>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default ConsolidateOrganisationsOwner;

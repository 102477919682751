import React, { useEffect, useState } from "react";
import Sidebar from "../../../navigation";
import { BsQuestionCircle } from "react-icons/bs";
import Select from "react-select";
import xero from "../../../assets/logos/xero_logo.png";
import { useDispatch, useSelector } from "react-redux";
import {
  connectXero,
  resetIntegration,
  updateAppConnection,
} from "../../../redux-features/slices/integrations/integrationSlice";
import {
  getAllOrganisations,
  selectOrganisation,
} from "../../../redux-features/slices/organisation/orgSlice";
import Spinner from "../../components/ui-elements/utilis/spinner";
import { CONNECT_XERO, ENV_URL } from "../../../constant";
import { dropdownIndicatorStyles } from "../../../styles/selectStyle";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { Link } from "react-router-dom";
import cin7 from "../../../assets/logos/cin7_logo.png";

function DearSuccessPage() {
  const dispatch = useDispatch();
  const { organisations, organisation } = useSelector(
    (state) => state.organisation
  );
  const { user } = useSelector((state) => state.auth);
  const token = user?.data?.api_token;

  const [defaultOrganisation, setDefaultOrganisation] = useState({});
  useEffect(() => {
    if (organisation) {
      setDefaultOrganisation({
        value: organisation.name,
        label: organisation.name,
        slug: organisation.slug,
      });
    }
  }, [organisation]);
  useEffect(() => {
    dispatch(getAllOrganisations({ token }));
  }, []);

  useEffect(() => {
    const app_data = {
      app: "cin7",
      organisation: organisation?.name,
    };
    dispatch(updateAppConnection({ app_data, token }));
  }, []);

  const options = organisations?.map((item) => ({
    value: item.name,
    label: item.name,
    slug: item.slug,
  }));

  const handleOrgChange = async (e) => {
    const slug = e.slug;
    dispatch(selectOrganisation({ slug, token }));
    setDefaultOrganisation(e);
  };
  const handleBack = () => {
    dispatch(resetIntegration());
  };
  return (
   
      <div className="w-full bg-light-white overflow-y-scroll scrollbar-thin scrollbar-rounded-lg scrollbar-h-12 scrollbar-thumb-gray-400 scrollbar-track-gray-100 scroll-padding">
        <div
          className={` flex flex-col w-full  justify-start items-start scroll-padding px-16 py-2 `}
        >
          <div className="flex w-full  items-end justify-between mt-4">
            <div>
              <div className="relative">
                <h2 className="text-xl font-medium ">Connected Apps</h2>
              </div>
            </div>
            {/* <div className="flex flex-row gap-6">
              <div className="relative">
                <Select
                  styles={{
                    dropdownIndicator: dropdownIndicatorStyles,
                  }}
                  className="basic-single z-20 w-96 border-2 rounded-md border-purple"
                  classNamePrefix="select"
                  value={defaultOrganisation}
                  name="color"
                  options={options}
                  onChange={(e) => {
                    handleOrgChange(e);
                  }}
                />
              </div>
              <BsQuestionCircle className=" mt-1 text-purple" size={32} />
            </div> */}
          </div>
          <div className="flex flex-col mt-6 w-full gap-4">
            <div className="flex flex-col gap-4">
              <img src={cin7} alt="" className="w-16 h-16" />
              <h3 className="text-lg font-bold">
                Cin7 Core Connection for
                <span className="font-medium pl-2">
                  {defaultOrganisation ? (
                    <>{defaultOrganisation.value}</>
                  ) : (
                    <>{organisations[0]?.name}</>
                  )}
                </span>
              </h3>
            </div>
            <div className="flex flex-col w-full justify-center items-center mt-8 ">
              <div className="title flex flex-col gap-2 items-center px-12">
                <IoIosCheckmarkCircle size="120px" className="text-purple" />
                <h2 className="text-2xl font-bold">Connection to Cin7 Core</h2>
                <h5 className="text-default text-center">
                  Congratulations! You have successfully connected to Cin7 Core
                  integration.
                </h5>
              </div>

              <div className="mt-12 w-1/4  ">
                <Link to="/connected-apps/apps/dear/">
                  <button
                    className="rounded-md bg-light-purple font-bold text-[12px] w-full text-black py-3 px-4"
                    onClick={handleBack}
                  >
                    Go Back
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default DearSuccessPage;

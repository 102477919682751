import React, { useEffect, useState } from "react";
import { Form, Formik, useFormik } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import FormikControl from "../../../components/form-elements/formik-control";
import { SetNewPasswordSchema } from "../../../../validation-schemas";
import { RiLockPasswordFill } from "react-icons/ri";
import Spinner from "../../../components/ui-elements/utilis/spinner";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setNewPassword, resetAuth } from "../../../../redux-features/slices/auth/authSlice";
import { BsArrowDown } from "react-icons/bs";
import logo from "../../../../assets/logos/qurk_logo.png";
import icons from "../../../../assets/logos/group_icons_1.png";
import dashboard from "../../../../assets/images/auth/dashboard.png";
function SetNewPasswordScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token, encoded_pk } = useParams();
  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );
  useEffect(() => {
    if (isError) {
      toast.error(message, {
        autoClose: 10000,
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
      });
    }
    if (isSuccess && user) {
      // toast.success("You have successfully reset your password!", {
      //   toastId: "toast1",
      //   autoClose: 3000,
      //   hideProgressBar: true,
      //   position: toast.POSITION.TOP_CENTER,
      // });
       navigate("/signin")
    }
    dispatch(resetAuth());
  }, [user, isError, isSuccess, message, dispatch, navigate]);

  const onSubmit = async (values, actions) => {
    const user_data = {
      password: values.new_password,
      token,
      encoded_pk,
    };
    if (user_data) {
      dispatch(setNewPassword(user_data));
    }
    await new Promise((resolve) => setTimeout(resolve, 1000));
    actions.resetForm();
  };

  const initialValues = {
    new_password: "",
    confirm_password: "",
  };
  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div className="flex flex-col md:flex-row  md:min-h-screen h-screen w-screen bg-dark-purple  overflow-y-auto">
      <div
        className=" w-full  h-full lg:w-3/4"
        style={{
          background:
            "linear-gradient(0deg, rgba(32,32,53,1) 0%, rgba(46,28,90,1) 30%, rgba(91,72,135,1) 100%)",
        }}
      >
        <div className=" flex flex-col w-full justify-start items-center">
          <div className="w-full h-full pt-3">
            <div className="flex  justify-between pl-4 ">
              <img src={logo} alt="" className="w-12 h-12 " />
              <div className="  justify-self-end pt-4 pr-16  ">
                <Link to="/customer-support">
                  <h3 className="text-md font-regular text-white">Help</h3>
                </Link>
              </div>
            </div>
          </div>
          <div className="flex flex-col h-full mt-28 w-1/2 justify-center items-start rounded-md ">
            <div className="flex flex-row gap-2 items-center ">
              <div className="">
                {/* <RiAccountPinCircleFill size="70px" className="text-primary" /> */}
              </div>
              <div className=" flex flex-col text-white items-start ">
                <h2 className="text-lg font-extrabold">Set new password</h2>
                <h5 className="text-sm">
                  Please share your new password for account To recover your
                </h5>
              </div>
            </div>

            <div className="w-full h-full">
              <Formik
                className="set-new-password form "
                initialValues={initialValues}
                validationSchema={SetNewPasswordSchema}
                onSubmit={onSubmit}
              >
                {(formik) => (
                  <Form className="py-4 ">
                    <div className="flex flex-col items-start gap-5  mt-4 w-full ">
                      <FormikControl
                        control="input"
                        type="password"
                        label="New Password"
                        name="new_password"
                        placeholder="Enter new password"
                      />
                      <FormikControl
                        control="input"
                        type="password"
                        label="Confirm Password"
                        name="confirm_password"
                        placeholder="Confirm new password"
                      />
                    </div>
                    <div className="flex flex-col items justify-center w-full gap-6 mt-6">
                      <button
                        className="rounded-full bg-warning text-md font-bold text-dark-purple py-3 px-12"
                        type="submit"
                      >
                        Reset new password
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>

              <div className="flex flex-col items-center justify-center w-full  gap-2 ">
                <Link to="/customer-support">
                  <button className="rounded-md  text-sm text-white font-regular w-full  py-3 ">
                    Having any issues?{" "}
                    <span className="font-bold underline">
                      Customer Support
                    </span>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/2 hidden lg:block h-full overflow-hidden">
        <div className="flex flex-col w-full h-full justify-start items-center bg-light-bg rounded-md px-4 py-20">
          <h5 className="text-md text-center text-dark-purple font-semibold">
            Integrate, consolidate and validate your data and build reports in
            minutes
          </h5>
          <img src={icons} className="w- h-52 mt-8" alt="" />
          <BsArrowDown size={32} className="text-purple" />
          <img src={dashboard} className="w-full max-w-sm " alt="" />
        </div>
      </div>
    </div>
  );
}

export default SetNewPasswordScreen;

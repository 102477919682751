import { Formik } from 'formik';
import React from 'react'
import { Form, Link } from 'react-router-dom';
import FormikControl from '../../components/form-elements/formik-control';

function CashFlowAssumptionsComponent() {
      const onSubmit = async (values, actions) => {
        const user_data = {
          email: values.email,
        };
        // if (user_data) {
        //   dispatch(getNewPassword(user_data));
        // }
        await new Promise((resolve) => setTimeout(resolve, 1000));
        actions.resetForm();
      };

      const initialValues = {
        email: "",
      };
  return (
    <div>
      <div className="w-full h-full ">
        <div className="flex flex-col px-2 gap-1 mt-6">
          <p className="font-bold">Cashflow Assumptions</p>
          <p className="text-sm">
            Provide your assumptions before creating financial projections
          </p>
        </div>

        <div>
          <Formik
            className="get-new-password form "
            initialValues={initialValues}
            onSubmit={onSubmit}
          >
            {(formik) => (
              <Form className="py-4 px-2">
                <div className="flex flex-col items-start gap-5  mt-4 w-full ">
                  <div className="flex flex-row gap-3 w-full">
                    <FormikControl
                      control="input"
                      label="Business Name "
                      type="email"
                      name="email"
                      placeholder="Email Address"
                    />
                    <FormikControl
                      control="input"
                      label="Purchase on Credit (%) "
                      type="number"
                      name="poc"
                      placeholder="Purchase on Credit"
                    />
                    <FormikControl
                      control="input"
                      label="Sales on Credit (%) "
                      type="number"
                      name="soc"
                      placeholder="Sales on Credit"
                    />
                  </div>
                  <div className="flex flex-row gap-3 w-full">
                    <FormikControl
                      control="month"
                      label="Financial Year End "
                      type="text"
                      name="financial_year"
                      placeholder="Financial Year End"
                    />
                    <FormikControl
                      control="input"
                      label="Days to get Paid "
                      type="number"
                      name="days"
                      placeholder="Days to get Paid"
                    />
                    <FormikControl
                      control="input"
                      label="VAT Period"
                      type="number"
                      name="vat"
                      placeholder="VAT Period"
                    />
                    <FormikControl
                      control="month"
                      label="End of Next VAT Period "
                      type="text"
                      name="end_of_next_vat"
                      placeholder="End of Next VAT Period "
                    />
                  </div>
                  <div className="flex flex-row gap-3 w-full">
                    <FormikControl
                      control="month"
                      label="Month that Corporation Tax will be paid "
                      type="text"
                      name="tax_paid_month"
                      placeholder="Month that Corporation Tax will be paid"
                    />
                    <FormikControl
                      control="input"
                      label="Corporation Tax Liability (£) "
                      type="number"
                      name="corporation_tax"
                      placeholder="Corporation Tax Liability"
                    />
                  </div>
                </div>
                <div className="flex flex-col mt-6  w-full ">
                  <div>
                    <button
                      className="rounded bg-purple text-[12px] font-semibold text-white py-2 px-6"
                      type="submit"
                    >
                      Lock Assumptions
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        <div className="flex flex-col items-center justify-center w-full  gap-2 ">
          <Link to="/customer-support">
            <button className="rounded-md  text-sm text-white font-regular w-full  py-3 ">
              Having any issues?{" "}
              <span className="font-bold underline">Customer Support</span>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default CashFlowAssumptionsComponent
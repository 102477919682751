// Yup Validation Validation Schemas
import * as Yup from "yup";
export const SignUpSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, "First name is too short!")
    .required("First name is required"),
  last_name: Yup.string()
    .min(2, "Last name is too short!")
    .required("Last name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email address is required"),
  password: Yup.string().required("Password is required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), ""], "Password must match")
    .required("Password is required"),
  user_type: Yup.string().required("User type required"),
  terms: Yup.mixed().required("Please accept terms and conditions"),
});


export const SignInSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email address is required"),
  password: Yup.string().required("Password is required"),
});
export const GetNewPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email address is required")
});
export const SetNewPasswordSchema = Yup.object().shape({
  new_password: Yup.string().required("Required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("new_password"), ""], "Password must match")
    .required("Password is required"),
});
export const Cin7Schema = Yup.object().shape({
  // org: Yup.string().required("Please select organisation"),
  key: Yup.string()
  .length(32, "Key is invalid. Should be exactly 32 characters long!")
  .required("Key is required"),
  username: Yup.string().min(2,'Username is too short!').required("Username is required"),
});

// Create new organisation
export const CreateNewOrganisation = Yup.object().shape({
  name: Yup.string()
    .required('Organisation Name is required')
    .min(2, 'Organisation Name must be at least 2 characters')
    .max(50, 'Organisation Name cannot exceed 50 characters'),
  
  industry: Yup.string()
    .required('Industry selection is required'),
  
  role: Yup.string()
    .required('Role selection is required'),
  
  country: Yup.string()
    .required('Country selection is required'),
  
  currency: Yup.string()
    .required('Currency selection is required'),
  
  financial_year: Yup.string()
    .required('Financial Year is required')
});
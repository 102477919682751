import React, { useEffect, useState } from "react";
import Sidebar from "../../../navigation";
import { BsQuestionCircle } from "react-icons/bs";
import Select from "react-select";
import xero from "../../../assets/logos/xero_logo.png";
import xeroWhite from "../../../assets/logos/xero_logo_white.png";
import { useDispatch, useSelector } from "react-redux";
import { connectXero } from "../../../redux-features/slices/integrations/integrationSlice";
import { getAllOrganisations, selectOrganisation } from "../../../redux-features/slices/organisation/orgSlice";
import Spinner from "../../components/ui-elements/utilis/spinner";
import { CONNECT_XERO, ENV_URL } from "../../../constant";
import { dropdownIndicatorStyles } from "../../../styles/selectStyle";
import { Link } from "react-router-dom";
import initial from '../../../assets/images/xero/intial.png'
import step1 from "../../../assets/images/xero/step1.png";
import step2 from "../../../assets/images/xero/step2.png";
// import step3 from "../../../assets/images/xero/step3.png";
import step3 from "../../../assets/images/xero/step32.jpg";
import step4 from "../../../assets/images/xero/step4.jpg";

function XeroAppPage() {
  const dispatch = useDispatch();
  const { organisations, organisation } = useSelector(
    (state) => state.organisation
  );
  const { user } = useSelector((state) => state.auth);
  const token = user?.data?.api_token;

const [defaultOrganisation, setDefaultOrganisation] = useState({});
useEffect(() => {
  if (organisation) {
    setDefaultOrganisation({
      value: organisation?.name,
      label: organisation?.name,
      slug: organisation?.slug,
    });
  }
}, [organisation]);
   
  useEffect(() => {
    dispatch(getAllOrganisations({token}));
  }, []);

 const options = organisations?.map((item) => ({
   value: item.name,
   label: item.name,
   slug: item.slug,
 }));
  
const handleOrgChange =async (e) => {
  const slug = e.slug;
  dispatch(selectOrganisation({ slug,token }));
  setDefaultOrganisation(e)
};
const handleXeroConnect=()=>{
  const slug=organisation?.slug
  dispatch(connectXero({slug, token}))
}
  return (
    <div className="w-full bg-light-white overflow-y-scroll scrollbar-thin scrollbar-rounded-lg scrollbar-h-12 scrollbar-thumb-gray-400 scrollbar-track-gray-100 scroll-padding">
      <div
        className={` flex flex-col w-full  justify-start items-start scroll-padding px-16 py-2 `}
      >
        <div className="flex w-full  items-end justify-between mt-4">
          <div>
            <div className="relative">
              <h2 className="text-xl font-medium ">Connected Apps</h2>
            </div>
          </div>
          {/* <div className="flex flex-row gap-6">
            <div className="relative">
              <Select
                styles={{
                  dropdownIndicator: dropdownIndicatorStyles,
                }}
                className="basic-single z-20 w-96 border-2 rounded-md border-purple"
                classNamePrefix="select"
                value={defaultOrganisation}
                name="color"
                options={options}
                onChange={(e) => {
                  handleOrgChange(e);
                }}
              />
            </div>
            <BsQuestionCircle className=" mt-1 text-purple" size={32} />
          </div> */}
        </div>
        <div className="flex flex-col mt-6 w-full gap-4">
          <div className="flex flex-col gap-4">
            <img src={xero} alt="" className="w-16 h-16" />
            <h3 className="text-lg font-bold">
              Xero Connection for
              <span className="font-medium pl-2">
                {defaultOrganisation ? (
                  <>{defaultOrganisation.value}</>
                ) : (
                  <>{organisations[0]?.name}</>
                )}
              </span>
            </h3>
            <p>
              Follow the instructions to add your Xero data to your dashboard
            </p>
          </div>
          <div className="flex space-x-20 w-full text-[14px]">
            <div className="flex w-2/5 flex-col">
              <p>1. Click the Connect to handshake with Xero</p>
              {organisation && (
                <a
                  disabled
                  class="flex mt-6 ml-2 justify-center items-center gap-2 border-2 border-light-grey bg-xero-text text-md text-xero-bg font-medium w-56   rounded"
                  href={`${ENV_URL}${CONNECT_XERO}${organisation?.slug}/`}
                >
                  <img src={xeroWhite} alt="" className="w-14 h-14" />
                  Connect to Xero
                </a>
              )}
            </div>
            {/* <div className="flex flex-col">
              <p>2. Enter email and password of Qurk account.</p>
              <img src={step1} alt="" className="w-full h-48" />
            </div> */}
            <div className="flex flex-col space-y-6">
              <p>2. Proceed to enter Xero username and password.</p>
              <img src={step3} alt="" className="rounded" />
            </div>
          </div>
          <div className="flex flex-col space-y-6 text-[14px]">
              <p>3.  Select the relevant organisation. <b>NOTE : </b>Your Xero Organisation name and Qurk Organisation name  must be the same, otherwise the connection won't work</p>
              <img src={step4} alt="" className="w-[400px] rounded" />
            </div>
          <div className=" w-full flex items-end justify-end my-4">
            {Object.keys(organisation)?.length === 0 ? (
              <>
                <a
                  class="bg-warning text-[12px] opacity-75 text-black font-bold w-28 p-2 px-8 rounded"
                  href="#"
                  disabled
                >
                  Connect
                </a>
              </>
            ) : (
              <>
                {/* {organisation && (
                  <a
                    disabled
                    class=" flex justify-center items-center gap-2 border-2 border-light-grey bg-xero-text text-md text-xero-bg font-medium w-56 p-2  rounded"
                    href={`${ENV_URL}${CONNECT_XERO}${organisation?.slug}/`}
                  >
                    <img src={xero} alt="" className="w-8 h-8" />
                    Connect to Xero
                  </a>
                )} */}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default XeroAppPage;

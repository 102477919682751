import React from "react";
import SpinnerKPI from "../../../utilis/spinner-kpi";

const TotalBankTable = ({ data, isLoading, currencySymbol }) => {
  return (
    <div className="rounded-md border border-gray-300">
      <table className="border-separate border-spacing-0 w-full text-xs text-center">
        <thead className="bg-gray-200 sticky top-0 z-10">
          <tr>
            <th className="border border-gray-300 px-4 py-2">Account</th>
            <th className="border border-gray-300 px-4 py-2">Total Bank Value</th>
          </tr>
        </thead>
        <tbody className="text-gray-600">
          {isLoading ? (
            <tr>
              <td className="border border-gray-300 text-center px-4 py-2" colSpan="2">
                <div className="flex items-center justify-center h-full w-full">
                  <SpinnerKPI />
                </div>
              </td>
            </tr>
          ) : (
            <>
              {data && data.length > 0 ? (
                data.map((row, index) => (
                  <tr key={index}>
                    <td className="border border-gray-300 px-4 py-2">{row.account}</td>
                    <td className="border border-gray-300 px-4 py-2">
                      {currencySymbol}{row.totalValue.toFixed(2)}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="border border-gray-300 px-4 py-2" colSpan="2">
                    No data available
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TotalBankTable;

import React from "react";
import Countdown from "react-countdown";
import { FaEnvelope, FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";
const ComingSoonPage = (props) => {
  const {isForecasting}=props
  // Countdown Timer
  const launchDate = new Date("2023-03-01");
  const launchDate2 = new Date("2024-02-01");
  // Email Subscription Form
  const handleSubscribe = (e) => {
    e.preventDefault();
    // Implement your subscribe logic here
  };

  return (
    <div className="flex flex-col items-center justify-center gap-6 h-full w-full ">
      {/* Countdown Timer */}
      
      <div className="flex flex-col mt-auto justify-center items-center">
        <div className="mt-8 text-[72px]  text-purple font-light">
          <Countdown date={`${isForecasting ? launchDate2:launchDate}`} />
        </div>
        <h1 className="text-4xl font-medium mb-4 tracking-widest">
          COMING SOON
        </h1>
        <p className="text-lg text-gray-600">
          We're working on something amazing. Stay tuned!
        </p>
      </div>

      {/* Email Subscription Form */}

      <button
        type="submit"
        className="bg-dark-purple text-white py-2 px-8 rounded-md  hover:bg-white hover:border-2 hover:border-dark-purple hover:text-dark-purple transition-colors"
        onClick={handleSubscribe}
      >
        Subscribe
      </button>

      <div className="mt-auto mb-6 flex flex-col items-center justify-center">
        {/* Contact Information */}
        <p className="mt-8 justify-end text-gray-600">
          For inquiries, please email us at{" "}
          <a
            href="https://qurk.app/"
            className="text-purple font-medium hover:underline"
          >
            qurk.com
          </a>
        </p>

        {/* Social Media Icons */}
        <div className="flex mt-2 gap-4">
          <a
            href="https://www.facebook.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook className="text-2xl text-purple hover:text-warning transition-colors" />
          </a>
          <a
            href="https://www.twitter.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter className="text-2xl text-purple hover:text-warning transition-colors" />
          </a>
          <a
            href="https://www.instagram.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram className="text-2xl text-purple hover:text-warning transition-colors" />
          </a>
          <a href="mailto:info@example.com">
            <FaEnvelope className="text-2xl text-purple hover:text-warning transition-colors" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ComingSoonPage;

import React, { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { faker } from "@faker-js/faker";
import KPIDrillDownModal from "../../ui-elements/modals/kpis-modals/drill-down-modal";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const monthMap = {
  '01': 'Jan',
  '02': 'Feb',
  '03': 'Mar',
  '04': 'Apr',
  '05': 'May',
  '06': 'Jun',
  '07': 'Jul',
  '08': 'Aug',
  '09': 'Sep',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec'
};

function LineChartDear(props) {
  const { currencySymbol, companyData, company, title } = props;
  const options = {
    maintainAspectRatio: false,
    aspectRatio: 2, // You can adjust this value based on your preference
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: `${title} ${company?.name}`,
      },
      tooltip: {
        mode: 'index',
        callbacks: {
          label: function (tooltipItem) {
            const datasetLabel = tooltipItem.dataset.label || '';
            const value = tooltipItem.raw.toFixed(2);
            const formattedValue = new Intl.NumberFormat('en-US', {
              style: 'decimal',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }).format(value);
            return ` ${datasetLabel}:  ${currencySymbol}${formattedValue}`;
          }
        }
      }
    },
    scales: {
      x: {
        display: true,
        grid: {
          display: false,
        },
        type: 'category',
        ticks: {
          callback: function (value, index, values) {
            const label = this.getLabelForValue(value)
            const month = label.split('-')[1]
            return monthMap[month] || month
          }
        }
      },
      y: {
        display: true,
        grid: {
          display: false,
        },
        // ticks: {
        //   callback: function (value) {
        //     if (value >= 1e9) {
        //       return (value / 1e9) + 'B'; // Billion
        //     } else if (value >= 1e6) {
        //       return (value / 1e6) + 'M'; // Million
        //     } else if (value >= 1e3) {
        //       return (value / 1e3) + 'K'; // Thousand
        //     }
        //     return value; // Less than thousand
        //   }
        // }
        ticks: {
          callback: function (value) {
            const isNegative = value < 0;
            const absValue = Math.abs(value);

            let formattedValue;

            if (absValue >= 1e9) {
              formattedValue = (absValue / 1e9) + 'B'; // Billion
            } else if (absValue >= 1e6) {
              formattedValue = (absValue / 1e6) + 'M'; // Million
            } else if (absValue >= 1e3) {
              formattedValue = (absValue / 1e3) + 'K'; // Thousand
            } else {
              formattedValue = absValue; // Less than thousand
            }

            return isNegative ? `-${formattedValue}` : formattedValue;
          }
        }
      },
    },
  };

  const chartContainerStyle = {
    minHeight: "300px", // Adjust this value as needed
  };
  const [toggleKPIDrillDownModal, setKPIDrillDownModal] = useState(false);
  const toggleDrillDownModalSheet = (title) => {
    setKPIDrillDownModal(!toggleKPIDrillDownModal);
  };
  return (
    <div
      className="mt-5 p-2 border rounded-lg border-light-grey bg-white"
      style={chartContainerStyle}
    >

      <Line options={options} data={companyData} />
    </div>
  );
}

export default LineChartDear;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import lookerService from "./lookerService";
// Initial State of Auth Reducer
const initialState = {
  lookerURL: {},
  appType:'', 
  embedURL:'',
  isSuccessLooker: false,
  isLoadingLooker: false,
  isErrorLooker: false,
  message: "",
};

// const clearCookiesForDomain = (domain) => {
//   return new Promise((resolve) => {
//     const cookies = document.cookie.split(";");
//         console.log("Cookies ", cookies);

//     cookies.forEach((cookie) => {
//       const cookieParts = cookie.split("=");
//       const cookieName = cookieParts[0].trim();

//       // Check if the cookie domain matches the target domain
//       const cookieDomain = cookieParts[1].trim().split(";")[0];
//       if (cookieDomain === domain) {
//         // Set the cookie to expire in the past to remove it
//         console.log('Cookies of kounteq',cookieName);
//         document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${cookieDomain}`;
//       }
//     });

//     // Resolve the promise after cookies are cleared
//     resolve();
//   });
// };
// const removeAllCookies = () => {
//   const cookies = document.cookie.split(";");

//   console.log("Removing all cookies for the current domain.");

//   cookies.forEach((cookie) => {
//     const cookieParts = cookie.split("=");
//     const cookieName = cookieParts[0].trim();

//     // To remove a cookie, set its expiration to a past date
//     document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
//     console.log("Removed cookie:", cookieName);
//   });
// };

export function setEmbedURLFunction(state, action) {
  const { embedURL, appType } = action.payload;
  state.isSuccessLooker = true;
  state.embedURL = embedURL;
  state.appType = appType;
}

// Action Creators
export const embedLooker = createAsyncThunk(
  "looker/dashboard",
  async ({ slug, token }, thunkAPI) => {
    try {
      // Log cookies for the specific domain
      // removeAllCookies()
      // clearCookiesForDomain('kounteq.cloud.looker.com')

      // Now make the API call to embedLooker
      return await lookerService.embedLooker(slug, token);
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);


export const lookerSlice = createSlice({
  name: "looker",
  initialState,
  reducers: {
    resetLooker: (state) => {
      state.isLoadingLooker = false;
      state.isSuccessLooker = false;
      state.isErrorLooker = false;
      state.message = ""
    },
    resetEmbed: (state) => {
            state.lookerURL = null;
    },
    setEmbedURL: setEmbedURLFunction,

  },
  extraReducers: (builder) => {
    builder
    // For Xero
      .addCase(embedLooker.pending, (state) => {
        state.isLoadingLooker = true;
        state.isSuccessLooker = false;
        state.lookerURL = '';

      })
      .addCase(embedLooker.fulfilled, (state, action) => {
        state.isLoadingLooker = false
        state.isSuccessLooker = true
        state.lookerURL = action.payload
        state.embedURL=action.payload.embed

      })
      .addCase(embedLooker.rejected, (state, action) => {
        state.isLoadingLooker = false;
        state.isErrorLooker = true;
        state.message = action.payload;
        state.lookerURL = action.payload;
        
      })
  },
});
export default lookerSlice.reducer;
export const { resetLooker, setEmbedURL, resetEmbed } = lookerSlice.actions;

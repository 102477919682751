import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import consolidateOrgService from "./consolidationService";
const organisation = JSON.parse(localStorage.getItem("organisation"));
const isOrganisationNull =
  organisation === null || typeof organisation === "undefined";

const initialState = {
  consolidatedOrganisations: [],
  consolidateOrganisation:{},
  isSuccess: false,
  isSuccessEdit: false,
  isLoading: false,
  isLoadingAll: false,
  isError: false,
  isErrorAll: false,
  message: "",
};

// Action Creators
export const getAllConsolidateOrganisations = createAsyncThunk(
  "org/consolidated/get/all",
  async ({ token }, thunkAPI) => {
    try {
      return await consolidateOrgService.getAllConsolidateOrganisations(token);
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const createConsolidateOrganisation = createAsyncThunk(
  "org/consolidated/create",
  async ({ org_data, token }, thunkAPI) => {
    try {
      return await consolidateOrgService.createConsolidateOrganisation(org_data, token);
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const consolidationOrgSlice = createSlice({
  name: "consolidatedOrganisation",
  initialState,
  reducers: {
    resetOrg: (state) => {
      state.isLoading = false;
      state.isLoadingAll = false;
      state.isSuccess = false;
      state.isSuccessEdit = false;
      state.isErrorAll = false;
      state.isError = false;
      state.message = "";
    },
    resetOrganisationStorage: (state) => {
      state.organisation = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllConsolidateOrganisations.pending, (state) => {
        state.isLoadingAll = true;
      })
      .addCase(getAllConsolidateOrganisations.fulfilled, (state, action) => {
        state.isLoadingAll = false;
        state.consolidatedOrganisations = action.payload;
      })
      .addCase(getAllConsolidateOrganisations.rejected, (state, action) => {
        state.isLoadingAll = false;
        state.isErrorAll = true;
        state.message = action.payload;
        state.consolidatedOrganisations = null;
      })
      //   Create Organisation
      .addCase(createConsolidateOrganisation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createConsolidateOrganisation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.consolidateOrganisation = action.payload;
      })
      .addCase(createConsolidateOrganisation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.consolidateOrganisation = null;
      })
      
     
  },
});
export default consolidationOrgSlice.reducer;
export const { resetOrg, resetOrganisationStorage } = consolidationOrgSlice.actions;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DashboardBanner from "../../../components/ui-elements/banner/dashbaord-banner";
import KPISheetModal from "../../../components/ui-elements/modals/kpis-modals/kpi-sheet-modal";
import StackedBarChart from "../../../components/graphs/react-chartjs-two/stacked-bar-chart";
import GraphData from "./graphs";
import BoxCard02 from "../../../components/ui-elements/cards/box-card-02";
import HorizontalBarChart from "../../../components/graphs/react-chartjs-two/horizontal-bar-chart-dear";
import { Link } from "react-router-dom";
import LineChartDear from "../../../components/graphs/react-chartjs-two/line-chart-dear";
import SpinnerKPI from "../../../components/ui-elements/utilis/spinner-kpi";
import BoxCard02WithFilter from "../../../components/ui-elements/cards/box-card-02-with-custom-filter";
import CategoryComponent from "./components/category-component";

function DashboardHomePage(props) {
  const {
    xeroKPIFigures,
    dearProductSalesCategory,
    dearGrossProfitMarginCategory,
    dearInventoryValueCategory,
    isLoading,
    dearProductSalesCategoryLoading,
    dearInventoryValueCategoryLoading,
    dearGrossProfitMarginCategoryLoading,
  } = useSelector((state) => state.kpi);

  const { organisation } = useSelector((state) => state.organisation);
  const { user } = useSelector((state) => state.auth);
 

  const extractFilters = (kpiTitle) => {
    const savedFilters =
      organisation && organisation.saved_filters && organisation.saved_filters[organisation.name];
    const filter = savedFilters?.find((filter) => filter.kpi === kpiTitle);
    if (filter) {
      return {
        firstFilter: filter.first_filter,
        comparedTo: filter.compared_to,
      };
    }
    return { firstFilter: null, comparedTo: null };
  };
  
  const productSalesFilters = extractFilters("Product Sales");
  const grossProfitMarginFilters = extractFilters("Gross Profit Margin");
  const inventoryValueFilters = extractFilters("Inventory Value");

  // KPI Sheet

  const [toggleKPI, setKPISheetToggle] = useState(false);
  const [kpiSheetTitle, setKPISheetTitle] = useState(""); // Added state to store KPI sheet title

  const toggleKPISheet = (title) => {
    setKPISheetTitle(title); // Set the title in the state
    setKPISheetToggle(!toggleKPI);
  };

  // Advanced Filters
  const [isAdvancedDearFilters, setIsAdvancedDearFilters] = useState(false);
  const [openKPIAdvancedFilters, setOpenKPIAdvancedFilters] = useState({
    "Product Sales": false,
    Profit: false,
    "Units Sold": false,
    "% of Customers Who Make Up 80% of Sales": false,
    '% of Products That Make Up 80% of Sales': false,
  });

  // Function to toggle advanced filters for a specific BoxCard02 component
  const toggleAdvancedFilters = (title) => {
    setOpenKPIAdvancedFilters((prevState) => ({
      ...prevState,
      [title]: !prevState[title],
    }));
  };
  const categories = [
    "Superior",
    "Superior Deluxe Panels",
    "Ultimate Panels",
    "Aluminium Posts",
    "Concrete Posts",
    "Composite Gates",
    "Lights",
    "Pergolas",
    "3D Wall Panels",
    "Composite Posts",
    "Aluminium Gates",
    "Screens",
  ];
  
  const products = [
    [
      "Chestnut Fence Boards",
      "Chestnut Fence Boards - 2200mm",
      "Chestnut Fence Boards - 2400mm",
      "Chestnut Fence Boards - 2500mm",
      "Irish Oak Fence Boards",
      "Irish Oak Fence Boards - 2.8m",
      "Irish Oak Fence Boards - 2200mm",
      "Irish Oak Fence Boards - 2500mm",
      "Irish Oak Fence Boards 2.4m",
      "Pebble Grey Fence Boards",
      "Pebble Grey Fence Boards - 2200mm",
      "Pebble Grey Fence Boards - 2500mm",
      "Pebble Grey Woodgrain",
      "Pebble Grey Woodgrain Fence Boards - 2200mm",
      "Pebble Grey Woodgrain Fence Boards - 2500mm",
      "Sanded Chestnut Fence Boards",
      "Slate Grey (Sanded) Fence Boards",
      "Slate Grey Fence Boards - 2200mm",
      "Slate Grey Fence Boards - 2500mm",
      "Slate Grey Fence Boards - 3000mm",
      "Slate Grey Woodgrain",
      "Slate Grey Woodgrain Board 2.75m",
      "Slate Grey Woodgrain Fence Boards - 2200mm",
      "Slate Grey Woodgrain Fence Boards - 2500mm",
      "Walnut Fence Boards",
      "Walnut Fence Boards - 2200mm",
      "Walnut Fence Boards - 2500mm"
    ],
    [
      "Black Deluxe Fence Board",
      "Chestnut Deluxe Deluxe Fence Board",
      "Irish Oak Deluxe Fence Board",
      "Pebble Grey Deluxe Fence Board",
      "Pebble Grey Woodgrain Deluxe Fence Board",
      "Slate Grey (Sanded) Deluxe Fence Board",
      "Slate Grey Woodgrain Deluxe Fence Board",
      "Slate Grey Woodgrain Deluxe Fence Board - 2500mm",
      "Walnut Deluxe Fence Board"
    ],
    
    [
      "Evergreen Fence Board",
      "Evergreen Woodgrain Fence Board",
      "Wpc Fence Board (Charcoal Woodgrain)",
      "Wpc Fence Board (Charcoal)",
      "Wpc Fence Board (Charcoal) Sanded",
      "Wpc Fence Board (Dark Oak)",
      "Wpc Fence Board (SANDED Dark Oak)",
      "Wpc Fence Board (Stone)"
    ],
    [
      "Superior - 1.9M Aluminium Post Kit - Graphite",
      "Superior - 2.4M Aluminium End Post Kit - Graphite",
      "Superior - 2.7M Aluminium End Post Kit - Graphite",
      "Ultimate Package 3.5m Dark Grey Post",
      "Ultimate Package 3C - End Post Grey",
      "Ultimate Package 3m Dark Grey Post"
    ],    
    ["Concrete Channel Insert (6ft)"],
    [
      "Composite Pedestrian Gate - Graphite & Chestnut - 900mm x 1800mm",
      "Composite Pedestrian Gate - Graphite & Irish Oak - 900mm x 1800mm",
      "Composite Pedestrian Gate - Graphite & Pebble Grey Sanded - 900mm x 1800mm",
      "Composite Pedestrian Gate - Graphite & Pebble Grey Woodgrain - 900mm x 1800mm",
      "Composite Pedestrian Gate - Graphite & Slate Grey Sanded - 900mm x 1800mm",
      "Composite Pedestrian Gate - Graphite & Slate Grey Woodgrain - 900mm x 1800mm",
      "Composite Pedestrian Gate - Graphite & Walnut - 900mm x 1800mm",
      "Composite Pedestrian Gate Frame - 900mm x 1200mm - Graphite",
      "Composite Pedestrian Gate Frame - 900mm x 1200mm - RAL 7016",
      "Composite Pedestrian Gate Frame - 900mm x 2000mm - Graphite",
      "Composite Pedestrian Split Gate Frame - 900mm x 1800mm - Graphite"
    ],    
    [
      "Led - Vertical Solar Strip Light",
      "Led Post Cap",
      "Led Strip light c/w solar post cap",
      "Superior - Led Post Lights",
      "Ultimate Post Light"
    ],
    [
      "Pergola - 3m Electric Screen",
      "Pergola - 3m Glass Doors",
      "Pergola - 4m Electric Screen",
      "Pergola - 4m Glass Doors",
      "Pergola - LED Strip Light",
      "Pergola Structure - 3m x 2m",
      "Pergola Structure - 3m x 3m",
      "Pergola Structure - 3m x 4m"
    ],    
    [
      "3D Wall Sample - Platinum, Flint, Driftwood & Rosewood",
      "Driftwood Wall - 2.4m 3D Panel",
      "Flint Wall - 2.4m 3D Panel",
      "Platinum Wall - 2.4m 3D Panel",
      "Rosewood Wall - 2.4m 3D Panel"
    ],
    [
      "Superior - 1.9M Composite Corner Post - Slate Grey",
      "Superior - 1.9M Composite Corner Post - Walnut",
      "Superior - 1.9M Composite Mid Post - Slate Grey",
      "Superior - 1.9M Composite Mid Post - Walnut"
    ]
    ,
    [
      "Aluminium Pedestrian - RAL 7016 - Horizontal",
      "Aluminium Pedestrian - RAL 7016 - Vertical",
      "Horizontal Aluminium Pedestrian Gate Anthracite - 1000mm x 1800mm",
      "100mm W, 3m Long Anthracite Pedestrian Gate Post",
      "2.4M Driveway Gate Post 100mm x 100mm",
      "2.4m Driveway Gate Post 150mm x 150mm",
      "3m Driveway Gate Post 150mm x 150mm",
      "50mm x 80mm 3m Long Pedestrian Gate Post",
      "Horizontal Anthracite 1000mm x 1600mm Gate",
      "Horizontal Anthracite 1000mm x 1800mm Gate",
      "Horizontal Anthracite 1000mm x 2000mm Gate",
      "Horizontal Anthracite 1000mm x 2200mm Gate",
      "Horizontal Anthracite 1200mm x 1600mm Gate",
      "Horizontal Anthracite 1200mm x 1800mm Gate",
      "Horizontal Anthracite 1200mm x 2000mm Gate",
      "Horizontal Anthracite 1200mm x 2200mm Gate",
      "Horizontal Anthracite 3000mm x 1600mm Driveway Gate",
      "Horizontal Anthracite 3000mm x 1800mm Driveway Gate",
      "Horizontal Anthracite 3000mm x 2000mm Driveway Gate",
      "Horizontal Anthracite 3250mm x 1600mm Driveway Gate",
      "Horizontal Anthracite 3250mm x 1800mm Driveway Gate",
      "Horizontal Anthracite 3250mm x 2000mm Driveway Gate",
      "Horizontal Anthracite 3500mm x 1600mm Driveway Gate",
      "Horizontal Anthracite 3500mm x 1800mm Driveway Gate",
      "Horizontal Anthracite 3500mm x 2000mm Driveway Gate",
      "Horizontal Anthracite 3750mm x 1600mm Driveway Gate",
      "Horizontal Anthracite 3750mm x 1800mm Driveway Gate",
      "Horizontal Anthracite 3750mm x 2000mm Driveway Gate",
      "Horizontal Anthracite 3750mm x 2200mm Driveway Gate",
      "Horizontal Anthracite 4000mm x 1600mm Driveway Gate",
      "Horizontal Anthracite 4000mm x 1800mm Driveway Gate",
      "Horizontal Anthracite 4000mm x 2000mm Driveway Gate",
      "Horizontal Anthracite 4000mm x 2200mm Driveway Gate",
      "Horizontal Anthracite 900mm x 1600mm Gate",
      "Horizontal Anthracite 900mm x 1800mm Gate",
      "Horizontal Anthracite 900mm x 2000mm Gate",
      "Horizontal Anthracite 900mm x 2200mm Gate",
      "Horizontal Black 1000mm x 1600mm Black",
      "Horizontal Black 1000mm x 1800mm Gate",
      "Horizontal Black 1000mm x 2000mm Gate",
      "Horizontal Black 1000mm x 2200mm Gate",
      "Horizontal Black 1200mm x 1600mm Gate",
      "Horizontal Black 1200mm x 1800mm Gate",
      "Horizontal Black 1200mm x 2000mm Gate",
      "Horizontal Black 1200mm x 2200mm Gate",
      "Horizontal Black 3000mm x 1800mm Driveway Gate",
      "Horizontal Black 3250mm x 1600mm Driveway Gate",
      "Horizontal Black 3250mm x 1800mm Driveway Gate",
      "Horizontal Black 3250mm x 2000mm Driveway Gate",
      "Horizontal Black 3500mm x 1600mm Driveway Gate",
      "Horizontal Black 3500mm x 1800mm Driveway Gate",
      "Horizontal Black 3500mm x 2000mm Driveway Gate",
      "Horizontal Black 3750mm x 1600mm Driveway Gate",
      "Horizontal Black 3750mm x 1800mm Driveway Gate",
      "Horizontal Black 3750mm x 2000mm Driveway Gate",
      "Horizontal Black 3750mm x 2200mm Driveway Gate",
      "Horizontal Black 4000mm x 1600mm Driveway Gate",
      "Horizontal Black 4000mm x 1800mm Driveway Gate",
      "Horizontal Black 4000mm x 2000mm Driveway Gate",
      "Horizontal Black 4000mm x 2200mm Driveway Gate",
      "Horizontal Black 900mm x 1600mm Gate",
      "Horizontal Black 900mm x 1800mm Gate",
      "Horizontal Black 900mm x 2000mm Gate",
      "Horizontal Black 900mm x 2200mm Gate",
      "Horizontal Driveway Gate Black 3000mm x 1600mm",
      "Horizontal Graphite 900mm x 1800mm Gate",
      "Pedestrian Gate 2.4m Anthracite Post",
      "Pedestrian Gates Keypad Lock",
      "Vertical Anthracite 1000mm x 1600mm Gate",
      "Vertical Anthracite 1000mm x 1800mm Gate",
      "Vertical Anthracite 1000mm x 2000mm Gate",
      "Vertical Anthracite 1000mm x 2200mm Gate",
      "Vertical Anthracite 1100mm x 1800mm Gate",
      "Vertical Anthracite 1100mm x 2000mm Gate",
      "Vertical Anthracite 1100mm x 2200mm Gate",
      "Vertical Anthracite 1200mm x 1600mm Gate",
      "Vertical Anthracite 1200mm x 1800mm Gate",
      "Vertical Anthracite 1200mm x 2000mm Gate",
      "Vertical Anthracite 1200mm x 2200mm Gate",
      "Vertical Anthracite 900mm x 1600mm Gate",
      "Vertical Anthracite 900mm x 1800mm Gate",
      "Vertical Anthracite 900mm x 2000mm Gate",
      "Vertical Anthracite 900mm x 2200mm Gate",
      "Vertical Anthracite Pedestrian Gate 1100mm x 1600mm",
      "Vertical Black 1000mm x 1600mm Gate",
      "Vertical Black 1000mm x 1800mm Gate",
      "Vertical Black 1000mm x 2000mm Gate",
      "Vertical Black 1000mm x 2200mm Gate",
      "Vertical Black 1100mm x 1600mm Gate",
      "Vertical Black 1100mm x 1800mm Gate",
      "Vertical Black 1100mm x 2000mm Gate",
      "Vertical Black 1100mm x 2200mm Gate",
      "Vertical Black 1200mm x 1600mm Gate",
      "Vertical Black 1200mm x 1800mm Gate",
      "Vertical Black 1200mm x 2000mm Gate",
      "Vertical Black 1200mm x 2200mm Gate",
      "Vertical Black 900mm x 1600mm Gate",
      "Vertical Black 900mm x 1800mm Gate",
      "Vertical Black 900mm x 2000mm Gate",
      "Vertical Black 900mm x 2200mm Gate",
      "Vertical Graphite 1000mm x 1600mm Gate",
      "Vertical Graphite 1000mm x 1800mm Gate",
      "Vertical Graphite 1000mm x 2000mm Gate",
      "Vertical Graphite 1000mm x 2200mm Gate",
      "Vertical Graphite 1100mm x 1600mm Gate",
      "Vertical Graphite 1100mm x 1800mm Gate",
      "Vertical Graphite 1100mm x 2000mm Gate",
      "Vertical Graphite 1100mm x 2200mm Gate",
      "Vertical Graphite 1200mm x 1600mm Gate",
      "Vertical Graphite 1200mm x 1800mm Gate",
      "Vertical Graphite 1200mm x 2000mm Gate",
      "Vertical Graphite 1200mm x 2200mm Gate",
      "Vertical Graphite 900mm x 1600mm Gate",
      "Vertical Graphite 900mm x 1800mm Gate",
      "Vertical Graphite 900mm x 2000mm Gate",
      "Vertical Graphite 900mm x 2200mm Gate"
    ],    
    [
      "Autumn Life - 19mm - Black - 300mm x 1830mm",
      "Autumn Life - 19mm - Black - 900mm x 1830mm",
      "Autumn Life - 19mm - Dove Grey - 300mm x 1830mm",
      "Autumn Life - 19mm - Grey - 300mm x 1830mm",
      "Autumn Life - 19mm - Grey - 900mm x 1830mm",
      "Autumn Life - 6mm - Black - 300mm x 1830mm",
      "Autumn Life - 6mm - Black - 900mm x 1830mm",
      "Autumn Life - 6mm - Grey - 300mm x 1830mm",
      "Autumn Life - 6mm - Grey - 900mm x 1830mm",
      "Bonsai Breeze - 19mm - Black - 300mm x 1830mm",
      "Bonsai Breeze - 19mm - Black - 900mm x 1830mm",
      "Bonsai Breeze - 19mm - Dove Grey - 300mm x 1830mm",
      "Bonsai Breeze - 19mm - Grey - 300mm x 1830mm",
      "Bonsai Breeze - 19mm - Grey - 900mm x 1830mm",
      "Bonsai Breeze - 6mm - Black - 300mm x 1830mm",
      "Bonsai Breeze - 6mm - Black - 900mm x 1830mm",
      "Bonsai Breeze - 6mm - Grey - 300mm x 1830mm",
      "Bonsai Breeze - 6mm - Grey - 900mm x 1830mm",
      "Bonsai Breeze Curve - 19mm - Black - 400mm x 1830mm",
      "Criss Cross - 19mm - Black - 300mm x 1830mm",
      "Criss Cross - 19mm - Dove Grey - 300mm x 1830mm",
      "Criss Cross - 19mm - Grey - 300mm x 1830mm",
      "Heat Wave - 19mm - Black - 300mm x 1830mm",
      "Heat Wave - 19mm - Black - 900mm x 1830mm",
      "Heat Wave - 19mm - Grey - 300mm x 1830mm",
      "Heat Wave - 19mm - Grey - 900mm x 1830mm",
      "Heat Wave - 6mm - Black - 300mm x 1830mm",
      "Heat Wave - 6mm - Black - 900mm x 1830mm",
      "Heat Wave - 6mm - Grey - 300mm x 1830mm",
      "Heat Wave - 6mm - Grey - 900mm x 1830mm",
      "Indian Summer - 19mm - Black - 300mm x 1830mm",
      "Indian Summer - 19mm - Black - 900mm x 1830mm",
      "Indian Summer - 19mm - Grey - 300mm x 1830mm",
      "Indian Summer - 19mm - Grey - 900mm x 1830mm",
      "Indian Summer - 6mm - Black - 300mm x 1830mm",
      "Indian Summer - 6mm - Black - 900mm x 1830mm",
      "Indian Summer - 6mm - Grey - 300mm x 1830mm",
      "Indian Summer - 6mm - Grey - 900mm x 1830mm",
      "Japanese Garden - 19mm - Black - 300mm x 1830mm",
      "Japanese Garden - 19mm - Black - 900mm x 1830mm",
      "Japanese Garden - 19mm - Grey - 300mm x 1830mm",
      "Japanese Garden - 19mm - Grey - 900mm x 1830mm",
      "Japanese Garden - 6mm - Black - 300mm x 1830mm",
      "Japanese Garden - 6mm - Black - 900mm x 1830mm",
      "Japanese Garden - 6mm - Grey - 300mm x 1830mm",
      "Japanese Garden - 6mm - Grey - 900mm x 1830mm",
      "Paradise Palm - 19mm - Black - 300mm x 1830mm",
      "Paradise Palm - 19mm - Black - 900mm x 1830mm",
      "Paradise Palm - 19mm - Grey - 300mm x 1830mm",
      "Paradise Palm - 19mm - Grey - 900mm x 1830mm",
      "Paradise Palm - 6mm - Black - 300mm x 1830mm",
      "Paradise Palm - 6mm - Black - 900mm x 1830mm",
      "Paradise Palm - 6mm - Grey - 300mm x 1830mm",
      "Paradise Palm - 6mm - Grey - 900mm x 1830mm",
      "Rosemary Spice - 19mm - Black - 300mm x 1830mm",
      "Rosemary Spice - 19mm - Black - 900mm x 1830mm",
      "Slatted - 19mm - Black - 300mm x 1830mm",
      "Slatted - 19mm - Grey - 300mm x 1830mm"
    ],
    
  ];
  const xeroKPIComponent = <div className="mx-0 "></div>;
  const dearKPIComponent = (
    <div class="mx-0 mt-5 flex flex-col gap-6">
      {categories.map((categoryName, index) => (
        <CategoryComponent
          key={index}
          categoryName={categoryName}
          products={products[index]}
          isLoadingProductSalesCategory={dearProductSalesCategoryLoading[index]}
          isLoadingInventoryValueCategory={dearInventoryValueCategoryLoading[index]}
          isLoadingGrossProfitMarginCategory={dearGrossProfitMarginCategoryLoading[index]}
          productSalesData={dearProductSalesCategory[index]}
          grossProfitMarginData={dearGrossProfitMarginCategory[index]}
          inventoryValueData={dearInventoryValueCategory[index]}
          openKPIAdvancedFilters={openKPIAdvancedFilters}
          productSalesFilters={productSalesFilters}
          inventoryValueFilters={inventoryValueFilters}
          grossProfitMarginFilters={grossProfitMarginFilters}
          toggleAdvancedFilters={toggleAdvancedFilters}
          toggleKPISheet={toggleKPISheet}
        />
      ))}
    </div>
  );

  const displayKPIComponent = (item) => {
    switch (item) {
      case "XERO":
        return xeroKPIComponent;
      case "DEAR":
        return dearKPIComponent;
      default:
        return "Unknown"; 
    }
  };

  return (
    <div className="w-full h-full px-5 py-5 bg-light-white flex flex-col">
      <div
        className={
          isAdvancedDearFilters
            ? ` flex-1  p-5 border-2 border-light-grey mt-5 justify-center items-start`
            : `bg-white flex-1  p-5 border-2 border-light-grey mt-5 justify-center items-start`
        }
      >
        <DashboardBanner
          title={
            isLoading ? (
              <SpinnerKPI />
            ) : (
              `Welcome! ${user?.data?.name}` || "Home"
            )
          }
        />

        {organisation?.slug === "ecomposite-products" ? (
          <>
            {organisation?.connected_apps &&
              organisation.connected_apps.map((item, index) => (
                <div key={index}>{displayKPIComponent(item)}</div>
              ))}
          </>
        ) : (
          <div className="flex flex-col w-full h-full justify-center items-center gap-1">
            <p className="text-[12px] font-light text-grey italic">
              Your Favourite KPIs will be here - The Favourite Feature is not
              currently Available.
            </p>
            <p className="text-[12px] font-light text-grey italic">
              Please visit one of the other tabs to view your KPIs
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default DashboardHomePage;

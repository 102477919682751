import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BoxCard01 from "../../../components/ui-elements/cards/box-card-01";
import BoxCard03 from "../../../components/ui-elements/cards/box-card-03";
import LineChart from "../../../components/graphs/react-chartjs-two/line-chart";
import DashboardBanner from "../../../components/ui-elements/banner/dashbaord-banner";
import GraphData from "./graphs";
import KPISheetModal from "../../../components/ui-elements/modals/kpis-modals/kpi-sheet-modal";
import countryToSymbolMap from "../../../../constant/country-code-currency.json";

function DashboardBalanceSheetPage(props) {
  const { xeroKPIFigures } = useSelector((state) => state.kpi);
  const { organisation } = useSelector((state) => state.organisation);
  const countryCode =
    organisation && organisation.country_alpha3
      ? organisation.country_alpha3.replace(/"/g, "")
      : null;
  const currencySymbol = countryCode ? countryToSymbolMap[countryCode] : null;

  // GRAPHS
  const {
    currentAssetsData,
    currentAssetsLineGraphData,
    currentLiabilitiesData,
    currentLiabilitiesLineGraphData,
    averageCreditorDaysData,
    averageCreditorDaysLineGraphData,
    combinedAssetsLiabilitiesLineGraphData
  } = GraphData();

  // BOX KPIs
  const getKPIFigure = (kpiName) => {
    if (Array.isArray(xeroKPIFigures)) {
      const figure = xeroKPIFigures.find((item) => item.KPI === kpiName);
      return figure || null;
    } else {
      return null;
    }
  };

  const averageCreditorDaysFigureData = getKPIFigure("Average Creditor Days");
  const currentAssetsLessCurrentLiabilitiesFigureData = getKPIFigure(
    "Current Assets Less Current Liabilities"
  );
  const currentAssetsFigureData = getKPIFigure("Current Assets");

  // Saved Filters
  const extractFilters = (kpiTitle) => {
    const savedFilters = organisation?.saved_filters[organisation?.name];
    const filter = savedFilters?.find(filter => filter.kpi === kpiTitle);
    if (filter) {
      return { firstFilter: filter.first_filter, comparedTo: filter.compared_to };
    }
    return { firstFilter: null, comparedTo: null };
  };
  const currentAssetLessLiabilitiesSavedFitlers = extractFilters('Current Assets Less Liabilities')
  const averageCreditorDaysSavedFitlers = extractFilters('Average Creditor Days')
  console.log(currentAssetLessLiabilitiesSavedFitlers);




  // Spark Lines
  function filterLastMonthsData(data, months = 6) {
    if (Array.isArray(data)) {
      return data.slice(0, months);
    } else {
      return [];
    }
  }

  const extractSparkLineData = (data, key) =>
    data.map((entry) => entry[key] || 0);
  function generateSparkLineData(rawData, key) {
    if (!Array.isArray(rawData) || rawData.length === 0) {
      return [];
    }
    // Sort the data by month in descending order
    const sortedData = rawData.slice().sort((a, b) => {
      if (a.month < b.month) return 1;
      if (a.month > b.month) return -1;
      return 0;
    });

    // Extract the last 6 months' data and reverse it
    const last6MonthsData = filterLastMonthsData(sortedData).reverse();
    const sparkLine = extractSparkLineData(last6MonthsData, key);
    return sparkLine; // Return sparkLine array directly without adding 0
  }


  const averageCreditorDaysSpark =
    averageCreditorDaysData?.average_creditor_days || [];
  const sparkLine01AverageCreditorDaysData = generateSparkLineData(
    averageCreditorDaysSpark,
    "average_creditor_days"
  );
  const sparkLine02AverageCreditorDaysData = generateSparkLineData(
    averageCreditorDaysSpark,
    "previous_year_average_creditor_days"
  );

  // KPI Sheet Modal
  const [toggleKPI, setKPISheetToggle] = useState(false);
  const [kpiSheetTitle, setKPISheetTitle] = useState(""); // Added state to store KPI sheet title

  const toggleKPISheet = (title) => {
    setKPISheetTitle(title); // Set the title in the state
    setKPISheetToggle(!toggleKPI);
  };

  const xeroKPIComponent = (
    <div class="mx-0 ">
      <div class="grid  grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
        <BoxCard01
          customSymbol={" "}
          isCustomSymbol={false}

          toggleKPISheet={() =>
            toggleKPISheet("Current Assets Less Liabilities")
          }
          savedFilterA={currentAssetLessLiabilitiesSavedFitlers.firstFilter}
          savedFilterB={currentAssetLessLiabilitiesSavedFitlers.comparedTo}
          title="Current Assets Less Liabilities"
          data={currentAssetsLessCurrentLiabilitiesFigureData}
          sparkLine01Data={{}}
          sparkLine02Data={{}}

        />

        <BoxCard01
          customSymbol={" Days"}
          toggleKPISheet={() => toggleKPISheet("Average Creditor Days")}
          isCustomSymbol={true}
          savedFilterA={averageCreditorDaysSavedFitlers.firstFilter}
          savedFilterB={averageCreditorDaysSavedFitlers.comparedTo}
          title="Average Creditor Days"
          data={averageCreditorDaysFigureData}
          sparkLine01Data={sparkLine01AverageCreditorDaysData}
          sparkLine02Data={sparkLine02AverageCreditorDaysData}
        />
      </div>
    </div>
  )
  const dearKPIComponent = (
    <p>

    </p>
  )
  const displayKPIComponent = (item) => {
    switch (item) {
      case 'XERO':
        return xeroKPIComponent
      case 'DEAR':
        return dearKPIComponent
      default:
        return 'Unknown';
    }
  };


  return (
    <div className="w-full h-full px-5 py-5 bg-light-white flex flex-col">
      <div className="bg-white flex-1 p-5 border-2 border-light-grey mt-5 justify-center items-start">
        <DashboardBanner title="Balance Sheet"
          isGlobalFilterRequired={false} />
        <KPISheetModal
          toggle={toggleKPI}
          toggleModal={toggleKPISheet}
          kpiName={kpiSheetTitle}
        >
          <p className="font-bold text-lg">{kpiSheetTitle}</p>
          {kpiSheetTitle === "Number of Sales Overpayments" && (
            <p>Your custom component here for "Number of Sales Overpayments"</p>
          )}
          {kpiSheetTitle === "Number of Sales Credit Notes" && (
            <p>Your custom component here for "Number of Sales Credit Notes"</p>
          )}
          {kpiSheetTitle === "Current Assets Less Liabilities" && (
            <div className="w-full gap-2 flex flex-col">
              <LineChart
                currencySymbol={currencySymbol}
                companyData={combinedAssetsLiabilitiesLineGraphData}
                company={organisation}
                title={"Current Assets Less Current Liabilities of"}
              />
              <LineChart
                currencySymbol={currencySymbol}
                companyData={currentAssetsLineGraphData}
                company={organisation}
                title={"Current Assets of"}
              />
              <LineChart
                currencySymbol={currencySymbol}
                companyData={currentLiabilitiesLineGraphData}
                company={organisation}
                title={"Current Liabilities of"}
              />
            </div>
          )}
          {kpiSheetTitle === "Average Creditor Days" && (
            <LineChart
              currencySymbol={''}
              companyData={averageCreditorDaysLineGraphData}
              company={organisation}
              title={"Average Creditor Days"}
            />
          )}
          {kpiSheetTitle === "Outstanding bill overpayment value" && (
            <></>)}

          {kpiSheetTitle === "Outstanding bill prepayment value" && (
            <></>// Your custom component or content can be added here
          )}

          {kpiSheetTitle === "Number of Bill Credit Notes" && (
            <></>// Your custom component or content can be added here
          )}

          {kpiSheetTitle === "Number of outstanding bill overpayments" && (
            <></>// Your custom component or content can be added here
          )}

          {kpiSheetTitle === "Number of outstanding bill prepayments" && (
            <></> // Your custom component or content can be added here
          )}

          {kpiSheetTitle === "Number of outstanding bills" && (
            <></>// Your custom component or content can be added here
          )}

          {kpiSheetTitle === "Number of outstanding overdue bills" && (
            <></>// Your custom component or content can be added here
          )}

        </KPISheetModal>

        {organisation?.connected_apps && organisation.connected_apps.map((item, index) => (
          <div key={index}>
            {displayKPIComponent(item)}
          </div>
        ))}
        {/* <div class="mx-0 mt-5">
          <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            <div class="flex rounded-md">
              <BoxCard03
                toggleKPISheet={() =>
                  toggleKPISheet("Number Bill Credit Notes")
                }
                customSymbol={""}

                title="Number of Bill Credit Notes"
                isCustomSymbol={false}
                data={numberOutstandingBillCreditNotesFigureData}
                value={numberOutstandingBillCreditNotesFigureData?.this_month}
              />
            </div>
            <div class=" flex rounded-md">
              <BoxCard03
                toggleKPISheet={() =>
                  toggleKPISheet("Number of outstanding bill overpayments")
                }
                customSymbol={""}

                title="Number of outstanding bill overpayments"
                isCustomSymbol={false}
                data={numberOutstandingBillOverpaymentsFigureData}
                value={numberOutstandingBillOverpaymentsFigureData?.this_month}
              />
            </div>
            <div class="flex rounded-md">
              <BoxCard03
                toggleKPISheet={() =>
                  toggleKPISheet("Number of outstanding bill prepayments")
                }
                customSymbol={""}

                title="Number of outstanding bill prepayments"
                isCustomSymbol={false}
                data={numberOutstandingBillPrepaymentsFigureData}
                value={numberOutstandingBillPrepaymentsFigureData?.this_month}
              />
            </div>

            <div class="flex rounded-md">
              <BoxCard03
                toggleKPISheet={() =>
                  toggleKPISheet("Number of outstanding bills")
                }
                customSymbol={""}
                title="Number of outstanding bills"
                isCustomSymbol={false}
                data={numberOutstandingBillsFigureData}
                value={numberOutstandingBillPrepaymentsFigureData?.this_month}
              />
            </div>
          </div>
        </div>
        <div class="container mx-0 mt-5">
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            <div class="flex rounded-md">
              <BoxCard03
                toggleKPISheet={() =>
                  toggleKPISheet("Number of outstanding overdue bills")
                }
                customSymbol={""}
                title="Number of outstanding overdue bills"
                isCustomSymbol={false}
                data={numOutstandingOverdueBillsFigureData}
                value={numOutstandingOverdueBillsFigureData?.this_month}
              />
            </div>
            <div class="flex rounded-md">
              
            </div>
            <div class="flex rounded-md">
              
            </div>
            <div class="flex rounded-md">
              
            </div>
          </div>
        </div> */}

        {/* <p className="font-semibold mt-5">Bank Account Table</p>
        <div className="w-full gap-2 flex flex-row mt-5">
          <div className="overflow-x-auto w-2/3">
            <table className="min-w-full bg-white border border-gray-300">
              <thead>
                <tr>
                  <th className="py-2 px-4 border-b border-r text-left">
                    Account Type
                  </th>
                  <th className="py-2 px-4 border-b text-left">Balance</th>
                </tr>
              </thead>
              <tbody className="text-sm">
                {Array.isArray(bankAccountTable) &&
                bankAccountTable.length > 0 ? (
                  bankAccountTable.map((account, index) => (
                    <tr
                      key={index}
                      className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}
                    >
                      <td className="py-2 px-4 border-b border-r ">
                        {account.balance_sheet_transform_type}
                      </td>
                      <td className="py-2 px-4 border-b ">
                        {account.balance_sheet_transform_filtered_current}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="2">No data available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default DashboardBalanceSheetPage;

import React from 'react'
import Checkbox from './custom-checkbox';
import DatePicker from './custom-datepicker';
import Input from './custom-input'
import Radio from './custom-radio';
import Select from './custom-select';
import Textarea from './custom-textarea';
import MonthPicker from './custom-monthpicker';

function FormikControl(props) {
    const {control, ...rest}=props
    switch (control) {
      case "input":
        return <Input {...rest} />;
      case "textarea":
        return <Textarea {...rest} />;
      case "select":
        return <Select {...rest} />;
      case "radio":
        return <Radio {...rest} />;
      case "checkbox":
        return <Checkbox {...rest} />;
      case "date":
        return <DatePicker {...rest} />;
      case "month":
        return <MonthPicker {...rest} />;
      default:
        return null;
    }
}

export default FormikControl
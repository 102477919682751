import axios from "axios";

import {
  EMBED_LOOKER,
 ENV_URL,
} from "../../../constant";




const embedLooker = async (slug, token) => {
  const response = await axios.get(`${ENV_URL}${EMBED_LOOKER}${slug}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    withCredentials: false, // Ensure cookies are not sent
  });
  return response.data;
};



const lookerService = {
embedLooker,
};
export default lookerService;

import React, { useEffect, useState } from "react";
import Sidebar from "../../../navigation";
import { BsQuestionCircle } from "react-icons/bs";
import Select from "react-select";
import logo from "../../../assets/logos/klogo.png";
import cin7 from "../../../assets/logos/cin7_logo.png";
import { Form, Formik, useFormik } from "formik";
import FormikControl from "../../components/form-elements/formik-control";
import XeroAppPage from "../app-integrations/xero";
import { NavLink, useNavigate } from "react-router-dom";
import Cin7AppPage from "../app-integrations/cin-7";
import { getAllOrganisations, resetOrganisationStorage, selectOrganisation } from "../../../redux-features/slices/organisation/orgSlice";
import { useDispatch, useSelector } from "react-redux";
import { dropdownIndicatorStyles } from "../../../styles/selectStyle";
import { resetAuth, signOutUser } from "../../../redux-features/slices/auth/authSlice";
import { ImSwitch } from "react-icons/im";
import CashFlowAssumptionsComponent from "./cashflow-assumptions";
import RevenueComponent from "./revenue";
import DirectCostsComponent from "./direct-costs";


function ForecastingPage() {
    const [activeComponent, setActiveComponent] = useState(
      "CashflowAssumptions"
    );
 const dispatch = useDispatch();
 const navigate=useNavigate()
  const { user } = useSelector((state) => state.auth);
  const token = user?.data?.api_token;

  useEffect(() => {
    dispatch(getAllOrganisations({token}));
  }, []);
  const {
    organisations,
  
  } = useSelector((state) => state.organisation)
 
  const options = organisations?.map((item) => ({
    value: item.name,
    label: item.name,
  }));
const { organisation } = useSelector(
  (state) => state.organisation
);
useEffect(() => {
  const fetchOrganisations = async () => {
    if (token) {
      await dispatch(getAllOrganisations({ token }));
    }
  };

  fetchOrganisations();
}, []);
// For Select
const [defaultOrganisation, setDefaultOrganisation] = useState({});
const optionsOrg = organisations?.map((item) => ({
  value: item.name,
  label: item.name,
  slug: item.slug,
}));

useEffect(() => {
  if (organisation) {
    setDefaultOrganisation({
      value: organisation?.name,
      label: organisation?.name,
      slug: organisation?.slug,
      owner: organisation?.owner,
    });
  }
}, [organisation]);

const handleOrgChange = async (e) => {
  const slug = e.slug;
  dispatch(selectOrganisation({ slug, token }));
  setDefaultOrganisation(e);
};
  const onSubmit = async (values, actions) => {
    const user_data = {
      password: values.new_password,
    };
    if (user_data) {
    }
    await new Promise((resolve) => setTimeout(resolve, 1000));
    actions.resetForm();
  };

  const initialValues = {
    new_password: "",
    confirm_password: "",
  };
    const handleClick = (component) => {
      setActiveComponent(component);
    };

 
  return (
   
      <div className="w-full bg-light-white overflow-y-scroll scrollbar-thin scrollbar-rounded-lg scrollbar-h-12 scrollbar-thumb-gray-400 scrollbar-track-gray-100 scroll-padding">
        <div
          className={` flex flex-col w-full  justify-start items-start scroll-padding px-6 py-2 `}
        >
          <div className="flex w-full  items-end justify-between mt-4">
            <div>
              <div className="relative">
                <h2 className="text-xl font-medium ">Forecasting</h2>
              </div>
            </div>
            <div className="flex flex-row gap-6">
              <Select
                styles={{
                  dropdownIndicator: dropdownIndicatorStyles,
                }}
                className="basic-single z-20 w-96 border-2 rounded-md border-purple"
                classNamePrefix="select"
                value={defaultOrganisation}
                name="color"
                options={optionsOrg}
                onChange={(e) => {
                  handleOrgChange(e);
                }}
              />
              <BsQuestionCircle className=" mt-1 text-purple" size={32} />
            </div>
          </div>

          <div className="flex flex-col w-full mt-10 p-4  border-2 border-light-grey justify-center items-start bg-white ">
            <div className="flex flex-row w-full justify-between">
              <div className="space-x-2 font-semibold text-[12px]">
                <button
                  className={`  ${
                    activeComponent === "CashflowAssumptions"
                      ? " rounded-md bg-warning font-bold text-black"
                      : "border-0  text-gray-600"
                  }  px-4 py-2 `}
                  onClick={() => handleClick("CashflowAssumptions")}
                >
                  Cashflow Assumptions
                </button>
                <button
                  className={`  ${
                    activeComponent === "Revenue"
                      ? " rounded-md bg-warning font-bold text-black"
                      : "border-0  text-gray-600 "
                  }  px-4 py-2 `}
                  onClick={() => handleClick("Revenue")}
                >
                  Revenue
                </button>
                <button
                  className={`  ${
                    activeComponent === "Direct Costs"
                      ? " rounded-md bg-warning font-bold text-black"
                      : "border-0  text-gray-600 "
                  }  px-4 py-2 `}
                  onClick={() => handleClick("Direct Costs")}
                >
                  Direct Costs
                </button>
                <button
                  className={`  ${
                    activeComponent === "Overheads"
                      ? " rounded-md bg-warning font-bold text-black"
                      : "border-0  text-gray-600 "
                  }  px-4 py-2 `}
                  onClick={() => handleClick("Overheads")}
                >
                  Overheads
                </button>
                <button
                  className={`  ${
                    activeComponent === "Loans"
                      ? " rounded-md bg-warning font-bold text-black"
                      : "border-0  text-gray-600 "
                  }  px-4 py-2 `}
                  onClick={() => handleClick("Loans")}
                >
                  Loans
                </button>
                <button
                  className={`  ${
                    activeComponent === "Fixed Assets"
                      ? " rounded-md bg-warning font-bold text-black"
                      : "border-0  text-gray-600 "
                  }  px-4 py-2 `}
                  onClick={() => handleClick("Fixed Assets")}
                >
                  Fixed Assets
                </button>
                <button
                  className={`  ${
                    activeComponent === "Capital Expenditure"
                      ? " rounded-md bg-warning font-bold text-black"
                      : "border-0  text-gray-600 "
                  }  px-4 py-2 `}
                  onClick={() => handleClick("Capital Expenditure")}
                >
                  Capital Expenditure
                </button>
                <button
                  className={`  ${
                    activeComponent === "Equity"
                      ? " rounded-md bg-warning font-bold text-black"
                      : "border-0  text-gray-600 "
                  }  px-4 py-2 `}
                  onClick={() => handleClick("Equity")}
                >
                  Equity
                </button>
              </div>
            </div>

            <div className="w-full h-full">
              {activeComponent === "CashflowAssumptions" && (
                <CashFlowAssumptionsComponent />
              )}

              {activeComponent === "Revenue" && <RevenueComponent />}
              {activeComponent === "Direct Costs" && <DirectCostsComponent />}
            </div>
          </div>
        </div>
      </div>
  );
}

export default ForecastingPage;



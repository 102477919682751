import React, { useState } from "react";
import DateView from "react-datepicker";
import { Field, ErrorMessage } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import TextError from "./text-error";

function DatePicker(props) {
  const { label, name, ...rest } = props;
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <div className="form-control w-full relative">
      <Field name={name}>
        {({ form, field }) => {
          const { setFieldValue } = form;
          const { value } = field;
          return (
            <DateView
              id={name}
              {...field}
              {...rest}
              selected={value}
              onChange={(val) => setFieldValue(name, val)}
              onFocus={handleFocus}
              onBlur={handleBlur}
              className="peer h-10 pt-8 pb-4 w-full border-1.5 rounded-md text-[12px] focus:text-[15px] border-default text-gray-600 placeholder-transparent focus:outline-none"
            />
          );
        }}
      </Field>
      <label
        htmlFor={name}
        className={`absolute pt-1 left-3 ${
          isFocused ? "top-0.5" : "top-3"
        } pb-4 font-bold text-gray-700 text-[12px] transition-all  peer-placeholder-shown:text-gray-900  peer-placeholder-shown:font-semibold peer-placeholder-shown:top-3 peer-focus:top-0.5 peer-focus:text-gray-900 peer-focus:font-semibold peer-focus:text-[10px]`}
      >
        {label}
      </label>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
}

export default DatePicker;

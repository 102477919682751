import { ErrorMessage, Field, useFormikContext } from 'formik'
import React from 'react'
import TextError from './text-error'

function Input(props) {
  
    const{name,label, ...rest}=props
  return (
    <div className="form-control w-full relative">
      <Field
        className="peer h-10 pt-8 pb-4 w-full border-1.5 rounded-md text-[12px]   focus:text-[15px] border-default text-gray-600 placeholder-transparent focus:outline-none "
        id={name}
        name={name}
        {...rest}
        autoComplete="off"
      />
      <label
        htmlFor={name}
        className="absolute pt-1 left-3 top-0.5 pb-4 font-bold text-gray-700 text-[12px] transition-all  peer-placeholder-shown:text-gray-900  peer-placeholder-shown:font-semibold peer-placeholder-shown:top-3 peer-focus:top-0.5 peer-focus:text-gray-900 peer-focus:font-semibold peer-focus:text-[10px]"
      >
        {label}
      </label>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
}

export default Input
import React, { useEffect, useState } from "react";
import Sidebar from "../../../navigation";
import { BsQuestionCircle } from "react-icons/bs";
import Select from "react-select";
import logo from "../../../assets/logos/klogo.png";
import cin7 from "../../../assets/logos/cin7_logo.png";
import { Form, Formik, useFormik } from "formik";
import FormikControl from "../../components/form-elements/formik-control";
import {
  getAllOrganisations,
  selectOrganisation,
} from "../../../redux-features/slices/organisation/orgSlice";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
  connectCin7,
  resetIntegration,
} from "../../../redux-features/slices/integrations/integrationSlice";
import { toast } from "react-toastify";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { Cin7Schema } from "../../../validation-schemas";
import { dropdownIndicatorStyles } from "../../../styles/selectStyle";

function Cin7AppPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { organisations, organisation } = useSelector(
    (state) => state.organisation
  );
  const { user } = useSelector((state) => state.auth);
  const token = user?.data?.api_token;

  const { isSuccessCin7, isLoading, isError, message } = useSelector(
    (state) => state.integration
  );
  useEffect(() => {
    dispatch(getAllOrganisations({ token }));
  }, []);
  useEffect(() => {
    if (isError) {
      toast.error(message, {
        toastId: "toast1",
        autoClose: 10000,
        hideProgressBar: true,
        position: toast.POSITION.TOP_CENTER,
      });
      dispatch(resetIntegration());
    }
    if (isSuccessCin7 && organisation) {
      // toast.success("Connection to Cin7 is initiated", {
      //   toastId: "toast3",
      //   autoClose: 10000,
      //   hideProgressBar: true,
      //   position: toast.POSITION.TOP_CENTER,
      // });
      navigate("/connected-apps/apps/cin7/success");
    }
  }, [isError, isSuccessCin7, message, dispatch]);
  const options = organisations?.map((item) => ({
    value: item.name,
    label: item.name,
    slug: item.slug,
    owner: item.owner,
  }));

  const [defaultOrganisation, setDefaultOrganisation] = useState({});
  useEffect(() => {
    if (organisation) {
      setDefaultOrganisation({
        value: organisation?.name,
        label: organisation?.name,
        slug: organisation?.slug,
        owner: organisation.owner,
      });
    }
  }, [organisation]);

  const handleOrgChange = async (e) => {
    const slug = e.slug;
    dispatch(selectOrganisation({ slug, token }));
    setDefaultOrganisation(e);
  };

 
  const onSubmit = async (values, actions) => {
    const org_data = {
      org: values.org != "" ? values.org : defaultOrganisation.value,
      key: values.key,
      username: values.username,
    }
    const slug=organisation?.slug
    if(org_data && token){
    dispatch(connectCin7({ org_data,slug, token }));
    }
    await new Promise((resolve) => setTimeout(resolve, 15000));
    actions.resetForm();
  };
  const initialValues = {
    org: "",
    key: "",
    username: "",
  };
  return (
   
      <div className="w-full bg-light-white overflow-y-scroll scrollbar-thin scrollbar-rounded-lg scrollbar-h-12 scrollbar-thumb-gray-400 scrollbar-track-gray-100 scroll-padding">
        <div
          className={` flex flex-col w-full  justify-start items-start scroll-padding px-16 py-2 `}
        >
          <div className="flex w-full  items-end justify-between mt-4">
            <div>
              <div className="relative">
                <h2 className="text-xl font-medium ">Connected Apps</h2>
              </div>
            </div>
            {/* <div className="flex flex-row gap-6">
              <div className="relative">
                <Select
                  styles={{
                    dropdownIndicator: dropdownIndicatorStyles,
                  }}
                  className="basic-single z-20 w-96 border-2 rounded-md border-purple"
                  classNamePrefix="select"
                  value={defaultOrganisation}
                  name="color"
                  options={options}
                  onChange={(e) => {
                    handleOrgChange(e);
                  }}
                />
              </div>
              <BsQuestionCircle className=" mt-1 text-purple" size={32} />
            </div> */}
          </div>

          <div className="flex flex-col gap-4 mt-6">
            <img src={cin7} alt="" className="w-12 h-12" />
            <h3 className="text-lg font-bold">
              Cin7 Omni Connection for
              <span className="font-medium pl-2">
                {defaultOrganisation ? (
                  <>{defaultOrganisation.value}</>
                ) : (
                  <>{organisations[0]?.name}</>
                )}
              </span>
            </h3>
          </div>

          <div className="flex flex-col mt-6 w-full gap-4">
            <p>
              Follow the instructions to add your CIN7 Omni data to your dashboard
            </p>
            <div className="grid grid-cols-3 gap-10 w-full text-[14px]">
              <div>1. Log in to CIN7</div>
              <div>2. Add API Connection, take note of your API Username.s</div>
              <div>3. Enter Qurk for the App name</div>
              <div>
                4. <span className="font-bold ">IMPORTANT:</span> You will
                receive an API key. Make sure that you write this API key down
                as you will not be able to retrieve it in the future
              </div>
              <div>
                5. Click on your API Connections list and visit the
                "Permissions" link
              </div>
              <div>
                <p>
                  6. Select the permissions you wish to allow Cin7 Omni you wish to
                  obtain reports from.
                </p>
              </div>
              <div>
                <p>
                  7. Complete the connection by entering your API username and
                  API Key below{" "}
                </p>
              </div>
            </div>
            <Formik
              className="sign-in form "
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={Cin7Schema}
            >
              {(formik) => (
                <Form className="py-4 ">
                  <div className="flex flex-col items-start gap-4  mt-4 w-full ">
                    <FormikControl
                      control="input"
                      disabled
                      type="text"
                      name="org"
                      value={defaultOrganisation.value}
                      label="Organisation"
                      placeholder="Organisation"
                    />
                    <FormikControl
                      control="input"
                      type="text"
                      name="username"
                      label="Username"
                      placeholder="Username"
                    />
                    <FormikControl
                      control="input"
                      type="text"
                      name="key"
                      label="API Key"
                      placeholder="API Key"
                    />
                  </div>
                  <div className=" w-full flex items-end justify-end mt-4">
                    {Object.keys(organisation)?.length === 0 ? (
                      <>
                        <button 
                        disabled
                        className="  bg-warning text-[12px] opacity-80 text-black font-bold w-28 p-2 rounded">
                          Connect
                        </button>
                      </>
                    ) : (
                      <>
                        <button className="  bg-warning text-[12px] text-black font-bold w-28 p-2 rounded"
                        >
                          Connect
                        </button>
                      </>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
  );
}

export default Cin7AppPage;

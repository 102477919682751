import axios from "axios";

import {
 LIST_ORGANISATION,
 ENV_URL,
 SELECT_ORGANISATION,
 CREATE_ORGANISATION,
 SEND_STAFF_INVITATION,
 REMOVE_STAFF
} from "../../../constant";

const getAllOrganisations = async (token) => {
  const response = await axios.get(`${ENV_URL}${LIST_ORGANISATION}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

const createOrganisation = async (orgData, token) => {
  const response = await axios.post(`${ENV_URL}${CREATE_ORGANISATION}`, orgData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
const selectOrganisation = async (slug, token) => {
  const response = await axios.get(`${ENV_URL}${SELECT_ORGANISATION}${slug}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.data) {
    localStorage.setItem("organisation", JSON.stringify(response.data));
  }
  return response.data;
};
const updateOrganisation = async (slug, orgData, token) => {
  const response = await axios.put(`${ENV_URL}${SELECT_ORGANISATION}${slug}/`,orgData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
const deleteOrganisation = async (slug, token) => {
  const response = await axios.delete(`${ENV_URL}${SELECT_ORGANISATION}${slug}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

const sendStaffInvitation = async (staffData, token, slug) => {
  const response = await axios.post(`${ENV_URL}${SEND_STAFF_INVITATION}${slug}/`, staffData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
const removeStaff = async (staffData, token, slug) => {
  const response = await axios.post(`${ENV_URL}${REMOVE_STAFF}${slug}/`, staffData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
const orgService = {
getAllOrganisations,
selectOrganisation, 
deleteOrganisation, 
updateOrganisation, 
createOrganisation,
sendStaffInvitation,
removeStaff
};
export default orgService;

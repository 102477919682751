import React from "react";
import SpinnerKPI from "../../../../utilis/spinner-kpi";

const CustomerWithoutTagsTable = ({ data, isLoading }) => {
  return (
    <div className="rounded-md border h-[500px] border-gray-300 scrollbar-none overflow-auto">
      <table className="min-w-full text-sm text-center">
        <thead className="sticky top-0 bg-gray-200">
          <tr>
            <th className="border border-gray-300 px-4 py-2">
              Customer Names
            </th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td className="border border-gray-300 px-4 py-2" colSpan="1">
                <SpinnerKPI />
              </td>
            </tr>
          ) : (
            <>
              {data && data.length > 0 ? (
                data.map((item, index) => (
                  <tr key={index}>
                    <td className="border border-gray-300 px-4 py-2">
                      {item.Name}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="border border-gray-300 px-4 py-2" colSpan="1">
                    No data available
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default CustomerWithoutTagsTable;

import React, { useEffect, useState } from "react";
import { IoIosCheckmarkCircle, IoIosClose } from "react-icons/io";
import { FcApproval } from "react-icons/fc";
import moment from "moment";
import xeroWhite from "../../../../assets/logos/xero_logo_white.png";
import { useDispatch, useSelector } from "react-redux";
import {
  disconnectXero,
  resetIntegration,
} from "../../../../redux-features/slices/integrations/integrationSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner2 from "../utilis/spinner-2";
import { selectOrganisation } from "../../../../redux-features/slices/organisation/orgSlice";
import Spinner from "../utilis/spinner";
import SpinnerKPI from "../utilis/spinner-kpi";
import { RiErrorWarningFill } from "react-icons/ri";

function XeroDisconnectModal(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isDisconnectClicked, setIsDisconnectClicked] = useState(false);

  const { toggle, toggleModal, organisation } = props;
  const { user } = useSelector((state) => state.auth);
  const { isLoading, isError, isSuccessXero, message } = useSelector(
    (state) => state.integration
  );
  const token = user?.data?.api_token;
  const slug = organisation?.slug;

  useEffect(() => {
    const handleIntegrationStatus = async () => {
      if (isError) {
        toast.error(message, {
          toastId: "toast1",
          autoClose: 10000,
          hideProgressBar: true,
          position: toast.POSITION.TOP_CENTER,
        });
        dispatch(resetIntegration());
      }
      if (isSuccessXero && organisation) {
        toast.success("Xero is disconnected successfully", {
          toastId: "toast3",
          autoClose: 10000,
          hideProgressBar: true,
          position: toast.POSITION.TOP_CENTER,
        });

        // Wait for 3 seconds
        await new Promise((resolve) => setTimeout(resolve, 3000));
        dispatch(resetIntegration());
      }
    };

    handleIntegrationStatus();
  }, [isError, isSuccessXero, message, dispatch, organisation, toggleModal]);

  const handleXeroDisconnect = async () => {
    await dispatch(disconnectXero({ slug, token }));
    dispatch(selectOrganisation({ slug, token }));
    setIsDisconnectClicked(false);
    toggleModal();
  };
  
  const handleDisconnectButton = () => {
    setIsDisconnectClicked(true);
  };

  const handleNo = () => {
    setIsDisconnectClicked(false);
    toggleModal();
  };


  return (
    <div>
      {toggle && (
        <div className="fixed z-40 inset-0 w-full overflow-y-auto">
          <div className="flex items-center justify-center gap-6 bg-black bg-opacity-50 min-h-screen p-4">
            <div className="bg-white rounded-lg md:w-2/5 sm:w-3/5 w-4/5 p-8">
              <div className="flex flex-row justify-between items-start">
                <div className="flex gap-2 ">
{isDisconnectClicked ?(
                <RiErrorWarningFill size="44" className="text-red-500"  />

):(
  <IoIosCheckmarkCircle size="44" className="text-purple" />
)}                  
                  <div className="mt-2">
                    {isDisconnectClicked ? (
                      <h2 className="text-lg font-medium">
                      Are you sure you want to disconnect your Xero Data for {" "}
                      <span className="font-bold">{organisation?.name}</span>?
                    </h2>
                    ):(
                      <h2 className="text-lg font-medium">
                      Xero App Integration to {" "}
                      <span className="font-bold text-purple">{organisation?.name}</span>
                    </h2>
                    )}
                  </div>
                </div>
                <IoIosClose
                  onClick={toggleModal}
                  className="cursor-pointer"
                  size={35}
                />
              </div>
              <div className="mt-3 ml-12">
                {isDisconnectClicked?(
                    <p className="text-grey">
                    If you disconnect, you will not be able to see your Xero data on Qurk
                    </p>
                ):
                <p className="text-grey">
                  You are currently connected to Xero 
                </p>}
                
              </div>
              <div className="flex w-full justify-end ">
                {isDisconnectClicked ? (
                  <>
                  {isLoading ?( 
<div className="flex justify-center pt-4 items-center w-64">
<SpinnerKPI/>

</div>
):(
                    <button
                    onClick={handleXeroDisconnect}
                    className="mt-6 ml-2 p-1 items-center gap-2 justify-center border-2 border-red-400 bg-red-500 text-white hover:bg-red-400 text-md font-medium w-60 rounded-sm"
                  >
                    Yes, disconnect my Xero
                  </button>
                  )}
                  <button
                    onClick={handleNo}
                    className=" mt-6 ml-2 p-1 items-center gap-2 justify-center border-2 border-light-grey hover:bg-gray-200 text-md font-medium w-36 rounded-sm"
                  >
                    No
                  </button>
                </>
                ) : (
                  <>
                    <button
                      onClick={handleDisconnectButton}
                      className="flex mt-6 ml-2 items-center gap-2 border-2 border-light-grey bg-xero-text text-md text-xero-bg font-medium w-64 rounded"
                    >
                      <img
                        src={xeroWhite}
                        alt="Xero Logo"
                        className="w-14 h-14"
                      />
                      Disconnect from Xero
                    </button>
                  </>
                  
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default XeroDisconnectModal;

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import OrganisationModal from "../modals/organisation-modal";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllconsolidatedOrganisations,
  selectOrganisation,
} from "../../../../redux-features/slices/organisation/orgSlice";
import { toast } from "react-toastify";
import moment from "moment";
import { MdDelete } from "react-icons/md";
import { BsThreeDotsVertical } from "react-icons/bs";
import cin7 from "../../../../assets/logos/cin7_logo.png";
import xero from "../../../../assets/logos/xero_logo.png";
import quickbooks from "../../../../assets/logos/quickbooks_logo.png";
import hubspot from "../../../../assets/logos/hubspot_logo.png";
import pipedrive from "../../../../assets/logos/pipedrive_logo.png";
import katana from "../../../../assets/logos/katana_logo.png";
import dearsystems from "../../../../assets/logos/dearsystems_logo.png";
import mrp from "../../../../assets/logos/mrpreasy_logo.png";
import { BsPlus } from "react-icons/bs";
import { AiFillEdit } from "react-icons/ai";
import OrganisationActiveUsersModal from "../modals/organisation-active-users";
import Spinner2 from "../utilis/spinner-2";
import {
  embedLooker,
  setEmbedURL,
} from "../../../../redux-features/slices/looker/lookerSlice";
function ConsolidateconsolidatedOrganisationsTable(props) {
  const apps = [
    {
      name: "Cin7",
      logo: cin7,
      category: "Accounting",
      link: "/connected-apps/apps/cin-7/",
    },
    {
      name: "Xero",
      logo: xero,
      category: "Accounting",
      link: "/connected-apps/apps/xero/",
    },
    {
      name: "Quickbooks",
      logo: quickbooks,
      category: "Accounting",
      link: "/",
    },
    {
      name: "MRP Easy",
      logo: mrp,
      category: "MRP/ERP",
      link: "/",
    },
    {
      name: "Katana",
      logo: katana,
      category: "MRP/ERP",
      link: "/",
    },
    {
      name: "Dear Systems",
      logo: dearsystems,
      category: "MRP/ERP",
      link: "/",
    },
    {
      name: "Pipedrive",
      logo: pipedrive,
      category: "CRM",
      link: "/",
    },
    {
      name: "Hubspot",
      logo: hubspot,
      category: "CRM",
      link: "/",
    },
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const { consolidatedOrganisations, organisation, isLoadingAll, isDisplay, token } = props;
  const [toggleOrganisation, setOrganisationToggle] = useState(false);
  const [toggleOrganisationActiveUsers, setOrganisationActiveUsersToggle] =
    useState(false);

  const toggleOrganisationActiveUsersModal = (slug) => {
    setOrganisationActiveUsersToggle(!toggleOrganisationActiveUsers);
    dispatch(selectOrganisation({ slug, token }));
  };
  const toggleOrganisationModal = () => {
    setOrganisationToggle(!toggleOrganisation);
  };
  const [openDropdownOrgId, setOpenDropdownOrgId] = useState(null);

  const toggleDropdown = (orgSlug) => {
    setOpenDropdownOrgId(openDropdownOrgId === orgSlug ? null : orgSlug);
  };
  const handleOrganisationDelete = (slug) => {
    toast.info("A request has been sent to the admin in this regards", {
      autoClose: 10000,
      hideProgressBar: true,
      position: toast.POSITION.TOP_CENTER,
    });
    toggleDropdown(slug);
  };
  const [isEdit, setIsEdit] = useState(false);
  const handleOrganisationEdit = async (slug) => {
    setIsEdit(true);
    dispatch(selectOrganisation({ slug, token }));
    toggleOrganisationModal();
  };
  const handleconsolidatedOrganisationselect = (slug) => {
    dispatch(selectOrganisation({ slug, token }));
    navigate("/connected-apps/apps/");
  };
  const handleconsolidatedOrganisationselectLooker = (slug) => {
    dispatch(selectOrganisation({ slug, token }));
    dispatch(embedLooker({ slug, token }));
    navigate("/dashboard/main");
  };

  if (consolidatedOrganisations?.length === 0) {
    return (
      <>
        <div className="flex flex-col bg-light-grey-2  w-full last:border-2">
          <div>
            <table className="table-auto w-full relative border-collapse ">
              <thead>
                <tr className="justify-center border-b-2  border-grey  shadow-sm">
                  <th className=" text-lg text-dark-green py-2 px-4">
                    Organisation
                  </th>
                  <th className=" text-md text-dark-green p-4 font-normal ">
                    Connected Apps
                  </th>
                  <th className=" text-md text-dark-green font-normal p-4">
                    Access Level
                  </th>
                  <th className=" text-md text-dark-green font-normal p-4">
                    Active Users
                  </th>
                  <th className=" text-md text-dark-green font-normal py-2 px-4">
                    Last Updated
                  </th>
                  <th className=" text-md text-dark-green font-normal p-4 ">
                    Last Viewed
                  </th>
                </tr>
              </thead>
            </table>
          </div>
          <div className="flex flex-col h-64 items-center gap-6 justify-center">
            <h4 className="text-md">
              To get started, please connect your first organisation
            </h4>
            <button
              onClick={toggleOrganisationModal}
              className="  bg-warning text-black font-bold py-2 px-4 rounded"
            >
              + Add new organisation
            </button>
            <OrganisationModal
              token={token}
              organisation={organisation}
              toggle={toggleOrganisation}
              toggleModal={toggleOrganisationModal}
            />
          </div>
        </div>
      </>
    ); // or return a loading state or error message
  }
  return (
    <div className="relative overflow-x-auto text-sm h-full">
      <OrganisationActiveUsersModal
        token={token}
        organisation={organisation}
        toggle={toggleOrganisationActiveUsers}
        toggleModal={toggleOrganisationActiveUsersModal}
      />
      {isDisplay ? (
        <>
          {consolidatedOrganisations && (
            <div className="bg-white p-3 w-full pb-16 z-20 rounded-lg">
              <table className="table-auto  z-5 w-full relative border-collapse bg-white p-2 ">
                <thead className="text-black border-b-2 border-black rounded-md">
                  <tr className=" text-left text-md shadow-sm ">
                    <th className="border-b font-bold  border-gray-300 p-4">
                      Organisation
                    </th>
                    <th className="border-b font-medium border-gray-300 p-4">
                      Connected Apps
                    </th>
                    <th className="border-b font-medium border-gray-300 p-4">
                      Access Level
                    </th>
                    <th className="border-b font-medium border-gray-300 p-4">
                      Active Users
                    </th>
                    <th className="border-b font-medium border-gray-300 p-4">
                      Last Updated
                    </th>
                    <th className="border-b font-medium border-gray-300 p-4 "></th>
                  </tr>
                </thead>
                <tbody className={`bg-light-grey-2"}`}>
                  {consolidatedOrganisations
                    ?.slice()
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((org) => (
                      <>
                        {org && (
                          <tr key={org?.slug} className=" ">
                            <td className=" px-2 py-2 border-b-2 border-grey ">
                              <span className="font-bold">
                                [Consolidated]{" "}
                              </span>
                              {org.name}
                            </td>
                            <td className=" px-4 py-2 border-b-2 border-grey ">
                              ---
                            </td>
                            {/* <td className="border-b-2 border-grey z-10 px-4 py-2">
                              {org.connected_apps.length ? (
                                <div className="flex flex-row justify-start ">
                                  {apps
                                    ?.filter((app) =>
                                      org?.connected_apps?.some(
                                        (connectedApp) =>
                                          connectedApp.toLowerCase() ===
                                          app.name.toLowerCase()
                                      )
                                    )
                                    .map((app) => (
                                      <div className="cursor-pointer">
                                        {app.name === "Xero" ? (
                                          <>
                                            <div
                                              className="rounded-full  h-9 w-9"
                                              onClick={() =>
                                                handleconsolidatedOrganisationselectLooker(
                                                  org.slug
                                                )
                                              }
                                            >
                                              <Tippy
                                                theme="custom-white"
                                                size="small"
                                                placement="top"
                                                content="Xero"
                                              >
                                                <img
                                                  className="rounded-full object-contain h-full w-full"
                                                  src={app.logo}
                                                  alt={app.name}
                                                />
                                              </Tippy>
                                            </div>
                                          </>
                                        ) : null}
                                        {app.name === "Cin7" ? (
                                          <div
                                            className="rounded-full m-1.5 h-6 w-6"
                                            onClick={() =>
                                              handleconsolidatedOrganisationselectLooker(
                                                org.slug
                                              )
                                            }
                                          >
                                            <img
                                              className="rounded-full object-contain h-full w-full"
                                              src={app.logo}
                                            />
                                          </div>
                                        ) : null}
                                      </div>
                                    ))}

                                  <div
                                    className="p-2 pl-16 cursor-pointer"
                                    onClick={() =>
                                      handleconsolidatedOrganisationselect(org.slug)
                                    }
                                  >
                                    <BsPlus
                                      size={20}
                                      className="text-black bg-warning "
                                    />
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="cursor-pointer"
                                  onClick={() =>
                                    handleconsolidatedOrganisationselect(org.slug)
                                  }
                                >
                                  <button className="  bg-warning text-sm text-black font-bold p-2 rounded">
                                    + Connect Apps
                                  </button>
                                </div>
                              )}
                            </td> */}
                            <td className="border-b-2 px-4 py-2 border-grey">
                                Admin
                            </td>
                            <td className="border-b-2 border-grey text-primary underline px-4 py-2 cursor-pointer">
                              <span
                                onClick={() =>
                                  toggleOrganisationActiveUsersModal(org.slug)
                                }
                              >
                                User
                              </span>
                            </td>

                            <td className="border-b-2 border-grey px-4 py-2">
                              {moment(org.updated_at).fromNow()}
                            </td>
                            <td className="border-b-2 z-5  border-grey px-4 py-2 cursor-pointer">
                              <div
                                className="relative flex flex-row gap-3"
                                onClick={() => toggleDropdown(org.slug)}
                              >
                                <AiFillEdit
                                  onClick={() => {
                                    handleOrganisationEdit(org.slug);
                                  }}
                                  className="text-grey hover:text-warning"
                                  size={20}
                                />

                                <MdDelete
                                  className="text-grey hover:text-rose-500"
                                  size={20}
                                  onClick={() => {
                                    handleOrganisationDelete(org.slug);
                                  }}
                                />
                              </div>
                            </td>
                          </tr>
                        )}
                      </>
                    ))}
                  <OrganisationModal
                    token={token}
                    toggleDropdown={toggleDropdown}
                    isEdit={isEdit}
                    toggle={toggleOrganisation}
                    toggleModal={toggleOrganisationModal}
                  />
                </tbody>
              </table>
            </div>
          )}
        </>
      ) : (
        <div className=" h-96 w-full bg-light-white">
          <Spinner2 note="Please wait while we are fetching your consolidatedOrganisations..." />
        </div>
      )}
    </div>
  );
}

export default ConsolidateconsolidatedOrganisationsTable;

import React from 'react'

function TextError(props) {
  return (
    <div className={`error text-warning text-xs font-normal mt-1`}>
      {props.children}
    </div>
  );
}

export default TextError
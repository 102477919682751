import React, { useEffect, useState } from "react";
import PricingCard from "../../components/ui-elements/cards/pricing-cards";
import SubscriptionPaymentModal from "../../components/ui-elements/modals/subscription-payment";
import { useDispatch, useSelector } from "react-redux";
import { getAllSubscriptionPlans, getCustomerSubscription } from "../../../redux-features/slices/subscription/subscriptionSlice";

const SubscriptionPagePlans = [
  {
    title: "Basic",
    price: { monthly: 10, yearly: 100 },
    features: ["Feature 1", "Feature 2", "Feature 3"],
    description:'Best for personal use',
    buttonText: "Sign up",
  },
  {
    title: "Enterprise",
    price: { monthly: 10, yearly: 100 },
    description:'Best for teams/corporations',
    features: ["Feature 1", "Feature 2", "Feature 3", "Feature 4"],
    buttonText: "Sign up",
  },
  {
    title: "Business",
    price: { monthly: 10, yearly: 100 },
    description:'Best for business owners',
    features: ["Feature 1", "Feature 2", "Feature 3", "Feature 4", "Feature 5"],
    buttonText: "Sign up",
  },
];

const SubscriptionPage = () => {
 const dispatch = useDispatch();
 const { user } = useSelector((state) => state.auth);
 const { subscriptionPlans, subscription, payment_status } = useSelector((state) => state.subscription);
const sub_data =  subscription?.subscription?.customer
 const token = user?.data?.api_token;  
 const [selectedOption, setSelectedOption] = useState("monthly");

  const handleOptionChange = (newOption) => {
    setSelectedOption(newOption);
  };

useEffect(() => {
  dispatch(getAllSubscriptionPlans({ token }))
  dispatch(getCustomerSubscription({sub_data, token}))
}, []);

  return (
    <div className="flex  flex-col w-full  justify-center items-center">
      <div className="flex flex-col items-center justify-center mb-4">
        <div className="flex-col flex items-center justify-center gap-4">
          <p className="text-3xl font-bold">Pricing Plans</p>
          <p className="text-md font-light">
            14 days of free trail. Limited time offer for this year. Grab it
            now!{" "}
          </p>
        </div>
        <div className="flex flex-row gap-5">
          {/* <div className="mt-4">
            <p className="text-[10px] text-warning py-1 px-3 rounded-full border-2 border-warning">
              {selectedOption === "monthly" ? "10%" : "20%"} Off
            </p>
          </div> */}
          {/* <div className="relative mt-4">
            <label className="inline-flex relative items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={selectedOption === "monthly"}
                readOnly
              />
              <div
                onClick={() => {
                  handleOptionChange(
                    selectedOption === "monthly" ? "yearly" : "monthly"
                  );
                }}
                className={`w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-green-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all ${
                  selectedOption === "monthly" ? "peer-checked:bg-purple" : ""
                }`}
              ></div>
              <span className="ml-2 text-md font-regular text-gray-900">
                {selectedOption === "monthly" ? "Monthly" : "Yearly"}
              </span>
            </label>
          </div> */}
        </div>
      </div>
      
      <div className="flex flex-col w-full md:flex-row">
        {subscriptionPlans[0]?.map(
          (
            plan,i
          ) => (
            <PricingCard
              plan={plan}
              key={i} // Use a unique identifier as the key
              selectedOption={selectedOption}
              payment_status={payment_status}
            />
          )
        )}
      </div>
    </div>
  );
};

export default SubscriptionPage;

import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "./text-error";
import { Link } from "react-router-dom";
function Checkbox(props) {
  const { label, name, formType,options, ...rest } = props;
  return (
    <div
      className=''
    >
      <label>{label}</label>
      <Field name={name}>
        {({ field }) => {
          return options.map((option) => {
            return (
              <React.Fragment key={option.key}>
                <div className={`flex flex-row gap-2`}>
                  <input
                    type="checkbox"
                    id={option.value}
                    {...field}
                    {...rest}
                    value={option.value}
                    className=" form-checkbox h-4 w-4 text-warning rounded focus:ring-warning"
                    checked={field.value.includes(option.value)}
                  />
                  {option.value === "Terms & Conditions" ? (
                  <p className="text-sm text-white">
                  <span>I accept </span>
                  <a href="https://qurk.app/terms-of-service/" target="_blank" rel="noopener noreferrer">
                    <span className=" mx-1 underline font-bold">
                      terms and conditions {""}
                    </span>
                  </a>
                  and 
                  <a href="https://qurk.app/privacy-policy/" target="_blank" rel="noopener noreferrer">
                    <span className=" mx-1 underline font-bold">
                    privacy policy {""}
                    </span>
                  </a>
                  <span>of Qurk </span>
                </p>
                  ) : (
                    <label className="text-sm" htmlFor={option.value}>
                      {option.key}
                    </label>
                  )}
                </div>
              </React.Fragment>
            );
          });
        }}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
}

export default Checkbox;

import React, { useEffect, useRef, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import {
  updateDearProfitByBrandComparisonFilter,
  updateDearProfitByBrandDateFilter,
  updateDearProfitByCategoryComparisonFilter,
  updateDearProfitByCategoryDateFilter,
  updateDearProfitByCustomerComparisonFilter,
  updateDearProfitByCustomerDateFilter,
  updateDearProfitByCustomerTagComparisonFilter,
  updateDearProfitByCustomerTagDateFilter,
  updateDearProfitByProductComparisonFilter,
  updateDearProfitByProductDateFilter,
  updateDearProfitByProductTagComparisonFilter,
  updateDearProfitByProductTagDateFilter,
  updateDearProfitByProductTypeComparisonFilter,
  updateDearProfitByProductTypeDateFilter,
  updateDearSalesByBrandComparisonFilter,
  updateDearSalesByBrandDateFilter,
  updateDearSalesByCategoryComparisonFilter,
  updateDearSalesByCategoryDateFilter,
  updateDearSalesByCustomerComparisonFilter,
  updateDearSalesByCustomerDateFilter,
  updateDearSalesByCustomerTagComparisonFilter,
  updateDearSalesByCustomerTagDateFilter,
  updateDearSalesByProductComparisonFilter,
  updateDearSalesByProductDateFilter,
  updateDearSalesByProductTagComparisonFilter,
  updateDearSalesByProductTagDateFilter,
  updateDearSalesByProductTypeComparisonFilter,
  updateDearSalesByProductTypeDateFilter,
  updateDearUnitsSoldByBrandComparisonFilter,
  updateDearUnitsSoldByBrandDateFilter,
  updateDearUnitsSoldByCategoryComparisonFilter,
  updateDearUnitsSoldByCategoryDateFilter,
  updateDearUnitsSoldByCustomerComparisonFilter,
  updateDearUnitsSoldByCustomerDateFilter,
  updateDearUnitsSoldByCustomerTagComparisonFilter,
  updateDearUnitsSoldByCustomerTagDateFilter,
  updateDearUnitsSoldByProductComparisonFilter,
  updateDearUnitsSoldByProductDateFilter,
  updateDearUnitsSoldByProductTagComparisonFilter,
  updateDearUnitsSoldByProductTagDateFilter,
  updateDearUnitsSoldByProductTypeComparisonFilter,
  updateDearUnitsSoldByProductTypeDateFilter,
  updateDearUnitsShippedByBrandDateFilter,
  updateDearUnitsShippedByBrandComparisonFilter,
  updateDearUnitsShippedByProductDateFilter,
  updateDearUnitsShippedByProductComparisonFilter,
  updateDearUnitsShippedByCategoryDateFilter,
  updateDearUnitsShippedByCategoryComparisonFilter,
  updateDearUnitsShippedByCustomerDateFilter,
  updateDearUnitsShippedByCustomerComparisonFilter,
  updateDearUnitsShippedByProductTagDateFilter,
  updateDearUnitsShippedByProductTagComparisonFilter,
  updateDearUnitsShippedByProductTypeDateFilter,
  updateDearUnitsShippedByProductTypeComparisonFilter,
  updateDearUnitsShippedByCustomerTagDateFilter,
  updateDearUnitsShippedByCustomerTagComparisonFilter,
} from "../../../../redux-features/slices/kpi/kpiSlice";
import { useDispatch, useSelector } from "react-redux";
import KPIDrillDownModal from "../../ui-elements/modals/kpis-modals/drill-down-modal";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function HorizontalBarChart(props) {
  const { currencySymbol, data, title, drilldownTitle, drilldownTooltip, drilldownData, drilldownType } = props;
  const dispatch = useDispatch();
  const {
    // Dear Sales By Filters
    salesByBrandDateFilter,
    salesByBrandCompareFilter,
    salesByProductDateFilter,
    salesByProductCompareFilter,
    salesByCategoryDateFilter,
    salesByCategoryCompareFilter,
    salesByCustomerDateFilter,
    salesByCustomerCompareFilter,
    salesByProductTagDateFilter,
    salesByProductTagCompareFilter,
    salesByProductTypeDateFilter,
    salesByProductTypeCompareFilter,
    salesByCustomerTagDateFilter,
    salesByCustomerTagCompareFilter,

    // Dear Profit By Filters
    profitByCategoryDateFilter,
    profitByCategoryCompareFilter,
    profitByProductDateFilter,
    profitByProductCompareFilter,
    profitByBrandDateFilter,
    profitByBrandCompareFilter,
    profitByCustomerDateFilter,
    profitByCustomerCompareFilter,
    profitByCustomerTagDateFilter,
    profitByCustomerTagCompareFilter,
    profitByProductTagDateFilter,
    profitByProductTagCompareFilter,
    profitByProductTypeDateFilter,
    profitByProductTypeCompareFilter,

    // Dear Units Sold By Filters
    unitsSoldByBrandDateFilter,
    unitsSoldByBrandCompareFilter,
    unitsSoldByProductDateFilter,
    unitsSoldByProductCompareFilter,
    unitsSoldByCategoryDateFilter,
    unitsSoldByCategoryCompareFilter,
    unitsSoldByCustomerDateFilter,
    unitsSoldByCustomerCompareFilter,
    unitsSoldByProductTagDateFilter,
    unitsSoldByProductTagCompareFilter,
    unitsSoldByProductTypeDateFilter,
    unitsSoldByProductTypeCompareFilter,
    unitsSoldByCustomerTagDateFilter,
    unitsSoldByCustomerTagCompareFilter,

    // Dear Units Shipped By Filters
    unitsShippedByBrandDateFilter,
    unitsShippedByBrandCompareFilter,
    unitsShippedByProductDateFilter,
    unitsShippedByProductCompareFilter,
    unitsShippedByCategoryDateFilter,
    unitsShippedByCategoryCompareFilter,
    unitsShippedByCustomerDateFilter,
    unitsShippedByCustomerCompareFilter,
    unitsShippedByProductTagDateFilter,
    unitsShippedByProductTagCompareFilter,
    unitsShippedByProductTypeDateFilter,
    unitsShippedByProductTypeCompareFilter,
    unitsShippedByCustomerTagDateFilter,
    unitsShippedByCustomerTagCompareFilter,
  } = useSelector((state) => state.kpi);
  let initialFilterA = "This Month";
  let initialFilterB = "Previous Period";

  switch (title) {
    case "Top 10 Brands by Net Sales":
      initialFilterA =
        salesByBrandDateFilter ||
        initialFilterA;
      initialFilterB =
        salesByBrandCompareFilter ||
        initialFilterB;
      break;
    case "Top 10 Product Name by Net Sales":
      initialFilterA =
        salesByProductDateFilter ||
        initialFilterA;
      initialFilterB =
        salesByProductCompareFilter ||
        initialFilterB;
      break;
    case "Top 10 Categories by Net Sales":
      initialFilterA =
        salesByCategoryDateFilter  ||
        initialFilterA;
      initialFilterB =
        salesByCategoryCompareFilter ||
        initialFilterB;
      break;
    case "Top 10 Customers by Net Sales":
      initialFilterA =
        salesByCustomerDateFilter ||
        initialFilterA;
      initialFilterB =
        salesByCustomerCompareFilter ||
        initialFilterB;
      break;
    case "Top 10 Product Tags by Net Sales":
      initialFilterA =
        salesByProductTagDateFilter ||
        initialFilterA;
      initialFilterB =
        salesByProductTagCompareFilter ||
        initialFilterB;
      break;
    case "Sales by Product Type":
      initialFilterA =
        salesByProductTypeDateFilter?.toLowerCase().replace(" ", "_") ||
        initialFilterA;
      initialFilterB =
        salesByProductTypeCompareFilter?.toLowerCase().replace(" ", "_") ||
        initialFilterB;
      break;
    case "Top 10 Customer Tag by Net Sales":
      initialFilterA =
        salesByCustomerTagDateFilter ||
        initialFilterA;
      initialFilterB =
        salesByCustomerTagCompareFilter ||
        initialFilterB;
      break;
    case "Top 10 Categories by Gross Profit":
      initialFilterA =
        profitByCategoryDateFilter ||
        initialFilterA;
      initialFilterB =
        profitByCategoryCompareFilter ||
        initialFilterB;
      break;
    case "Top 10 Product Name by Gross Profit":
      initialFilterA =
        profitByProductDateFilter ||
        initialFilterA;
      initialFilterB =
        profitByProductCompareFilter ||
        initialFilterB;
      break;
    case "Top 10 Brands by Gross Profit":
      initialFilterA =
        profitByBrandDateFilter ||
        initialFilterA;
      initialFilterB =
        profitByBrandCompareFilter ||
        initialFilterB;
      break;
    case "Top 10 Customers by Gross Profit":
      initialFilterA =
        profitByCustomerDateFilter ||
        initialFilterA;
      initialFilterB =
        profitByCustomerCompareFilter ||
        initialFilterB;
      break;
    case "Top 10 Customer Tag by Gross Profit":
      initialFilterA =
        profitByCustomerTagDateFilter ||
        initialFilterA;
      initialFilterB =
        profitByCustomerTagCompareFilter ||
        initialFilterB;
      break;
    case "Top 10 Product Tags by Gross Profit":
      initialFilterA =
        profitByProductTagDateFilter ||
        initialFilterA;
      initialFilterB =
        profitByProductTagCompareFilter ||
        initialFilterB;
      break;
    case "Profit By Product Type":
      initialFilterA =
        profitByProductTypeDateFilter?.toLowerCase().replace(" ", "_") ||
        initialFilterA;
      initialFilterB =
        profitByProductTypeCompareFilter?.toLowerCase().replace(" ", "_") ||
        initialFilterB;
      break;
    case "Top 10 Brands by Units Sold":
      initialFilterA =
        unitsSoldByBrandDateFilter ||
        initialFilterA;
      initialFilterB =
        unitsSoldByBrandCompareFilter ||
        initialFilterB;
      break;
    case "Top 10 Product Name by Units Sold":
      initialFilterA =
        unitsSoldByProductDateFilter ||
        initialFilterA;
      initialFilterB =
        unitsSoldByProductCompareFilter ||
        initialFilterB;
      break;
    case "Top 10 Categories by Units Sold":
      initialFilterA =
        unitsSoldByCategoryDateFilter ||
        initialFilterA;
      initialFilterB =
        unitsSoldByCategoryCompareFilter ||
        initialFilterB;
      break;
    case "Top 10 Customers by Units Sold":
      initialFilterA =
        unitsSoldByCustomerDateFilter ||
        initialFilterA;
      initialFilterB =
        unitsSoldByCustomerCompareFilter ||
        initialFilterB;
      break;
    case "Top 10 Product Tags by Units Sold":
      initialFilterA =
        unitsSoldByProductTagDateFilter ||
        initialFilterA;
      initialFilterB =
        unitsSoldByProductTagCompareFilter ||
        initialFilterB;
      break;
    case "Units Sold by Product Type":
      initialFilterA =
        unitsSoldByProductTypeDateFilter?.toLowerCase().replace(" ", "_") ||
        initialFilterA;
      initialFilterB =
        unitsSoldByProductTypeCompareFilter?.toLowerCase().replace(" ", "_") ||
        initialFilterB;
      break;
    case "Top 10 Customer Tag by Units Sold":
      initialFilterA =
        unitsSoldByCustomerTagDateFilter ||
        initialFilterA;
      initialFilterB =
        unitsSoldByCustomerTagCompareFilter ||
        initialFilterB;
      break;
    case "Top 10 Brands by Units Shipped":
      initialFilterA =
        unitsShippedByBrandDateFilter ||
        initialFilterA;
      initialFilterB =
        unitsShippedByBrandCompareFilter ||
        initialFilterB;
      break;
    case "Top 10 Product Name by Units Shipped":
      initialFilterA =
        unitsShippedByProductDateFilter ||
        initialFilterA;
      initialFilterB =
        unitsShippedByProductCompareFilter ||
        initialFilterB;
      break;
    case "Top 10 Categories by Units Shipped":
      initialFilterA =
        unitsShippedByCategoryDateFilter ||
        initialFilterA;
      initialFilterB =
        unitsShippedByCategoryCompareFilter ||
        initialFilterB;
      break;
    case "Top 10 Customers by Units Shipped":
      initialFilterA =
        unitsShippedByCustomerDateFilter ||
        initialFilterA;
      initialFilterB =
        unitsShippedByCustomerCompareFilter ||
        initialFilterB;
      break;
    case "Top 10 Product Tags by Units Shipped":
      initialFilterA =
        unitsShippedByProductTagDateFilter ||
        initialFilterA;
      initialFilterB =
        unitsShippedByProductTagCompareFilter || initialFilterB;
      break;
    case "Units Shipped by Product Type":
      initialFilterA =
        unitsShippedByProductTypeDateFilter?.toLowerCase().replace(" ", "_") ||
        initialFilterA;
      initialFilterB =
        unitsShippedByProductTypeCompareFilter?.toLowerCase().replace(" ", "_") || initialFilterB;
      break;
    case "Top 10 Customer Tag by Units Shipped":
      initialFilterA =
        unitsShippedByCustomerTagDateFilter ||
        initialFilterA;
      initialFilterB =
        unitsShippedByCustomerTagCompareFilter || initialFilterB;
      break;
    default:
      break;
  }

  const [filterA, setFilterA] = useState(initialFilterA);
  const [filterB, setFilterB] = useState(initialFilterB);

  const [filteredText, setFilteredText] = useState("Select Filters");
  
  useEffect(() => {
    let newFilteredText = "";
    switch (`${filterA}_${filterB}`) {
      case "This Week_Previous Period":
        newFilteredText = formatDate(thisWeekStartDate);
        break;
      case "Last Week_Previous Period":
        newFilteredText = formatDate(lastWeekStartDate);
        break;
      case "This Month_Previous Period":
        newFilteredText = formatDate(thisMonthStartDate);
        break;
      case "Last Month_Previous Period":
        newFilteredText = formatDate(lastMonthStartDate);
        break;
      case "This Quarter_Previous Period":
        newFilteredText =
          formatDate(thisQuarterStartDate) +
          "-" +
          formatDate(thisQuarterEndDate);
        break;
      case "Last Quarter_Previous Period":
        newFilteredText =
          formatDate(lastQuarterStartDate) +
          "-" +
          formatDate(lastQuarterEndDate);
        break;
      case "This Year_Previous Period":
        newFilteredText =
          formatDate(thisYearStartDate) + "-" + formatDate(thisYearEndDate);
        break;
      case "Last Year_Previous Period":
        newFilteredText =
          formatDate(lastYearStartDate) + "-" + formatDate(lastYearEndDate);
        break;
      case "This Week_Previous Year":
        newFilteredText =
          formatDate(thisWeekStartDate) + "-" + formatDate(thisWeekEndDate);
        break;
      case "Last Week_Previous Year":
        // text = `Filtered text based on ${filterA} and ${filterB}`;
        newFilteredText =
          formatDate(lastWeekStartDate) + "-" + formatDate(lastWeekEndDate);
        break;
      case "This Month_Previous Year":
        newFilteredText =
          formatDate(thisMonthStartDate) + " - " + formatDate(thisMonthEndDate);
        break;
      case "Last Month_Previous Year":
        newFilteredText =
          formatDate(lastMonthStartDate) + " - " + formatDate(lastMonthEndDate);
        break;
      case "This Quarter_Previous Year":
        newFilteredText =
          formatDate(thisQuarterStartDate) +
          "-" +
          formatDate(thisQuarterEndDate);
        break;
      case "Last Quarter_Previous Year":
        newFilteredText =
          formatDate(lastQuarterStartDate) +
          "-" +
          formatDate(lastQuarterEndDate);
        break;
      case "This Year_Previous Year":
        newFilteredText =
          formatDate(thisYearStartDate) + "-" + formatDate(thisYearEndDate);
        break;
      case "Last Year_Previous Year":
        // text = `Filtered text based on ${filterA} and ${filterB}`;
        newFilteredText =
          formatDate(lastYearStartDate) + "-" + formatDate(lastYearEndDate);
        break;

      default:
        newFilteredText =
          formatDate(thisMonthStartDate) + " - " + formatDate(thisMonthEndDate);
    }
    setFilteredText(newFilteredText);
  }, [filterA, filterB]);
  
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleFilterChange = (filterType, value) => {
    if (filterType === "filterA") {
      setFilterA(value);
    } else if (filterType === "filterB") {
      setFilterB(value);
    }
  };

  const options = {
    maintainAspectRatio: false,
    aspectRatio: 2, // You can adjust this value based on your preference
    indexAxis: "y",
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: title,
      },
      tooltip: {
        mode: 'index',
        callbacks: {
          label: function (tooltipItem) {
            const datasetLabel = tooltipItem.dataset.label || '';
            const value = tooltipItem.raw.toFixed(2);
            const formattedValue = new Intl.NumberFormat('en-US', {
              style: 'decimal',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }).format(value);
            return ` ${datasetLabel}:  ${currencySymbol}${formattedValue}`;
          }
        }
      }
    },
    scales: {
      x: {
        display: true,
        grid: {
          display: true,
        },
        ticks: {
          callback: function (value) {
            const isNegative = value < 0;
            const absValue = Math.abs(value);
            let formattedValue;
            if (absValue >= 1e9) {
              formattedValue = (absValue / 1e9) + 'B'; // Billion
            } else if (absValue >= 1e6) {
              formattedValue = (absValue / 1e6) + 'M'; // Million
            } else if (absValue >= 1e3) {
              formattedValue = (absValue / 1e3) + 'K'; // Thousand
            } else {
              formattedValue = absValue; // Less than thousand
            }

            return isNegative ? `-${formattedValue}` : formattedValue; 
          }
        }
      },
      y: {
        display: true,
        grid: {
          display: true,
        },
      },
    },
  };

  // Get the current date
  const currentDate = new Date();
  function formatDate(date) {
    const options = { month: "short", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }
  // Get the current day of the week (0 is Sunday, 1 is Monday, ..., 6 is Saturday)
  const currentDayOfWeek = currentDate.getDay();

  // Calculate the difference in days between the current day and Sunday (assuming Sunday is the first day of the week)
  const daysUntilSunday = currentDayOfWeek === 0 ? 0 : 7 - currentDayOfWeek;

  // Calculate the start date of the current week (Sunday)
  const thisWeekStartDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - daysUntilSunday
  );

  // Calculate the end date of the current week (Saturday)
  const thisWeekEndDate = new Date(
    thisWeekStartDate.getFullYear(),
    thisWeekStartDate.getMonth(),
    thisWeekStartDate.getDate() + 6
  );

  // Calculate the start date of the previous week (7 days before the start of the current week)
  const lastWeekStartDate = new Date(
    thisWeekStartDate.getFullYear(),
    thisWeekStartDate.getMonth(),
    thisWeekStartDate.getDate() - 7
  );

  // Calculate the end date of the previous week (6 days before the start of the current week)
  const lastWeekEndDate = new Date(
    thisWeekStartDate.getFullYear(),
    thisWeekStartDate.getMonth(),
    thisWeekStartDate.getDate() - 1
  );

  // Calculate dates for this month
  const thisMonthStartDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const thisMonthEndDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );

  // Calculate dates for last month
  const lastMonthStartDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 1,
    1
  );
  const lastMonthEndDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    0
  );

  // Calculate dates for this quarter
  const currentQuarter = Math.floor(currentDate.getMonth() / 3);
  const thisQuarterStartDate = new Date(
    currentDate.getFullYear(),
    currentQuarter * 3,
    1
  );
  const thisQuarterEndDate = new Date(
    currentDate.getFullYear(),
    (currentQuarter + 1) * 3,
    0
  );

  // Calculate dates for last quarter
  const lastQuarterStartDate = new Date(
    currentDate.getFullYear(),
    (currentQuarter - 1) * 3,
    1
  );
  const lastQuarterEndDate = new Date(
    currentDate.getFullYear(),
    currentQuarter * 3,
    0
  );

  // Calculate dates for this year
  const thisYearStartDate = new Date(currentDate.getFullYear(), 0, 1);
  const thisYearEndDate = new Date(currentDate.getFullYear(), 11, 31);

  // Calculate dates for last year
  const lastYearStartDate = new Date(currentDate.getFullYear() - 1, 0, 1);
  const lastYearEndDate = new Date(currentDate.getFullYear() - 1, 11, 31);

  const handleGoButtonClick = () => {
    let text = "";
    switch (`${filterA}_${filterB}`) {
      case "This Week_Previous Period":
        text = formatDate(thisWeekStartDate);
        break;
      case "Last Week_Previous Period":
        text = formatDate(lastWeekStartDate);
        break;
      case "This Month_Previous Period":
        text = formatDate(thisMonthStartDate);
        break;
      case "Last Month_Previous Period":
        text = formatDate(lastMonthStartDate);
        break;
      case "This Quarter_Previous Period":
        text =
          formatDate(thisQuarterStartDate) +
          "-" +
          formatDate(thisQuarterEndDate);
        break;
      case "Last Quarter_Previous Period":
        text =
          formatDate(lastQuarterStartDate) +
          "-" +
          formatDate(lastQuarterEndDate);
        break;
      case "This Year_Previous Period":
        text =
          formatDate(thisYearStartDate) + "-" + formatDate(thisYearEndDate);
        break;
      case "Last Year_Previous Period":
        text =
          formatDate(lastYearStartDate) + "-" + formatDate(lastYearEndDate);
        break;
      case "This Week_Previous Year":
        text =
          formatDate(thisWeekStartDate) + "-" + formatDate(thisWeekEndDate);
        break;
      case "Last Week_Previous Year":
        // text = `Filtered text based on ${filterA} and ${filterB}`;
        text =
          formatDate(lastWeekStartDate) + "-" + formatDate(lastWeekEndDate);
        break;
      case "This Month_Previous Year":
        text =
          formatDate(thisMonthStartDate) + " - " + formatDate(thisMonthEndDate);
        break;
      case "Last Month_Previous Year":
        text =
          formatDate(lastMonthStartDate) + " - " + formatDate(lastMonthEndDate);
        break;
      case "This Quarter_Previous Year":
        text =
          formatDate(thisQuarterStartDate) +
          "-" +
          formatDate(thisQuarterEndDate);
        break;
      case "Last Quarter_Previous Year":
        text =
          formatDate(lastQuarterStartDate) +
          "-" +
          formatDate(lastQuarterEndDate);
        break;
      case "This Year_Previous Year":
        text =
          formatDate(thisYearStartDate) + "-" + formatDate(thisYearEndDate);
        break;
      case "Last Year_Previous Year":
        // text = `Filtered text based on ${filterA} and ${filterB}`;
        text =
          formatDate(lastYearStartDate) + "-" + formatDate(lastYearEndDate);
        break;

      default:
        text =
          formatDate(thisMonthStartDate) + " - " + formatDate(thisMonthEndDate);
    }
    setFilteredText(text);
    if (title === "Top 10 Brands by Net Sales") {
      dispatch(updateDearSalesByBrandDateFilter({ dateFilter: filterA }));
      dispatch(
        updateDearSalesByBrandComparisonFilter({ comparisonFilter: filterB })
      );
    }
    if (title === "Top 10 Product Name by Net Sales") {
      dispatch(updateDearSalesByProductDateFilter({ dateFilter: filterA }));
      dispatch(
        updateDearSalesByProductComparisonFilter({ comparisonFilter: filterB })
      );
    }
    if (title === "Top 10 Categories by Net Sales") {
      dispatch(updateDearSalesByCategoryDateFilter({ dateFilter: filterA }));
      dispatch(
        updateDearSalesByCategoryComparisonFilter({ comparisonFilter: filterB })
      );
    }
    if (title === "Top 10 Customers by Net Sales") {
      dispatch(updateDearSalesByCustomerDateFilter({ dateFilter: filterA }));
      dispatch(
        updateDearSalesByCustomerComparisonFilter({ comparisonFilter: filterB })
      );
    }
    if (title === "Top 10 Product Tags by Net Sales") {
      dispatch(updateDearSalesByProductTagDateFilter({ dateFilter: filterA }));
      dispatch(
        updateDearSalesByProductTagComparisonFilter({
          comparisonFilter: filterB,
        })
      );
    }
    if (title === "Sales by Product Type") {
      dispatch(updateDearSalesByProductTypeDateFilter({ dateFilter: filterA }));
      dispatch(
        updateDearSalesByProductTypeComparisonFilter({
          comparisonFilter: filterB,
        })
      );
    }
    if (title === "Top 10 Customer Tag by Net Sales") {
      dispatch(updateDearSalesByCustomerTagDateFilter({ dateFilter: filterA }));
      dispatch(
        updateDearSalesByCustomerTagComparisonFilter({
          comparisonFilter: filterB,
        })
      );
    }
    if (title === "Top 10 Categories by Gross Profit") {
      dispatch(updateDearProfitByCategoryDateFilter({ dateFilter: filterA }));
      dispatch(
        updateDearProfitByCategoryComparisonFilter({
          comparisonFilter: filterB,
        })
      );
    }
    if (title === "Top 10 Product Name by Gross Profit") {
      dispatch(updateDearProfitByProductDateFilter({ dateFilter: filterA }));
      dispatch(
        updateDearProfitByProductComparisonFilter({ comparisonFilter: filterB })
      );
    }
    if (title === "Top 10 Brands by Gross Profit") {
      dispatch(updateDearProfitByBrandDateFilter({ dateFilter: filterA }));
      dispatch(
        updateDearProfitByBrandComparisonFilter({ comparisonFilter: filterB })
      );
    }
    if (title === "Top 10 Customers by Gross Profit") {
      dispatch(updateDearProfitByCustomerDateFilter({ dateFilter: filterA }));
      dispatch(
        updateDearProfitByCustomerComparisonFilter({
          comparisonFilter: filterB,
        })
      );
    }
    if (title === "Top 10 Customer Tag by Gross Profit") {
      dispatch(
        updateDearProfitByCustomerTagDateFilter({ dateFilter: filterA })
      );
      dispatch(
        updateDearProfitByCustomerTagComparisonFilter({
          comparisonFilter: filterB,
        })
      );
    }
    if (title === "Top 10 Product Tags by Gross Profit") {
      dispatch(updateDearProfitByProductTagDateFilter({ dateFilter: filterA }));
      dispatch(
        updateDearProfitByProductTagComparisonFilter({
          comparisonFilter: filterB,
        })
      );
    }
    if (title === "Profit By Product Type") {
      dispatch(
        updateDearProfitByProductTypeDateFilter({ dateFilter: filterA })
      );
      dispatch(
        updateDearProfitByProductTypeComparisonFilter({
          comparisonFilter: filterB,
        })
      );
    }

    if (title === "Top 10 Brands by Units Sold") {
      dispatch(updateDearUnitsSoldByBrandDateFilter({ dateFilter: filterA }));
      dispatch(
        updateDearUnitsSoldByBrandComparisonFilter({
          comparisonFilter: filterB,
        })
      );
    }
    if (title === "Top 10 Product Name by Units Sold") {
      dispatch(updateDearUnitsSoldByProductDateFilter({ dateFilter: filterA }));
      dispatch(
        updateDearUnitsSoldByProductComparisonFilter({
          comparisonFilter: filterB,
        })
      );
    }
    if (title === "Top 10 Categories by Units Sold") {
      dispatch(
        updateDearUnitsSoldByCategoryDateFilter({ dateFilter: filterA })
      );
      dispatch(
        updateDearUnitsSoldByCategoryComparisonFilter({
          comparisonFilter: filterB,
        })
      );
    }
    if (title === "Top 10 Customers by Units Sold") {
      dispatch(
        updateDearUnitsSoldByCustomerDateFilter({ dateFilter: filterA })
      );
      dispatch(
        updateDearUnitsSoldByCustomerComparisonFilter({
          comparisonFilter: filterB,
        })
      );
    }
    if (title === "Top 10 Product Tags by Units Sold") {
      dispatch(
        updateDearUnitsSoldByProductTagDateFilter({ dateFilter: filterA })
      );
      dispatch(
        updateDearUnitsSoldByProductTagComparisonFilter({
          comparisonFilter: filterB,
        })
      );
    }
    if (title === "Units Sold by Product Type") {
      dispatch(
        updateDearUnitsSoldByProductTypeDateFilter({ dateFilter: filterA })
      );
      dispatch(
        updateDearUnitsSoldByProductTypeComparisonFilter({
          comparisonFilter: filterB,
        })
      );
    }
    if (title === "Top 10 Customer Tag by Units Sold") {
      dispatch(
        updateDearUnitsSoldByCustomerTagDateFilter({ dateFilter: filterA })
      );
      dispatch(
        updateDearUnitsSoldByCustomerTagComparisonFilter({
          comparisonFilter: filterB,
        })
      );
    }
    // Units Shipped
    if (title === "Top 10 Brands by Units Shipped") {
      dispatch(
        updateDearUnitsShippedByBrandDateFilter({ dateFilter: filterA })
      );
      dispatch(
        updateDearUnitsShippedByBrandComparisonFilter({
          comparisonFilter: filterB,
        })
      );
    }
    if (title === "Top 10 Product Name by Units Shipped") {
      dispatch(
        updateDearUnitsShippedByProductDateFilter({ dateFilter: filterA })
      );
      dispatch(
        updateDearUnitsShippedByProductComparisonFilter({
          comparisonFilter: filterB,
        })
      );
    }
    if (title === "Top 10 Categories by Units Shipped") {
      dispatch(
        updateDearUnitsShippedByCategoryDateFilter({ dateFilter: filterA })
      );
      dispatch(
        updateDearUnitsShippedByCategoryComparisonFilter({
          comparisonFilter: filterB,
        })
      );
    }
    if (title === "Top 10 Customers by Units Shipped") {
      dispatch(
        updateDearUnitsShippedByCustomerDateFilter({ dateFilter: filterA })
      );
      dispatch(
        updateDearUnitsShippedByCustomerComparisonFilter({
          comparisonFilter: filterB,
        })
      );
    }
    if (title === "Top 10 Product Tags by Units Shipped") {
      dispatch(
        updateDearUnitsShippedByProductTagDateFilter({ dateFilter: filterA })
      );
      dispatch(
        updateDearUnitsShippedByProductTagComparisonFilter({
          comparisonFilter: filterB,
        })
      );
    }
    if (title === "Units Shipped by Product Type") {
      dispatch(
        updateDearUnitsShippedByProductTypeDateFilter({ dateFilter: filterA })
      );
      dispatch(
        updateDearUnitsShippedByProductTypeComparisonFilter({
          comparisonFilter: filterB,
        })
      );
    }
    if (title === "Top 10 Customer Tag by Units Shipped") {
      dispatch(
        updateDearUnitsShippedByCustomerTagDateFilter({ dateFilter: filterA })
      );
      dispatch(
        updateDearUnitsShippedByCustomerTagComparisonFilter({
          comparisonFilter: filterB,
        })
      );
    }

    setIsDropdownOpen(false);
  };
  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!dropdownRef?.current?.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [dropdownRef]);
  const filterOptions = [
    { label: "This Month", value: "This Month" },
    { label: "Last Month", value: "Last Month" },
    { label: "This Quarter", value: "This Quarter" },
    { label: "Last Quarter", value: "Last Quarter" },
    { label: "Last 365 Days", value: "Last 365 Days" },
    // { label: "Last Year", value: "Last Year" },
  ];
  const [toggleKPIDrillDownModal, setKPIDrillDownModal] = useState(false);
  const toggleDrillDownModalSheet = (title) => {
    setKPIDrillDownModal(!toggleKPIDrillDownModal);
  };
  
  const handleClick = () => {
    if (drilldownData && data.datasets.length > 0) {
      toggleDrillDownModalSheet();
    }
  };
  
  return (
    <div className="mt-5 w-full flex flex-col p-2 border rounded-lg border-light-grey bg-white">
      <div className="relative w-7/8 self-end text-left">
        <div>
          <span className="rounded-md z-10 shadow-sm">
            <button
              type="button"
              className="inline-flex capitalize justify-center text-[10px] w-full rounded-md border border-gray-300 bg-white px-3 py-1 font-medium text-gray-400 hover:bg-gray-50 focus:outline-none"
              id="options-menu"
              aria-haspopup="true"
              aria-expanded={isDropdownOpen ? "true" : "false"}
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              {filterA.replace("_", " ").replace(/^\w/, (c) => c.toUpperCase())}{" "}
              ({filteredText})
              <svg
                className="-mr-1 ml-2 h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M10 12a1 1 0 01-.7-.29l-4-4a1 1 0 111.41-1.42L10 9.59l3.29-3.3a1 1 0 111.42 1.42l-4 4a1 1 0 01-.71.29z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </span>
        </div>
        <div ref={dropdownRef}>
          {isDropdownOpen && (
            <div
              ref={dropdownRef}
              className="origin-top-right z-20 absolute text-gray-500 text-xs w-[155px] rounded-md shadow-md bg-white ring-1 ring-black ring-opacity-5"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <div className="flex gap-2 text-xs px-2 py-4 flex-col" role="none">
                <p className="font-semibold text-xs text-grey">
                  Current Period
                </p>
                {filterOptions.map((option) => (
                  <label key={option.value} className="inline-flex items-center">
                    <input
                      type="radio"
                      className="form-radio text-purple h-4 w-4"
                      name="filterA"
                      value={option.value}
                      checked={filterA === option.value}
                      onChange={() =>
                        handleFilterChange("filterA", option.value)
                      }
                    />
                    <span className="ml-2">{option.label}</span>
                  </label>
                ))}
                <hr className="my-1" />
                <p className="font-semibold text-xs text-grey">
                  Compare with
                </p>
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    className="form-radio text-purple h-4 w-4"
                    name="filterB"
                    value="Previous Period"
                    checked={filterB === "Previous Period"}
                    onChange={() =>
                      handleFilterChange("filterB", "Previous Period")
                    }
                  />
                  <span className="ml-2">Previous Period</span>
                </label>
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    className="form-radio text-purple h-4 w-4"
                    name="filterB"
                    value="Previous Year"
                    checked={filterB === "Previous Year"}
                    onChange={() =>
                      handleFilterChange("filterB", "Previous Year")
                    }
                  />
                  <span className="ml-2">Previous Year</span>
                </label>
              </div>
              <div className="flex justify-end px-2 py-2">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm py-1 bg-purple text-base font-medium text-white hover:bg-purple/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                  onClick={handleGoButtonClick}
                >
                  Apply
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className={`h-[550px] mt-5 ${drilldownData ? 'cursor-pointer' : ''}`}
        onClick={handleClick}
      >
        
        <KPIDrillDownModal
          title={title}
          drilldownTitle={drilldownTitle}
          drilldownTooltip={drilldownTooltip}
          drilldownType={drilldownType}
          toggle={toggleKPIDrillDownModal}
          toggleModal={toggleDrillDownModalSheet}
          kpiName={title}
          tableData={drilldownData}
        />
        <Bar data={data} options={options} />
      </div>
    </div>
  );
}

export default HorizontalBarChart;

import React from "react";
import { Form, Formik } from "formik";
import {
  IoIosCheckmarkCircle,
  IoIosCheckmarkCircleOutline,
} from "react-icons/io";
import QRCode from "../../../assets/images/security/qr-code.png";
import { RxSwitch } from "react-icons/rx";
import FormikControl from "../../components/form-elements/formik-control";
function UserProfilePage(props) {
  const {user}=props
  const initialValues = {};
  const onSubmit = () => {};
  return (
    <div className="w-full h-full flex  flex-col ">
      <div className=" flex flex-col border-2 w-full  p-4  border-light-grey">
        <div className="flex flex-row w-full justify-between">
          <p className="text-xl font-semibold">User Profile</p>
          <div className="relative">
            <button disabled className="px-4 py-2 rounded-sm bg-grey text-[12px] text-white">
              Edit Profile
            </button>
          </div>
        </div>
        {/* <p className="text-sm font-light mt-2">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p> */}
        <div className="flex flex-col gap-4  w-full mt-6">
          <div className="border-[1.5px]  h-full border-light-grey rounded-sm">
            <div className="w-full border-b-2 px-4 py-3 text-dark-purple font-medium border-b-light-grey">
              Personal Information
            </div>
            <div className="flex flex-row p-4 w-full justify-start gap-20">
              <div className="flex flex-col gap-5">
                <div>
                  <p className="text-[12px] text-grey font-regular">
                    First Name
                  </p>
                  <p className="text-sm font-light">{user.data?.name}</p>
                </div>

                <div>
                  <p className="text-[12px] text-grey font-regular">
                    User Bio
                  </p>
                  <p className="text-sm font-light">Company</p>
                </div>
              </div>
              <div className="flex flex-col gap-5">
                <div>
                  <p className="text-[12px] text-grey font-regular">
                    Email Address
                  </p>
                  <p className="text-sm font-light">{user.data?.email}</p>
                </div>
                <div>
                  <p className="text-[12px] text-grey font-regular">
                    Phone Number
                  </p>
                  <p className="text-sm font-light">---</p>
                </div>
              </div>
            </div>
          </div>
          <div className="border-[1.5px]  h-full border-light-grey rounded-sm">
            <div className="w-full border-b-2 px-4 py-3 text-dark-purple font-medium border-b-light-grey">
              Address Information
            </div>
            <div className="flex flex-row p-4 w-full justify-start gap-20">
              <div className="flex flex-col gap-5">
                <div>
                  <p className="text-[12px] text-grey font-regular">
                    Country
                  </p>
                  <p className="text-sm font-light">---</p>
                </div>
                <div>
                  <p className="text-[12px] text-grey font-regular">
                    Postal Code
                  </p>
                  <p className="text-sm font-light">---</p>
                </div>
              </div>
              <div className="flex flex-col gap-5">
                <div>
                  <p className="text-[12px] text-grey font-regular">
                    City/State
                  </p>
                  <p className="text-sm font-light">---</p>
                </div>
                <div>
                  <p className="text-[12px] text-grey font-regular">
                    Tax ID
                  </p>
                  <p className="text-sm font-light">---</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserProfilePage;

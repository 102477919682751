import React, { useState } from "react";
import { IoIosClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { disconnectXero } from "../../../../../redux-features/slices/integrations/integrationSlice";
import { CONNECT_XERO, ENV_URL } from "../../../../../constant";
import xeroWhite from '../../../../../assets/logos/xero_logo_white.png'
import { RiErrorWarningFill } from "react-icons/ri";

function XeroReauthrizeModal({ toggle, toggleModal, message }) {
  let organization = 'Organization not found';
  const { user } = useSelector((state) => state.auth);
  const token = user?.data?.api_token; 
  const [isIgnoreClicked, setIsIgnoreClicked] = useState(false);
  
  const orgName = message.organization?.org_name || 'Organization not found';
  const slug = message.organization?.org_slug || 'Slug not found';

  const dispatch = useDispatch();
  
  if (message.data && message.data.tenants && message.data.tenants.length > 0) {
    organization = message.data.tenants[0].organization;
  }
  
  const handleReconnect = () => {
    dispatch(disconnectXero({ slug, token }))
      .then(() => {
        window.location.href = `${ENV_URL}${CONNECT_XERO}${slug}/`;
      })
      .catch(error => {
        console.error("Error during disconnection:", error);
      });
  };

  const handleIgnore = () => {
    setIsIgnoreClicked(true);
  };

  const handleNo = () => {
    setIsIgnoreClicked(false);
  };

  return (
    <div className="fixed z-40 inset-0 w-full overflow-y-auto">
      <div className="flex items-center justify-center gap-6 bg-black bg-opacity-50 min-h-screen p-4">
        <div className="bg-white rounded-lg md:w-[500px] sm:w-3/5 w-4/5 p-8">
          <div className="flex flex-row justify-between items-start">
            <div className="flex gap-2">
            <RiErrorWarningFill size="44" className="text-red-500"  />
              <div className="mt-2">
                <h2 className="text-lg font-semibold">
                  {isIgnoreClicked
                    ? "Are you sure you don't want to Reauthorise your Xero Connection?"
                    : <>
                        Xero Connection has been Expired for {" "}
                        <span className="text-purple ">{orgName}</span>
                      </>}
                </h2>
              </div>
            </div>
            <IoIosClose
              onClick={toggleModal}
              className="cursor-pointer"
              size={35}
            />
          </div>
          <div className="mt-3 ml-9">
            <p className="text-grey">
              {isIgnoreClicked
                ? "If you do not reconnect, you will no longer be able to access your Xero data on Qurk."
                : "Please reconnect your Xero data to continue using Qurk's Xero Connection."}
            </p>
          </div>
          <div className="flex flex-row w-full justify-end">
            {isIgnoreClicked ? (
              <>
                <button
                  onClick={toggleModal}
                  className="flex mt-6 ml-2 p-1 items-center gap-2 justify-center border-2 border-red-400 bg-red-500 text-white hover:bg-red-400 text-md font-medium w-60 rounded-sm"
                >
                  Yes, disconnect my Xero
                </button>
                <button
                  onClick={handleNo}
                  className="flex mt-6 ml-2 p-1 items-center gap-2 justify-center border-2 border-light-grey hover:bg-gray-200 text-md font-medium w-36 rounded-sm"
                >
                  No, Reconnect
                </button>
              </>
            ) : (
              <>
                <button
                         onClick={handleReconnect}
                  className="flex mt-6 ml-2 p-1 items-center justify-center gap-2 border-2 border-purple hover:bg-light-purple hover:text-dark-purple bg-purple text-md text-xero-bg font-medium w-36 rounded"
                >
                  Reconnect
                </button>
                {/* <button
         className="flex mt-6 ml-2 items-center gap-2 border-2 border-light-grey bg-xero-text text-md text-xero-bg font-medium w-64 rounded"
       >
         <img src={xeroWhite} alt="" className="w-14 h-14" />
         Disconnect to Xero
       </button> */}
                <button
                  onClick={handleIgnore}
                  className="flex mt-6 ml-2 p-1 items-center gap-2 justify-center border-2 border-light-grey hover:bg-gray-200 text-md font-medium w-36 rounded-sm"
                >
                  Ignore
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default XeroReauthrizeModal;

import React, { useState } from 'react'
import Sidebar from '../../../../../navigation'
import OrganisationTable from '../../../../components/ui-elements/tables/organisation-table';
import { IoIosSearch } from "react-icons/io";
import OrganisationModal from '../../../../components/ui-elements/modals/organisation-modal';
import { Link } from 'react-router-dom';
import { BsQuestionCircle } from 'react-icons/bs';

function OrganisationAdvisor() {
    const [toggle, setToggle] = useState(false);

    const toggleModal = () => {
      setToggle(!toggle);
    };

  return (
    
      <div
        className={` flex flex-col w-full  justify-start items-start m-8 col-span-8`}
      >
        <div className="flex w-full  items-end justify-between mt-8">
          <div>
            <div className="relative">
              <h2 className="text-2xl font-medium ">Organisation Owner</h2>
            </div>
          </div>
          <div className="flex flex-row gap-2">
            <BsQuestionCircle className="  text-purple" size={30} />
          </div>
        </div>
        <div className="w-full p-8">
          <div className="flex w-full  items-end justify-between mt-12">
            <div>
              {/* <div className="relative">
              <input
                type="text"
                className="w-full  border-gray-200 border-0 outline-none border-grey border-b-2 pl-10 pr-3 py-2 focus:outline-none "
                placeholder="Search"
              />
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <IoIosSearch className="text-gray-400" />
              </div>
            </div> */}
            </div>
            <div className="flex flex-row gap-4">
              <button
                onClick={toggleModal}
                className="bg-grey hover:bg-light-grey text-dark-green font-bold py-2 px-4 rounded"
              >
                + Add new organisation
              </button>
              <button
                onClick={toggleModal}
                className="bg-grey hover:bg-light-grey text-dark-green font-bold py-2 px-4 rounded"
              >
                + Consolidate organisations
              </button>
              <OrganisationModal toggle={toggle} toggleModal={toggleModal} />
            </div>
          </div>
          <div className="w-full mt-10">
            <OrganisationTable />
          </div>
          <div className="flex w-full  items-end justify-end mt-8">
            <div className="flex flex-row gap-4 hover:text-primary hover:underline">
              <Link>Premium Subscription Activated</Link>
            </div>
          </div>
        </div>
      </div>
  );
}

export default OrganisationAdvisor
import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "./text-error";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "../../../custom-themes/tippy-tooltip/tippy-themes.css";
import {BsFillQuestionCircleFill} from 'react-icons/bs/index'
function Radio(props) {
  const { label, name, formType, options, ...rest } = props;
  const sign_up_control_style ="flex items-center justify-start   w-full flex-row gap-2 font-normal text-md";
  return (
    <div className="flex flex-col items-start   ">
      <div
        className={`form-control ${
          formType === "sign-up"
            ? "flex flex-row w-full items-center justify-end text-white"
            : ""
        }`}
      >
        <label>{label}</label>
        <Field name={name}>
          {({ field }) => {
            return options.map((option) => {
              return (
                <React.Fragment key={option.key}>
                  <div
                    className={`form-control  ${
                      formType === "sign-up" ? `${sign_up_control_style}` : ""
                    }`}
                  >
                    <input
                      type="radio"
                      id={option.value}
                      {...field}
                      {...rest}
                      value={option.value}
                      className=" p-2 focus:ring-0 focus:ring-offset-0 cursor-pointer "
                      checked={field.value === option.value}
                    />

                    <label className="text-sm " htmlFor={option.value}>
                      {option.key}
                    </label>
                    {formType==='invite-user' ? (
                      <p>
                        {option.description}
                      </p>
                    ): null}
                    {option.tooltip && formType === "sign-up" ? (
                      <Tippy
                        theme="default"
                        content={option.tooltip}
                        size="small"
                        placement="right"
                      >
                        <div>
                          <BsFillQuestionCircleFill
                            className=" ml-1 mt-1 text-default"
                            size={16}
                          />
                        </div>
                      </Tippy>
                    ) : null}
                  </div>
                </React.Fragment>
              );
            });
          }}
        </Field>
      </div>
      <div className=" mt-1">
        <ErrorMessage component={TextError} name={name} />
      </div>
    </div>
  );
}

export default Radio;



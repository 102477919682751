import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import FormikControl from "../../../components/form-elements/formik-control";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { SignInSchema } from "../../../../validation-schemas";
import {
  registerWithXero,
  resetAuth,
  resetXeroRedirect,
  signInUser,
} from "../../../../redux-features/slices/auth/authSlice";
import Spinner from "../../../components/ui-elements/utilis/spinner";
import { BsArrowDown } from "react-icons/bs";
import logo from "../../../../assets/logos/qurk_logo.png";
import icons from "../../../../assets/logos/group_icons_1.png";
import dashboard from "../../../../assets/images/auth/dashboard.png";
import { getAllOrganisations, selectOrganisation } from "../../../../redux-features/slices/organisation/orgSlice";
import { getDashboardDearData, getDashboardXeroData } from "../../../../redux-features/slices/kpi/kpiSlice";
import xerologo from '../../../../assets/logos/xero_logo.png';
import SpinnerKPI from "../../../components/ui-elements/utilis/spinner-kpi";
import { FaEye, FaEyeSlash } from "react-icons/fa";

function SignInScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, isLoading, isError, isSuccess, message, isLoadingXero, xeroRedirect, isSuccessXero } = useSelector(
    (state) => state.auth
  );
  const last_viewed = user?.data?.custom_fields?.last_viewed;
  const token = user?.data?.api_token;

  const [rememberMe, setRememberMe] = useState(false);
  const location = useLocation();
  const [status, setStatus] = useState("");
  const [app, setApp] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (isSuccessXero && xeroRedirect.redirect_url) {
      window.open(xeroRedirect.redirect_url);
      dispatch(resetXeroRedirect())
    }
  }, [isSuccessXero, xeroRedirect, dispatch]);



  const handleOrganisationDashboard = async () => {
    const slug = last_viewed?.slug;
    await dispatch(selectOrganisation({ slug, token }));
    dispatch(getDashboardXeroData({ slug, token }));
    const params = {
      brand: "",
      category: "",
      contact: "",
      product: "",
      productType: "",
      productTags: "",
      customerTags: "",
    };
    dispatch(getDashboardDearData({ slug, token, params }));
    dispatch(getAllOrganisations({ token }));
    navigate("/dashboard/home");
  };

  useEffect(() => {
    if (isError) {
      toast.error(message, {
        autoClose: 10000,
        hideProgressBar: true,
      });
    }
    if (isSuccess && user) {
      if (last_viewed?.redirect === true) {
        handleOrganisationDashboard();
      } else {
        navigate("/owner/organisation/single");
      }
    }
    dispatch(resetAuth());
  }, [user, isError, isSuccess, message, dispatch, navigate, last_viewed]);

  const handleCheckboxChange = () => {
    setRememberMe(!rememberMe);
  };

  const onSubmit = async (values, actions) => {
    const user_data = {
      email: values.email,
      password: values.password,
      rememberMe: rememberMe,
    };
    if (user_data) {
      storeCredentials(user_data);
      dispatch(signInUser(user_data));
    }
  };

  const storedCredentials = localStorage.getItem("loginCredentials");
  const initialValues = storedCredentials
    ? JSON.parse(storedCredentials)
    : {
      email: "",
      password: "",
    };

  const storeCredentials = (values) => {
    if (rememberMe) {
      localStorage.setItem("loginCredentials", JSON.stringify(values));
    } else {
      localStorage.removeItem("loginCredentials");
    }
  };

  const userLocal = JSON.parse(localStorage.getItem("user"));


  if (isLoading && userLocal) {
    return <Spinner />;
  }

  const handleSignUpWithXero = () => {
    dispatch(registerWithXero())
  }
  return (
    <div className="flex flex-col md:flex-row md:min-h-screen h-screen w-screen bg-dark-purple overflow-y-auto">
      <div
        className="w-full h-full lg:w-3/4"
        style={{
          background:
            "linear-gradient(0deg, rgba(32,32,53,1) 0%, rgba(46,28,90,1) 30%, rgba(91,72,135,1) 100%)",
        }}
      >
        <div className="flex flex-col w-full justify-start items-center">
          <div className="w-full h-full pt-3">
            <div className="flex justify-between pl-4">
              <img src={logo} alt="" className="w-12 h-12" />
              <div className="flex flex-row gap-2 items-center pt-4 pr-16 text-sm text-white">
                Don't have an account?{" "}
                <Link to="/">
                  <button className="rounded-full border-warning border-2 font-bold text-warning py-2 px-6">
                    Sign Up
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="flex flex-col h-full mt-28 w-1/2 justify-center items-start rounded-md">
            <div className="flex flex-row gap-2 items-center">
              <div className="">
                {/* <RiAccountPinCircleFill size="70px" className="text-primary" /> */}
              </div>
              <div className="flex flex-col text-white items-start">
                <h5 className="text-sm">Welcome back!</h5>
                <h2 className="text-2xl font-extrabold">
                  Sign into your account
                </h2>

              </div>

            </div>
            <div>

            </div>

            <div className="w-full h-full">
              <Formik
                className="sign-in form"
                initialValues={initialValues}
                validationSchema={SignInSchema}
                onSubmit={onSubmit}
              >
                {(formik) => (
                  <Form className="py-4 px-2">
                    {/* <p className="text-warning text-sm  text-center">
                  {app==="xero" && status==="success" ? (
<>Your default password is "pass=123". Please change it once you have signed in </>
                  ):status==="error"?(
                    <p className="text-danger">Oops! Signup with Xero failed</p>
                  ):(
                    <></>
                  )}
                </p> */}
                    <div className="flex flex-col items-start gap-4 mt-4 w-full">
                      <FormikControl
                        control="input"
                        type="email"
                        name="email"
                        label="Email Address"
                        placeholder="Enter email address"
                      />
                      <div className="relative w-full">
                        <FormikControl
                          control="input"
                          type={showPassword ? "text" : "password"}
                          name="password"
                          label="Password"
                          placeholder="Enter password"
                        />
                        <button
                          type="button"
                          onClick={() => setShowPassword(!showPassword)}
                          className="absolute top-4 right-1 pr-3"
                        >
                          {showPassword ? (
                            <FaEyeSlash size={20} className="text-gray-500" />
                          ) : (
                            <FaEye size={20} className="text-gray-500" />
                          )}
                        </button>

                      </div>

                      <div className="flex w-full items-center justify-between">
                        <div className="flex items-center">
                          <input
                            type="checkbox"
                            id="rememberMe"
                            className="form-checkbox h-4 w-4 text-warning rounded focus:ring-warning"
                            checked={rememberMe}
                            onChange={handleCheckboxChange}
                          />
                          <label
                            htmlFor="rememberMe"
                            className="ml-2 text-sm text-white"
                          >
                            Remember me {app}
                          </label>
                        </div>
                        <Link
                          className="text-warning text-sm underline font-medium justify-self-end"
                          to="/get-new-password"
                        >
                          Forgot Password?
                        </Link>
                      </div>
                    </div>
                    <div className="flex flex-col items justify-center w-full gap-6 mt-8">
                      <button
                        className="rounded-full bg-warning text-md font-bold text-dark-purple py-3 px-12"
                        type="submit"
                      >
                        Login
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="flex flex-col justify-center items-center w-full mt-6 gap-4">
              <p className="mb-4 text-white ">Or</p>
              {isLoadingXero ? (
                <SpinnerKPI />
              ) : (
                <button
                  onClick={handleSignUpWithXero}
                  className=" flex rounded-full justify-center items-center bg-white text-md text-xero-text text-md font-bold w-full py-2"
                >
                  <img src={xerologo} alt="" className="w-8 h-8 mx-4" />
                  Sign in with Xero
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/2 hidden lg:block h-full overflow-hidden">
        <div className="flex flex-col w-full h-full justify-start items-center bg-light-bg rounded-md px-4 py-20">
          <h5 className="text-md text-center text-dark-purple font-semibold">
            Integrate, consolidate and validate your data and build reports in
            minutes
          </h5>
          <img src={icons} className="w- h-52 mt-8" alt="" />
          <BsArrowDown size={32} className="text-purple" />
          <img src={dashboard} className="w-full max-w-sm" alt="" />
        </div>
      </div>
    </div>
  );
}

export default SignInScreen;

import React from 'react'
import RevenueTable from '../../components/ui-elements/tables/revenue-table';
import DirectCostsTable from '../../components/ui-elements/tables/direct-costs-table';

function DirectCostsComponent() {
  return (
    <div>
      {/* <LineGraph data={data} /> */}
      <div>
        <div className="flex flex-col gap-1 mt-6">
          <p className="font-bold">Direct Costs</p>
          <p className="text-sm">
            Here are your direct costs details:
          </p>
        </div>

        <div>
          <DirectCostsTable />
        </div>
      </div>
    </div>
  );
}

export default DirectCostsComponent
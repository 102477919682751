import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import subscriptionService from "./subscriptionService";

const initialState = {
  subscriptionPlans: [],
  paymentMethodId: {},
  subscription: {},
  payment_status:{},
  subscription_status: [],
  isSuccess: false,
  isSuccessEdit: false,
  isLoading: false,
  isLoadingAll: false,
  isError: false,
  isErrorCustomer: false,
  isErrorAll: false,
  message: "",
};


// Action Creators
export const getAllSubscriptionPlans = createAsyncThunk(
  "/plans/get/all",
  async ({token},thunkAPI) => {
    try {
      return await subscriptionService.getAllPlans(token)
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getCustomerSubscription = createAsyncThunk(
  "/plans/get",
  async ({ sub_data, token }, thunkAPI) => {
    try {
      return await subscriptionService.getCustomerSubscription(sub_data, token);
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const createSubscription = createAsyncThunk(
  "/plans/create",
  async ({ sub_data, token }, thunkAPI) => {
    try {
      return await subscriptionService.createSubscription(sub_data,token);
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const paySubscription = createAsyncThunk(
  "/plans/pay",
  async ({ sub_data, token }, thunkAPI) => {
    try {
      return await subscriptionService.paySubscription(sub_data,token);
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteSubscription = createAsyncThunk(
  "/plans/delete",
  async ({ token }, thunkAPI) => {
    try {
      return await subscriptionService.deleteSubscription(token);
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    resetSubscription: (state) => {
      state.isLoading = false;
      state.isLoadingAll = false;
      state.isSuccess = false;
      state.isSuccessEdit = false;
      state.isErrorAll = false;
      state.isError = false;
      state.message = "";
    },
    resetPaymentMethodId:(state, action)=>{
      state.paymentMethodId=''
    },
    setPaymentMethodId:(state, action)=>{
      state.paymentMethodId=action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllSubscriptionPlans.pending, (state) => {
        state.isLoadingAll = true;
      })
      .addCase(getAllSubscriptionPlans.fulfilled, (state, action) => {
        state.isLoadingAll = false;
        state.subscriptionPlans = [action.payload];
      })
      .addCase(getAllSubscriptionPlans.rejected, (state, action) => {
        state.isLoadingAll = false;
        state.isErrorAll = true;
        state.message = action.payload;
        state.subscriptionPlans = null;
      })
      .addCase(createSubscription.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createSubscription.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.subscription = action.payload;
      })
      .addCase(createSubscription.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.subscription = null;
      })
      .addCase(paySubscription.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(paySubscription.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.payment_status=action.payload
        state.isError = false;
      })
      .addCase(paySubscription.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getCustomerSubscription.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCustomerSubscription.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.subscription_status=action.payload
      })
      .addCase(getCustomerSubscription.rejected, (state, action) => {
        state.isLoading = false;
        state.isErrorCustomer = true;
        state.message = action.payload;
      });
  }
});
export default subscriptionSlice.reducer;
export const { resetSubscription, setPaymentMethodId, resetPaymentMethodId } =
  subscriptionSlice.actions;
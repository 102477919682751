import React, { useState } from "react";
import { IoMdMail } from "react-icons/io";

import { FiMail } from "react-icons/fi";
import { ImMail4 } from "react-icons/im";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import logo from "../../../../assets/logos/qurk_logo.png";

function VerifyEmailScreen() {
   const { user } = useSelector(
     (state) => state.auth
   );
  return (
    <div
      className="h-screen flex flex-col justify-between"
      style={{
        background:
          "linear-gradient(0deg, rgba(32,32,53,1) 0%, rgba(46,28,90,1) 30%, rgba(91,72,135,1) 100%)",
      }}
    >
      <div className=" text-white py-4 flex justify-between items-between">
        <div className="w-full h-full   ">
          <div className="flex  justify-between pl-4 ">
            <img src={logo} alt="" className="w-12 h-12 " />
            <div className="  justify-self-end pt-4 pr-16  ">
              <Link to="/customer-support">
                <h3 className="text-md font-regular text-white">Help</h3>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className=" text-white py-24 h-full  flex flex-col items-center justify-start gap-4">
        <div className="">
          <IoMdMail
            size="120px"
            className="text-warning bg-white p-6 rounded-full"
          />
        </div>
        <h2 className="text-2xl font-bold text-white">Verify your email</h2>
        <h5 className="text-white text-center font-light text-sm w-1/3">
          We have send verification link to your email. Click on the link to
          verify your account. The link is valid for 5 minutes
        </h5>
      </div>
      <div className="  py-4 flex flex-col items-center justify-center gap-4 text-white mb-6 ">
        <Link to="/customer-support">
          <button className="rounded-md">
            Having any issues?{" "}
            <span className="font-bold underline text-warning">
              Customer Support
            </span>
          </button>
        </Link>
      </div>
    </div>
  );
}

export default VerifyEmailScreen;

import React from "react";
import Sidebar from "../navigation";

const Layout = ({ children }) => {
  return (
    <div className="h-screen w-screen flex flex-row">
      <div className="bg-dark-purple" style={{ flex: "0 0 50px" }}>
        <Sidebar />
      </div>
      <div className="flex-grow h-screen bg-light-white overflow-auto">
        {children}
      </div>
    </div>
  );
};

export default Layout;

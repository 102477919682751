import BoxCard02WithFilter from "../../../../components/ui-elements/cards/box-card-02-with-custom-filter";

const CategoryComponent = ({
  categoryName,
  products,
  productSalesData,
  inventoryValueData,
  grossProfitMarginData,
  openKPIAdvancedFilters,
  productSalesFilters,
  grossProfitMarginFilters,
  toggleAdvancedFilters,
  toggleKPISheet,
  inventoryValueFilters,
  isLoadingProductSalesCategory,
  isLoadingInventoryValueCategory,
  isLoadingGrossProfitMarginCategory
}) => (
  <div className="flex flex-col gap-2">
    <p className="text-xl text-gray-700 font-bold">{categoryName}</p>
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
      <div className="rounded-md">
        <BoxCard02WithFilter
        isLoading={isLoadingProductSalesCategory}
          isAdvancedDearFilters={openKPIAdvancedFilters[productSalesData?.results?.[0]?.kpi || 'Product Sales']}
          categoryFilter={categoryName}
          products={products}
          setIsAdvancedDearFilters={(value) =>
            toggleAdvancedFilters(productSalesData?.results?.[0]?.kpi || 'Product Sales', value)
          }
          isCustomSymbol={false}
          toggleKPISheet={() => toggleKPISheet(productSalesData?.results?.[0]?.kpi || 'Product Sales')}
          title={productSalesData?.results?.[0]?.kpi || 'Product Sales'}
            tooltip={productSalesData?.results?.[0]?.description || 'The total sum of product lines in sales orders minus credit notes, not including tax in Cin7 Core'}
            data={
              productSalesData &&
                productSalesData.results &&
                productSalesData.results?.[0]?.data?.length > 0
                ? productSalesData.results?.[0]?.data[0]
                : null
            }
          savedFilterA={productSalesFilters.firstFilter}
          savedFilterB={productSalesFilters.comparedTo}
        />
      </div>
      <div className="rounded-md">
        <BoxCard02WithFilter
        isLoading={isLoadingGrossProfitMarginCategory}
          isAdvancedDearFilters={openKPIAdvancedFilters[grossProfitMarginData?.results?.[0]?.kpi || 'Gross Profit Margin']}
          categoryFilter={categoryName}
          setIsAdvancedDearFilters={(value) =>
            toggleAdvancedFilters(grossProfitMarginData?.results?.[0]?.kpi || 'Gross Profit Margin', value)
          }
          isCustomSymbol={true}
          products={products}
          customSymbol="%"
          toggleKPISheet={() => toggleKPISheet(grossProfitMarginData?.results?.[0]?.kpi || 'Gross Profit Margin')}
          title={ grossProfitMarginData?.results?.[0]?.kpi || 'Gross Profit Margin'}
          tooltip={ grossProfitMarginData?.results?.[0]?.description || 'Gross profit divided by total revenue in Cin7 Core'}
          data={
             grossProfitMarginData &&
               grossProfitMarginData.results &&
               grossProfitMarginData.results?.[0]?.data?.length > 0
              ?  grossProfitMarginData.results?.[0]?.data[0]
              : null
          }
          savedFilterA={grossProfitMarginFilters.firstFilter}
          savedFilterB={grossProfitMarginFilters.comparedTo}
        />
      </div>
      
      <div className="rounded-md">
        <BoxCard02WithFilter
        isLoading={isLoadingInventoryValueCategory}
          isAdvancedDearFilters={openKPIAdvancedFilters[inventoryValueData?.results?.[0]?.kpi || 'Inventory Value']}
          categoryFilter={categoryName}
          setIsAdvancedDearFilters={(value) =>
            toggleAdvancedFilters(inventoryValueData?.results?.[0]?.kpi || 'Inventory Value', value)
          }
          isCustomSymbol={true}
          products={products}
          customSymbol=" "
          toggleKPISheet={() => toggleKPISheet(inventoryValueData?.results?.[0]?.kpi || 'Inventory Value')}
          title={inventoryValueData?.results?.[0]?.kpi || 'Inventory Value'}
            tooltip={inventoryValueData?.results?.[0]?.description || 'Sum of stock on hand value of products in Cin7 Core'}
          data={
            inventoryValueData && inventoryValueData.results?.[0]?.data[0] 
              ? inventoryValueData.results?.[0]?.data[0]?.total_stock_value
              : null
          }
          savedFilterA={inventoryValueFilters.firstFilter}
          savedFilterB={inventoryValueFilters.comparedTo}
        />
      </div>
    </div>
  </div>
);

export default CategoryComponent;

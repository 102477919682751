
import React, { useEffect } from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import TextError from "./text-error";

function Select(props) {
  const { label, name, options, ...rest } = props;
  const { setFieldValue } = useFormikContext();
  const [selectedOption, setSelectedOption] = React.useState(props.value || "");

  useEffect(() => {
    setFieldValue(name, selectedOption);
  }, [selectedOption, setFieldValue, name]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div className="form-control w-full relative">
      <Field
        as="select"
        className="peer h-10 pt-7 pb-4 w-full border-1.5 rounded-md text-[12px] focus:text-[15px] border-default text-gray-600 placeholder-transparent focus:outline-none"
        id={name}
        name={name}
        {...rest}
        autoComplete="off"
        onChange={handleOptionChange}
        value={selectedOption}
      >
        <option value="" disabled hidden>
          Select an option
        </option>
        {options.map((option) => (
          <option
            key={option.key}
            value={option.value}
            className="text-black font-medium  hover:bg-gray-200 rounded-md px-3 py-2"
          >
            {option.key}
          </option>
        ))}
      </Field>
      <label
        htmlFor={name}
        className="absolute pt-1 left-3 top-5 font-light text-gray-700 text-[12px] transition-all"
        style={{
          transform: `translateY(${
            selectedOption ? "-20px" : "calc(-50% + 2px)"
          })`,
          fontSize: selectedOption ? "12px" : "12px",
          fontWeight: selectedOption ? "700" : "600",
        }}
      >
        {label}
      </label>
      <label
        htmlFor={name}
        className={`absolute pt-1 left-3 bottom-3 font-light text-grey-700 text-${
          selectedOption ? "xs" : "base"
        } transition-all ${selectedOption ? " text-gray-700 font-medium" : ""}`}
      >
        {selectedOption
          ? options.find((option) => option.value === selectedOption).key
          : ""}
      </label>
        <ErrorMessage component={TextError} name={name} />
    </div>
  );
}

export default Select;

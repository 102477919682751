import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { Field, ErrorMessage } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import TextError from "./text-error";

function MonthPicker(props) {
  const { label, name, ...rest } = props;
  const [isFocused, setIsFocused] = useState(false);
  const currentYear = new Date().getFullYear(); // Get the current year
  const minYear = currentYear;
  const maxYear = 2024; // Set the maximum year you want to display (e.g., 2024)

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const getLastDayOfMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  return (
    <div className="form-control w-full relative">
      <Field name={name}>
        {({ form, field }) => {
          const { setFieldValue } = form;
          const { value } = field;

          const handleChange = (date) => {
            const lastDayOfMonth = getLastDayOfMonth(
              date.getFullYear(),
              date.getMonth()
            );
            const formattedDate = new Date(
              date.getFullYear(),
              date.getMonth(),
              lastDayOfMonth
            );
            setFieldValue(name, formattedDate);
          };

          return (
            <DatePicker
              id={name}
              {...field}
              {...rest}
              selected={value ? new Date(value) : null}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              showMonthYearPicker
              dateFormat="dd/MM/yyyy"
              minDate={new Date(minYear, 0)}
              maxDate={new Date(maxYear, 11, 31)} // Use 31 to set the maximum date to the end of December
              className="peer h-10 pt-8 pb-4 w-full border-1.5 rounded-md text-[12px] focus:text-[15px] border-default text-gray-600 placeholder-transparent focus:outline-none"
            />
          );
        }}
      </Field>
      <label
        htmlFor={name}
        className={`absolute pt-1 left-3 ${
          isFocused ? "top-0.5" : "top-3"
        } pb-4 font-semibold text-gray-700 text-[12px] transition-all  peer-placeholder-shown:text-gray-900  peer-placeholder-shown:font-semibold peer-placeholder-shown:top-3 peer-focus:top-0.5 peer-focus:text-gray-900 peer-focus:font-semibold peer-focus:text-[10px]`}
      >
        {label}
      </label>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
}

export default MonthPicker;
